import { createApp } from 'vue'
import App from './App.vue'
import "@popperjs/core";
import "../node_modules/bootstrap/dist/js/bootstrap";
import './registerServiceWorker'
import router from './router'
import store from './store'
import "@/extensions/date.extension";
import "@/extensions/string.extension";
import "@/extensions/number.extension";
import JMCalendar from "@/components/JMCalendar.vue";
import JMSort from "@/components/JMSort.vue";
import JMMonth from "@/components/JMMonth.vue";
import JMTablePaginate from "@/components/JMTablePaginate.vue";
import JMEntriesItem from "@/components/JMEntriesItem.vue";
import JMUploads from "@/components/JMUploads.vue";
import JMUpload from "@/components/JMUpload.vue";
import NoAccess from "@/components/NoAccess.vue";
import JMGantt from "@/components/JMGantt.vue";

const app = createApp(App)
  .use(store)
  .use(router);

app.component("JMTablePaginate", JMTablePaginate);
app.component("JMEntriesItem", JMEntriesItem);
app.component("JMCalendar", JMCalendar);
app.component("JMMonth", JMMonth);
app.component("JMSort", JMSort);
app.component("JMUploads", JMUploads);
app.component("JMUpload", JMUpload);
app.component("NoAccess", NoAccess);
app.component("JMGantt", JMGantt);

app.mount("#app");