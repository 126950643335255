import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "rounded shadow bg-white pb-3" }
const _hoisted_2 = { class: "d-flex justify-content-between px-4 py-3 mt-5" }
const _hoisted_3 = { class: "d-flex align-items-center gap-2" }
const _hoisted_4 = { class: "table main-table" }
const _hoisted_5 = { class: "text-primary fw-bold" }
const _hoisted_6 = { class: "text-muted" }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JMEntriesItem = _resolveComponent("JMEntriesItem")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_JMSort = _resolveComponent("JMSort")!
  const _component_JMTablePaginate = _resolveComponent("JMTablePaginate")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[36] || (_cache[36] = _createElementVNode("h2", null, "Small Tool", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[24] || (_cache[24] = _createElementVNode("span", null, "Entries", -1)),
          _withDirectives(_createElementVNode("select", {
            class: "form-select",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.show) = $event)),
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fetchData(),_ctx.clearPage()))
          }, [
            _createVNode(_component_JMEntriesItem)
          ], 544), [
            [_vModelSelect, _ctx.show]
          ]),
          _createVNode(_component_router_link, {
            class: "btn btn-primary",
            to: "/small-tool/form"
          }, {
            default: _withCtx(() => _cache[21] || (_cache[21] = [
              _createElementVNode("div", { class: "text-nowrap text-white" }, "Add Record", -1)
            ])),
            _: 1
          }),
          _createElementVNode("a", {
            class: "btn btn-outline-success",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$refs.uploadExcel.click()))
          }, _cache[22] || (_cache[22] = [
            _createElementVNode("div", { class: "text-nowrap" }, "Import Excel", -1)
          ])),
          _createElementVNode("input", {
            class: "d-none",
            id: "file-input",
            ref: "uploadExcel",
            type: "file",
            name: "name",
            onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.uploadExcel($event)))
          }, null, 544),
          _createVNode(_component_router_link, {
            class: "btn btn-info",
            to: "/small-tool/summary"
          }, {
            default: _withCtx(() => _cache[23] || (_cache[23] = [
              _createElementVNode("div", { class: "text-nowrap text-white" }, "Small Tool Summary", -1)
            ])),
            _: 1
          })
        ])
      ]),
      _createElementVNode("table", _hoisted_4, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _cache[33] || (_cache[33] = _createElementVNode("th", null, [
              _createElementVNode("span", null, "#")
            ], -1)),
            _createElementVNode("th", null, [
              _cache[25] || (_cache[25] = _createElementVNode("span", null, "LG", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "ld",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[26] || (_cache[26] = _createElementVNode("span", null, "Year", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "year",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[27] || (_cache[27] = _createElementVNode("span", null, "Month", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "month",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[28] || (_cache[28] = _createElementVNode("span", null, "Status", -1)),
              _createVNode(_component_JMSort, {
                sortkey: "status",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[11] || (_cache[11] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[29] || (_cache[29] = _createElementVNode("span", null, "Description", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "description",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[13] || (_cache[13] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[30] || (_cache[30] = _createElementVNode("span", null, "Unit", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "unit",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[15] || (_cache[15] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[31] || (_cache[31] = _createElementVNode("span", null, "QTY", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "qty",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[17] || (_cache[17] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[32] || (_cache[32] = _createElementVNode("span", null, "Remark", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "remark",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[19] || (_cache[19] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _cache[34] || (_cache[34] = _createElementVNode("th", null, null, -1))
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.docs, (item, index) => {
            return (_openBlock(), _createElementBlock("tr", null, [
              _createElementVNode("td", null, [
                _createElementVNode("div", _hoisted_5, "#" + _toDisplayString(((_ctx.page-1)*_ctx.show)+(index+1)), 1)
              ]),
              _createElementVNode("td", null, _toDisplayString(item.lg), 1),
              _createElementVNode("td", null, _toDisplayString(item.year), 1),
              _createElementVNode("td", null, _toDisplayString(_ctx.monthsEN[item.month]), 1),
              _createElementVNode("td", null, _toDisplayString(item.status), 1),
              _createElementVNode("td", null, _toDisplayString(item.description), 1),
              _createElementVNode("td", null, _toDisplayString(item.unit), 1),
              _createElementVNode("td", null, _toDisplayString(item.qty), 1),
              _createElementVNode("td", null, _toDisplayString(item.remark), 1),
              _createElementVNode("td", null, [
                _createElementVNode("div", _hoisted_6, [
                  (_ctx.store.state.accessLevel>=5)
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        to: `/small-tool/edit/${item._id}`
                      }, {
                        default: _withCtx(() => _cache[35] || (_cache[35] = [
                          _createElementVNode("i", { class: "class fal fa-pencil text-warning" }, null, -1)
                        ])),
                        _: 2
                      }, 1032, ["to"]))
                    : _createCommentVNode("", true),
                  (_ctx.store.state.accessLevel>=5)
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 1,
                        class: "class fal fa-trash text-danger ms-2",
                        onClick: ($event: any) => (_ctx.deleteItem(item._id))
                      }, null, 8, _hoisted_7))
                    : _createCommentVNode("", true)
                ])
              ])
            ]))
          }), 256))
        ])
      ]),
      _createVNode(_component_JMTablePaginate, {
        page: _ctx.page,
        pages: _ctx.pages,
        show: _ctx.show,
        total: _ctx.total,
        onChange: _cache[20] || (_cache[20] = ($event: any) => {_ctx.page=$event; _ctx.fetchData()})
      }, null, 8, ["page", "pages", "show", "total"])
    ])
  ], 64))
}