
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class MainContainer extends Vue {
  store = useStore()
  checkAccess(name:string){
    try {
      return this.store.state.access.find((ac:string)=>ac==name)!==undefined
    } catch (error) {
      return false
    }
  }
}
