import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container rounded shadow bg-white px-4 pb-5 pt-3" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "col-sm-6 mb-2" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "col-sm-6 mb-2" }
const _hoisted_7 = { class: "col-sm-6 mb-2" }
const _hoisted_8 = { class: "col-sm-6 mb-2" }
const _hoisted_9 = { class: "form-label" }
const _hoisted_10 = ["value"]
const _hoisted_11 = { class: "col-sm-6 mb-2" }
const _hoisted_12 = { class: "col-sm-6 mb-2" }
const _hoisted_13 = { class: "col-sm-6 mb-2" }
const _hoisted_14 = { class: "col-sm-6 mb-2" }
const _hoisted_15 = { class: "col-sm-12 mb-2" }
const _hoisted_16 = { class: "col-sm-12 mb-2" }
const _hoisted_17 = { class: "row g-3 d-flex justify-content-center" }
const _hoisted_18 = { class: "col-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.params.id == undefined)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, "Add Form"))
      : (_openBlock(), _createElementBlock("h2", _hoisted_3, "Edit Form")),
    _createElementVNode("form", {
      class: "row needs-validation",
      onSubmit: _cache[10] || (_cache[10] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_4, [
        _cache[11] || (_cache[11] = _createElementVNode("label", { class: "form-label" }, "Job", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.doc.job) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.jobs, (item) => {
            return (_openBlock(), _createElementBlock("option", {
              value: item.j
            }, "J." + _toDisplayString(item.j) + " : " + _toDisplayString(item.name), 9, _hoisted_5))
          }), 256))
        ], 512), [
          [_vModelSelect, _ctx.doc.job]
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[12] || (_cache[12] = _createElementVNode("label", { class: "form-label" }, "LG ", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.doc.lg) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (item) => {
            return (_openBlock(), _createElementBlock("option", null, _toDisplayString(decodeURIComponent(item.name)), 1))
          }), 256))
        ], 512), [
          [_vModelSelect, _ctx.doc.lg]
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _cache[14] || (_cache[14] = _createElementVNode("label", { class: "form-label" }, "Description", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.doc.description) = $event))
        }, _cache[13] || (_cache[13] = [
          _createElementVNode("option", null, "Shear Pin", -1),
          _createElementVNode("option", null, "Shackle", -1),
          _createElementVNode("option", null, "Wire Rope Sling", -1)
        ]), 512), [
          [_vModelSelect, _ctx.doc.description]
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("label", _hoisted_9, "Diameter " + _toDisplayString(_ctx.doc.diameter), 1),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.doc.diameter) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.diameters, (item) => {
            return (_openBlock(), _createElementBlock("option", {
              value: `${item}`
            }, _toDisplayString(item), 9, _hoisted_10))
          }), 256))
        ], 512), [
          [_vModelSelect, _ctx.doc.diameter]
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _cache[15] || (_cache[15] = _createElementVNode("label", { class: "form-label" }, "Detail", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.doc.detail) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.detail]
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _cache[17] || (_cache[17] = _createElementVNode("label", { class: "form-label" }, "Status", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.doc.status) = $event))
        }, _cache[16] || (_cache[16] = [
          _createElementVNode("option", null, "100%", -1),
          _createElementVNode("option", null, "80%", -1),
          _createElementVNode("option", null, "50%", -1),
          _createElementVNode("option", null, "Damage", -1)
        ]), 512), [
          [_vModelSelect, _ctx.doc.status]
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _cache[18] || (_cache[18] = _createElementVNode("label", { class: "form-label" }, "Qty ", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "number",
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.doc.qty) = $event))
        }, null, 512), [
          [
            _vModelText,
            _ctx.doc.qty,
            void 0,
            { number: true }
          ]
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _cache[20] || (_cache[20] = _createElementVNode("label", { class: "form-label" }, "Type of Sling", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.doc.typeOfSling) = $event))
        }, _cache[19] || (_cache[19] = [
          _createElementVNode("option", null, "สลิงอัดปลอกห่วงหัวใจ (ละเอียด)", -1),
          _createElementVNode("option", null, "สลิงอัดปลอกห่วงหัวใจ (หยาบ)", -1),
          _createElementVNode("option", null, "สลิงอัดปลอก (หยาบ)", -1)
        ]), 512), [
          [_vModelSelect, _ctx.doc.typeOfSling]
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _cache[21] || (_cache[21] = _createElementVNode("label", { class: "form-label" }, "Diameter Hook ", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.doc.diameterHook) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.diameterHook]
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _cache[22] || (_cache[22] = _createElementVNode("label", { class: "form-label" }, "Remark ", -1)),
        _withDirectives(_createElementVNode("textarea", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.doc.remark) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.remark]
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          _createVNode(_component_router_link, {
            class: "w-100 btn btn-secondary text-white btn-lg mt-4",
            to: "/jack"
          }, {
            default: _withCtx(() => _cache[23] || (_cache[23] = [
              _createTextVNode("ย้อนกลับ")
            ])),
            _: 1
          })
        ]),
        _cache[24] || (_cache[24] = _createElementVNode("div", { class: "col-5" }, [
          _createElementVNode("button", {
            class: "w-100 btn btn-primary btn-lg mt-4 text-white",
            id: "save",
            type: "submit"
          }, "บันทึก")
        ], -1))
      ])
    ], 32)
  ]))
}