
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator"
import address from "@/variable";
import axios from "axios";
import Sortable from "sortablejs";
import { useStore } from "vuex";
import * as _ from "lodash"
import { DateTime } from "luxon";

@Options({
  components: {},
})
export default class Form extends Vue {
  store = useStore()
  doc: any = {
  };
  code1: Array<any> = [];
  code2: Array<any> = [];
  lgs: Array<any> = [];
  job:any = {}
  erections:Array<any> = []

  updateCycleTime(){
    let start,end
    if(typeof this.doc.startDate==="string") start = DateTime.fromISO(this.doc.startDate??new Date())
    else start = DateTime.fromJSDate(this.doc.startDate??new Date())
    if(typeof this.doc.endDate==="string") end = DateTime.fromISO(this.doc.endDate??new Date())
    else end = end = DateTime.fromJSDate(this.doc.endDate??new Date())
    let compare = start
    let diff = end.diff(start,'days').toObject();
    let sundays = 0
    while ((end.diff(compare,'days').toObject().days??0)>0) {
      if(compare.get('weekday')==7) sundays += 1
      compare = compare.plus({day:1})
    }
    console.log(this.doc.startDate,this.doc.endDate)
    this.doc.timeCalendar = Math.round(diff.days??0) + 1
    this.doc.timePerform = Math.round((diff.days??0)-sundays) + 1
  }
  get spans(){
    let spans = this.erections.map(el=>el.span)
    return _.uniq(spans)
  }
  get sides(){
    let sides = this.erections.map(el=>el.side)
    return _.uniq(sides)
  }
  pushActivity() {
    if (!Array.isArray(this.doc.activities)) this.doc.activities = [];
    this.doc.activities.push({
      code1: "",
      code2: "",
      period:1,
      remarks:""
    });
  }

  mounted() {
    this.doc.lg = decodeURIComponent(this.$route.params.lg as string)
    if (this.$route.params.id != undefined) this.fetchData();
    axios.get(`${address}/code-by-field/name/Progress Code 1`).then((response) => {
      this.code1 = response.data.member;
    });
    axios.get(`${address}/code-by-field/name/Progress Code 2`).then((response) => {
      this.code2 = response.data.member;
    });
    axios.get(`${address}/code-by-field/name/Launching Girders`).then((response) => {
      this.lgs = response.data.member;
      this.doc.job = (this.lgs.find(el=>el.name==this.$route.params.lg)??{}).text2
      axios.get(`${address}/job-by-field/j/${this.doc.job}`).then((response2) => {
        this.job = response2.data;
      });
      axios.post(`${address}/erections-paginate`,{
        search:{
          job:this.doc.job
        },
        limit:999,
        page:1,
      }).then((response2) => {
        this.erections = response2.data.docs;
      });
    });
  }
  pushMember() {
    if (!Array.isArray(this.doc.member)) this.doc.member = [];
    this.doc.member.push({
      name: "",
      text1: "",
      // category:"",
      // text2:"",
      // text3:"",
      // number1:0,
      // number2:0,
      // number3:0,
      // boolean1:false,
      // boolean2:false,
      // boolean3:false,
    });
  }
  sortItem(evt: any) {
    let x = evt.oldIndex;
    let y = evt.newIndex;
    var b = this.doc.member[y];
    this.doc.member[y] = this.doc.member[x];
    this.doc.member[x] = b;
  }
  fetchData() {
    axios.get(`${address}/progress/${this.$route.params.id}`).then((response) => {
      let data = response.data;
      (data.member ?? []).forEach((el: any) => {
        el.name = decodeURI(el.name);
      });
      this.doc = data;
      let self = this
      var el = this.$refs.items as HTMLElement;
      var sortable = Sortable.create(el, {
        animation: 150,
        ghostClass: "sortable-ghost",
        chosenClass: "sortable-chosen",
        handle: ".handle",
        onUpdate: self.sortItem,
      });
    });
  }
  submitForm() {
    let prep:any = JSON.parse(JSON.stringify(this.doc));
    (prep.member ?? []).forEach((el: any) => {
      el.name = encodeURI(el.name);
    });
    if (this.$route.params.id != undefined) {
      axios.patch(`${address}/progress/${this.doc._id}`, prep).then((response) => {
        alert("แก้ไขข้อมูลแล้ว");
        this.$router.push("/progress");
      });
    } else {
      axios
        .post(`${address}/progress`, {
          ...prep,
        })
        .then((response) => {
          alert("บันทึกข้อมูลแล้ว");
          this.$router.push("/progress");
        });
    }
  }
}
