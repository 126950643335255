
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { DateTime } from "luxon";
import * as d3 from "d3";

@Options({
  props: {
    modelValue: Object,
    editable: Boolean,
    "onUpdate:modelValue": Function,
  },
})
export default class JMGantt extends Vue {
  modelValue!: any;
  editable!: boolean;
  doc: any = {};
  today: Date = new Date();
  schedules: Array<any> = [];
  dateArray: Array<DateTime> = [];

  svg: any;
  ganttBody: d3.Selection<any, any, any, any> = d3.selectAll("#ne");
  ganttHeader: d3.Selection<any, any, any, any> = d3.selectAll("#ne");
  ganttHeaderBg: d3.Selection<any, any, any, any> = d3.selectAll("#ne");
  ganttBackground: d3.Selection<any, any, any, any> = d3.selectAll("#ne");

  barHeight = 20;
  headerHeight = 50;
  totalHeight = 500;
  duration = 365;
  dayWidth = 4;

  init = false;
  timerId: number | undefined = undefined;

  @Watch("schedules", { deep: true })
  updateDoc() {
    console.log("updating...");
    this.doc.schedules = this.schedules;
    this.$emit("update:modelValue", this.doc);
    this.$forceUpdate();
  }

  initData() {
    this.doc = this.modelValue;
    this.schedules = this.modelValue.schedules ?? [];
    let projectStart = DateTime.fromISO(this.doc.startDate)
    let projectFinish = DateTime.fromISO(this.doc.endDate)
    this.duration = projectFinish.diff(projectStart,'days').days

    this.schedules = this.schedules.map((elem: any, i: number) => {
      let start =
        typeof elem.start == "string"
          ? DateTime.fromISO(elem.start)
          : DateTime.fromJSDate(elem.start);
      let finish =
        typeof elem.finish == "string"
          ? DateTime.fromISO(elem.finish)
          : DateTime.fromJSDate(elem.finish);
      let depCalc = 0;
      return {
        ...elem,
        diff: Math.round(
          finish.endOf("days").diff(start.startOf("days"), "days").days ?? 0
        ),
        custom_class: "bar-schedules",
      };
    });

    this.totalHeight = this.schedules.length * 30 * 2;

    if (this.doc.startDate != undefined) {
      this.dateArray = [];
      for (let i = 0; i < this.duration; i++) {
        this.dateArray.push(
          DateTime.fromISO(this.doc.startDate).plus({ day: i })
        );
      }
      this.svg = d3
        .select("#jmgantt")
        .append("svg")
        .attr("id", "main-gantt-container")
        .attr("width", "100%")
        .attr("height", "100%")
        .call(this.containerZoomHandler())
        .append("g")
        .attr("id", "g-gantt-container");
      this.initGantt();
    }
  }

  updated() {
    if (this.doc !== this.modelValue) this.initData();
  }

  mounted() {
    this.initData();
  }

  throttleFunction(func: Function, delay: number) {
    let self = this;
    if (this.timerId) {
      return;
    }
    this.timerId = setTimeout(function() {
      func();
      self.timerId = undefined;
    }, delay);
  }

  containerZoomHandler() {
    let self = this;
    var zoomHanlder = d3
      .zoom<SVGSVGElement, unknown>()
      .on("zoom", function(elem: any, data: any) {
        self.throttleFunction(() => {
          let headerContent = d3.selectAll("g#gantt-header-content");
          let bodyBackground = d3.selectAll("g#gantt-body-background");
          let bodyContent = d3.selectAll("g#gantt-body-content");
          headerContent.attr("transform", (el) => {
            let x = 0;
            if (elem.transform.x < 0) {
              x = elem.transform.x;
            }
            return `translate(${x},${0}) scale(${elem.transform.k})`;
          });
          bodyBackground.attr("transform", (el) => {
            let x = 0;
            if (elem.transform.x < 0) {
              x = elem.transform.x;
            }
            return `translate(${x},${0}) scale(${elem.transform.k})`;
          });
          bodyContent.attr("transform", (el) => {
            let x = 0;
            if (elem.transform.x < 0) {
              x = elem.transform.x;
            }
            let y = 0;
            if (elem.transform.y < 0) {
              y = elem.transform.y;
            }
            return `translate(${x},${y}) scale(${elem.transform.k})`;
          });
        }, 20);
      });
    return zoomHanlder;
  }

  containerDragHandler() {
    let self = this;
    var deltaX: number;
    var deltaY: number;
    function start(el: any, data: any) {
      //@ts-ignore
      var current = d3.select(this);
      let getX: any = current
        .attr("transform")
        .replace("translate(", "")
        .replace(")", "")
        .split(",")[0];
      getX = parseInt(getX);
      deltaX = getX - el.x;
      self.$forceUpdate();
      //@ts-ignore
      var current = d3.select(this);
    }
    function drag(el: any, data: any) {
      //@ts-ignore
      var current = d3.select(this);
      current.attr("transform", (task: any, i: number) => {
        let fi = self.schedules.findIndex((s: any) => s._id == el.subject._id);
        let y = (fi - 1) * (self.barHeight + 10) + 55 + self.headerHeight;
        return `translate(${el.x + deltaX}, ${y})`;
      });
    }
    function end(el: any, data: any) {
      let calcStartDate = Math.round((el.x + deltaX) / self.dayWidth);
      let fi = self.schedules.findIndex((s: any) => s.id == el.subject.id);
      let found = self.schedules[fi];
      let oldStart = DateTime.fromISO(found.start).startOf("day");
      let oldFinish = DateTime.fromISO(found.finish).startOf("day");
      let oldDiff = oldFinish.diff(oldStart, "days").days;

      //@ts-ignore
      var current = d3.select(this);
      current.attr("transform", (task: any, i: number) => {
        let fi = self.schedules.findIndex((s: any) => s._id == el.subject._id);
        let y = (fi - 1) * (self.barHeight + 10) + 55 + self.headerHeight;
        // prettier-ignore
        self.schedules[fi].start = DateTime.fromISO(self.doc.startDate)
          .plus({ day: calcStartDate })
          .startOf("day")
          .toISO();
        // prettier-ignore
        self.schedules[fi].finish = DateTime.fromISO(self.schedules[fi].start)
          .plus({ day: oldDiff })
          .startOf("day")
          .toISO();
        console.log(calcStartDate, oldDiff);
        return `translate(${calcStartDate * self.dayWidth}, ${y})`;
      });
    }
    var dragHandler = d3
      .drag<SVGGElement, unknown, SVGSVGElement>()
      .on("start", this.editable ? start : () => {})
      .on("drag", this.editable ? drag : () => {})
      .on("end", this.editable ? end : () => {});

    return dragHandler;
  }

  progressEndDragHandler() {
    let self = this;
    var deltaX: number = 0;
    var deltaY: number = 0;
    var diff = 0;
    function start(el: any, data: any) {}
    function drag(el: any, data: any) {
      let fi = self.schedules.findIndex((s: any) => s._id == el.subject._id);
      let y = fi * (self.barHeight + 10) + 55;
      var progressBar = d3.select(
        d3.selectAll("g.progress-bar-container").nodes()[fi]
      );
      let roundPosition =
        Math.round((el.x + deltaX) / self.dayWidth) * self.dayWidth;
      var endBar = progressBar.select("rect.progress-bar-end");
      endBar.attr("x", roundPosition);
    }
    function end(el: any, data: any) {
      let roundPosition =
        Math.round((el.x + deltaX) / self.dayWidth) * self.dayWidth;
      let fi = self.schedules.findIndex((s: any) => s._id == el.subject._id);
      let task = self.schedules[fi];
      self.schedules[fi].finish = DateTime.fromISO(self.schedules[fi].start)
        .plus({ day: Math.round(el.x / self.dayWidth) - 1 })
        .startOf("day")
        .toISO();
      let finish = DateTime.fromISO(self.schedules[fi].finish);
      let start = DateTime.fromISO(self.schedules[fi].start);
      diff = Math.round(
        finish.endOf("days").diff(start.startOf("days"), "days").days ?? 0
      );
      let id = "#progress-bar-" + self.schedules[fi]._id;
      let progressBar = d3.select(id);
      progressBar.attr("width", (el) => {
        return diff * self.dayWidth;
      });
      var progressBar2 = d3.select(
        d3.selectAll("g.progress-bar-container").nodes()[fi]
      );
      var endBar = progressBar2.select("rect.progress-bar-end");
      endBar.attr("x", roundPosition);
      var textBar = progressBar2.select(".gantt-bar-text");

      textBar
        .transition()
        .duration(500)
        .attr("x", (task: any) => {
          return diff * self.dayWidth + 10;
        });
    }
    var dragHandler = d3
      .drag<SVGRectElement, unknown, SVGSVGElement>()
      .on("start", this.editable ? start : () => {})
      .on("drag", this.editable ? drag : () => {})
      .on("end", this.editable ? end : () => {});

    return dragHandler;
  }

  initGantt() {
    let self = this;
    this.init = true;
    this.removeGantt();
    //prettier-ignore
    this.ganttBackground = this.svg.append("g").attr("id", "gantt-body-background");
    //prettier-ignore
    this.ganttBody = this.svg.append("g").attr("id", "gantt-body-content");
    //prettier-ignore
    this.ganttHeaderBg = this.svg.append("g").attr("id", "gantt-header-background");
    //prettier-ignore
    this.ganttHeader = this.svg.append("g").attr("id", "gantt-header-content");
    this.updateGantt();
  }

  initZoom() {}

  removeGantt() {
    this.ganttBackground.remove();
    this.ganttBody.remove();
    this.ganttHeaderBg.remove();
    this.ganttHeader.remove();
  }
  updateGantt() {
    this.renderBackground();
    this.renderHeader();
    this.renderBody();
  }
  renderHeader() {
    this.ganttHeader
      .append("rect")
      .attr("width", this.dayWidth * this.duration)
      .attr("height", this.headerHeight)
      .style("fill", "#FFFFFF");
    var headers = this.ganttHeader
      .selectAll("rect.hgrid-container")
      .data(this.dateArray)
      .enter()
      .append("g");
    headers
      .append("text")
      .attr("class", "hgrid-date")
      .attr("x", (task: any, i: number) => {
        if (this.dayWidth < 10) {
          return i % 3 === 0 ? i * this.dayWidth + this.dayWidth / 2 : "";
        } else {
          return i * this.dayWidth + this.dayWidth / 2;
        }
      })
      .attr("y", 45)
      .attr("width", 1)
      .attr("font-size", "0.75rem")
      .attr("height", (day: any, i: number) => {
        return this.totalHeight + this.headerHeight;
      })
      .attr("text-anchor", "middle")
      .style("fill", (day: any, i: number) => {
        return day.toFormat("EEEEE") == "S" ? "#FF0000" : "#999";
      })
      .text(function(day: any, i: number) {
        return day.toFormat("d");
      });

    headers
      .append("text")
      .attr("class", "hgrid-date")
      .attr("x", (task: any, i: number) => {
        if (this.dayWidth < 12) {
          return i % 3 === 0 ? i * this.dayWidth + this.dayWidth / 2 : "";
        } else {
          return i * this.dayWidth + this.dayWidth / 2;
        }
      })
      .attr("y", 35)
      .attr("width", 1)
      .attr("font-size", "0.75rem")
      .attr("height", this.totalHeight)
      .attr("text-anchor", "middle")
      .style("fill", (day: any, i: number) => {
        return day.toFormat("EEEEE") == "S" ? "#FF0000" : "#999";
      })
      .text(function(day: any, i: number) {
        return day.toFormat("EEEEE");
      });

    headers
      .append("text")
      .attr("class", "hgrid-month")
      .attr("x", (task: any, i: number) => {
        return 5 + i * this.dayWidth;
      })
      .attr("y", 20)
      .attr("font-size", "0.75em")
      .attr("width", 1)
      .attr("height", this.totalHeight)
      .attr("text-anchor", "start")
      .attr("font-weight", "bold")
      .style("fill", "#999")
      .text(function(day: any, i: number) {
        if (day.toFormat("d") == 1)
          return day
            .setLocale("th")
            .reconfigure({ outputCalendar: "buddhist" })
            .toFormat("MMMM yyyy");
        else return "";
      });
  }
  renderBody() {
    let startDate = DateTime.fromISO(this.doc.startDate);

    var keydates = this.ganttHeader
      .selectAll("rect.key-date")
      .data(this.doc.keydates)
      .enter()
      .append("g")
      .attr("class", "key-date-container");
    keydates
      .append("rect")
      .attr("class", "key-date-line")
      .attr("x", (day: any, i: number) => {
        return day.projectDate * this.dayWidth;
      })
      .attr("y", (day: any, i: number) => {
        return this.headerHeight;
      })
      .attr("width", 2)
      .attr("height", (day: any, i: number) => {
        return this.totalHeight + 15;
      })
      .style("fill", "#EE0000");
    keydates
      .append("text")
      .attr("class", "key-date-text")
      .attr("x", (day: any, i: number) => {
        return day.projectDate * this.dayWidth + 10;
      })
      .attr("y", (day: any, i: number) => {
        return this.headerHeight + 10;
      })
      .text(function(task: any) {
        return task.name + ` [${task.projectDate} วัน]`;
      })
      .style("fill", "#EE0000");

    var progressBar = this.ganttBody
      .selectAll("rect.progress-bar")
      .data(this.schedules)
      .enter()
      .append("g")
      .attr("class", "progress-bar-container")
      .attr("id", (task: any) => {
        return "progress-bar-" + task.code.split(".").join("_");
      })
      .attr("transform", (task: any, i: number) => {
        let taskStart = DateTime.fromISO(task.start).startOf("day");
        let diff = taskStart.diff(startDate, "days").days ?? 0;
        let x = diff * this.dayWidth;
        let y = i * (this.barHeight + 10) + 75;
        return `translate(${x}, ${y})`;
      })
      .call(this.containerDragHandler());

    var progressBarInfo = this.ganttBody
      .selectAll("rect.progress-bar-info")
      .data(this.schedules)
      .enter()
      .append("g")
      .attr("class", "progress-bar-info")
      .attr("transform", (task: any, i: number) => {
        let taskStart = DateTime.fromISO(task.start).startOf("day");
        let diff = taskStart.diff(startDate, "days").days ?? 0;
        let x = diff * this.dayWidth;
        let y = i * (this.barHeight + 10) + 75;
        return `translate(${x}, ${y})`;
      });

    progressBarInfo
      .append("g")
      .attr("transform", (task: any, i: number) => {
        return `translate(-13, 3)`;
      })
      .attr("opacity", (task: any, i: number) => {
        //prettier-ignore
        return (task.dependencies!="" && task.dependencies!= undefined)?1:0;
      })
      .attr("class", "triangle")
      .append("path")
      .attr("d", "M 0 0 12 6 0 12 3 6")
      .style("fill", "black");

    progressBarInfo
      .append("g")
      .attr("transform", (task: any, i: number) => {
        return `translate(0, 9)`;
      })
      .attr("opacity", (task: any, i: number) => {
        //prettier-ignore
        return (task.dependencies!="" && task.dependencies!= undefined)?1:0;
      })
      .append("path")
      .attr("d", (task: any, i: number) => {
        let dep =
          "#progress-bar-" + (task.dependencies ?? "").split(".").join("_");
        let offsetX = 0;
        let offsetY = 0;
        try {
          let found = d3
            .select(dep)
            .attr("transform")
            .replace("translate(", "")
            .replace(")", "")
            .replace(" ", "")
            .split(",");
          offsetX = parseInt(found[0]);
          offsetY = parseInt(found[1]);
        } catch (error) {}

        let taskStart = DateTime.fromISO(task.start).startOf("day");
        let diff = taskStart.diff(startDate, "days").days ?? 0;
        let x = diff * this.dayWidth - offsetX - 0.5 * this.dayWidth;
        let y = i * (this.barHeight + 10) + 75 - offsetY;
        return `M 0 0 L ${-x} 0 ${-x} ${-y}`;
        // return "M 0 0 -8 0 -8 -20";
      })
      .attr("stroke-width", 1)
      .attr("stroke", "black")
      .style("fill", "transparent");

    progressBar
      .append<SVGRectElement>("rect")
      .attr("class", "progress-bar")
      .attr("id", (task: any) => {
        return "progress-bar-" + task._id;
      })
      .attr("width", (task: any) => {
        return task.diff * this.dayWidth;
      })
      .attr("rx", 5)
      .attr("y", 0)
      .attr("height", (task: any) => {
        return task.category == "Group" ? 5 : this.barHeight;
      })
      .attr("fill", (task: any) => {
        return task.category == "Group" ? "#FF9D5C" : "#AAAAAA";
      })
      .attr("stroke", "#FFF")
      .attr("stroke-width", (task: any) => {
        return task.category == "Group" ? 1 : 4;
      });
    progressBar
      .append<SVGRectElement>("rect")
      .attr("class", "progress-bar")
      .attr("id", (task: any) => {
        return "progress-bar-" + task._id;
      })
      .attr("width", (task: any) => {
        if(task.code=="Assembly") return task.diff * this.dayWidth;
        return task.diff * this.dayWidth * (task.progress / 100);
      })
      .attr("rx", 5)
      .attr("y", 0)
      .attr("height", (task: any) => {
        return task.category == "Group" ? 5 : this.barHeight;
      })
      .attr("fill", (task: any) => {
        return task.category == "Group" ? "#FF9D5C" : "#62c379";
      })
      .attr("stroke", "#FFF")
      .attr("stroke-width", (task: any) => {
        return task.category == "Group" ? 1 : 4;
      });

    progressBar
      .append<SVGTextElement>("text")
      .attr("class", "progress-bar-text")
      .attr("x", (task: any) => {
        // return task.category == "Group" ? task.diff * this.dayWidth + 10 : 10;
        return task.diff * this.dayWidth + 10;
      })
      .attr("y", (task: any) => {
        return task.category == "Group" ? 5 : 10;
      })
      .attr("class", "gantt-bar-text")
      .attr("dy", ".30em")
      .text(function(task: any) {
        return (
          task.code +
          ") " +
          task.name +
          ` - ${task.diff}D `
        );
      });

    progressBar
      .append<SVGRectElement>("rect")
      .attr("class", "progress-bar-end")
      .attr("width", (task: any) => {
        return 3;
      })
      .attr("x", (task: any) => {
        return task.diff * this.dayWidth;
      })
      .attr("height", (task: any) => {
        return task.category == "Group" ? 10 : this.barHeight;
      })
      .attr("fill", "#0000FF")
      .call(this.progressEndDragHandler());
  }
  renderBackground() {
    var days = this.ganttBackground
      .selectAll("rect.grid-horizontal-container")
      .data(this.dateArray)
      .enter()
      .append("g");

    days
      .append("rect")
      .attr("class", "hgrid-item-weekend")
      .attr("x", (task: any, i: number) => {
        return i * this.dayWidth;
      })
      .attr("width", this.dayWidth)
      .attr("height", (day: any, i: number) => {
        return this.totalHeight + this.headerHeight;
      })
      .style("fill", (day: any, i: number) => {
        return day.toFormat("EEEEE") == "S" ? "#DDFFFF" : "#FFFFFF";
      });

    days
      .append("rect")
      .attr("class", "hgrid-item")
      .attr("x", (day: any, i: number) => {
        return i * this.dayWidth;
      })
      .attr("y", (day: any, i: number) => {
        if (day.toFormat("d") == 1) return 0;
        else return this.headerHeight;
      })
      .attr("width", 1)
      .attr("height", (day: any, i: number) => {
        if (day.toFormat("d") == 1) return this.totalHeight + this.headerHeight;
        else return this.totalHeight;
      })
      .style("fill", "#DDD");

    var background = this.ganttBackground
      .selectAll("rect.hgrid-container")
      .data([1])
      .enter()
      .append("g");
    background
      .append("rect")
      .attr("class", "hgrid-item")
      .attr("x", (day: any, i: number) => {
        let diff =
          DateTime.now()
            .startOf("day")
            .diff(DateTime.fromISO(this.doc.startDate).startOf("day"), "days")
            .days ?? 0;
        return diff * this.dayWidth;
      })
      .attr("y", (day: any, i: number) => {
        return 0;
      })
      .attr("width", this.dayWidth)
      .attr("height", (day: any, i: number) => {
        return this.totalHeight + this.headerHeight;
      })
      .style("fill", "#FFFFA0");
  }
}
