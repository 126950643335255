import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container rounded shadow bg-white px-4 pb-5 pt-3" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "col-sm-6 mb-2" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "col-sm-6 mb-2" }
const _hoisted_7 = { class: "col-sm-6 mb-2" }
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "col-sm-6 mb-2" }
const _hoisted_10 = ["value"]
const _hoisted_11 = { class: "col-sm-6 mb-2" }
const _hoisted_12 = { class: "col-sm-6 mb-2" }
const _hoisted_13 = { class: "col-sm-6 mb-2" }
const _hoisted_14 = { class: "col-sm-6 mb-2" }
const _hoisted_15 = { class: "col-sm-4 mb-2" }
const _hoisted_16 = { class: "col-sm-4 mb-2" }
const _hoisted_17 = { class: "form-label" }
const _hoisted_18 = ["value"]
const _hoisted_19 = { class: "col-sm-4 mb-2" }
const _hoisted_20 = ["value"]
const _hoisted_21 = { class: "col-sm-12 mb-2" }
const _hoisted_22 = { class: "row g-3 d-flex justify-content-center" }
const _hoisted_23 = { class: "col-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JMCalendar = _resolveComponent("JMCalendar")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.params.id == undefined)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, "Add Form"))
      : (_openBlock(), _createElementBlock("h2", _hoisted_3, "Edit Form")),
    _createElementVNode("form", {
      class: "row needs-validation",
      onSubmit: _cache[12] || (_cache[12] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_4, [
        _cache[13] || (_cache[13] = _createElementVNode("label", { class: "form-label" }, "Job", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.doc.job) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.jobs, (item) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (item.category!='Other Job Site')
                ? (_openBlock(), _createElementBlock("option", {
                    key: 0,
                    value: item.j
                  }, "J." + _toDisplayString(item.j) + " : " + _toDisplayString(item.name), 9, _hoisted_5))
                : _createCommentVNode("", true)
            ], 64))
          }), 256))
        ], 512), [
          [_vModelSelect, _ctx.doc.job]
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[14] || (_cache[14] = _createElementVNode("label", { class: "form-label" }, "Equipment Code", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.doc.code) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.code]
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _cache[15] || (_cache[15] = _createElementVNode("label", { class: "form-label" }, "Code 1", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          type: "text",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.doc.code1) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.code1, (item) => {
            return (_openBlock(), _createElementBlock("option", {
              value: item.name
            }, _toDisplayString(decodeURIComponent(item.name)), 9, _hoisted_8))
          }), 256))
        ], 512), [
          [_vModelSelect, _ctx.doc.code1]
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _cache[16] || (_cache[16] = _createElementVNode("label", { class: "form-label" }, "Code 2", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          type: "text",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.doc.code2) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.code2, (item) => {
            return (_openBlock(), _createElementBlock("option", {
              value: item.name
            }, _toDisplayString(decodeURIComponent(item.name)), 9, _hoisted_10))
          }), 256))
        ], 512), [
          [_vModelSelect, _ctx.doc.code2]
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _cache[17] || (_cache[17] = _createElementVNode("label", { class: "form-label" }, "Supplier", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.doc.supplier) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.supplier]
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _cache[18] || (_cache[18] = _createElementVNode("label", { class: "form-label" }, "Rate", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "number",
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.doc.rate) = $event))
        }, null, 512), [
          [
            _vModelText,
            _ctx.doc.rate,
            void 0,
            { number: true }
          ]
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_JMCalendar, {
          label: "Start",
          modelValue: _ctx.doc.start,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.doc.start) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_JMCalendar, {
          label: "Finish",
          modelValue: _ctx.doc.finish,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.doc.finish) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _cache[20] || (_cache[20] = _createElementVNode("label", { class: "form-label" }, "Status", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.doc.status) = $event))
        }, _cache[19] || (_cache[19] = [
          _createElementVNode("option", null, "under working", -1),
          _createElementVNode("option", null, "finish rental", -1)
        ]), 512), [
          [_vModelSelect, _ctx.doc.status]
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("label", _hoisted_17, "Equipment Type " + _toDisplayString(_ctx.doc.type), 1),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.doc.type) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.equipments, (item) => {
            return (_openBlock(), _createElementBlock("option", {
              value: item.name
            }, _toDisplayString(decodeURI(item.name)), 9, _hoisted_18))
          }), 256))
        ], 512), [
          [_vModelSelect, _ctx.doc.type]
        ])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _cache[21] || (_cache[21] = _createElementVNode("label", { class: "form-label" }, "LG", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          type: "text",
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.doc.lg) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (item) => {
            return (_openBlock(), _createElementBlock("option", {
              value: item.name
            }, _toDisplayString(decodeURIComponent(item.name)) + " - " + _toDisplayString(decodeURIComponent(item.text1)), 9, _hoisted_20))
          }), 256))
        ], 512), [
          [_vModelSelect, _ctx.doc.lg]
        ])
      ]),
      _createElementVNode("div", _hoisted_21, [
        _cache[22] || (_cache[22] = _createElementVNode("label", { class: "form-label" }, "Remarks", -1)),
        _withDirectives(_createElementVNode("textarea", {
          class: "form-control",
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.doc.remarks) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.remarks]
        ])
      ]),
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("div", _hoisted_23, [
          _createVNode(_component_router_link, {
            class: "w-100 btn btn-secondary text-white btn-lg mt-4",
            to: "/equipment"
          }, {
            default: _withCtx(() => _cache[23] || (_cache[23] = [
              _createTextVNode("ย้อนกลับ")
            ])),
            _: 1
          })
        ]),
        _cache[24] || (_cache[24] = _createElementVNode("div", { class: "col-5" }, [
          _createElementVNode("button", {
            class: "w-100 btn btn-primary btn-lg mt-4 text-white",
            id: "save",
            type: "submit"
          }, "บันทึก")
        ], -1))
      ])
    ], 32)
  ]))
}