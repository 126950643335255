import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "container rounded shadow bg-white px-4 pb-5 pt-3 mt-4" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "row mt-3" }
const _hoisted_5 = { class: "col-sm-4 mb-2" }
const _hoisted_6 = { class: "col-sm-4 mb-2" }
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "col-sm-4 mb-2" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-sm-6 mb-2" }
const _hoisted_11 = { class: "col-sm-6 mb-2" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-sm-6 mb-2" }
const _hoisted_14 = ["value"]
const _hoisted_15 = { class: "col-sm-6 mb-2" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "col-sm-12 mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JMMonth = _resolveComponent("JMMonth")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.params.id == undefined)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, "Small Tool Form"))
      : (_openBlock(), _createElementBlock("h2", _hoisted_3, "Edit Form")),
    _createElementVNode("form", {
      class: "row needs-validation",
      onSubmit: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _cache[11] || (_cache[11] = _createElementVNode("label", { class: "form-label" }, "LG ", -1)),
          _withDirectives(_createElementVNode("select", {
            class: "form-select",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.doc.lg) = $event))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (item) => {
              return (_openBlock(), _createElementBlock("option", null, _toDisplayString(decodeURIComponent(item.name)), 1))
            }), 256)),
            _cache[10] || (_cache[10] = _createElementVNode("option", null, "Na Phalan", -1))
          ], 512), [
            [_vModelSelect, _ctx.doc.lg]
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[12] || (_cache[12] = _createElementVNode("label", { class: "form-label" }, "Year", -1)),
          _withDirectives(_createElementVNode("select", {
            class: "form-select",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.doc.year) = $event))
          }, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (item) => {
              return _createElementVNode("option", {
                value: new Date().getFullYear() - item + 1
              }, _toDisplayString(new Date().getFullYear() - item + 1), 9, _hoisted_7)
            }), 64))
          ], 512), [
            [_vModelSelect, _ctx.doc.year]
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _cache[13] || (_cache[13] = _createElementVNode("label", { class: "form-label" }, "Month", -1)),
          _createVNode(_component_JMMonth, {
            modelValue: _ctx.doc.month,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.doc.month) = $event)),
            modelModifiers: { text: true },
            onChange2: _cache[3] || (_cache[3] = ($event: any) => (_ctx.doc.month = JSON.stringify($event)))
          }, null, 8, ["modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _cache[15] || (_cache[15] = _createElementVNode("label", { class: "form-label" }, "Status", -1)),
          _withDirectives(_createElementVNode("select", {
            class: "form-select",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.doc.status) = $event))
          }, _cache[14] || (_cache[14] = [
            _createElementVNode("option", null, "Incoming", -1),
            _createElementVNode("option", null, "Outgoing", -1)
          ]), 512), [
            [_vModelSelect, _ctx.doc.status]
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[16] || (_cache[16] = _createElementVNode("label", { class: "form-label" }, "Description", -1)),
          _withDirectives(_createElementVNode("select", {
            class: "form-select",
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.doc.description) = $event))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stores, (item) => {
              return (_openBlock(), _createElementBlock("option", null, _toDisplayString(item.name), 1))
            }), 256))
          ], 512), [
            [_vModelSelect, _ctx.doc.description]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _cache[17] || (_cache[17] = _createElementVNode("label", { class: "form-label" }, "Unit", -1)),
          _withDirectives(_createElementVNode("select", {
            class: "form-select",
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.doc.unit) = $event))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.units, (unit) => {
              return (_openBlock(), _createElementBlock("option", {
                value: unit.name
              }, _toDisplayString(unit.name), 9, _hoisted_14))
            }), 256))
          ], 512), [
            [_vModelSelect, _ctx.doc.unit]
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _cache[18] || (_cache[18] = _createElementVNode("label", { class: "form-label" }, "Qty", -1)),
          _withDirectives(_createElementVNode("input", {
            class: "form-control",
            type: "number",
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.doc.qty) = $event))
          }, null, 512), [
            [
              _vModelText,
              _ctx.doc.qty,
              void 0,
              { number: true }
            ]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          _cache[19] || (_cache[19] = _createElementVNode("label", { class: "form-label" }, "Remark", -1)),
          _withDirectives(_createElementVNode("textarea", {
            class: "form-control",
            type: "text",
            placeholder: "",
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.doc.remark) = $event))
          }, null, 512), [
            [_vModelText, _ctx.doc.remark]
          ])
        ])
      ]),
      _cache[20] || (_cache[20] = _createStaticVNode("<div class=\"row g-3 d-flex justify-content-center\"><div class=\"col-5\"><button class=\"w-100 btn btn-secondary text-white btn-lg mt-4\">ย้อนกลับ</button></div><div class=\"col-5\"><button class=\"w-100 btn btn-primary btn-lg mt-4 text-white\" id=\"save\" type=\"submit\">บันทึก</button></div></div>", 1))
    ], 32)
  ]))
}