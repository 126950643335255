import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "rounded shadow bg-white pb-3" }
const _hoisted_2 = { class: "summary-container summary-01" }
const _hoisted_3 = { class: "table main-table" }
const _hoisted_4 = { width: "100" }
const _hoisted_5 = { width: "700" }
const _hoisted_6 = { class: "fw-bold" }
const _hoisted_7 = { class: "summary-container summary-02" }
const _hoisted_8 = { class: "table main-table" }
const _hoisted_9 = { width: "100" }
const _hoisted_10 = { width: "700" }
const _hoisted_11 = { class: "fw-bold" }
const _hoisted_12 = { class: "summary-container summary-03" }
const _hoisted_13 = { class: "table main-table" }
const _hoisted_14 = { class: "fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("h2", null, "Segment Erection Manpower under LGSC Management", -1)),
      _createElementVNode("table", _hoisted_3, [
        _cache[2] || (_cache[2] = _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", null, [
              _createElementVNode("span", null, "JOB")
            ]),
            _createElementVNode("th", null, [
              _createElementVNode("span", null, "JOB NAME")
            ]),
            _createElementVNode("th", null, "EN"),
            _createElementVNode("th", null, "AC"),
            _createElementVNode("th", null, "ST"),
            _createElementVNode("th", null, "Daily"),
            _createElementVNode("th", null, "Total")
          ])
        ], -1)),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.internalJob, (item) => {
            return (_openBlock(), _createElementBlock("tr", null, [
              _createElementVNode("td", _hoisted_4, "J." + _toDisplayString(item.j), 1),
              _createElementVNode("td", _hoisted_5, _toDisplayString(item.name), 1),
              _createElementVNode("td", null, _toDisplayString((_ctx.getNumberJob(_ctx.group1,item.j,'EN')??0).formatShort()), 1),
              _createElementVNode("td", null, _toDisplayString((_ctx.getNumberJob(_ctx.group1,item.j,'AC')??0).formatShort()), 1),
              _createElementVNode("td", null, _toDisplayString((_ctx.getNumberJob(_ctx.group1,item.j,'ST')??0).formatShort()), 1),
              _createElementVNode("td", null, _toDisplayString((_ctx.getNumberJob(_ctx.group1,item.j,'Daily')??0).formatShort()), 1),
              _createElementVNode("td", null, _toDisplayString((_ctx.getNumberJobTotal(_ctx.group1,item.j)??0).formatShort()), 1)
            ]))
          }), 256)),
          _createElementVNode("tr", _hoisted_6, [
            _cache[0] || (_cache[0] = _createElementVNode("td", null, null, -1)),
            _cache[1] || (_cache[1] = _createElementVNode("td", null, "Total", -1)),
            _createElementVNode("td", null, _toDisplayString((_ctx.getNumberTypeTotal(_ctx.group1,'EN',_ctx.internalJob)??0).formatShort()), 1),
            _createElementVNode("td", null, _toDisplayString((_ctx.getNumberTypeTotal(_ctx.group1,'AC',_ctx.internalJob)??0).formatShort()), 1),
            _createElementVNode("td", null, _toDisplayString((_ctx.getNumberTypeTotal(_ctx.group1,'ST',_ctx.internalJob)??0).formatShort()), 1),
            _createElementVNode("td", null, _toDisplayString((_ctx.getNumberTypeTotal(_ctx.group1,'Daily',_ctx.internalJob)??0).formatShort()), 1),
            _createElementVNode("td", null, _toDisplayString(_ctx.getNumberTypeTotal(_ctx.group1,'EN',_ctx.internalJob)+_ctx.getNumberTypeTotal(_ctx.group1,'AC',_ctx.internalJob)+_ctx.getNumberTypeTotal(_ctx.group1,'ST',_ctx.internalJob)+_ctx.getNumberTypeTotal(_ctx.group1,'Daily',_ctx.internalJob)), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _cache[7] || (_cache[7] = _createElementVNode("h2", null, "Segment Erection Manpower under other Jobsite", -1)),
      _createElementVNode("table", _hoisted_8, [
        _cache[6] || (_cache[6] = _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", { width: "100" }, "JOB"),
            _createElementVNode("th", { width: "700" }, "JOB NAME"),
            _createElementVNode("th", null, "EN"),
            _createElementVNode("th", null, "AC"),
            _createElementVNode("th", null, "ST"),
            _createElementVNode("th", null, "Daily"),
            _createElementVNode("th", null, "Total")
          ])
        ], -1)),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherJob, (item) => {
            return (_openBlock(), _createElementBlock("tr", null, [
              _createElementVNode("td", _hoisted_9, "J." + _toDisplayString(item.j), 1),
              _createElementVNode("td", _hoisted_10, _toDisplayString(item.name), 1),
              _createElementVNode("td", null, _toDisplayString((_ctx.getNumberJob(_ctx.group2,item.j,'EN')??0).formatShort()), 1),
              _createElementVNode("td", null, _toDisplayString((_ctx.getNumberJob(_ctx.group2,item.j,'AC')??0).formatShort()), 1),
              _createElementVNode("td", null, _toDisplayString((_ctx.getNumberJob(_ctx.group2,item.j,'ST')??0).formatShort()), 1),
              _createElementVNode("td", null, _toDisplayString((_ctx.getNumberJob(_ctx.group2,item.j,'Daily')??0).formatShort()), 1),
              _createElementVNode("td", null, _toDisplayString((_ctx.getNumberJobTotal(_ctx.group2,item.j)??0).formatShort()), 1)
            ]))
          }), 256)),
          _createElementVNode("tr", _hoisted_11, [
            _cache[4] || (_cache[4] = _createElementVNode("td", null, null, -1)),
            _cache[5] || (_cache[5] = _createElementVNode("td", null, "Total", -1)),
            _createElementVNode("td", null, _toDisplayString((_ctx.getNumberTypeTotal(_ctx.group2,'EN', _ctx.otherJob)??0).formatShort()), 1),
            _createElementVNode("td", null, _toDisplayString((_ctx.getNumberTypeTotal(_ctx.group2,'AC', _ctx.otherJob)??0).formatShort()), 1),
            _createElementVNode("td", null, _toDisplayString((_ctx.getNumberTypeTotal(_ctx.group2,'ST', _ctx.otherJob)??0).formatShort()), 1),
            _createElementVNode("td", null, _toDisplayString((_ctx.getNumberTypeTotal(_ctx.group2,'Daily', _ctx.otherJob)??0).formatShort()), 1),
            _createElementVNode("td", null, _toDisplayString((_ctx.getNumberTypeTotal(_ctx.group2,'EN',_ctx.otherJob)+_ctx.getNumberTypeTotal(_ctx.group2,'AC',_ctx.otherJob)+_ctx.getNumberTypeTotal(_ctx.group2,'ST',_ctx.otherJob)+_ctx.getNumberTypeTotal(_ctx.group2,'Daily',_ctx.otherJob)??0).formatShort()), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _cache[11] || (_cache[11] = _createElementVNode("h2", null, "Segment Erection Manpower by LG", -1)),
      _createElementVNode("table", _hoisted_13, [
        _cache[10] || (_cache[10] = _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", { width: "100" }, "LG"),
            _createElementVNode("th", { width: "700" }, "JOB NAME"),
            _createElementVNode("th", null, "EN"),
            _createElementVNode("th", null, "AC"),
            _createElementVNode("th", null, "ST"),
            _createElementVNode("th", null, "Daily"),
            _createElementVNode("th", null, "Total")
          ])
        ], -1)),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (item) => {
            return (_openBlock(), _createElementBlock("tr", null, [
              (item.name!='')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("td", null, _toDisplayString(decodeURIComponent(item.name)), 1),
                    _createElementVNode("td", null, _toDisplayString(item.text1), 1),
                    _createElementVNode("td", null, _toDisplayString((_ctx.getNumberLG(_ctx.group3,item.name,'EN')??0).formatShort()), 1),
                    _createElementVNode("td", null, _toDisplayString((_ctx.getNumberLG(_ctx.group3,item.name,'AC')??0).formatShort()), 1),
                    _createElementVNode("td", null, _toDisplayString((_ctx.getNumberLG(_ctx.group3,item.name,'ST')??0).formatShort()), 1),
                    _createElementVNode("td", null, _toDisplayString((_ctx.getNumberLG(_ctx.group3,item.name,'Daily')??0).formatShort()), 1),
                    _createElementVNode("td", null, _toDisplayString((_ctx.getNumberLGTotal(_ctx.group3,item.name)??0).formatShort()), 1)
                  ], 64))
                : _createCommentVNode("", true)
            ]))
          }), 256)),
          _createElementVNode("tr", _hoisted_14, [
            _cache[8] || (_cache[8] = _createElementVNode("td", null, null, -1)),
            _cache[9] || (_cache[9] = _createElementVNode("td", null, "Total", -1)),
            _createElementVNode("td", null, _toDisplayString((_ctx.getNumberLGTypeTotal(_ctx.group3,'EN')??0).formatShort()), 1),
            _createElementVNode("td", null, _toDisplayString((_ctx.getNumberLGTypeTotal(_ctx.group3,'AC')??0).formatShort()), 1),
            _createElementVNode("td", null, _toDisplayString((_ctx.getNumberLGTypeTotal(_ctx.group3,'ST')??0).formatShort()), 1),
            _createElementVNode("td", null, _toDisplayString((_ctx.getNumberLGTypeTotal(_ctx.group3,'Daily')??0).formatShort()), 1),
            _createElementVNode("td", null, _toDisplayString(_ctx.getNumberLGTypeTotal(_ctx.group3,'EN')+_ctx.getNumberLGTypeTotal(_ctx.group3,'AC')+_ctx.getNumberLGTypeTotal(_ctx.group3,'ST')+_ctx.getNumberLGTypeTotal(_ctx.group3,'Daily')), 1)
          ])
        ])
      ])
    ])
  ]))
}