
import { Options, Vue } from 'vue-class-component';
import axios from "axios";
import address  from "@/variable";
import Excel from "exceljs";
import { useStore } from 'vuex';

@Options({
  components: {
  },
})
export default class List extends Vue {
  store = useStore()
  docs: Array<any> = [];
  filter1:any = {}
  show: number = 50;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  sort:any = {
    month:-1,
    year:-1,
  }

  mounted(){
    this.fetchData()
  }
  deleteItem(id: string) {
    var c = confirm("คุณแน่ใจหรือว่าต้องการลบรายการนี้");
    if (c) {
      axios.delete(`${address}/subcontractor/${id}`).then((response) => {
        this.fetchData();
      });
    }
  }
  clearPage() {
    if (this.show == 999999999) {
      this.page = 0;
      this.fetchData();
    }
  }
  fetchData(){
    this.docs = []
    let sort:any = this.sort
    let query = {
      search:{
        ...this.filter1
      },
      page:this.page,
      limit:this.show,
      skip:0,
      sort
    }
    axios.post(`${address}/subcontractors-paginate`,query).then((response) => {
      console.log(response)
      this.docs = response.data.docs;
      this.pages = response.data.pages;
      this.total = response.data.total;
    });
  }
}
