
import { Options, Vue } from "vue-class-component";
import address from "@/variable";
import axios from "axios";

@Options({
  components: {},
})
export default class Form extends Vue {
  doc: any = {
    member:[]
  };
  ledgers: Array<any> = [];
  year: number = new Date().getFullYear();

  getBooked(str:string){
    try {
      let split = str.split("/")
      let value = parseFloat(split[0])
      return value
    } catch (error) {
      return 0 
    }
  }
  getScrap(str:string){
    try {
      let split = str.split("/")
      let value = parseFloat(split[1])
      return value
    } catch (error) {
      return 0 
    }
  }

  getFromCodeCurrent(code: string) {
    try {
      let filter =  this.ledgers.filter(el=>{
        // console.log(el.code2, encodeURIComponent(code))
        return el.code2 == (code) && el.code1 == encodeURIComponent("LG Depre") && el.year == this.year
      })
      let map = filter.map(el=>el.amount)
      let sum = map.reduce((a:number,b:number)=>a+b,0)
      console.log(sum)
      return sum
    } catch (error) {
      console.log(error)
      return 0;
    }
  }
  getFromCodePrevious(code: string) {
    try {
      let filter =  this.ledgers.filter(el=>{
        return el.code2 == (code) && el.code1 == encodeURIComponent("LG Depre") && el.year < this.year
      })
      let map = filter.map(el=>el.amount)
      let sum = map.reduce((a:number,b:number)=>a+b,0)
      console.log(sum)
      return sum
    } catch (error) {
      console.log(error)
      return 0;
    }
  }

  getTotalBooked(codes: Array<string>) {
    let total = 0
    codes.forEach(code=>{
      total += this.getBooked(code)
    })
    return total
  }
  
  getTotalScrap(codes: Array<string>) {
    let total = 0
    codes.forEach(code=>{
      total += this.getScrap(code)
    })
    return total
  }

  getTotalCodeCurrent(codes: Array<string>) {
    let total = 0
    codes.forEach(code=>{
      total += this.getFromCodeCurrent(code)
    })
    return total
  }
  getTotalCodePrevious(codes: Array<string>) {
    let total = 0
    codes.forEach(code=>{
      total += this.getFromCodePrevious(code)
    })
    return total
  }
  mounted() {
    this.fetchData();
  }
  fetchData() {
    axios
      .get(`${address}/code-by-field/name/${encodeURI("Depre. LG & Lowering")}`)
      .then((response) => {
        this.doc = response.data;
      });
    let query = {
      search: {
        // job: this.$route.params.j,
        category: "Expense",
      },
      page: 1,
      limit: 9999999,
    };
    axios.post(`${address}/ledgers-paginate`, query).then((response) => {
      console.log(response);
      this.ledgers = response.data.docs;
    });
  }
}
