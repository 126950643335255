import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { style: {"list-style-type":"none"} }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "mt-1 bd-highlight" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.JobList, (item) => {
      return (_openBlock(), _createElementBlock("li", _hoisted_2, [
        _createVNode(_component_router_link, {
          class: "col-12 shadow btn btn-secondary",
          to: `/budget-agreement/j/${item[0].text2}`
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h5", _hoisted_4, "J." + _toDisplayString(item[0].text2) + ": " + _toDisplayString(item[0].text3), 1)
            ])
          ]),
          _: 2
        }, 1032, ["to"])
      ]))
    }), 256))
  ]))
}