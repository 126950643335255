import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "rounded shadow bg-white pb-3" }
const _hoisted_2 = { class: "d-flex justify-content-between px-4 py-3" }
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "table main-table" }
const _hoisted_6 = { class: "text-primary fw-bold" }
const _hoisted_7 = { class: "text-start" }
const _hoisted_8 = ["href"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  key: 1,
  class: "fad fa-cloud text-warning"
}
const _hoisted_11 = { class: "text-muted" }
const _hoisted_12 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JMEntriesItem = _resolveComponent("JMEntriesItem")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_JMSort = _resolveComponent("JMSort")!
  const _component_JMTablePaginate = _resolveComponent("JMTablePaginate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[19] || (_cache[19] = _createElementVNode("span", { class: "me-3" }, "Entries", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select me-3",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.show) = $event)),
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fetchData(),_ctx.clearPage()))
        }, [
          _createVNode(_component_JMEntriesItem)
        ], 544), [
          [_vModelSelect, _ctx.show]
        ]),
        _createVNode(_component_router_link, {
          class: "btn btn-primary me-3",
          to: "/presentation/form"
        }, {
          default: _withCtx(() => _cache[16] || (_cache[16] = [
            _createElementVNode("div", { class: "text-nowrap text-white" }, "Add Record", -1)
          ])),
          _: 1
        }),
        _createElementVNode("a", {
          class: "btn btn-outline-success",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$refs.uploadExcel.click()))
        }, _cache[17] || (_cache[17] = [
          _createElementVNode("div", { class: "text-nowrap" }, "Import Excel", -1)
        ])),
        _createElementVNode("input", {
          class: "d-none",
          id: "file-input",
          ref: "uploadExcel",
          type: "file",
          name: "name",
          onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.uploadExcel($event)))
        }, null, 544),
        _createElementVNode("a", {
          class: "ms-3",
          href: `${_ctx.publicPath}xlsx/itd_import_presentation.xlsx`
        }, "(Template)", 8, _hoisted_4),
        _createElementVNode("a", {
          class: "text-danger ms-3",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.deleteMany())),
          style: {"font-size":"0.7rem"}
        }, _cache[18] || (_cache[18] = [
          _createElementVNode("span", { class: "text-nowrap" }, "Delete Many (Caution!)", -1)
        ]))
      ]),
      _cache[20] || (_cache[20] = _createElementVNode("div", { class: "d-flex align-items-center" }, null, -1))
    ]),
    _createElementVNode("table", _hoisted_5, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _cache[26] || (_cache[26] = _createElementVNode("th", null, [
            _createElementVNode("span", null, "#")
          ], -1)),
          _createElementVNode("th", null, [
            _cache[21] || (_cache[21] = _createElementVNode("span", null, "Job", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "job",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", null, [
            _cache[22] || (_cache[22] = _createElementVNode("span", null, "Description", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "description",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", null, [
            _cache[23] || (_cache[23] = _createElementVNode("span", null, "Subcon/Supplier", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "supplier",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", null, [
            _cache[24] || (_cache[24] = _createElementVNode("span", null, "Code", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "code",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[12] || (_cache[12] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", null, [
            _cache[25] || (_cache[25] = _createElementVNode("span", null, "File", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "files",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[14] || (_cache[14] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _cache[27] || (_cache[27] = _createElementVNode("th", null, [
            _createElementVNode("span", null, "Actions")
          ], -1))
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.docs, (item, index) => {
          return (_openBlock(), _createElementBlock("tr", null, [
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_6, "#" + _toDisplayString(((_ctx.page-1)*_ctx.show)+(index+1)), 1)
            ]),
            _createElementVNode("td", null, "J." + _toDisplayString(item.job), 1),
            _createElementVNode("td", _hoisted_7, _toDisplayString(item.description), 1),
            _createElementVNode("td", null, _toDisplayString(item.supplier), 1),
            _createElementVNode("td", null, _toDisplayString(decodeURIComponent(item.code)), 1),
            _createElementVNode("td", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.files, (item) => {
                return (_openBlock(), _createElementBlock("a", {
                  href: `${_ctx.address}/${item}`,
                  target: "_blank"
                }, [
                  (item.search('.mp4')!=-1)
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 0,
                        class: "fas fa-file-video text-danger me-1",
                        type: "button",
                        "data-bs-toggle": "modal",
                        "data-bs-target": "#resolutionModal",
                        onClick: ($event: any) => (_ctx.showVideo(item))
                      }, null, 8, _hoisted_9))
                    : (_openBlock(), _createElementBlock("i", _hoisted_10))
                ], 8, _hoisted_8))
              }), 256))
            ]),
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_11, [
                (_ctx.store.state.accessLevel>=5)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: `/presentation/edit/${item._id}`
                    }, {
                      default: _withCtx(() => _cache[28] || (_cache[28] = [
                        _createElementVNode("i", { class: "class fal fa-pencil text-warning" }, null, -1)
                      ])),
                      _: 2
                    }, 1032, ["to"]))
                  : _createCommentVNode("", true),
                (_ctx.store.state.accessLevel>=5)
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 1,
                      class: "class fal fa-trash text-danger ms-2",
                      onClick: ($event: any) => (_ctx.deleteItem(item._id))
                    }, null, 8, _hoisted_12))
                  : _createCommentVNode("", true)
              ])
            ])
          ]))
        }), 256))
      ])
    ]),
    _createVNode(_component_JMTablePaginate, {
      page: _ctx.page,
      pages: _ctx.pages,
      show: _ctx.show,
      total: _ctx.total,
      onChange: _cache[15] || (_cache[15] = ($event: any) => {_ctx.page=$event; _ctx.fetchData()})
    }, null, 8, ["page", "pages", "show", "total"])
  ]))
}