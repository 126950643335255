
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import address from "@/variable";
import { useStore } from "vuex";
import * as _ from "lodash";

@Options({
  components: {},
})
export default class Summary extends Vue {
  store = useStore();
  docs: Array<any> = [];
  lgs: Array<any> = [];
  code1: Array<any> = [];
  group1: Array<any> = [];
  get jobs() {
    let results = _.uniqBy(this.group1, "job");
    results = results.map((el) => {
      return (el._id ?? {}).job ?? "";
    });
    if (results.find((g) => g == "2496") == undefined) {
      results.push("2496");
    }
    results = _.sortBy(results);
    return results
  }

  getNumberJob(array: Array<any>, job: string, type: string) {
    let found = array.find((el) => {
      return el.job == job && el.type == type;
    });
    return (found ?? {}).count ?? 0;
  }
  getNumberType(array: Array<any>, type: string) {
    let found = array.filter((el) => {
      return el.type == type;
    });
    return found
      .map((el) => el.count ?? 0)
      .reduce((a: number, b: number) => a + b, 0);
  }
  mounted() {
    axios.get(`${address}/summary/equipment/1/`).then((response) => {
      console.log(response);
      this.group1 = response.data.map((el: any) => {
        return {
          ...el,
          type: decodeURIComponent(el._id.code1) ?? "",
          job: el._id.job ?? "",
        };
      });
    });
    axios
      .get(`${address}/code-by-field/name/Equipment Code 1`)
      .then((response) => {
        this.code1 = response.data.member;
      });

    axios
      .get(`${address}/code-by-field/name/Launching Girders`)
      .then((response) => {
        this.lgs = response.data.member;
      });
  }
}
