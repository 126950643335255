
import { Options, Vue } from 'vue-class-component';
import axios from "axios";
import address  from "@/variable";
import { useStore } from 'vuex';

@Options({
  components: {
  },
})
export default class List extends Vue {
  store = useStore()
  docs: Array<any> = [];
  total: number = 0;
  show: number = 50;
  page: number = 1;
  pages: number = 0;
  sort:any = {
    month:-1,
    year:-1,
  }
  details: Array<any> = [];
  sortKey:string = "name"
  sortDir:number = 1
  publicPath = "";
  search1:string = ""
  search2:string = ""
  search3:string = ""
  filter1:any = {}
  filter2:any = {}

  deleteMany(){
    let promises: Array<Promise<any>> = [];
    this.docs.forEach(el=>{
      promises.push(axios.delete(`${address}/jack/${el._id}`));
    })
    Promise.all(promises).then((responses) => {
      console.log(responses);
      this.fetchData();
    });
  }

  async uploadExcel(ev: any) {
    const Excel = require("exceljs/dist/es5/exceljs.browser");
    let self = this;
    let file = ev.target.files[0];
    const workbook = new Excel.Workbook();
    let book = await workbook.xlsx.load(file);
    let sheet = book.getWorksheet("import");
    let arr: Array<any> = [];
    sheet.eachRow(function(row: any, rowNumber: any) {
      if (rowNumber > 1 && row.getCell(1).value != null) {
        arr.push({
          job: row.getCell("A").value,
          lg: row.getCell("B").value,
          detail: row.getCell("C").value,
          capacity: row.getCell("D").value,
          brand: row.getCell("E").value,
          series: row.getCell("F").value,
          acting: row.getCell("G").value,
          diameter: row.getCell("H").value,
          height: row.getCell("I").value,
          stroke: row.getCell("J").value,
          status: row.getCell("K").value,
          qty: row.getCell("L").value,
          remarks: row.getCell("M").value,
        });
      }
    });
    let promises: Array<Promise<any>> = [];
    arr.forEach((el) => {
      promises.push(axios.post(`${address}/jack`, el));
    });
    Promise.all(promises).then((responses) => {
      console.log(responses);
      this.fetchData();
    });
  }
  mounted(){
    this.fetchData()
    this.publicPath = process.env.BASE_URL;
    axios.get(`${address}/code-by-field/name/Jack Detail`).then((response) => {
      this.details = response.data.member;
    });
  }
  updatePaginate(i: number) {
    this.page = i;
    // console.log(this.page);
    this.fetchData();
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }
  clearPage() {
    if (this.show == 999999999) {
      this.page = 0;
      this.fetchData();
    }
  }
  deleteItem(id: string) {
    var c = confirm("คุณแน่ใจหรือว่าต้องการลบรายการนี้");
    if (c) {
      axios.delete(`${address}/jack/${id}`).then((response) => {
        this.fetchData();
      });
    }
  }
  fetchData(){
    this.docs = []
    let sort:any = this.sort
    let query = {
      search:{
            detail: this.search1!=""?{ $regex: this.search1, $options: '-i'  }:undefined,
            series: this.search2!=""?{ $regex: this.search2, $options: '-i'  }:undefined,
            capacity: this.search3!=""?{ $regex: this.search3, $options: '-i'  }:undefined,
            ...this.filter1,
            ...this.filter2,
        },
      page:this.page,
      limit:this.show,
      skip:0,
      sort
    }
    axios.post(`${address}/jacks-paginate`,query).then((response) => {
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
    });
  }
}
