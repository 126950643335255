
import { Options, Vue } from "vue-class-component";
import address from "@/variable";
import axios from "axios";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class Form extends Vue {
  store = useStore()
  doc: any = {
    job:"2369"
  };
  // updateAmount(){
  //   this.doc.amount = parseFloat(((this.doc.length??0)*(this.doc.rate??0)).toFixed(2))
  // }
  mounted() {
    if (this.$route.params.id != undefined) this.fetchData();
  }
  fetchData() {
    axios.get(`${address}/ledger/${this.$route.params.id}`).then((response) => {
      this.doc = response.data;
      // this.doc.code1 = decodeURIComponent(this.doc.code1);
      // this.doc.code2 = decodeURIComponent(this.doc.code2);
      // this.doc.code3 = decodeURIComponent(this.doc.code3);
      this.doc.amount = parseFloat((this.doc.amount ?? 0).toFixed(2));
    });
  }
  submitForm() {
    if (this.$route.params.id != undefined) {
      axios
        .patch(`${address}/ledger/${this.doc._id}`, {
          ...this.doc,
          // code1: encodeURIComponent(this.doc.code1),
          // code2: encodeURIComponent(this.doc.code2),
          // code3: encodeURIComponent(this.doc.code3),
          amount: parseFloat((this.doc.amount ?? 0).toFixed(2)),
        })
        .then((response) => {
          alert("แก้ไขข้อมูลแล้ว");
          this.$router.push("/cost");
        });
    } else {
      axios
        .post(`${address}/ledger`, {
          ...this.doc,
          // code1: encodeURIComponent(this.doc.code1),
          // code2: encodeURIComponent(this.doc.code2),
          // code3: encodeURIComponent(this.doc.code3),
          amount: parseFloat((this.doc.amount ?? 0).toFixed(2)),
        })
        .then((response) => {
          alert("บันทึกข้อมูลแล้ว");
          this.$router.push("/cost");
        });
    }
  }
}
