
import { Options, Vue } from "vue-class-component";
import address from "@/variable";
import axios from "axios";
import * as _ from "lodash";

@Options({
  components: {},
})
export default class Form extends Vue {
  jobs: Array<any> = [];
  ledgers: Array<any> = [];
  year = new Date().getFullYear();
  _ = _;
  getPrevious(cat: string, job: string) {
    try {
      let input = _.chain(
        cat == "Income" ? this.getIncome(job) : this.getExpense(job)
      )
        .values()
        .flatten()
        .filter((el: any) => el.year != this.year)
        .map((el: any) => el.amount);
      let result: any = input.reduce((a: any, b: any) => a + b, 0);
      result = parseFloat(result);
      return result;
    } catch (error) {
      return 0;
    }
  }
  getCurrent(cat: string, job: string) {
    try {
      let input = _.chain(
        cat == "Income" ? this.getIncome(job) : this.getExpense(job)
      )
        .values()
        .flatten()
        .filter((el: any) => el.year == this.year)
        .map((el: any) => el.amount);
      let result: any = input.reduce((a: any, b: any) => a + b, 0);
      result = parseFloat(result);
      return result;
    } catch (error) {
      return 0;
    }
  }
  percent(l: number, r: number) {
    let result = (l / r) * 100;
    result = parseFloat(result.toFixed(2));
    if (Number.isFinite(result)) return result;
    else return 0;
  }
  getBudget(code: string, job: string) {
    let foundJob = this.jobs.find((el) => el.j == job);
    let foundBudget = foundJob.budget.find(
      (el: any) => decodeURIComponent(el.code) == decodeURIComponent(code)
    ) ?? { amount: 0 };
    return foundBudget.amount;
  }

  //New
  getBudgetWork(job: string) {
    let foundJob = this.jobs.find((el) => el.j == job);
    let foundBudget = foundJob.budget.filter((el: any) => {
      return decodeURIComponent(el.code) != "Engineering Service" && el.name != "TOTAL LENGTH (m)" && el.amount > 0;
    });
    let map = foundBudget.map((el: any) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }
  getBudgetService(job: string) {
    let foundJob = this.jobs.find((el) => el.j == job);
    let foundBudget = foundJob.budget.filter((el: any) => {
      return decodeURIComponent(el.code) == "Engineering Service" && el.name != "TOTAL LENGTH (m)" && el.amount > 0;
    });
    let map = foundBudget.map((el: any) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }
  getBudgetErection(job: string) {
    let foundJob = this.jobs.find((el) => el.j == job);
    let foundBudget = foundJob.budget.filter((el: any) => {
      return decodeURIComponent(el.code) != "Engineering Service" && el.name != "TOTAL LENGTH (m)" && el.amount > 0;
    });
    let map = foundBudget.map((el: any) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce - this.getBudgetDepre(job);
  }
  getBudgetDepre(job: string) {
    let foundJob = this.jobs.find((el) => el.j == job);
    let foundBudget = foundJob.budget.filter((el: any) => {
      return decodeURIComponent(el.code) == "LG Depre" && el.name != "TOTAL LENGTH (m)" && el.amount > 0;
    });
    let map = foundBudget.map((el: any) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }
  //New 2
  getPreviousWork(job: string) {
    let foundBudget = this.ledgers.filter((el: any) => {
      return (
        el.job == job &&
        decodeURIComponent(el.code1) != "Engineering Payment" &&
        el.year != this.year &&
        el.category == "Income"
      );
    });
    let map = foundBudget.map((el: any) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }
  getCurrentWork(job: string) {
    let foundBudget = this.ledgers.filter((el: any) => {
      return (
        el.job == job &&
        decodeURIComponent(el.code1) != "Engineering Payment" &&
        el.year == this.year &&
        el.category == "Income"
      );
    });
    let map = foundBudget.map((el: any) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }
  getPreviousService(job: string) {
    let foundBudget = this.ledgers.filter((el: any) => {
      return (
        el.job == job &&
        decodeURIComponent(el.code1) == "Engineering Payment" &&
        el.year != this.year &&
        el.category == "Income"
      );
    });
    let map = foundBudget.map((el: any) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }
  getCurrentService(job: string) {
    let foundBudget = this.ledgers.filter((el: any) => {
      return (
        el.job == job &&
        decodeURIComponent(el.code1) == "Engineering Payment" &&
        el.year == this.year &&
        el.category == "Income"
      );
    });
    let map = foundBudget.map((el: any) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }
  getPreviousErection(job: string) {
    let foundBudget = this.ledgers.filter((el: any) => {
      return (
        el.job == job &&
        decodeURIComponent(el.code1) != "LG Depre" &&
        el.year != this.year &&
        el.category == "Expense"
      );
    });
    let map = foundBudget.map((el: any) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }
  getCurrentErection(job: string) {
    let foundBudget = this.ledgers.filter((el: any) => {
      return (
        el.job == job &&
        decodeURIComponent(el.code1) != "LG Depre" &&
        el.year == this.year &&
        el.category == "Expense"
      );
    });
    let map = foundBudget.map((el: any) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }
  getPreviousDepre(job: string) {
    let foundBudget = this.ledgers.filter((el: any) => {
      return (
        el.job == job &&
        decodeURIComponent(el.code1) == "LG Depre" &&
        el.year != this.year &&
        el.category == "Expense"
      );
    });
    let map = foundBudget.map((el: any) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }
  getCurrentDepre(job: string) {
    let foundBudget = this.ledgers.filter((el: any) => {
      return (
        el.job == job &&
        decodeURIComponent(el.code1) == "LG Depre" &&
        el.year == this.year &&
        el.category == "Expense"
      );
    });
    let map = foundBudget.map((el: any) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }

  //New 3
  getTotalBudgetWork() {
    let foundJob:Array<any> = []
    this.jobs.forEach((el) => {
      foundJob.push(...el.budget)
    });
    let foundBudget = foundJob.filter((el: any) => {
      return decodeURIComponent(el.code) != "Engineering Service" && el.name != "TOTAL LENGTH (m)" && el.amount > 0;
    });
    let map = foundBudget.map((el: any) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }
  getTotalBudgetService() {
    let foundJob:Array<any> = []
    this.jobs.forEach((el) => {
      foundJob.push(...el.budget)
    });
    let foundBudget = foundJob.filter((el: any) => {
      return decodeURIComponent(el.code) == "Engineering Service" && el.name != "TOTAL LENGTH (m)" && el.amount > 0;
    });
    let map = foundBudget.map((el: any) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }
  getTotalBudgetErection() {
    let foundJob:Array<any> = []
    this.jobs.forEach((el) => {
      foundJob.push(...el.budget)
    });
    let foundBudget = foundJob.filter((el: any) => {
      return decodeURIComponent(el.code) != "Engineering Service" && el.name != "TOTAL LENGTH (m)" && el.amount > 0;
    });
    let map = foundBudget.map((el: any) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce - this.getTotalBudgetDepre();
  }
  getTotalBudgetDepre() {
    let foundJob:Array<any> = []
    this.jobs.forEach((el) => {
      foundJob.push(...el.budget)
    });
    let foundBudget = foundJob.filter((el: any) => {
      return decodeURIComponent(el.code) == "LG Depre" && el.name != "TOTAL LENGTH (m)" && el.amount > 0;
    });
    let map = foundBudget.map((el: any) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }

  
  getTotalPreviousWork() {
    let foundBudget = this.ledgers.filter((el: any) => {
      return (
        (el.job == "2452" || el.job == "2496" || el.job == "2501") &&
        decodeURIComponent(el.code1) != "Engineering Payment" &&
        el.year != this.year &&
        el.category == "Income"
      );
    });
    let map = foundBudget.map((el: any) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }
  getTotalCurrentWork() {
    let foundBudget = this.ledgers.filter((el: any) => {
      return (
        (el.job == "2452" || el.job == "2496" || el.job == "2501") &&
        decodeURIComponent(el.code1) != "Engineering Payment" &&
        el.year == this.year &&
        el.category == "Income"
      );
    });
    let map = foundBudget.map((el: any) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }
  getTotalPreviousService() {
    let foundBudget = this.ledgers.filter((el: any) => {
      return (
        (el.job == "2452" || el.job == "2496" || el.job == "2501") &&
        decodeURIComponent(el.code1) == "Engineering Payment" &&
        el.year != this.year &&
        el.category == "Income"
      );
    });
    let map = foundBudget.map((el: any) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }
  getTotalCurrentService() {
    let foundBudget = this.ledgers.filter((el: any) => {
      return (
        (el.job == "2452" || el.job == "2496" || el.job == "2501") &&
        decodeURIComponent(el.code1) == "Engineering Payment" &&
        el.year == this.year &&
        el.category == "Income"
      );
    });
    let map = foundBudget.map((el: any) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }
  getTotalPreviousErection() {
    let foundBudget = this.ledgers.filter((el: any) => {
      return (
        (el.job == "2452" || el.job == "2496" || el.job == "2501") &&
        decodeURIComponent(el.code1) != "LG Depre" &&
        el.year != this.year &&
        el.category == "Expense"
      );
    });
    let map = foundBudget.map((el: any) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }
  getTotalCurrentErection() {
    // return 6
    let foundBudget = this.ledgers.filter((el: any) => {
      return (
        (el.job == "2452" || el.job == "2496" || el.job == "2501") &&
        decodeURIComponent(el.code1) != "LG Depre" &&
        el.year == this.year &&
        el.category == "Expense"
      );
    });
    let map = foundBudget.map((el: any) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }
  getTotalPreviousDepre() {
    let foundBudget = this.ledgers.filter((el: any) => {
      return (
        (el.job == "2452" || el.job == "2496" || el.job == "2501") &&
        decodeURIComponent(el.code1) == "LG Depre" &&
        el.year != this.year &&
        el.category == "Expense"
      );
    });
    let map = foundBudget.map((el: any) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }
  getTotalCurrentDepre() {
    let foundBudget = this.ledgers.filter((el: any) => {
      return (
        (el.job == "2452" || el.job == "2496" || el.job == "2501") &&
        decodeURIComponent(el.code1) == "LG Depre" &&
        el.year == this.year &&
        el.category == "Expense"
      );
    });
    let map = foundBudget.map((el: any) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }
















  getIncome(job: string) {
    let input = this.ledgers.filter(
      (el) => el.job == job && el.category == "Income"
    );
    let result = _.chain(input).groupBy("code1");
    return result;
  }
  getExpense(job: string) {
    let input = this.ledgers.filter(
      (el) => el.job == job && el.category == "Expense"
    );
    let result = _.chain(input).groupBy("code1");
    return result;
  }
  mounted() {
    this.fetchData();
  }
  fetchData() {
    axios
      .post(`${address}/jobs-paginate`, {
        search: {
          category: { $nin: ["Other Job Site", "Etc."] },
        },
        page: 1,
        limit: 99999,
      })
      .then((response) => {
        this.jobs = response.data.docs;
        this.jobs = this.jobs.filter((el) => el.j != "2369");
      });
    let query = {
      search: {},
      page: 1,
      limit: 9999999,
    };
    axios.post(`${address}/ledgers-paginate`, query).then((response) => {
      console.log(response);
      this.ledgers = response.data.docs;
    });
  }
}
