import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "rounded shadow bg-white pb-3" }
const _hoisted_2 = { class: "table main-table" }
const _hoisted_3 = { class: "fw-bold" }
const _hoisted_4 = { class: "text-start" }
const _hoisted_5 = { class: "text-end" }
const _hoisted_6 = { class: "text-end" }
const _hoisted_7 = { class: "text-end" }
const _hoisted_8 = { class: "text-end" }
const _hoisted_9 = { class: "blue" }
const _hoisted_10 = { class: "text-end" }
const _hoisted_11 = { class: "text-end" }
const _hoisted_12 = { class: "text-end" }
const _hoisted_13 = { class: "text-end" }
const _hoisted_14 = { class: "text-end" }
const _hoisted_15 = { class: "blue" }
const _hoisted_16 = { class: "text-end" }
const _hoisted_17 = { class: "fw-bold text-end" }
const _hoisted_18 = { class: "fw-bold text-end" }
const _hoisted_19 = { class: "fw-bold text-end" }
const _hoisted_20 = { class: "fw-bold text-end blue" }
const _hoisted_21 = { class: "fw-bold text-end" }
const _hoisted_22 = { class: "text-end" }
const _hoisted_23 = { class: "text-end" }
const _hoisted_24 = { class: "text-end" }
const _hoisted_25 = { class: "text-end" }
const _hoisted_26 = { class: "blue" }
const _hoisted_27 = { class: "text-end" }
const _hoisted_28 = { class: "text-end" }
const _hoisted_29 = { class: "text-end" }
const _hoisted_30 = { class: "text-end" }
const _hoisted_31 = { class: "text-end" }
const _hoisted_32 = { class: "blue" }
const _hoisted_33 = { class: "text-end" }
const _hoisted_34 = { class: "fw-bold text-end" }
const _hoisted_35 = { class: "fw-bold text-end" }
const _hoisted_36 = { class: "fw-bold text-end" }
const _hoisted_37 = { class: "fw-bold text-end blue" }
const _hoisted_38 = { class: "fw-bold text-end" }
const _hoisted_39 = { class: "fw-bold text-end" }
const _hoisted_40 = {
  key: 0,
  class: "fw-bold text-end"
}
const _hoisted_41 = {
  key: 1,
  class: "fw-bold text-end text-danger"
}
const _hoisted_42 = {
  key: 0,
  class: "fw-bold text-end"
}
const _hoisted_43 = {
  key: 1,
  class: "fw-bold text-end text-danger"
}
const _hoisted_44 = {
  key: 0,
  class: "fw-bold text-end"
}
const _hoisted_45 = {
  key: 1,
  class: "fw-bold text-end text-danger"
}
const _hoisted_46 = {
  key: 0,
  class: "fw-bold text-end"
}
const _hoisted_47 = {
  key: 1,
  class: "fw-bold text-end text-danger"
}
const _hoisted_48 = { class: "text-end" }
const _hoisted_49 = { class: "text-end" }
const _hoisted_50 = { class: "text-end" }
const _hoisted_51 = { class: "text-end blue" }
const _hoisted_52 = { class: "text-end" }
const _hoisted_53 = { class: "text-end" }
const _hoisted_54 = { class: "text-end" }
const _hoisted_55 = { class: "text-end" }
const _hoisted_56 = { class: "text-end blue" }
const _hoisted_57 = { class: "text-end" }
const _hoisted_58 = { class: "text-end" }
const _hoisted_59 = { class: "text-end" }
const _hoisted_60 = { class: "text-end" }
const _hoisted_61 = { class: "text-end blue" }
const _hoisted_62 = { class: "text-end" }
const _hoisted_63 = { class: "text-end" }
const _hoisted_64 = { class: "text-end" }
const _hoisted_65 = { class: "text-end" }
const _hoisted_66 = { class: "text-end blue" }
const _hoisted_67 = { class: "text-end" }
const _hoisted_68 = { class: "text-end" }
const _hoisted_69 = { class: "text-end" }
const _hoisted_70 = { class: "text-end" }
const _hoisted_71 = { class: "text-end blue" }
const _hoisted_72 = { class: "text-end" }
const _hoisted_73 = { class: "text-end" }
const _hoisted_74 = { class: "text-end" }
const _hoisted_75 = { class: "text-end" }
const _hoisted_76 = { class: "text-end blue" }
const _hoisted_77 = { class: "text-end" }
const _hoisted_78 = { class: "text-end" }
const _hoisted_79 = {
  key: 0,
  class: "fw-bold text-end"
}
const _hoisted_80 = {
  key: 1,
  class: "fw-bold text-end text-danger"
}
const _hoisted_81 = { class: "text-end" }
const _hoisted_82 = {
  key: 0,
  class: "fw-bold text-end"
}
const _hoisted_83 = {
  key: 1,
  class: "fw-bold text-end text-danger"
}
const _hoisted_84 = { class: "text-end" }
const _hoisted_85 = {
  key: 0,
  class: "fw-bold text-end"
}
const _hoisted_86 = {
  key: 1,
  class: "fw-bold text-end text-danger"
}
const _hoisted_87 = { class: "text-end" }
const _hoisted_88 = {
  key: 0,
  class: "fw-bold text-end"
}
const _hoisted_89 = {
  key: 1,
  class: "fw-bold text-end text-danger"
}
const _hoisted_90 = { class: "text-end" }
const _hoisted_91 = {
  key: 0,
  class: "fw-bold text-end"
}
const _hoisted_92 = {
  key: 1,
  class: "fw-bold text-end text-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[43] || (_cache[43] = _createElementVNode("h2", null, "SUMMARY SITE OPERATION ", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("table", _hoisted_2, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _cache[0] || (_cache[0] = _createElementVNode("th", null, [
              _createElementVNode("span", null, "ITEM")
            ], -1)),
            _cache[1] || (_cache[1] = _createElementVNode("th", null, [
              _createElementVNode("span", null, "DESCRIPTION")
            ], -1)),
            _cache[2] || (_cache[2] = _createElementVNode("th", null, [
              _createElementVNode("span", null, "BUDGET")
            ], -1)),
            _createElementVNode("th", null, [
              _createElementVNode("span", null, "UP TO Y" + _toDisplayString(_ctx.year-1), 1)
            ]),
            _createElementVNode("th", null, [
              _createElementVNode("span", null, "Y" + _toDisplayString(_ctx.year), 1)
            ]),
            _cache[3] || (_cache[3] = _createElementVNode("th", null, [
              _createElementVNode("span", { class: "blue" }, "ACC.")
            ], -1)),
            _cache[4] || (_cache[4] = _createElementVNode("th", null, [
              _createElementVNode("span", null, "%")
            ], -1))
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobs, (item, i) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              _createElementVNode("tr", _hoisted_3, [
                _createElementVNode("td", null, _toDisplayString(i+1), 1),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_4, _toDisplayString(item.name) + " : (J." + _toDisplayString(item.j) + ")", 1)
                ]),
                _cache[5] || (_cache[5] = _createElementVNode("td", null, null, -1)),
                _cache[6] || (_cache[6] = _createElementVNode("td", null, null, -1)),
                _cache[7] || (_cache[7] = _createElementVNode("td", null, null, -1)),
                _cache[8] || (_cache[8] = _createElementVNode("td", null, null, -1)),
                _cache[9] || (_cache[9] = _createElementVNode("td", null, null, -1))
              ]),
              _cache[24] || (_cache[24] = _createElementVNode("tr", null, [
                _createElementVNode("td"),
                _createElementVNode("td", { class: "text-start" }, [
                  _createElementVNode("div", { class: "fw-bold ms-3" }, "Income")
                ]),
                _createElementVNode("td", { colspan: "10" })
              ], -1)),
              _createElementVNode("tr", null, [
                _cache[10] || (_cache[10] = _createElementVNode("td", null, null, -1)),
                _cache[11] || (_cache[11] = _createElementVNode("td", { class: "text-start" }, [
                  _createElementVNode("div", { class: "ms-5" }, "Segment & Bearing Erection Service")
                ], -1)),
                _createElementVNode("td", _hoisted_5, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.getBudgetWork(item.j).formatShort()), 1)
                ]),
                _createElementVNode("td", _hoisted_6, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.getPreviousWork(item.j).formatShort()), 1)
                ]),
                _createElementVNode("td", _hoisted_7, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.getCurrentWork(item.j).formatShort()), 1)
                ]),
                _createElementVNode("td", _hoisted_8, [
                  _createElementVNode("span", _hoisted_9, _toDisplayString((_ctx.getPreviousWork(item.j)+_ctx.getCurrentWork(item.j)).formatShort()), 1)
                ]),
                _createElementVNode("td", _hoisted_10, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.percent((_ctx.getPreviousWork(item.j)+_ctx.getCurrentWork(item.j)),_ctx.getBudgetWork(item.j))) + "%", 1)
                ])
              ]),
              _createElementVNode("tr", null, [
                _cache[12] || (_cache[12] = _createElementVNode("td", null, null, -1)),
                _cache[13] || (_cache[13] = _createElementVNode("td", { class: "text-start" }, [
                  _createElementVNode("div", { class: "ms-5" }, "Engineering Service")
                ], -1)),
                _createElementVNode("td", _hoisted_11, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.getBudgetService(item.j).formatShort()), 1)
                ]),
                _createElementVNode("td", _hoisted_12, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.getPreviousService(item.j).formatShort()), 1)
                ]),
                _createElementVNode("td", _hoisted_13, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.getCurrentService(item.j).formatShort()), 1)
                ]),
                _createElementVNode("td", _hoisted_14, [
                  _createElementVNode("span", _hoisted_15, _toDisplayString((_ctx.getPreviousService(item.j)+_ctx.getCurrentService(item.j)).formatShort()), 1)
                ]),
                _createElementVNode("td", _hoisted_16, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.percent((_ctx.getPreviousService(item.j)+_ctx.getCurrentService(item.j)),_ctx.getBudgetService(item.j))) + "%", 1)
                ])
              ]),
              _createElementVNode("tr", null, [
                _cache[14] || (_cache[14] = _createElementVNode("td", null, null, -1)),
                _cache[15] || (_cache[15] = _createElementVNode("td", { class: "text-start" }, [
                  _createElementVNode("div", { class: "fw-bold ms-5" }, "Total Income")
                ], -1)),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_17, _toDisplayString((_ctx.getBudgetWork(item.j)+_ctx.getBudgetService(item.j)).formatShort()), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.getPrevious('Income',item.j).formatShort()), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.getCurrent('Income',item.j).formatShort()), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_20, _toDisplayString((_ctx.getPrevious('Income',item.j)+_ctx.getCurrent('Income',item.j)).formatShort()), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.percent((_ctx.getPrevious('Income',item.j)+_ctx.getCurrent('Income',item.j)),(_ctx.getBudgetWork(item.j)+_ctx.getBudgetService(item.j)))) + "%", 1)
                ])
              ]),
              _cache[25] || (_cache[25] = _createElementVNode("tr", null, [
                _createElementVNode("td"),
                _createElementVNode("td", { class: "text-start" }, [
                  _createElementVNode("div", { class: "fw-bold ms-3" }, "Expense")
                ]),
                _createElementVNode("td", { colspan: "10" })
              ], -1)),
              _createElementVNode("tr", null, [
                _cache[16] || (_cache[16] = _createElementVNode("td", null, null, -1)),
                _cache[17] || (_cache[17] = _createElementVNode("td", { class: "text-start" }, [
                  _createElementVNode("div", { class: "ms-5" }, "Segment & Bearing Erection")
                ], -1)),
                _createElementVNode("td", _hoisted_22, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.getBudgetErection(item.j).formatShort()), 1)
                ]),
                _createElementVNode("td", _hoisted_23, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.getPreviousErection(item.j).formatShort()), 1)
                ]),
                _createElementVNode("td", _hoisted_24, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.getCurrentErection(item.j).formatShort()), 1)
                ]),
                _createElementVNode("td", _hoisted_25, [
                  _createElementVNode("span", _hoisted_26, _toDisplayString((_ctx.getPreviousErection(item.j)+_ctx.getCurrentErection(item.j)).formatShort()), 1)
                ]),
                _createElementVNode("td", _hoisted_27, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.percent((_ctx.getPreviousErection(item.j)+_ctx.getCurrentErection(item.j)),_ctx.getBudgetErection(item.j))) + "%", 1)
                ])
              ]),
              _createElementVNode("tr", null, [
                _cache[18] || (_cache[18] = _createElementVNode("td", null, null, -1)),
                _cache[19] || (_cache[19] = _createElementVNode("td", { class: "text-start" }, [
                  _createElementVNode("div", { class: "ms-5" }, "Depre. LG ")
                ], -1)),
                _createElementVNode("td", _hoisted_28, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.getBudgetDepre(item.j).formatShort()), 1)
                ]),
                _createElementVNode("td", _hoisted_29, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.getPreviousDepre(item.j).formatShort()), 1)
                ]),
                _createElementVNode("td", _hoisted_30, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.getCurrentDepre(item.j).formatShort()), 1)
                ]),
                _createElementVNode("td", _hoisted_31, [
                  _createElementVNode("span", _hoisted_32, _toDisplayString((_ctx.getPreviousDepre(item.j)+_ctx.getCurrentDepre(item.j)).formatShort()), 1)
                ]),
                _createElementVNode("td", _hoisted_33, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.percent((_ctx.getPreviousDepre(item.j)+_ctx.getCurrentDepre(item.j)),_ctx.getBudgetDepre(item.j))) + "%", 1)
                ])
              ]),
              _createElementVNode("tr", null, [
                _cache[20] || (_cache[20] = _createElementVNode("td", null, null, -1)),
                _cache[21] || (_cache[21] = _createElementVNode("td", { class: "text-start" }, [
                  _createElementVNode("div", { class: "fw-bold ms-5" }, "Total Expense")
                ], -1)),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_34, _toDisplayString((_ctx.getBudgetErection(item.j)+_ctx.getBudgetDepre(item.j)).formatShort()), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.getPrevious('Expense',item.j).formatShort()), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.getCurrent('Expense',item.j).formatShort()), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_37, _toDisplayString((_ctx.getPrevious('Expense',item.j)+_ctx.getCurrent('Expense',item.j)).formatShort()), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_38, _toDisplayString(_ctx.percent((_ctx.getPrevious('Expense',item.j)+_ctx.getCurrent('Expense',item.j)),(_ctx.getBudgetErection(item.j)+_ctx.getBudgetDepre(item.j)))) + "%", 1)
                ])
              ]),
              _createElementVNode("tr", null, [
                _cache[22] || (_cache[22] = _createElementVNode("td", null, null, -1)),
                _cache[23] || (_cache[23] = _createElementVNode("td", { class: "text-start" }, [
                  _createElementVNode("div", { class: "fw-bold ms-3" }, "Income - Expense")
                ], -1)),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_39, _toDisplayString(((_ctx.getBudgetWork(item.j)+_ctx.getBudgetService(item.j)) - (_ctx.getBudgetErection(item.j)+_ctx.getBudgetDepre(item.j))).formatShort()), 1)
                ]),
                _createElementVNode("td", null, [
                  (((_ctx.getPrevious('Income',item.j)-_ctx.getPrevious('Expense',item.j)))>=0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                        _createElementVNode("span", null, _toDisplayString((_ctx.getPrevious('Income',item.j)-_ctx.getPrevious('Expense',item.j)).formatShort()), 1)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_41, [
                        _createElementVNode("span", null, "(" + _toDisplayString((_ctx.getPrevious('Income',item.j)-_ctx.getPrevious('Expense',item.j)).formatShort()) + ")", 1)
                      ]))
                ]),
                _createElementVNode("td", null, [
                  (((_ctx.getCurrent('Income',item.j)-_ctx.getCurrent('Expense',item.j)))>=0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                        _createElementVNode("span", null, _toDisplayString(((_ctx.getCurrent('Income',item.j)-_ctx.getCurrent('Expense',item.j))).formatShort()), 1)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_43, [
                        _createElementVNode("span", null, "(" + _toDisplayString(((_ctx.getCurrent('Income',item.j)-_ctx.getCurrent('Expense',item.j))).formatShort()) + ")", 1)
                      ]))
                ]),
                _createElementVNode("td", null, [
                  (((_ctx.getPrevious('Income',item.j)+_ctx.getCurrent('Income',item.j))-(_ctx.getPrevious('Expense',item.j)+_ctx.getCurrent('Expense',item.j)))>=0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                        _createElementVNode("span", null, _toDisplayString(((_ctx.getPrevious('Income',item.j)+_ctx.getCurrent('Income',item.j))-(_ctx.getPrevious('Expense',item.j)+_ctx.getCurrent('Expense',item.j))).formatShort()), 1)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_45, [
                        _createElementVNode("span", null, "(" + _toDisplayString(((_ctx.getPrevious('Income',item.j)+_ctx.getCurrent('Income',item.j))-(_ctx.getPrevious('Expense',item.j)+_ctx.getCurrent('Expense',item.j))).formatShort()) + ")", 1)
                      ]))
                ]),
                _createElementVNode("td", null, [
                  ((_ctx.percent((_ctx.getPrevious('Income',item.j)+_ctx.getCurrent('Income',item.j)),(_ctx.getBudgetWork(item.j)+_ctx.getBudgetService(item.j))) - _ctx.percent((_ctx.getPrevious('Expense',item.j)+_ctx.getCurrent('Expense',item.j)),(_ctx.getBudgetErection(item.j)+_ctx.getBudgetDepre(item.j))))>0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
                        _createElementVNode("span", null, _toDisplayString((_ctx.percent((_ctx.getPrevious('Income',item.j)+_ctx.getCurrent('Income',item.j)),(_ctx.getBudgetWork(item.j)+_ctx.getBudgetService(item.j))) - _ctx.percent((_ctx.getPrevious('Expense',item.j)+_ctx.getCurrent('Expense',item.j)),(_ctx.getBudgetErection(item.j)+_ctx.getBudgetDepre(item.j)))).toFixed(2)) + "%", 1)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_47, [
                        _createElementVNode("span", null, "(" + _toDisplayString((_ctx.percent((_ctx.getPrevious('Income',item.j)+_ctx.getCurrent('Income',item.j)),(_ctx.getBudgetWork(item.j)+_ctx.getBudgetService(item.j))) - _ctx.percent((_ctx.getPrevious('Expense',item.j)+_ctx.getCurrent('Expense',item.j)),(_ctx.getBudgetErection(item.j)+_ctx.getBudgetDepre(item.j)))).toFixed(2)) + "%)", 1)
                      ]))
                ])
              ])
            ], 64))
          }), 256)),
          _cache[40] || (_cache[40] = _createElementVNode("tr", null, [
            _createElementVNode("td", null, [
              _createElementVNode("div", { class: "fw-bold" }, "4")
            ]),
            _createElementVNode("td", { class: "text-start" }, [
              _createElementVNode("div", { class: "fw-bold" }, "TOTAL SITE OPERATION")
            ]),
            _createElementVNode("td", { colspan: "10" })
          ], -1)),
          _cache[41] || (_cache[41] = _createElementVNode("tr", null, [
            _createElementVNode("td"),
            _createElementVNode("td", { class: "text-start" }, [
              _createElementVNode("div", { class: "fw-bold ms-3" }, "Income")
            ]),
            _createElementVNode("td", { colspan: "10" })
          ], -1)),
          _createElementVNode("tr", null, [
            _cache[26] || (_cache[26] = _createElementVNode("td", null, null, -1)),
            _cache[27] || (_cache[27] = _createElementVNode("td", { class: "text-start" }, [
              _createElementVNode("div", { class: "ms-5" }, "Segment & Bearing Erection Service")
            ], -1)),
            _createElementVNode("td", _hoisted_48, _toDisplayString(_ctx.getTotalBudgetWork().formatShort()), 1),
            _createElementVNode("td", _hoisted_49, _toDisplayString(_ctx.getTotalPreviousWork().formatShort()), 1),
            _createElementVNode("td", _hoisted_50, _toDisplayString(_ctx.getTotalCurrentWork().formatShort()), 1),
            _createElementVNode("td", _hoisted_51, _toDisplayString((_ctx.getTotalPreviousWork()+_ctx.getTotalCurrentWork()).formatShort()), 1),
            _createElementVNode("td", _hoisted_52, _toDisplayString(_ctx.percent((_ctx.getTotalPreviousWork()+_ctx.getTotalCurrentWork()),_ctx.getTotalBudgetWork()).toFixed(2)) + "%", 1)
          ]),
          _createElementVNode("tr", null, [
            _cache[28] || (_cache[28] = _createElementVNode("td", null, null, -1)),
            _cache[29] || (_cache[29] = _createElementVNode("td", { class: "text-start" }, [
              _createElementVNode("div", { class: "ms-5" }, "Engineering Service")
            ], -1)),
            _createElementVNode("td", _hoisted_53, _toDisplayString(_ctx.getTotalBudgetService().formatShort()), 1),
            _createElementVNode("td", _hoisted_54, _toDisplayString(_ctx.getTotalPreviousService().formatShort()), 1),
            _createElementVNode("td", _hoisted_55, _toDisplayString(_ctx.getTotalCurrentService().formatShort()), 1),
            _createElementVNode("td", _hoisted_56, _toDisplayString((_ctx.getTotalPreviousService()+_ctx.getTotalCurrentService()).formatShort()), 1),
            _createElementVNode("td", _hoisted_57, _toDisplayString(_ctx.percent((_ctx.getTotalPreviousService()+_ctx.getTotalCurrentService()),_ctx.getTotalBudgetService()).toFixed(2)) + "%", 1)
          ]),
          _createElementVNode("tr", null, [
            _cache[30] || (_cache[30] = _createElementVNode("td", null, null, -1)),
            _cache[31] || (_cache[31] = _createElementVNode("td", { class: "text-start" }, [
              _createElementVNode("div", { class: "fw-bold ms-5" }, "Total Income")
            ], -1)),
            _createElementVNode("td", _hoisted_58, _toDisplayString((_ctx.getTotalBudgetWork()+_ctx.getTotalBudgetService()).formatShort()), 1),
            _createElementVNode("td", _hoisted_59, _toDisplayString((_ctx.getTotalPreviousWork()+_ctx.getTotalPreviousService()).formatShort()), 1),
            _createElementVNode("td", _hoisted_60, _toDisplayString((_ctx.getTotalCurrentWork()+_ctx.getTotalCurrentService()).formatShort()), 1),
            _createElementVNode("td", _hoisted_61, _toDisplayString((_ctx.getTotalPreviousWork()+_ctx.getTotalPreviousService()+_ctx.getTotalCurrentWork()+_ctx.getTotalCurrentService()).formatShort()), 1),
            _createElementVNode("td", _hoisted_62, _toDisplayString(_ctx.percent((_ctx.getTotalPreviousWork()+_ctx.getTotalPreviousService()+_ctx.getTotalCurrentWork()+_ctx.getTotalCurrentService()),(_ctx.getTotalBudgetWork()+_ctx.getTotalBudgetService())).toFixed(2)) + "%", 1)
          ]),
          _cache[42] || (_cache[42] = _createElementVNode("tr", null, [
            _createElementVNode("td"),
            _createElementVNode("td", { class: "text-start" }, [
              _createElementVNode("div", { class: "fw-bold ms-3" }, "Expense")
            ]),
            _createElementVNode("td", { colspan: "10" })
          ], -1)),
          _createElementVNode("tr", null, [
            _cache[32] || (_cache[32] = _createElementVNode("td", null, null, -1)),
            _cache[33] || (_cache[33] = _createElementVNode("td", { class: "text-start" }, [
              _createElementVNode("div", { class: "ms-5" }, "Segment & Bearing Erection")
            ], -1)),
            _createElementVNode("td", _hoisted_63, _toDisplayString(_ctx.getTotalBudgetErection().formatShort()), 1),
            _createElementVNode("td", _hoisted_64, _toDisplayString(_ctx.getTotalPreviousErection().formatShort()), 1),
            _createElementVNode("td", _hoisted_65, _toDisplayString(_ctx.getTotalCurrentErection().formatShort()), 1),
            _createElementVNode("td", _hoisted_66, _toDisplayString((_ctx.getTotalPreviousErection()+_ctx.getTotalCurrentErection()).formatShort()), 1),
            _createElementVNode("td", _hoisted_67, _toDisplayString(_ctx.percent((_ctx.getTotalPreviousErection()+_ctx.getTotalCurrentErection()),_ctx.getTotalBudgetErection()).toFixed(2)) + "%", 1)
          ]),
          _createElementVNode("tr", null, [
            _cache[34] || (_cache[34] = _createElementVNode("td", null, null, -1)),
            _cache[35] || (_cache[35] = _createElementVNode("td", { class: "text-start" }, [
              _createElementVNode("div", { class: "ms-5" }, "Depre. LG")
            ], -1)),
            _createElementVNode("td", _hoisted_68, _toDisplayString(_ctx.getTotalBudgetDepre().formatShort()), 1),
            _createElementVNode("td", _hoisted_69, _toDisplayString(_ctx.getTotalPreviousDepre().formatShort()), 1),
            _createElementVNode("td", _hoisted_70, _toDisplayString(_ctx.getTotalCurrentDepre().formatShort()), 1),
            _createElementVNode("td", _hoisted_71, _toDisplayString((_ctx.getTotalPreviousDepre()+_ctx.getTotalCurrentDepre()).formatShort()), 1),
            _createElementVNode("td", _hoisted_72, _toDisplayString(_ctx.percent((_ctx.getTotalPreviousDepre()+_ctx.getTotalCurrentDepre()),_ctx.getTotalBudgetDepre()).toFixed(2)) + "%", 1)
          ]),
          _createElementVNode("tr", null, [
            _cache[36] || (_cache[36] = _createElementVNode("td", null, null, -1)),
            _cache[37] || (_cache[37] = _createElementVNode("td", { class: "text-start" }, [
              _createElementVNode("div", { class: "fw-bold ms-5" }, "Total Expense")
            ], -1)),
            _createElementVNode("td", _hoisted_73, _toDisplayString((_ctx.getTotalBudgetErection()+_ctx.getTotalBudgetDepre()).formatShort()), 1),
            _createElementVNode("td", _hoisted_74, _toDisplayString((_ctx.getTotalPreviousErection()+_ctx.getTotalPreviousDepre()).formatShort()), 1),
            _createElementVNode("td", _hoisted_75, _toDisplayString((_ctx.getTotalCurrentErection()+_ctx.getTotalCurrentDepre()).formatShort()), 1),
            _createElementVNode("td", _hoisted_76, _toDisplayString((_ctx.getTotalPreviousErection()+_ctx.getTotalPreviousDepre()+_ctx.getTotalCurrentErection()+_ctx.getTotalCurrentDepre()).formatShort()), 1),
            _createElementVNode("td", _hoisted_77, _toDisplayString(_ctx.percent((_ctx.getTotalPreviousErection()+_ctx.getTotalPreviousDepre()+_ctx.getTotalCurrentErection()+_ctx.getTotalCurrentDepre()),(_ctx.getTotalBudgetErection()+_ctx.getTotalBudgetDepre())).toFixed(2)) + "%", 1)
          ]),
          _createElementVNode("tr", null, [
            _cache[38] || (_cache[38] = _createElementVNode("td", null, null, -1)),
            _cache[39] || (_cache[39] = _createElementVNode("td", { class: "text-start" }, [
              _createElementVNode("div", { class: "fw-bold ms-3" }, "Income - Expense")
            ], -1)),
            _createElementVNode("td", _hoisted_78, [
              (((_ctx.getTotalBudgetWork()+_ctx.getTotalBudgetService()) - (_ctx.getTotalBudgetErection()+_ctx.getTotalBudgetDepre()))>=0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_79, [
                    _createElementVNode("span", null, _toDisplayString(((_ctx.getTotalBudgetWork()+_ctx.getTotalBudgetService()) - (_ctx.getTotalBudgetErection()+_ctx.getTotalBudgetDepre())).formatShort()), 1)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_80, [
                    _createElementVNode("span", null, _toDisplayString(((_ctx.getTotalBudgetWork()+_ctx.getTotalBudgetService()) - (_ctx.getTotalBudgetErection()+_ctx.getTotalBudgetDepre())).formatShort()), 1)
                  ]))
            ]),
            _createElementVNode("td", _hoisted_81, [
              (((_ctx.getTotalPreviousWork()+_ctx.getTotalPreviousService()) - (_ctx.getTotalPreviousErection()+_ctx.getTotalPreviousDepre()))>=0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_82, [
                    _createElementVNode("span", null, _toDisplayString(((_ctx.getTotalPreviousWork()+_ctx.getTotalPreviousService()) - (_ctx.getTotalPreviousErection()+_ctx.getTotalPreviousDepre())).formatShort()), 1)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_83, [
                    _createElementVNode("span", null, "(" + _toDisplayString(((_ctx.getTotalPreviousWork()+_ctx.getTotalPreviousService()) - (_ctx.getTotalPreviousErection()+_ctx.getTotalPreviousDepre())).formatShort()) + ")", 1)
                  ]))
            ]),
            _createElementVNode("td", _hoisted_84, [
              (((_ctx.getTotalCurrentWork()+_ctx.getTotalCurrentService()) - (_ctx.getTotalCurrentErection()+_ctx.getTotalCurrentDepre()))>=0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_85, [
                    _createElementVNode("span", null, _toDisplayString(((_ctx.getTotalCurrentWork()+_ctx.getTotalCurrentService()) - (_ctx.getTotalCurrentErection()+_ctx.getTotalCurrentDepre())).formatShort()), 1)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_86, [
                    _createElementVNode("span", null, "(" + _toDisplayString(((_ctx.getTotalCurrentWork()+_ctx.getTotalCurrentService()) - (_ctx.getTotalCurrentErection()+_ctx.getTotalCurrentDepre())).formatShort()) + ")", 1)
                  ]))
            ]),
            _createElementVNode("td", _hoisted_87, [
              ((((_ctx.getTotalPreviousWork()+_ctx.getTotalPreviousService()+_ctx.getTotalCurrentWork()+_ctx.getTotalCurrentService())) - (_ctx.getTotalPreviousErection()+_ctx.getTotalPreviousDepre()+_ctx.getTotalCurrentErection()+_ctx.getTotalCurrentDepre()) )>0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_88, [
                    _createElementVNode("span", null, _toDisplayString(( ((_ctx.getTotalPreviousWork()+_ctx.getTotalPreviousService()+_ctx.getTotalCurrentWork()+_ctx.getTotalCurrentService())) - (_ctx.getTotalPreviousErection()+_ctx.getTotalPreviousDepre()+_ctx.getTotalCurrentErection()+_ctx.getTotalCurrentDepre()) ).formatShort()), 1)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_89, [
                    _createElementVNode("span", null, "(" + _toDisplayString(( ((_ctx.getTotalPreviousWork()+_ctx.getTotalPreviousService()+_ctx.getTotalCurrentWork()+_ctx.getTotalCurrentService())) - (_ctx.getTotalPreviousErection()+_ctx.getTotalPreviousDepre()+_ctx.getTotalCurrentErection()+_ctx.getTotalCurrentDepre()) ).formatShort()) + ")", 1)
                  ]))
            ]),
            _createElementVNode("td", _hoisted_90, [
              ((_ctx.percent((_ctx.getTotalPreviousWork()+_ctx.getTotalPreviousService()+_ctx.getTotalCurrentWork()+_ctx.getTotalCurrentService()),(_ctx.getTotalBudgetWork()+_ctx.getTotalBudgetService()))-_ctx.percent((_ctx.getTotalPreviousErection()+_ctx.getTotalPreviousDepre()+_ctx.getTotalCurrentErection()+_ctx.getTotalCurrentDepre()),(_ctx.getTotalBudgetErection()+_ctx.getTotalBudgetDepre())))>=0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_91, [
                    _createElementVNode("span", null, _toDisplayString((_ctx.percent((_ctx.getTotalPreviousWork()+_ctx.getTotalPreviousService()+_ctx.getTotalCurrentWork()+_ctx.getTotalCurrentService()),(_ctx.getTotalBudgetWork()+_ctx.getTotalBudgetService()))-_ctx.percent((_ctx.getTotalPreviousErection()+_ctx.getTotalPreviousDepre()+_ctx.getTotalCurrentErection()+_ctx.getTotalCurrentDepre()),(_ctx.getTotalBudgetErection()+_ctx.getTotalBudgetDepre()))).toFixed(2)) + "%", 1)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_92, [
                    _createElementVNode("span", null, "(" + _toDisplayString((_ctx.percent((_ctx.getTotalPreviousWork()+_ctx.getTotalPreviousService()+_ctx.getTotalCurrentWork()+_ctx.getTotalCurrentService()),(_ctx.getTotalBudgetWork()+_ctx.getTotalBudgetService()))-_ctx.percent((_ctx.getTotalPreviousErection()+_ctx.getTotalPreviousDepre()+_ctx.getTotalCurrentErection()+_ctx.getTotalCurrentDepre()),(_ctx.getTotalBudgetErection()+_ctx.getTotalBudgetDepre()))).toFixed(2)) + "%)", 1)
                  ]))
            ])
          ])
        ])
      ])
    ])
  ], 64))
}