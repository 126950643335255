
import { Options, Vue } from "vue-class-component";
import address from "@/variable";
import axios from "axios";
import { useStore } from "vuex";

@Options({
  components: {},
  props: {
    job: String,
    activeIndex: Number,
  },
})
export default class Form extends Vue {
  store = useStore();
  doc: any = {
    group: "ไม่ระบุ",
  };
  address = address;
  filePath = address;
  groups: Array<any> = [];
  activeIndex?: number;
  mounted() {
    axios
      .get(`${address}/code-by-field/name/Drawing Group`)
      .then((response) => {
        // console.log(response.data.member)
        this.groups = response.data.member;
      });
    if (this.$route.params.id != undefined) this.fetchData();
    else {
    }
  }

  get group() {
    var val = ["ไม่ระบุ"];
    var text1 = ""
    try {
      let find = this.groups.find(o=>o.text2==this.doc.job && o.name == encodeURIComponent(this.doc.category))
      text1 = find.text1;
    } catch (error) {
      return val;
    }
    try {
      let find = this.groups.find(o=>o.text2==this.doc.job && o.name == encodeURIComponent(this.doc.category))
      text1 = find.text1.split(",");
      return [...text1,...val];
    } catch (error) {
      return val;
    }
  }
  get category() {
    try {
      return this.groups.filter((o) => o.text2 == this.doc.job);
    } catch (error) {
      return [];
    }
  }

  pushMember() {
    if (!Array.isArray(this.doc.member)) this.doc.member = [];
    this.doc.member.push({
      name: "",
      text1: "",
      // category:"",
      // text2:"",
      // text3:"",
      // number1:0,
      // number2:0,
      // number3:0,
      // boolean1:false,
      // boolean2:false,
      // boolean3:false,
    });
  }
  fetchData() {
    axios
      .get(`${address}/pierschedule/${this.$route.params.id}`)
      .then((response) => {
        let data = response.data;
        (data.member ?? []).forEach((el: any) => {
          el.name = decodeURI(el.name);
        });
        this.doc = data;
      });
  }
  submitForm() {
    let prep: any = JSON.parse(JSON.stringify(this.doc));
    (prep.member ?? []).forEach((el: any) => {
      el.name = encodeURI(el.name);
    });
    if (this.$route.params.id != undefined) {
      axios
        .patch(`${address}/pierschedule/${this.doc._id}`, prep)
        .then((response) => {
          alert("แก้ไขข้อมูลแล้ว");
          this.$router.push("/pierschedules");
        });
    } else {
      axios
        .post(`${address}/pierschedule`, {
          ...prep,
        })
        .then((response) => {
          alert("บันทึกข้อมูลแล้ว");
          this.$router.push("/pierSchedules");
        });
    }
  }
}
