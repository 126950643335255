
import { Options, Vue } from 'vue-class-component';
import axios from "axios";
import address  from "@/variable";
import { useStore } from 'vuex';

@Options({
  components: {
  },
})
export default class SummaryList extends Vue {
  store = useStore()
  filter1:any = {}
  docs: Array<any> = [];
  show: number = 50;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  sort:any = {
    job:1,
  }
  mounted(){
    this.fetchData()
  }
  fetchData(){
    this.docs = []
    let sort:any = this.sort
    let query = {
      search:{
        category:"Construction Site"
      },
      page:this.page,
      limit:this.show,
      skip:0,
      sort
    }
    axios.post(`${address}/jobs-paginate`,query).then((response) => {
      this.docs = response.data.docs;
      this.pages = response.data.pages;
      this.total = response.data.total;
    });
  }
}
