import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container rounded shadow bg-white px-4 pb-5 pt-3" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "col-sm-2 mb-2" }
const _hoisted_5 = { class: "col-sm-2 mb-2" }
const _hoisted_6 = { class: "col-sm-4 mb-2" }
const _hoisted_7 = { class: "col-sm-4 mb-2" }
const _hoisted_8 = { class: "col-sm-4 mb-2" }
const _hoisted_9 = { class: "col-sm-4 mb-2" }
const _hoisted_10 = { class: "col-sm-4 mb-2" }
const _hoisted_11 = { class: "col-sm-4 mb-2" }
const _hoisted_12 = ["value"]
const _hoisted_13 = { class: "col-sm-4 mb-2" }
const _hoisted_14 = ["value"]
const _hoisted_15 = { class: "col-sm-4 mb-2" }
const _hoisted_16 = ["value"]
const _hoisted_17 = { class: "col-sm-4 mb-2" }
const _hoisted_18 = { class: "col-sm-4 mb-2" }
const _hoisted_19 = { class: "col-sm-4 mb-2" }
const _hoisted_20 = { class: "col-sm-4 mb-2" }
const _hoisted_21 = { class: "col-sm-4 mb-2" }
const _hoisted_22 = { class: "col-sm-4 mb-2" }
const _hoisted_23 = { class: "text-start fw-bold mt-3" }
const _hoisted_24 = { class: "table table-striped" }
const _hoisted_25 = { class: "align-middle" }
const _hoisted_26 = ["onUpdate:modelValue"]
const _hoisted_27 = ["onUpdate:modelValue"]
const _hoisted_28 = ["onUpdate:modelValue"]
const _hoisted_29 = ["onUpdate:modelValue"]
const _hoisted_30 = ["onUpdate:modelValue"]
const _hoisted_31 = ["onClick"]
const _hoisted_32 = { class: "col-sm-12 mb-2" }
const _hoisted_33 = { class: "row g-3 d-flex justify-content-center" }
const _hoisted_34 = { class: "col-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JMCalendar = _resolveComponent("JMCalendar")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.params.id == undefined)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, "Add Form"))
      : (_openBlock(), _createElementBlock("h2", _hoisted_3, "Edit Form")),
    _createElementVNode("form", {
      class: "row needs-validation",
      onSubmit: _cache[19] || (_cache[19] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_4, [
        _cache[20] || (_cache[20] = _createElementVNode("label", { class: "form-label" }, "Code", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.doc.code) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.code]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[21] || (_cache[21] = _createElementVNode("label", { class: "form-label" }, "Prefix", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.doc.prefix) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.prefix]
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[22] || (_cache[22] = _createElementVNode("label", { class: "form-label" }, "First Name", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.doc.firstName) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.firstName]
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _cache[23] || (_cache[23] = _createElementVNode("label", { class: "form-label" }, "Last Name", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.doc.lastName) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.lastName]
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_JMCalendar, {
          label: "Date Of Birth",
          modelValue: _ctx.doc.birthDate,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.doc.birthDate) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _cache[24] || (_cache[24] = _createElementVNode("label", { class: "form-label" }, "Rate", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "number",
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.doc.rate) = $event))
        }, null, 512), [
          [
            _vModelText,
            _ctx.doc.rate,
            void 0,
            { number: true }
          ]
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _cache[25] || (_cache[25] = _createElementVNode("label", { class: "form-label" }, "Type", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.doc.type) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.types, (item) => {
            return (_openBlock(), _createElementBlock("option", null, _toDisplayString(item.name), 1))
          }), 256))
        ], 512), [
          [_vModelSelect, _ctx.doc.type]
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _cache[26] || (_cache[26] = _createElementVNode("label", { class: "form-label" }, "Job Payroll", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.doc.jobPayroll) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobs, (item) => {
            return (_openBlock(), _createElementBlock("option", {
              value: item.text2
            }, "J." + _toDisplayString(item.text2), 9, _hoisted_12))
          }), 256))
        ], 512), [
          [_vModelSelect, _ctx.doc.jobPayroll]
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _cache[27] || (_cache[27] = _createElementVNode("label", { class: "form-label" }, "Job Working", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.doc.jobWorking) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobs, (item) => {
            return (_openBlock(), _createElementBlock("option", {
              value: item.text2
            }, "J." + _toDisplayString(item.text2), 9, _hoisted_14))
          }), 256))
        ], 512), [
          [_vModelSelect, _ctx.doc.jobWorking]
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _cache[28] || (_cache[28] = _createElementVNode("label", { class: "form-label" }, "Position", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.doc.position) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.position, (item) => {
            return (_openBlock(), _createElementBlock("option", { value: item }, _toDisplayString(item), 9, _hoisted_16))
          }), 256))
        ], 512), [
          [_vModelSelect, _ctx.doc.position]
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _cache[30] || (_cache[30] = _createElementVNode("label", { class: "form-label" }, "Condition", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.doc.condition) = $event))
        }, _cache[29] || (_cache[29] = [
          _createElementVNode("option", null, "Under LGSC", -1),
          _createElementVNode("option", null, "Transferred", -1)
        ]), 512), [
          [_vModelSelect, _ctx.doc.condition]
        ])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _cache[32] || (_cache[32] = _createElementVNode("label", { class: "form-label" }, "LG No.", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.doc.lg) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (item) => {
            return (_openBlock(), _createElementBlock("option", null, _toDisplayString(decodeURIComponent(item.name)), 1))
          }), 256)),
          _cache[31] || (_cache[31] = _createElementVNode("option", null, "ไม่ระบุ", -1))
        ], 512), [
          [_vModelSelect, _ctx.doc.lg]
        ])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createVNode(_component_JMCalendar, {
          label: "Date Of Employement",
          modelValue: _ctx.doc.hiredDate,
          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.doc.hiredDate) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createVNode(_component_JMCalendar, {
          label: "INSURANCE",
          modelValue: _ctx.doc.insurance,
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.doc.insurance) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_21, [
        _createVNode(_component_JMCalendar, {
          label: "4 Phu Training",
          modelValue: _ctx.doc.training,
          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.doc.training) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_22, [
        _createVNode(_component_JMCalendar, {
          label: "Working at Height Training",
          modelValue: _ctx.doc.workingAtHeight,
          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.doc.workingAtHeight) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _cache[41] || (_cache[41] = _createElementVNode("hr", { class: "mt-3" }, null, -1)),
      _createElementVNode("h3", _hoisted_23, [
        _cache[34] || (_cache[34] = _createTextVNode("Educations")),
        _createElementVNode("a", {
          class: "btn btn-outline-primary ms-2",
          onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.pushItem('educations')))
        }, _cache[33] || (_cache[33] = [
          _createElementVNode("i", { class: "fas fa-plus-circle me-2" }, null, -1),
          _createElementVNode("span", null, "Add", -1)
        ]))
      ]),
      _createElementVNode("table", _hoisted_24, [
        _cache[37] || (_cache[37] = _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", null, "Sort"),
            _createElementVNode("th", null, "Academic Level"),
            _createElementVNode("th", null, "Degree"),
            _createElementVNode("th", null, "Major"),
            _createElementVNode("th", null, "Institute"),
            _createElementVNode("th", null, "Academic Year"),
            _createElementVNode("th", null, [
              _createElementVNode("i", { class: "fas fa-ellipsis-h" })
            ])
          ])
        ], -1)),
        _createVNode(_component_draggable, {
          modelValue: _ctx.doc.educations,
          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.doc.educations) = $event)),
          tag: "tbody",
          handle: ".handle",
          "item-key": "acedemicYear"
        }, {
          item: _withCtx(({element, index}) => [
            _createElementVNode("tr", _hoisted_25, [
              _cache[36] || (_cache[36] = _createElementVNode("td", null, [
                _createElementVNode("i", { class: "fal fa-bars handle" })
              ], -1)),
              _createElementVNode("td", null, [
                _withDirectives(_createElementVNode("input", {
                  class: "form-control",
                  type: "text",
                  "onUpdate:modelValue": ($event: any) => ((element.academicLevel) = $event)
                }, null, 8, _hoisted_26), [
                  [_vModelText, element.academicLevel]
                ])
              ]),
              _createElementVNode("td", null, [
                _withDirectives(_createElementVNode("input", {
                  class: "form-control",
                  type: "text",
                  "onUpdate:modelValue": ($event: any) => ((element.degree) = $event)
                }, null, 8, _hoisted_27), [
                  [_vModelText, element.degree]
                ])
              ]),
              _createElementVNode("td", null, [
                _withDirectives(_createElementVNode("input", {
                  class: "form-control",
                  type: "text",
                  "onUpdate:modelValue": ($event: any) => ((element.major) = $event)
                }, null, 8, _hoisted_28), [
                  [_vModelText, element.major]
                ])
              ]),
              _createElementVNode("td", null, [
                _withDirectives(_createElementVNode("input", {
                  class: "form-control",
                  type: "text",
                  "onUpdate:modelValue": ($event: any) => ((element.institute) = $event)
                }, null, 8, _hoisted_29), [
                  [_vModelText, element.institute]
                ])
              ]),
              _createElementVNode("td", null, [
                _withDirectives(_createElementVNode("input", {
                  class: "form-control",
                  type: "text",
                  "onUpdate:modelValue": ($event: any) => ((element.academicYear) = $event)
                }, null, 8, _hoisted_30), [
                  [_vModelText, element.academicYear]
                ])
              ]),
              _createElementVNode("td", null, [
                _createElementVNode("a", {
                  class: "btn delete",
                  onClick: ($event: any) => (_ctx.doc.educations.splice(index,1))
                }, _cache[35] || (_cache[35] = [
                  _createElementVNode("i", { class: "fas fa-trash-alt text-danger" }, null, -1)
                ]), 8, _hoisted_31)
              ])
            ])
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_32, [
        _cache[38] || (_cache[38] = _createElementVNode("label", { class: "form-label" }, "Remarks", -1)),
        _withDirectives(_createElementVNode("textarea", {
          class: "form-control",
          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.doc.remarks) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.remarks]
        ])
      ]),
      _createElementVNode("div", _hoisted_33, [
        _createElementVNode("div", _hoisted_34, [
          _createVNode(_component_router_link, {
            class: "w-100 btn btn-secondary text-white btn-lg mt-4",
            to: "/manpower"
          }, {
            default: _withCtx(() => _cache[39] || (_cache[39] = [
              _createTextVNode("ย้อนกลับ")
            ])),
            _: 1
          })
        ]),
        _cache[40] || (_cache[40] = _createElementVNode("div", { class: "col-5" }, [
          _createElementVNode("button", {
            class: "w-100 btn btn-primary btn-lg mt-4 text-white",
            id: "save",
            type: "submit"
          }, "บันทึก")
        ], -1))
      ])
    ], 32)
  ]))
}