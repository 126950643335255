import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view, {
    key: _ctx.tabIndex,
    job: _ctx.job,
    category: _ctx.category,
    groups: _ctx.groups
  }, null, 8, ["job", "category", "groups"]))
}