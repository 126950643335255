import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "rounded shadow bg-white pb-3 mb-3" }
const _hoisted_2 = { class: "summary-container summary-01" }
const _hoisted_3 = { class: "text-muted" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-sm-6" }
const _hoisted_6 = { class: "table main-table" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "col-sm-6" }
const _hoisted_10 = { ref: "chart1" }
const _hoisted_11 = { class: "table main-table mt-5" }
const _hoisted_12 = { class: "bg-warning" }
const _hoisted_13 = ["colspan"]
const _hoisted_14 = { class: "fw-bold" }
const _hoisted_15 = ["colspan"]
const _hoisted_16 = { class: "bg-warning" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[60] || (_cache[60] = _createElementVNode("h2", null, "SUMMARY ERECTION PROGRESS", -1)),
      _createElementVNode("h2", _hoisted_3, "J." + _toDisplayString(_ctx.$route.params.job), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("table", _hoisted_6, [
            _createElementVNode("tbody", null, [
              _createElementVNode("tr", null, [
                _cache[0] || (_cache[0] = _createElementVNode("td", null, "Total Segment Viaduct", -1)),
                _cache[1] || (_cache[1] = _createElementVNode("td", null, "deck", -1)),
                _createElementVNode("td", null, _toDisplayString(_ctx.erections.length), 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[2] || (_cache[2] = _createElementVNode("td", null, "Finished", -1)),
                _cache[3] || (_cache[3] = _createElementVNode("td", null, "deck", -1)),
                _createElementVNode("td", null, _toDisplayString(_ctx.progresses.filter((el) => el.segment).length), 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[4] || (_cache[4] = _createElementVNode("td", null, "% Finished", -1)),
                _cache[5] || (_cache[5] = _createElementVNode("td", null, null, -1)),
                _createElementVNode("td", null, _toDisplayString((((_ctx.progresses.filter((el) => el.segment) ?? []).length ?? 0) / (_ctx.erections.length ?? 0)).formatPercent()) + "%", 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[6] || (_cache[6] = _createElementVNode("td", null, "Start Erection", -1)),
                _cache[7] || (_cache[7] = _createElementVNode("td", null, null, -1)),
                _createElementVNode("td", null, [
                  (_ctx.progressesLength==0)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, "NaN"))
                    : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.startDate.formatDate()), 1))
                ])
              ]),
              _createElementVNode("tr", null, [
                _cache[8] || (_cache[8] = _createElementVNode("td", null, "Time Elapse", -1)),
                _cache[9] || (_cache[9] = _createElementVNode("td", null, "day", -1)),
                _createElementVNode("td", null, _toDisplayString(_ctx.timeElapse), 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[10] || (_cache[10] = _createElementVNode("td", null, "AVG. Cycle Time", -1)),
                _cache[11] || (_cache[11] = _createElementVNode("td", null, "day/deck", -1)),
                _createElementVNode("td", null, [
                  _createElementVNode("span", null, _toDisplayString(((_ctx.timeElapse * 2) / _ctx.progresses.filter((el) => el.segment).length).toFixed(2)), 1)
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("canvas", _hoisted_10, null, 512)
        ])
      ]),
      _createElementVNode("table", _hoisted_11, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _cache[12] || (_cache[12] = _createElementVNode("th", null, null, -1)),
            _cache[13] || (_cache[13] = _createElementVNode("th", null, null, -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (lg) => {
              return (_openBlock(), _createElementBlock("th", null, _toDisplayString(_ctx.renderFullName(lg)), 1))
            }), 256))
          ])
        ]),
        _createElementVNode("tbody", null, [
          _createElementVNode("tr", null, [
            _cache[14] || (_cache[14] = _createElementVNode("td", { class: "text-start" }, "Start Erection", -1)),
            _cache[15] || (_cache[15] = _createElementVNode("td", null, null, -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (lg) => {
              return (_openBlock(), _createElementBlock("td", null, _toDisplayString(_ctx.getMinDate(_ctx.getLGMap(lg, 'startDate')).formatDate()), 1))
            }), 256))
          ]),
          _createElementVNode("tr", null, [
            _cache[16] || (_cache[16] = _createElementVNode("td", { class: "text-start" }, "Finished Erection", -1)),
            _cache[17] || (_cache[17] = _createElementVNode("td", null, "deck", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (lg) => {
              return (_openBlock(), _createElementBlock("td", null, _toDisplayString(_ctx.getLGMap(lg, 'segment').map((el) => (el ? 1 : 0)).reduce((a, b) => a + b, 0)), 1))
            }), 256))
          ]),
          _createElementVNode("tr", null, [
            _cache[18] || (_cache[18] = _createElementVNode("td", { class: "text-start" }, "Period", -1)),
            _cache[19] || (_cache[19] = _createElementVNode("td", null, "day", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (lg) => {
              return (_openBlock(), _createElementBlock("td", null, _toDisplayString(_ctx.getDiffNow(_ctx.getMinDate(_ctx.getLGMap(lg, 'startDate')))), 1))
            }), 256))
          ]),
          _createElementVNode("tr", _hoisted_12, [
            _cache[20] || (_cache[20] = _createElementVNode("th", { class: "text-start" }, "Calendar Cycle Time", -1)),
            _cache[21] || (_cache[21] = _createElementVNode("th", null, "day/deck", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (lg) => {
              return (_openBlock(), _createElementBlock("th", null, _toDisplayString(( _ctx.getDiffNow(_ctx.getMinDate(_ctx.getLGMap(lg, 'startDate'))) / _ctx.getLGMap(lg, 'segment').map((el) => (el ? 1 : 0)).reduce((a, b) => a + b, 0) ).toFixed(1)), 1))
            }), 256))
          ]),
          _createElementVNode("tr", null, [
            _cache[22] || (_cache[22] = _createElementVNode("td", { class: "text-start" }, "Payment Segment", -1)),
            _cache[23] || (_cache[23] = _createElementVNode("td", null, "deck", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (lg) => {
              return (_openBlock(), _createElementBlock("td", null, _toDisplayString(_ctx.getLGMap(lg, 'segmentPayment').map((el) => (el ? 1 : 0)).reduce((a, b) => a + b, 0)), 1))
            }), 256))
          ]),
          _createElementVNode("tr", null, [
            _createElementVNode("td", {
              colspan: 2 + _ctx.lgs.length,
              style: {"height":"2.5rem"}
            }, null, 8, _hoisted_13)
          ]),
          _createElementVNode("tr", _hoisted_14, [
            _cache[24] || (_cache[24] = _createElementVNode("td", { class: "text-start" }, "LG Testing", -1)),
            _cache[25] || (_cache[25] = _createElementVNode("td", null, "day", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (lg) => {
              return (_openBlock(), _createElementBlock("td", null, _toDisplayString(_ctx.getLGActivity(lg).filter((el) => decodeURIComponent(el.code1) == 'LG Testing').map((el) => el.period ?? 0).reduce((a, b) => a + b, 0).formatShort()), 1))
            }), 256))
          ]),
          _createElementVNode("tr", null, [
            _createElementVNode("td", {
              colspan: 2 + _ctx.lgs.length,
              style: {"height":"2.5rem"}
            }, null, 8, _hoisted_15)
          ]),
          _createElementVNode("tr", null, [
            _cache[26] || (_cache[26] = _createElementVNode("td", { class: "text-start" }, "LG Breakdown", -1)),
            _cache[27] || (_cache[27] = _createElementVNode("td", null, null, -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (lg) => {
              return (_openBlock(), _createElementBlock("td", null, _toDisplayString(lg), 1))
            }), 256))
          ]),
          _createElementVNode("tr", null, [
            _cache[28] || (_cache[28] = _createElementVNode("td", { class: "text-start ps-5" }, "Accident", -1)),
            _cache[29] || (_cache[29] = _createElementVNode("td", null, "Day", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (lg) => {
              return (_openBlock(), _createElementBlock("td", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.getLGActivity(lg).filter((el) => decodeURIComponent(el.code2) == 'Accident').map((el) => el.period ?? 0).reduce((a, b) => a + b, 0).formatShort()), 1)
              ]))
            }), 256))
          ]),
          _createElementVNode("tr", null, [
            _cache[30] || (_cache[30] = _createElementVNode("td", { class: "text-start ps-5" }, "Increment Weather", -1)),
            _cache[31] || (_cache[31] = _createElementVNode("td", null, "Day", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (lg) => {
              return (_openBlock(), _createElementBlock("td", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.getLGActivity(lg).filter((el) => decodeURIComponent(el.code2) == 'Increment Weather').map((el) => el.period ?? 0).reduce((a, b) => a + b, 0).formatShort()), 1)
              ]))
            }), 256))
          ]),
          _createElementVNode("tr", null, [
            _cache[32] || (_cache[32] = _createElementVNode("td", { class: "text-start ps-5" }, "Sub-Structure", -1)),
            _cache[33] || (_cache[33] = _createElementVNode("td", null, "Day", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (lg) => {
              return (_openBlock(), _createElementBlock("td", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.getLGActivity(lg).filter((el) => decodeURIComponent(el.code2) == 'Sub-Structure').map((el) => el.period ?? 0).reduce((a, b) => a + b, 0).formatShort()), 1)
              ]))
            }), 256))
          ]),
          _createElementVNode("tr", null, [
            _cache[34] || (_cache[34] = _createElementVNode("td", { class: "text-start ps-5" }, "Utility Obstruction", -1)),
            _cache[35] || (_cache[35] = _createElementVNode("td", null, "Day", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (lg) => {
              return (_openBlock(), _createElementBlock("td", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.getLGActivity(lg).filter((el) => decodeURIComponent(el.code2) == 'Utility Obstruction').map((el) => el.period ?? 0).reduce((a, b) => a + b, 0).formatShort()), 1)
              ]))
            }), 256))
          ]),
          _createElementVNode("tr", null, [
            _cache[36] || (_cache[36] = _createElementVNode("td", { class: "text-start ps-5" }, "Holiday", -1)),
            _cache[37] || (_cache[37] = _createElementVNode("td", null, "Day", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (lg) => {
              return (_openBlock(), _createElementBlock("td", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.getLGActivity(lg).filter((el) => decodeURIComponent(el.code2) == 'Holiday').map((el) => el.period ?? 0).reduce((a, b) => a + b, 0).formatShort()), 1)
              ]))
            }), 256))
          ]),
          _createElementVNode("tr", null, [
            _cache[38] || (_cache[38] = _createElementVNode("td", { class: "text-start ps-5" }, "by Owner", -1)),
            _cache[39] || (_cache[39] = _createElementVNode("td", null, "Day", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (lg) => {
              return (_openBlock(), _createElementBlock("td", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.getLGActivity(lg).filter((el) => decodeURIComponent(el.code2) == 'by Owner').map((el) => el.period ?? 0).reduce((a, b) => a + b, 0).formatShort()), 1)
              ]))
            }), 256))
          ]),
          _createElementVNode("tr", null, [
            _cache[40] || (_cache[40] = _createElementVNode("td", { class: "text-start ps-5" }, "Equipment", -1)),
            _cache[41] || (_cache[41] = _createElementVNode("td", null, "Day", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (lg) => {
              return (_openBlock(), _createElementBlock("td", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.getLGActivity(lg).filter((el) => decodeURIComponent(el.code2) == 'Equipment').map((el) => el.period ?? 0).reduce((a, b) => a + b, 0).formatShort()), 1)
              ]))
            }), 256))
          ]),
          _createElementVNode("tr", null, [
            _cache[42] || (_cache[42] = _createElementVNode("td", { class: "text-start ps-5" }, "Engineering Issue", -1)),
            _cache[43] || (_cache[43] = _createElementVNode("td", null, "Day", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (lg) => {
              return (_openBlock(), _createElementBlock("td", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.getLGActivity(lg).filter((el) => decodeURIComponent(el.code2) == 'Engineering Issue').map((el) => el.period ?? 0).reduce((a, b) => a + b, 0).formatShort()), 1)
              ]))
            }), 256))
          ]),
          _createElementVNode("tr", null, [
            _cache[44] || (_cache[44] = _createElementVNode("td", { class: "text-start ps-5" }, "Sub-contractor", -1)),
            _cache[45] || (_cache[45] = _createElementVNode("td", null, "Day", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (lg) => {
              return (_openBlock(), _createElementBlock("td", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.getLGActivity(lg).filter((el) => decodeURIComponent(el.code2) == 'Sub-contractor').map((el) => el.period ?? 0).reduce((a, b) => a + b, 0).formatShort()), 1)
              ]))
            }), 256))
          ]),
          _createElementVNode("tr", null, [
            _cache[46] || (_cache[46] = _createElementVNode("td", { class: "text-start ps-5" }, "Working Sequence", -1)),
            _cache[47] || (_cache[47] = _createElementVNode("td", null, "Day", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (lg) => {
              return (_openBlock(), _createElementBlock("td", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.getLGActivity(lg).filter((el) => decodeURIComponent(el.code2) == 'Working Sequence').map((el) => el.period ?? 0).reduce((a, b) => a + b, 0).formatShort()), 1)
              ]))
            }), 256))
          ]),
          _createElementVNode("tr", null, [
            _cache[48] || (_cache[48] = _createElementVNode("td", { class: "text-start ps-5" }, "LG Improvement", -1)),
            _cache[49] || (_cache[49] = _createElementVNode("td", null, "Day", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (lg) => {
              return (_openBlock(), _createElementBlock("td", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.getLGActivity(lg).filter((el) => decodeURIComponent(el.code2) == 'LG Improvement').map((el) => el.period ?? 0).reduce((a, b) => a + b, 0).formatShort()), 1)
              ]))
            }), 256))
          ]),
          _createElementVNode("tr", null, [
            _cache[50] || (_cache[50] = _createElementVNode("td", { class: "text-start ps-5" }, "Segment Transporation", -1)),
            _cache[51] || (_cache[51] = _createElementVNode("td", null, "Day", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (lg) => {
              return (_openBlock(), _createElementBlock("td", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.getLGActivity(lg).filter((el) => decodeURIComponent(el.code2) == 'Segment Transporation').map((el) => el.period ?? 0).reduce((a, b) => a + b, 0).formatShort()), 1)
              ]))
            }), 256))
          ]),
          _createElementVNode("tr", null, [
            _cache[52] || (_cache[52] = _createElementVNode("th", { class: "text-start" }, "Total Breakdown", -1)),
            _cache[53] || (_cache[53] = _createElementVNode("td", null, "Day", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (lg) => {
              return (_openBlock(), _createElementBlock("th", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.getLGActivity(lg).filter((el) => decodeURIComponent(el.code1) == 'LG Breakdown').map((el) => el.period ?? 0).reduce((a, b) => a + b, 0).formatShort()), 1)
              ]))
            }), 256))
          ]),
          _createElementVNode("tr", null, [
            _cache[54] || (_cache[54] = _createElementVNode("th", { class: "text-start" }, "LG Relocation", -1)),
            _cache[55] || (_cache[55] = _createElementVNode("th", null, "Day", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (lg) => {
              return (_openBlock(), _createElementBlock("th", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.getLGActivity(lg).filter((el) => decodeURIComponent(el.code1) == 'LG Relocation').map((el) => el.period ?? 0).reduce((a, b) => a + b, 0).formatShort()), 1)
              ]))
            }), 256))
          ]),
          _createElementVNode("tr", null, [
            _cache[56] || (_cache[56] = _createElementVNode("th", { class: "text-start" }, "Total LG Suspend", -1)),
            _cache[57] || (_cache[57] = _createElementVNode("th", null, "Day", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (lg) => {
              return (_openBlock(), _createElementBlock("th", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.getLGActivity(lg).filter((el) => decodeURIComponent(el.code1) == 'LG Testing' || decodeURIComponent(el.code1) == 'LG Breakdown' || decodeURIComponent(el.code1) == 'LG Relocation').map((el) => el.period ?? 0).reduce((a, b) => a + b, 0).formatShort()), 1)
              ]))
            }), 256))
          ]),
          _createElementVNode("tr", _hoisted_16, [
            _cache[58] || (_cache[58] = _createElementVNode("td", { class: "text-start" }, [
              _createElementVNode("h6", null, "Perform Cycle Time"),
              _createElementVNode("p", null, "(Excl. Testing,Breakdown & Relocation)")
            ], -1)),
            _cache[59] || (_cache[59] = _createElementVNode("td", null, [
              _createElementVNode("h6", null, "Day")
            ], -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (lg) => {
              return (_openBlock(), _createElementBlock("td", null, [
                _createElementVNode("h6", null, _toDisplayString((_ctx.getLGMap(lg, 'timePerform').reduce((a, b) => a + b, 0) / _ctx.getLGMap(lg, 'timePerform').length).formatFull()), 1)
              ]))
            }), 256))
          ])
        ])
      ])
    ])
  ]))
}