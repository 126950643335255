
import { Options, Vue } from "vue-class-component";
import address, { filePath } from "@/variable";
import axios from "axios";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class Form extends Vue {
  store = useStore();
  doc: any = {};
  suppliers: Array<any> = [];
  lgs: Array<any> = [];
  code1: Array<any> = [];
  code2: Array<any> = [];
  filePath = filePath;
  mounted() {
    if (this.$route.params.id != undefined) this.fetchData();
    axios
      .post(`${address}/subcontractors-paginate`, {
        search: { type: "Supplier" },
        limit: 9999,
        page: 1,
      })
      .then((response) => {
        this.suppliers = response.data.docs;
      });

    axios
      .get(`${address}/code-by-field/name/Launching Girders`)
      .then((response) => {
        this.lgs = response.data.member;
      });
    axios
      .get(`${address}/code-by-field/name/Equipment Code 1`)
      .then((response) => {
        this.code1 = response.data.member;
      });
    axios
      .get(`${address}/code-by-field/name/Equipment Code 2`)
      .then((response) => {
        this.code2 = response.data.member;
      });
  }
  fetchData() {
    axios
      .get(`${address}/equipment/${this.$route.params.id}`)
      .then((response) => {
        this.doc = response.data;
      });
  }
  submitForm() {
    if (this.$route.params.id != undefined) {
      axios
        .patch(`${address}/equipment/${this.doc._id}`, this.doc)
        .then((response) => {
          console.log(response);
          alert("แก้ไขข้อมูลแล้ว");
          this.$router.push("/equipment");
        });
    } else {
      axios
        .post(`${address}/equipment`, {
          ...this.doc,
        })
        .then((response) => {
          alert("บันทึกข้อมูลแล้ว");
          this.$router.push("/equipment");
        });
    }
  }
}
