
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class TopNavigation extends Vue {
  store = useStore()
  logout() {
    this.store.commit("logout");
    this.$router.push('/login')
  }
  checkAccess(name:string){
    try {
      return this.store.state.access.find((ac:string)=>ac==name)!==undefined
    } catch (error) {
      return false
    }
  }
}
