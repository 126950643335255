import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-12 mb-3" }
const _hoisted_2 = { class: "form-label" }
const _hoisted_3 = { class: "input-group" }
const _hoisted_4 = {
  class: "form-control",
  id: "inputGroupFile04",
  type: "file",
  "aria-describedby": "inputGroupFileAddon04",
  "aria-label": "Upload",
  ref: "myfile"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.label) + " " + _toDisplayString(_ctx.uploadPercentage) + "%", 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("input", _hoisted_4, null, 512),
      _createElementVNode("button", {
        class: "btn btn-outline-secondary",
        id: "inputGroupFileAddon04",
        type: "button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.submitfile($event)))
      }, "อัปโหลด")
    ])
  ]))
}