
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import address, { filePath } from "@/variable";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class List extends Vue {
  store = useStore();
  docs: Array<any> = [];
  filter1: any = {};
  filter2: any = {};
  path: string = address;
  address = address;
  total: number = 0;
  show: number = 50;
  page: number = 1;
  pages: number = 0;
  sort: any = {
    month: -1,
    year: -1,
  };
  groups?: Array<any>;
  category: string = "";
  sortKey: string = "name";
  sortDir: number = 1;
  tabIndex: number = -1;
  get codes() {
    try {
      return this.groups![this.tabIndex].text1.split(",");
    } catch (error) {
      return new Array(1);
    }
  }

  mounted() {
    try {
      console.log(this.groups);
      let foundIndex = this.groups!.findIndex((o) => o.text2 == this.$route.params.job);
      this.category = this.groups![foundIndex].name;
      this.tabIndex = foundIndex
    } catch (error) {}
    this.fetchData();
  }
  updatePaginate(i: number) {
    this.page = i;
    // console.log(this.page);
    this.fetchData();
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }
  clearPage() {
    if (this.show == 999999999) {
      this.page = 0;
      this.fetchData();
    }
  }
  deleteItem(id: string) {
    var c = confirm("คุณแน่ใจหรือว่าต้องการลบรายการนี้");
    if (c) {
      axios.delete(`${address}/budget-agreement/${id}`).then((response) => {
        this.fetchData();
      });
    }
  }
  fetchData() {
    this.docs = [];
    let sort: any = this.sort;
    let query = {
      search: { 
        ...this.filter1, 
        ...this.filter2 ,
        job: this.$route.params.job,
      },
      page: this.page,
      limit: this.show,
      skip: 0,
      sort,
    };
    axios.post(`${address}/budget-agreements-paginate`, query).then((response) => {
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
    });
  }
}
