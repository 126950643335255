
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import address from "@/variable";
import { useStore } from "vuex";
import * as _ from "lodash";

@Options({
  components: {},
})
export default class Summary extends Vue {
  store = useStore();
  docs: any = {};
  year = new Date().getFullYear();
  stores: Array<any> = []
  incoming:Array<any> = []
  outgoing:Array<any> = []
  lgs: Array<any> = []
  stocks:Array<number> = []

  get currentYear(){
    return new Date().getFullYear()
  }
  updateStock(newValue:number, currentValue:number, name:string, unit:string){
    let diff = 0
    diff = newValue - currentValue
    console.log("อัพเดท", diff)

    axios
      .post(`${address}/stock`, {
        lg:"Na Phalan",
        year: this.year,
        month: new Date().getMonth() + 1,
        status: "Incoming",
        description: name,
        unit: unit,
        qty: diff,
        remark: "ปรับ Stock จากหน้า Consumable Summary"
      })
      .then((response) => {
        alert("ปรับ Consumable แล้ว!");
        this.fetchData()
      });
  }
  getStock(name:string){
    try {
      let outValue = this.outgoing.filter(item=>item.name==name).map(item=>item.outgoing).reduce((a:number,b:number)=>a+b,0)
      let inValue = this.incoming.filter(item=>item.name==name).map(item=>item.incoming).reduce((a:number,b:number)=>a+b,0)
      return inValue-outValue
    } catch (error) {
      return 0
    }
  }
  getIncoming(name:string){
    try {
      return this.incoming.filter(item=>item.name==name).map(item=>item.incoming).reduce((a:number,b:number)=>a+b,0)
    } catch (error) {
      return 0
    }
  }
  getOutgoing(name:string, lg:string){
    try {
      return this.outgoing.filter(item=>item.name==name&&item.lg==lg).map(item=>item.outgoing).reduce((a:number,b:number)=>a+b,0)
    } catch (error) {
      return 0
    }
  }
  fetchData(){
    console.log("fetchig...")
    axios.get(`${address}/stock-report-01/${this.year}`)
      .then((response) => {
      console.log(response.data)
      this.docs = response.data
      this.incoming = this.docs.incoming
      this.outgoing = this.docs.outgoing

      axios
        .get(`${address}/code-by-field/name/Launching Girders`)
        .then((response) => {
          this.lgs = response.data.member;
          this.lgs = this.lgs.map(lg=>{
            return decodeURIComponent(lg.name)
          })
          this.lgs = this.lgs.filter(lg=>lg!='')
          this.lgs.push("Na Phalan")

          axios.post(`${address}/stores-paginate`, {limit:9999, skip:0, page:1}).then((response) => {
            console.log(response);
            this.stores = response.data.docs;
            console.log(this.stores)
            this.stores.forEach((store,i)=>{

              console.log("store ตัวที่", i+1, store.name)
              this.stocks.push(this.getStock(store.name))
            })
          });
        });

      
    })

  }
  mounted() {
    this.fetchData()
  }
}
