import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "rounded shadow bg-white pb-3" }
const _hoisted_2 = { class: "summary-container summary-01" }
const _hoisted_3 = { class: "table main-table" }
const _hoisted_4 = ["rowspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("h2", null, "Summary Cylinder Jack", -1)),
      _createElementVNode("table", _hoisted_3, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _cache[0] || (_cache[0] = _createElementVNode("th", null, "DESCRIPTION", -1)),
            _cache[1] || (_cache[1] = _createElementVNode("th", null, "CAPACITY", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobs, (item) => {
              return (_openBlock(), _createElementBlock("th", null, [
                _createElementVNode("span", null, "J." + _toDisplayString(item), 1)
              ]))
            }), 256)),
            _cache[2] || (_cache[2] = _createElementVNode("th", null, "TOTAL", -1))
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.details, (item) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", {
                  class: "align-middle",
                  colspan: "1",
                  rowspan: _ctx.getUniqueCapacity(decodeURIComponent(item.name)).length  + 1
                }, _toDisplayString(decodeURIComponent(item.name)), 9, _hoisted_4)
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getUniqueCapacity(decodeURIComponent(item.name)), (el) => {
                return (_openBlock(), _createElementBlock("tr", null, [
                  _createElementVNode("td", null, _toDisplayString(el.capacity), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobs, (job) => {
                    return (_openBlock(), _createElementBlock("td", null, [
                      _createElementVNode("span", null, _toDisplayString((_ctx.getNumberJob(_ctx.group2[decodeURIComponent(item.name)],job,el.detail,el.capacity)??0).formatComma()), 1)
                    ]))
                  }), 256)),
                  _createElementVNode("td", null, [
                    _createElementVNode("span", null, _toDisplayString((_ctx.getNumberCapacity(_ctx.group2[decodeURIComponent(item.name)],_ctx.job,el.detail,el.capacity)??0).formatComma()), 1)
                  ])
                ]))
              }), 256))
            ], 64))
          }), 256))
        ])
      ])
    ])
  ]))
}