
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator"

@Options({
  props:{
    modelValue:Number,
    "onUpdate:modelValue": Function,
  }
})
export default class JMMonth extends Vue {
  month:number = 0
  modelValue!:number
  @Watch("modelValue")
  updateMonth(){
    this.month = this.modelValue
  }
  mounted(){
    this.updateMonth()
  }
  updateValue(){
    this.$emit('update:modelValue', this.month)
  }
}
