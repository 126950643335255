import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "card p-5 text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.checkAccess('Budget Agreement')||_ctx.checkAccess('Drawing')||_ctx.checkAccess('Submission')||_ctx.checkAccess('Presentation')||_ctx.checkAccess('Monthly Progress')||_ctx.checkAccess('Insurance')||_ctx.checkAccess('Pier Schedules (Erection)')||_ctx.checkAccess('Bearing'))
    ? (_openBlock(), _createBlock(_component_router_view, {
        key: _ctx.tabIndex+_ctx.groups.length,
        job: _ctx.job,
        category: _ctx.category,
        groups: _ctx.groups,
        activeIndex: _ctx.tabIndex,
        onChange: _cache[0] || (_cache[0] = ($event: any) => {_ctx.job=$event.job; _ctx.category=$event.category})
      }, null, 8, ["job", "category", "groups", "activeIndex"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[1] || (_cache[1] = [
        _createElementVNode("h1", { class: "text-danger" }, [
          _createTextVNode("คุณไม่มีสิทธิเข้าถึง"),
          _createElementVNode("i", { class: "fad fa-eye-slash ms-3" })
        ], -1)
      ])))
}