import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between px-4" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "me-3 fw-bold text-muted" }
const _hoisted_4 = { class: "d-flex align-items-center" }
const _hoisted_5 = { class: "pagination m-0" }
const _hoisted_6 = { class: "page-item me-3" }
const _hoisted_7 = {
  key: 0,
  class: "fas fa-chevron-left"
}
const _hoisted_8 = {
  key: 1,
  class: "fal fa-chevron-left"
}
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "page-item ms-3" }
const _hoisted_11 = {
  key: 0,
  class: "fas fa-chevron-right"
}
const _hoisted_12 = {
  key: 1,
  class: "fal fa-chevron-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, "Showing " + _toDisplayString(Math.min(((_ctx.page-1)*_ctx.show) + 1,_ctx.total)) + " to " + _toDisplayString(Math.min(((_ctx.page-1)*_ctx.show) + _ctx.show,_ctx.total)) + " of " + _toDisplayString(_ctx.total) + " entries", 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("ul", _hoisted_5, [
        _createElementVNode("li", _hoisted_6, [
          (_ctx.page!=1)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: "page-link",
                href: "#",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updatePaginate(_ctx.page-1)))
              }, [
                (_ctx.page!=1)
                  ? (_openBlock(), _createElementBlock("i", _hoisted_7))
                  : (_openBlock(), _createElementBlock("i", _hoisted_8))
              ]))
            : _createCommentVNode("", true)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginate, (item, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, null, [
            (index>_ctx.page-_ctx.limit&&index<_ctx.page+_ctx.limit)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 0,
                  class: _normalizeClass(["page-item", {'active':(index==(_ctx.page-1))}])
                }, [
                  _createElementVNode("a", {
                    class: "page-link",
                    href: "#",
                    onClick: ($event: any) => (_ctx.updatePaginate(index+1))
                  }, _toDisplayString(index+1), 9, _hoisted_9)
                ], 2))
              : _createCommentVNode("", true)
          ], 64))
        }), 256)),
        _createElementVNode("li", _hoisted_10, [
          (_ctx.page!=_ctx.pages)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: "page-link",
                href: "#",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updatePaginate(_ctx.page+1)))
              }, [
                (_ctx.page!=_ctx.pages)
                  ? (_openBlock(), _createElementBlock("i", _hoisted_11))
                  : (_openBlock(), _createElementBlock("i", _hoisted_12))
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}