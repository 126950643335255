
import { Options, Vue } from "vue-class-component";
import address, { filePath } from "@/variable";
import axios from "axios";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class Form extends Vue {
  store = useStore();
  doc: any = {};
  suppliers: Array<any> = [];
  lgs: Array<any> = [];
  code1: Array<any> = [];
  code2: Array<any> = [];
  filePath = filePath;
  mounted() {
    if (this.$route.params.id != undefined) this.fetchData();
  }
  fetchData() {
    axios
      .get(`${address}/steelSheet/${this.$route.params.id}`)
      .then((response) => {
        this.doc = response.data;
      });
  }
  submitForm() {
    if (this.$route.params.id != undefined) {
      axios
        .patch(`${address}/steelSheet/${this.doc._id}`, this.doc)
        .then((response) => {
          console.log(response);
          alert("แก้ไขข้อมูลแล้ว");
          this.$router.push("/steelSheet");
        });
    } else {
      let prep = this.doc
      axios
        .post(`${address}/steelSheet`, {
          prep
        })
        .then((response) => {
          console.log('prep',prep);
          
          console.log('res',response);
          
          alert("บันทึกข้อมูลแล้ว");
          this.$router.push("/steelSheet");
        });
    }
  }
}
