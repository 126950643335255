
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import address from "@/variable";
import Excel from "exceljs";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class List extends Vue {
  store = useStore()
  docs: Array<any> = [];
  filter1:any = {}
  show: number = 50;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  sort: any = {
    order:1
  };
  publicPath = "";
  mounted() {
    this.fetchData();
    this.publicPath = process.env.BASE_URL;
  }
  async uploadExcel(ev: any) {
    const Excel = require("exceljs/dist/es5/exceljs.browser");
    let self = this;
    let file = ev.target.files[0];
    const workbook = new Excel.Workbook();
    let book = await workbook.xlsx.load(file);
    let sheet = book.getWorksheet("import");
    let arr: Array<any> = [];
    sheet.eachRow(function(row: any, rowNumber: any) {
      if (rowNumber > 1 && row.getCell(1).value != null) {
        arr.push({
          order: rowNumber,
          span: row.getCell("A").value,
          side: row.getCell("B").value,
          type: row.getCell("C").value,
          length: row.getCell("D").value,
          rate: row.getCell("E").value,
          amount: parseFloat(row.getCell("F").text),
          erection: row.getCell("G").value,
          job: `${row.getCell("H").value}`,
        });
      }
    });
    let promises:Array<Promise<any>> = []
    arr.forEach(el=>{
      promises.push(axios.post(`${address}/erection`,el))
    })
    Promise.all(promises).then(responses=>{
      console.log(responses)
      this.fetchData()
    })
  }
  deleteItem(id: string) {
    var c = confirm("คุณแน่ใจหรือว่าต้องการลบรายการนี้");
    if (c) {
      axios.delete(`${address}/erection/${id}`).then((response) => {
        this.fetchData();
      });
    }
  }
  clearPage() {
    if (this.show == 999999999) {
      this.page = 0;
      this.fetchData();
    }
  }
  fetchData() {
    this.docs = [];
    let sort: any = this.sort;
    let query = {
      search: {
        ...this.filter1
      },
      page: this.page,
      limit: this.show,
      skip: 0,
      sort,
    };
    axios.post(`${address}/erections-paginate`, query).then((response) => {
      console.log(response);
      this.docs = response.data.docs;
      this.pages = response.data.pages;
      this.total = response.data.total;
    });
  }
}
