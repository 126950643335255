
import { Options, Vue } from "vue-class-component";
import address from "@/variable";
import axios from "axios";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class Form extends Vue {
  doc: any = {
    educations: [],
  };
  store = useStore();
  updateAmount() {
    this.doc.amount = parseFloat(
      ((this.doc.length ?? 0) * (this.doc.rate ?? 0)).toFixed(2)
    );
  }
  mounted() {
    if (this.$route.params.id != undefined) this.fetchData();
  }
  fetchData() {
    axios
      .get(`${address}/employee/${this.$route.params.id}`)
      .then((response) => {
        this.doc = response.data;
      });
  }
  pushItem(field: string) {
    if (!Array.isArray(this.doc[field])) this.doc[field] = [];
    console.log(this.doc[field])
    // this.doc[field].push({});
  }
  submitForm() {
    if (this.$route.params.id != undefined) {
      axios
        .patch(`${address}/employee/${this.doc._id}`, this.doc)
        .then((response) => {
          alert("แก้ไขข้อมูลแล้ว");
          this.$router.push("/employee");
        });
    } else {
      axios
        .post(`${address}/employee`, {
          ...this.doc,
        })
        .then((response) => {
          alert("บันทึกข้อมูลแล้ว");
          this.$router.push("/employee");
        });
    }
  }
}
