import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container rounded shadow bg-white px-4 pb-5 pt-3" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "col-sm-6 mb-2" }
const _hoisted_5 = { class: "col-sm-6 mb-2" }
const _hoisted_6 = { class: "col-sm-6 mb-2" }
const _hoisted_7 = { class: "col-sm-6 mb-2" }
const _hoisted_8 = { class: "col-sm-6 mb-2" }
const _hoisted_9 = { class: "col-sm-6 mb-2" }
const _hoisted_10 = { class: "col-sm-6 mb-2" }
const _hoisted_11 = { class: "col-sm-6 mb-2" }
const _hoisted_12 = { class: "col-sm-6 mb-2" }
const _hoisted_13 = { class: "col-sm-6 mb-2" }
const _hoisted_14 = { class: "col-sm-4 mb-2" }
const _hoisted_15 = { class: "col-sm-4 mb-2" }
const _hoisted_16 = { class: "col-sm-4 mb-2" }
const _hoisted_17 = { class: "col-sm-4 mb-2" }
const _hoisted_18 = { class: "col-sm-4 mb-2" }
const _hoisted_19 = { class: "col-sm-4 mb-2" }
const _hoisted_20 = { class: "col-sm-6 mb-2" }
const _hoisted_21 = { class: "col-sm-6 mb-2" }
const _hoisted_22 = { class: "list-group" }
const _hoisted_23 = { class: "list-group-item" }
const _hoisted_24 = { class: "d-flex justify-content-between" }
const _hoisted_25 = ["href"]
const _hoisted_26 = ["onClick"]
const _hoisted_27 = { class: "row g-3 d-flex justify-content-center" }
const _hoisted_28 = { class: "col-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JMUploads = _resolveComponent("JMUploads")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.params.id == undefined)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, "Add Form"))
      : (_openBlock(), _createElementBlock("h2", _hoisted_3, "Edit Form")),
    _createElementVNode("form", {
      class: "row needs-validation",
      onSubmit: _cache[17] || (_cache[17] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_4, [
        _cache[18] || (_cache[18] = _createElementVNode("label", { class: "form-label" }, "Job ", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.doc.job) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.job]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[19] || (_cache[19] = _createElementVNode("label", { class: "form-label" }, "Lg", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.doc.lg) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.lg]
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[20] || (_cache[20] = _createElementVNode("label", { class: "form-label" }, "Detail ", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.doc.detail) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.detail]
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _cache[21] || (_cache[21] = _createElementVNode("label", { class: "form-label" }, "Capacity", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.doc.capacity) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.capacity]
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[22] || (_cache[22] = _createElementVNode("label", { class: "form-label" }, "Brand", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.doc.brand) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.brand]
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _cache[23] || (_cache[23] = _createElementVNode("label", { class: "form-label" }, "Series", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.doc.series) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.series]
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _cache[24] || (_cache[24] = _createElementVNode("label", { class: "form-label" }, "Acting", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.doc.acting) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.acting]
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _cache[25] || (_cache[25] = _createElementVNode("label", { class: "form-label" }, "Diameter", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "number",
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.doc.diameter) = $event))
        }, null, 512), [
          [
            _vModelText,
            _ctx.doc.diameter,
            void 0,
            { number: true }
          ]
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _cache[26] || (_cache[26] = _createElementVNode("label", { class: "form-label" }, "Higth", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "number",
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.doc.higth) = $event))
        }, null, 512), [
          [
            _vModelText,
            _ctx.doc.higth,
            void 0,
            { number: true }
          ]
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _cache[27] || (_cache[27] = _createElementVNode("label", { class: "form-label" }, "Stroke", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "number",
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.doc.stroke) = $event))
        }, null, 512), [
          [
            _vModelText,
            _ctx.doc.stroke,
            void 0,
            { number: true }
          ]
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _cache[28] || (_cache[28] = _createElementVNode("label", { class: "form-label" }, "Status", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.doc.status) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.status]
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _cache[29] || (_cache[29] = _createElementVNode("label", { class: "form-label" }, "QTY", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "number",
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.doc.qty) = $event))
        }, null, 512), [
          [
            _vModelText,
            _ctx.doc.qty,
            void 0,
            { number: true }
          ]
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _cache[30] || (_cache[30] = _createElementVNode("label", { class: "form-label" }, "Price", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "number",
          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.doc.price) = $event))
        }, null, 512), [
          [
            _vModelText,
            _ctx.doc.price,
            void 0,
            { number: true }
          ]
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _cache[31] || (_cache[31] = _createElementVNode("label", { class: "form-label" }, "Number", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.doc.number) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.number]
        ])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _cache[32] || (_cache[32] = _createElementVNode("label", { class: "form-label" }, "Remark", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.doc.remark) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.remark]
        ])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _cache[33] || (_cache[33] = _createElementVNode("label", { class: "form-label" }, "Actions", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.doc.actions) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.actions]
        ])
      ]),
      _createElementVNode("div", _hoisted_20, [
        _cache[34] || (_cache[34] = _createElementVNode("label", { class: "form-label" }, "File Attach", -1)),
        _createVNode(_component_JMUploads, {
          modelValue: _ctx.doc.files,
          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.doc.files) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("ul", _hoisted_22, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.doc.files, (item, i) => {
            return (_openBlock(), _createElementBlock("li", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("span", null, _toDisplayString(item), 1),
                _createElementVNode("a", {
                  class: "btn btn-primary d-flex justify-content-center align-items-center text-white",
                  href: `${_ctx.filePath}/${item}`
                }, _cache[35] || (_cache[35] = [
                  _createElementVNode("i", { class: "fas fa-cloud" }, null, -1)
                ]), 8, _hoisted_25),
                _createElementVNode("a", {
                  class: "btn btn-danger d-flex justify-content-center align-items-center",
                  onClick: ($event: any) => (_ctx.doc.files.splice(i,1))
                }, _cache[36] || (_cache[36] = [
                  _createElementVNode("i", { class: "fas fa-trash-alt" }, null, -1)
                ]), 8, _hoisted_26)
              ])
            ]))
          }), 256))
        ])
      ]),
      _createElementVNode("div", _hoisted_27, [
        _createElementVNode("div", _hoisted_28, [
          _createVNode(_component_router_link, {
            class: "w-100 btn btn-secondary text-white btn-lg mt-4",
            to: "/equipment"
          }, {
            default: _withCtx(() => _cache[37] || (_cache[37] = [
              _createTextVNode("ย้อนกลับ")
            ])),
            _: 1
          })
        ]),
        _cache[38] || (_cache[38] = _createElementVNode("div", { class: "col-5" }, [
          _createElementVNode("button", {
            class: "w-100 btn btn-primary btn-lg mt-4 text-white",
            id: "save",
            type: "submit"
          }, "บันทึก")
        ], -1))
      ])
    ], 32)
  ]))
}