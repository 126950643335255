
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import address from "@/variable";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class Summary extends Vue {
  store = useStore();
  docs: Array<any> = [];
  lgs: Array<any> = [];
  group4: Array<any> = [];
  get internalJob() {
    return this.store.state.jobs.filter(
      (el: any) => el.category != "Other Job Site"
    );
  }
  get otherJob() {
    let result = this.store.state.jobs.filter(
      (el: any) => el.category == "Other Job Site"
    );
    try {
      result.push(this.store.state.jobs.find((el:any)=>el.j=="2452"))
    } catch (error) {
      
    }
    return result
  }
  calcP(l: number, r: number) {
    let result = l / r;
    if (r == 0) return "";
    else if (l == 0) return "";
    else return result.formatPercent() + " %";
  }
  getNumberLG(array: Array<any>, lg: string, type: string) {
    let found = array.find((el) => {
      return (
        el.lg == lg && decodeURIComponent(el.type) == decodeURIComponent(type)
      );
    });
    // return 0 // remove me when this all over
    return (found ?? {}).count ?? 0;
  }
  getNumberLGTotal(array: Array<any>, lg: string) {
    let filter = array.filter((el) => {
      return el.lg == lg;
    });
    let map = filter.map((el) => el.count ?? 0);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    // return 0 // remove me when this all over
    return reduce;
  }
  getNumberLGTypeTotal(array: Array<any>, type: string) {
    let filter = array.filter((el) => {
      return decodeURIComponent(el.type) == decodeURIComponent(type);
    });
    let map = filter.map((el) => el.count ?? 0);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    // return 0 // remove me when this all over
    return reduce;
  }

  getNumberCostPrevYear(array: Array<any>) {
    let filter = array.filter((el) => {
      return el.year < new Date().getFullYear() && (el.detail == 'Wage' || el.detail == 'Hardship' || el.detail == 'Incentive');
    });
    let sum = 0;
    filter.forEach((f) => {
      f.children.forEach((element: any) => {
        sum += element.amount;
      });
    });
    // return 0 // remove me when this all over
    return sum;
  }
  getNumberCostCurrYear(array: Array<any>) {
    let filter = array.filter((el) => {
      return (el.detail == 'Wage' || el.detail == 'Hardship' || el.detail == 'Incentive');
    });
    let sum = 0;
    filter.forEach((f) => {
      f.children.forEach((element: any) => {
        sum += element.amount;
      });
    });
    // return 0 // remove me when this all over
    return sum;
  }
  getNumberCostMonthYear(array: Array<any>, month: number) {
    let filter = array.filter((el) => {
      return el.year == new Date().getFullYear() && (el.detail == 'Wage' || el.detail == 'Hardship' || el.detail == 'Incentive');
    });

    let sum = 0;
    filter.forEach((f) => {
      f.children.forEach((element: any) => {
        if (element.month == month) sum += element.amount;
      });
    });
    // return 0 // remove me when this all over
    return sum;
  }
  getNumberCostPrev(array: Array<any>, lg: string, detail: string) {
    let found = array.find((el) => {
      return (
        el.lg == lg &&
        el.detail == encodeURIComponent(detail) &&
        el.year < new Date().getFullYear()
      );
    });
    // return 0 // remove me when this all over
    return (found ?? {}).sum ?? 0;
  }
  getNumberCostCurr(array: Array<any>, lg: string, detail: string) {
    let found = array.find((el) => {
      return (
        el.lg == lg &&
        el.detail == encodeURIComponent(detail) &&
        el.year == new Date().getFullYear()
      );
    });
    // return 0 // remove me when this all over
    return (found ?? {}).sum ?? 0;
  }
  getNumberCost(array: Array<any>, lg: string, detail: string, month: number) {
    let found = array.find((el) => {
      return (
        el.lg == lg &&
        el.detail == encodeURIComponent(detail) &&
        el.year == new Date().getFullYear()
      );
    });
    let children: Array<any> = (found ?? {}).children ?? [];
    let sum = 0;
    children.forEach((element: any) => {
      if (element.month == month) sum += element.amount;
    });
    // return 0 // remove me when this all over
    return sum;
  }
  getNumberCostTotal(array: Array<any>, lg: string, detail: string) {
    let found = array.filter((el) => {
      return (
        el.lg == lg &&
        el.detail == encodeURIComponent(detail)
      );
    });
    let sum = found.map(o=>o.sum).reduce((a,b)=>a+b,0)
    // return 0 // remove me when this all over
    return sum;
  }



  mounted() {
    axios.get(`${address}/summary/manpower/4/`).then((response) => {
      console.log(response);
      let filter = response.data.filter((o: any) => {
        return o.detail != ""
      });
      this.group4 = filter
    });

    axios
      .get(`${address}/code-by-field/name/Launching Girders`)
      .then((response) => {
        this.lgs = response.data.member;
      });
  }
}
