import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "rounded shadow bg-white pb-3 mb-3" }
const _hoisted_2 = { class: "summary-container summary-01" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "text-nowrap" }
const _hoisted_5 = { class: "col-6 px-3" }
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "table main-table" }
const _hoisted_8 = { class: "align-middle" }
const _hoisted_9 = { class: "align-middle" }
const _hoisted_10 = { class: "text-start" }
const _hoisted_11 = { class: "text-end" }
const _hoisted_12 = { class: "text-end" }
const _hoisted_13 = { class: "text-end" }
const _hoisted_14 = ["onSubmit"]
const _hoisted_15 = ["onUpdate:modelValue"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, "ACC. Summary POL. Material Y" + _toDisplayString(_ctx.year), 1),
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createElementVNode("select", {
            class: "form-select",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.year) = $event)),
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fetchData()))
          }, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(20, (item, i) => {
              return _createElementVNode("option", {
                value: _ctx.currentYear-i
              }, _toDisplayString(_ctx.currentYear-i), 9, _hoisted_6)
            }), 64))
          ], 544), [
            [_vModelSelect, _ctx.year]
          ])
        ])
      ]),
      _createElementVNode("table", _hoisted_7, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _cache[2] || (_cache[2] = _createElementVNode("th", null, "ITEM", -1)),
            _cache[3] || (_cache[3] = _createElementVNode("th", null, "DESCRIPTION", -1)),
            _cache[4] || (_cache[4] = _createElementVNode("th", null, "INCOMING", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (lg) => {
              return (_openBlock(), _createElementBlock("th", null, [
                _createElementVNode("span", null, _toDisplayString(lg), 1)
              ]))
            }), 256)),
            _cache[5] || (_cache[5] = _createElementVNode("th", null, "STOCK", -1))
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stores, (item, i) => {
            return (_openBlock(), _createElementBlock("tr", _hoisted_8, [
              _createElementVNode("td", _hoisted_9, _toDisplayString(i+1), 1),
              _createElementVNode("td", _hoisted_10, _toDisplayString(item.name), 1),
              _createElementVNode("td", _hoisted_11, _toDisplayString(_ctx.getIncoming(item.name).formatComma()), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (lg) => {
                return (_openBlock(), _createElementBlock("td", _hoisted_12, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.getOutgoing(item.name, lg).formatComma()), 1)
                ]))
              }), 256)),
              _createElementVNode("td", _hoisted_13, [
                _createElementVNode("form", {
                  onSubmit: _withModifiers(($event: any) => (_ctx.updateStock(_ctx.stocks[i],_ctx.getStock(item.name), item.name, item.unit)), ["prevent"])
                }, [
                  _withDirectives(_createElementVNode("input", {
                    class: "form-control text-end",
                    type: "number",
                    "onUpdate:modelValue": ($event: any) => ((_ctx.stocks[i]) = $event)
                  }, null, 8, _hoisted_15), [
                    [
                      _vModelText,
                      _ctx.stocks[i],
                      void 0,
                      { number: true }
                    ]
                  ]),
                  _cache[6] || (_cache[6] = _createElementVNode("button", {
                    class: "d-none",
                    type: "submit"
                  }, null, -1))
                ], 40, _hoisted_14)
              ])
            ]))
          }), 256))
        ])
      ])
    ])
  ]))
}