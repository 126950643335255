import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between px-4 py-3" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "col-3" }
const _hoisted_4 = { class: "room-group" }
const _hoisted_5 = { class: "room-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_JMCalendar = _resolveComponent("JMCalendar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, {
          class: "btn btn-primary me-3",
          to: "/accommodation/list"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("div", { class: "text-nowrap text-white" }, "Accommodation List", -1)
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_JMCalendar, {
          label: "As of Date",
          modelValue: _ctx.today,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.today) = $event)),
          onChange: _ctx.fetchData
        }, null, 8, ["modelValue", "onChange"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"bfl2\">Fl2</div><div class=\"bfl1\">Fl1</div><div class=\"afl2\">Fl2</div><div class=\"afl1\">Fl1</div><div class=\"outside-camp\"><div class=\"outside-camp-container\"><div class=\"road-name\">ทางหลวงหมายเลข 3385 หน้าพระลาน-หนองจาน</div><div class=\"lane-separator\"></div></div></div><div class=\"entrance-camp\"><div class=\"entrance-camp-container\"><div class=\"road-name\">ทางเข้า-ออก J.2369 LGSC</div><div class=\"lane-separator\"></div></div></div>", 6)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rooms, (item, i) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          class: _normalizeClass(["room-item", `${item.text1.toLowerCase()} ${item.isOccupied?'bg-secondary':'bg-white'}`]),
          to: `${item.isOccupied?'/accommodation/edit/'+item._id:'/accommodation/form?room='+item.name}`
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, _toDisplayString(item.name), 1),
            _createElementVNode("div", {
              class: _normalizeClass(["room-type", item.text1.toLowerCase()])
            }, _toDisplayString(item.text1), 3),
            _createElementVNode("div", {
              class: _normalizeClass(["room-status badge rounded-pill", item.isOccupied?'bg-secondary':'bg-success'])
            }, _toDisplayString(item.isOccupied?'Occupied':'Available'), 3)
          ]),
          _: 2
        }, 1032, ["class", "to"]))
      }), 256)),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "building-separator d-flex justify-content-center align-items-center" }, [
        _createElementVNode("div", { class: "mt-4" }, "ระยะห่างระหว่างตึก")
      ], -1))
    ])
  ], 64))
}