import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "rounded shadow bg-white pb-3" }
const _hoisted_2 = { class: "table main-table" }
const _hoisted_3 = { class: "text-start" }
const _hoisted_4 = { class: "text-end" }
const _hoisted_5 = { class: "text-primary fw-bold" }
const _hoisted_6 = { class: "text-start" }
const _hoisted_7 = { class: "text-end" }
const _hoisted_8 = { class: "text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JMSort = _resolveComponent("JMSort")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[15] || (_cache[15] = _createElementVNode("h2", null, "Progress Summary: Select Job", -1)),
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _cache[12] || (_cache[12] = _createElementVNode("th", null, [
            _createElementVNode("span", null, "#")
          ], -1)),
          _createElementVNode("th", _hoisted_3, [
            _cache[8] || (_cache[8] = _createElementVNode("span", null, "Name", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "name",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", null, [
            _cache[9] || (_cache[9] = _createElementVNode("span", null, "J.", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "j",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", null, [
            _cache[10] || (_cache[10] = _createElementVNode("span", null, "Category", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "category",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", _hoisted_4, [
            _cache[11] || (_cache[11] = _createElementVNode("span", null, "Total Cost", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "totalCost",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _cache[13] || (_cache[13] = _createElementVNode("th", null, "Actions", -1))
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.docs, (item, index) => {
          return (_openBlock(), _createElementBlock("tr", null, [
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_5, "#" + _toDisplayString(((_ctx.page-1)*_ctx.show)+(index+1)), 1)
            ]),
            _createElementVNode("td", _hoisted_6, [
              _createVNode(_component_router_link, {
                to: `/progress/summary/j/${item.j}`
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.name), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _createElementVNode("td", null, "J." + _toDisplayString(item.j), 1),
            _createElementVNode("td", null, _toDisplayString(item.category), 1),
            _createElementVNode("td", _hoisted_7, _toDisplayString((item.totalCost??0).formatShort()), 1),
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_8, [
                (_ctx.store.state.accessLevel>=5)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: `/progress/summary/j/${item.j}`
                    }, {
                      default: _withCtx(() => _cache[14] || (_cache[14] = [
                        _createElementVNode("i", { class: "class fal fa-eye" }, null, -1)
                      ])),
                      _: 2
                    }, 1032, ["to"]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]))
        }), 256))
      ])
    ])
  ]))
}