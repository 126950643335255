import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelText as _vModelText, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "container rounded shadow bg-white px-4 pb-5 pt-3" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "col-sm-4 mb-2" }
const _hoisted_5 = { class: "col-sm-4 mb-2" }
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "col-sm-4 mb-2" }
const _hoisted_8 = { class: "col-sm-4 mb-2" }
const _hoisted_9 = { class: "col-sm-4 mb-2" }
const _hoisted_10 = { class: "col-sm-4 mb-2" }
const _hoisted_11 = { class: "col-sm-6 mb-2" }
const _hoisted_12 = { class: "col-sm-6 mb-2" }
const _hoisted_13 = { class: "text-start fw-bold mt-3" }
const _hoisted_14 = { class: "table table-striped" }
const _hoisted_15 = ["onUpdate:modelValue"]
const _hoisted_16 = ["onUpdate:modelValue"]
const _hoisted_17 = ["onUpdate:modelValue"]
const _hoisted_18 = ["onUpdate:modelValue"]
const _hoisted_19 = ["onUpdate:modelValue"]
const _hoisted_20 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JMCalendar = _resolveComponent("JMCalendar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.params.id == undefined)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, "Add Form"))
      : (_openBlock(), _createElementBlock("h2", _hoisted_3, "Edit Form")),
    _createElementVNode("form", {
      class: "row needs-validation",
      onSubmit: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_4, [
        _cache[11] || (_cache[11] = _createElementVNode("label", { class: "form-label" }, "Type", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.doc.employementType) = $event))
        }, _cache[10] || (_cache[10] = [
          _createElementVNode("option", null, "AC", -1),
          _createElementVNode("option", null, "EN", -1),
          _createElementVNode("option", null, "ST", -1),
          _createElementVNode("option", null, "SA", -1),
          _createElementVNode("option", null, "รายวัน", -1)
        ]), 512), [
          [_vModelSelect, _ctx.doc.employementType]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[12] || (_cache[12] = _createElementVNode("label", { class: "form-label" }, "Job", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.doc.job) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.jobs, (item) => {
            return (_openBlock(), _createElementBlock("option", {
              value: item.j
            }, "J." + _toDisplayString(item.j) + " " + _toDisplayString(item.name), 9, _hoisted_6))
          }), 256))
        ], 512), [
          [_vModelSelect, _ctx.doc.job]
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _cache[13] || (_cache[13] = _createElementVNode("label", { class: "form-label" }, "ITD ID", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.doc.code) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.code]
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[14] || (_cache[14] = _createElementVNode("label", { class: "form-label" }, "Prefix", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.doc.prefix) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.prefix]
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _cache[15] || (_cache[15] = _createElementVNode("label", { class: "form-label" }, "First Name", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.doc.firstName) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.firstName]
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _cache[16] || (_cache[16] = _createElementVNode("label", { class: "form-label" }, "Last Name", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.doc.lastName) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.lastName]
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_JMCalendar, {
          label: "Hired Date",
          modelValue: _ctx.doc.hiredDate,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.doc.hiredDate) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _cache[17] || (_cache[17] = _createElementVNode("label", { class: "form-label" }, "Salary", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "number",
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.doc.salary) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.salary]
        ])
      ]),
      _cache[22] || (_cache[22] = _createElementVNode("hr", { class: "mt-3" }, null, -1)),
      _createElementVNode("h3", _hoisted_13, [
        _cache[19] || (_cache[19] = _createTextVNode("Educations")),
        _createElementVNode("a", {
          class: "btn btn-outline-primary ms-2",
          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.pushItem('educations')))
        }, _cache[18] || (_cache[18] = [
          _createElementVNode("i", { class: "fas fa-plus-circle me-2" }, null, -1),
          _createElementVNode("span", null, "Add", -1)
        ]))
      ]),
      _createElementVNode("table", _hoisted_14, [
        _cache[21] || (_cache[21] = _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", null, "Academic Level"),
            _createElementVNode("th", null, "Degree"),
            _createElementVNode("th", null, "Major"),
            _createElementVNode("th", null, "Institute"),
            _createElementVNode("th", null, "Academic Year"),
            _createElementVNode("th", null, [
              _createElementVNode("i", { class: "fas fa-ellipsis-h" })
            ])
          ])
        ], -1)),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.doc.educations, (item, index) => {
            return (_openBlock(), _createElementBlock("tr", null, [
              _createElementVNode("td", null, [
                _withDirectives(_createElementVNode("input", {
                  class: "form-control",
                  type: "text",
                  "onUpdate:modelValue": ($event: any) => ((item.academicLevel) = $event)
                }, null, 8, _hoisted_15), [
                  [_vModelText, item.academicLevel]
                ])
              ]),
              _createElementVNode("td", null, [
                _withDirectives(_createElementVNode("input", {
                  class: "form-control",
                  type: "text",
                  "onUpdate:modelValue": ($event: any) => ((item.degree) = $event)
                }, null, 8, _hoisted_16), [
                  [_vModelText, item.degree]
                ])
              ]),
              _createElementVNode("td", null, [
                _withDirectives(_createElementVNode("input", {
                  class: "form-control",
                  type: "text",
                  "onUpdate:modelValue": ($event: any) => ((item.major) = $event)
                }, null, 8, _hoisted_17), [
                  [_vModelText, item.major]
                ])
              ]),
              _createElementVNode("td", null, [
                _withDirectives(_createElementVNode("input", {
                  class: "form-control",
                  type: "text",
                  "onUpdate:modelValue": ($event: any) => ((item.institute) = $event)
                }, null, 8, _hoisted_18), [
                  [_vModelText, item.institute]
                ])
              ]),
              _createElementVNode("td", null, [
                _withDirectives(_createElementVNode("input", {
                  class: "form-control",
                  type: "text",
                  "onUpdate:modelValue": ($event: any) => ((item.academicYear) = $event)
                }, null, 8, _hoisted_19), [
                  [_vModelText, item.academicYear]
                ])
              ]),
              _createElementVNode("td", null, [
                _createElementVNode("a", {
                  class: "btn delete",
                  onClick: ($event: any) => (_ctx.doc.educations.splice(index,1))
                }, _cache[20] || (_cache[20] = [
                  _createElementVNode("i", { class: "fas fa-trash-alt text-danger" }, null, -1)
                ]), 8, _hoisted_20)
              ])
            ]))
          }), 256))
        ])
      ]),
      _cache[23] || (_cache[23] = _createStaticVNode("<div class=\"row g-3 d-flex justify-content-center\"><div class=\"col-5\"><button class=\"w-100 btn btn-secondary text-white btn-lg mt-4\">ย้อนกลับ</button></div><div class=\"col-5\"><button class=\"w-100 btn btn-primary btn-lg mt-4 text-white\" id=\"save\" type=\"submit\">บันทึก</button></div></div>", 1))
    ], 32)
  ]))
}