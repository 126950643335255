import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rounded shadow bg-white pb-3" }
const _hoisted_2 = { class: "table main-table table-bordered" }
const _hoisted_3 = {
  class: "align-middle",
  rowspan: "4"
}
const _hoisted_4 = { class: "blue" }
const _hoisted_5 = { class: "blue" }
const _hoisted_6 = { class: "blue" }
const _hoisted_7 = { class: "blue" }
const _hoisted_8 = { class: "fw-bold" }
const _hoisted_9 = { class: "blue" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[10] || (_cache[10] = _createElementVNode("h2", null, "Manpower Cost by LG", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("table", _hoisted_2, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _cache[0] || (_cache[0] = _createElementVNode("th", null, [
              _createElementVNode("span", null, "LG")
            ], -1)),
            _cache[1] || (_cache[1] = _createElementVNode("th", null, [
              _createElementVNode("span", null, "Detail")
            ], -1)),
            _createElementVNode("th", null, "Up to Y" + _toDisplayString(new Date().getFullYear()-1), 1),
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (item) => {
              return _createElementVNode("th", null, _toDisplayString(item.toEngMonth()), 1)
            }), 64)),
            _cache[2] || (_cache[2] = _createElementVNode("th", { class: "blue" }, "ACC", -1)),
            _cache[3] || (_cache[3] = _createElementVNode("th", null, "%", -1))
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lgs, (item) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", _hoisted_3, [
                  _createElementVNode("span", null, _toDisplayString(decodeURIComponent(item.name)), 1)
                ]),
                _cache[4] || (_cache[4] = _createElementVNode("th", null, "Wage", -1)),
                _createElementVNode("td", null, _toDisplayString(_ctx.getNumberCostPrev(_ctx.group4,item.name,"Wage").formatShort()), 1),
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (m) => {
                  return _createElementVNode("td", null, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.getNumberCost(_ctx.group4,item.name,"Wage",m).formatShort()), 1)
                  ])
                }), 64)),
                _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.getNumberCostTotal(_ctx.group4,item.name,"Wage").formatShort()), 1),
                _createElementVNode("td", null, _toDisplayString(_ctx.calcP(_ctx.getNumberCostTotal(_ctx.group4,item.name,"Wage"),_ctx.getNumberCostTotal(_ctx.group4,item.name,"Wage")+_ctx.getNumberCostTotal(_ctx.group4,item.name,"Hardship")+_ctx.getNumberCostTotal(_ctx.group4,item.name,"Incentive"))), 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[5] || (_cache[5] = _createElementVNode("th", null, "Hardship", -1)),
                _createElementVNode("td", null, _toDisplayString(_ctx.getNumberCostPrev(_ctx.group4,item.name,"Hardship").formatShort()), 1),
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (m) => {
                  return _createElementVNode("td", null, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.getNumberCost(_ctx.group4,item.name,"Hardship",m).formatShort()), 1)
                  ])
                }), 64)),
                _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.getNumberCostTotal(_ctx.group4,item.name,"Hardship").formatShort()), 1),
                _createElementVNode("td", null, _toDisplayString(_ctx.calcP(_ctx.getNumberCostTotal(_ctx.group4,item.name,"Hardship"),_ctx.getNumberCostTotal(_ctx.group4,item.name,"Wage")+_ctx.getNumberCostTotal(_ctx.group4,item.name,"Hardship")+_ctx.getNumberCostTotal(_ctx.group4,item.name,"Incentive"))), 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[6] || (_cache[6] = _createElementVNode("th", null, "Incentive", -1)),
                _createElementVNode("td", null, _toDisplayString(_ctx.getNumberCostPrev(_ctx.group4,item.name,"Incentive").formatShort()), 1),
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (m) => {
                  return _createElementVNode("td", null, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.getNumberCost(_ctx.group4,item.name,"Incentive",m).formatShort()), 1)
                  ])
                }), 64)),
                _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.getNumberCostTotal(_ctx.group4,item.name,"Incentive").formatShort()), 1),
                _createElementVNode("td", null, _toDisplayString(_ctx.calcP(_ctx.getNumberCostTotal(_ctx.group4,item.name,"Incentive"),_ctx.getNumberCostTotal(_ctx.group4,item.name,"Wage")+_ctx.getNumberCostTotal(_ctx.group4,item.name,"Hardship")+_ctx.getNumberCostTotal(_ctx.group4,item.name,"Incentive"))), 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[7] || (_cache[7] = _createElementVNode("th", null, "Total", -1)),
                _createElementVNode("td", null, _toDisplayString((_ctx.getNumberCostPrev(_ctx.group4,item.name,"Wage")+_ctx.getNumberCostPrev(_ctx.group4,item.name,"Hardship")+_ctx.getNumberCostPrev(_ctx.group4,item.name,"Incentive")).formatShort()), 1),
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (m) => {
                  return _createElementVNode("td", null, [
                    _createElementVNode("span", null, _toDisplayString((_ctx.getNumberCost(_ctx.group4,item.name,"Wage",m)+_ctx.getNumberCost(_ctx.group4,item.name,"Hardship",m)+_ctx.getNumberCost(_ctx.group4,item.name,"Incentive",m)).formatShort()), 1)
                  ])
                }), 64)),
                _createElementVNode("td", _hoisted_7, _toDisplayString((_ctx.getNumberCostTotal(_ctx.group4,item.name,"Wage")+_ctx.getNumberCostTotal(_ctx.group4,item.name,"Hardship")+_ctx.getNumberCostTotal(_ctx.group4,item.name,"Incentive")).formatShort()), 1),
                _createElementVNode("td", null, _toDisplayString(_ctx.calcP(_ctx.getNumberCostTotal(_ctx.group4,item.name,"Wage")+_ctx.getNumberCostTotal(_ctx.group4,item.name,"Hardship")+_ctx.getNumberCostTotal(_ctx.group4,item.name,"Incentive"),_ctx.getNumberCostTotal(_ctx.group4,item.name,"Wage")+_ctx.getNumberCostTotal(_ctx.group4,item.name,"Hardship")+_ctx.getNumberCostTotal(_ctx.group4,item.name,"Incentive"))), 1)
              ])
            ], 64))
          }), 256)),
          _createElementVNode("tr", _hoisted_8, [
            _cache[8] || (_cache[8] = _createElementVNode("td", { colspan: "2" }, "Total", -1)),
            _createElementVNode("td", null, _toDisplayString(_ctx.getNumberCostPrevYear(_ctx.group4).formatShort()), 1),
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (m) => {
              return _createElementVNode("td", null, _toDisplayString(_ctx.getNumberCostMonthYear(_ctx.group4,m).formatShort()), 1)
            }), 64)),
            _createElementVNode("td", _hoisted_9, _toDisplayString(_ctx.getNumberCostCurrYear(_ctx.group4).formatShort()), 1),
            _cache[9] || (_cache[9] = _createElementVNode("td", null, null, -1))
          ])
        ])
      ])
    ])
  ], 64))
}