
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import address from "@/variable";
import { useStore } from "vuex";
import * as _ from "lodash"

@Options({
  components: {},
})
export default class Summary extends Vue {
  store = useStore();
  docs: Array<any> = [];
  lgs:Array<any> = [];
  code1: Array<any> = [];
  group1: Array<any> = [];
  details: Array<any> = [];


  get jobs() {
    let results = _.uniqBy(this.group1, "job");
    results = results.map((el) => {
      return (el._id ?? {}).job ?? "";
    });
    if (results.find((g) => g == "2496") == undefined) {
      results.push("2496");
    }
    results = _.sortBy(results);
    return results
  }
  get group2(){
    let results:any = _.groupBy(this.group1,(o) => { return (o._id??{}).detail; })
    return results
  }
  getUniqueCapacity(detail:string){
    let results:any = _.uniqBy(this.group2[detail], (o:any)=>o.capacity)
    return results
  }

  getNumberJob(array:Array<any>,job:string,detail:string,capacity:string){
    let found = array.filter(el=>{
      return el.job==job&&el.capacity==capacity
    })
    let map = found.map(el=>el.count??0)
    return map.reduce((a:number,b:number)=>a+b,0)
  }
  getNumberCapacity(array:Array<any>,job:string,detail:string,capacity:string){
    let found = array.filter(el=>{
      return el.capacity==capacity
    })
    let map = found.map(el=>el.count??0)
    return map.reduce((a:number,b:number)=>a+b,0)
  }
  getNumberType(array:Array<any>,type:string){
    let found = array.filter(el=>{
      return el.type == type
    })
    return found.map(el=>el.count??0).reduce((a:number,b:number)=>a+b,0)
  }
  mounted() {
    axios.get(`${address}/summary/equipment/3/`).then((response) => {
      console.log(response);
      this.group1 = response.data.map((el: any) => {
        return {
          ...el,
          type: decodeURIComponent(el._id.code1) ?? "",
          detail: el._id.detail ?? "",
          capacity: el._id.capacity ?? "",
          job: el._id.job ?? "",
          capNumber: parseInt((el._id.capacity ?? "").replace("T","").replace("BAR",""))??0
        };
      });
      this.group1 = _.sortBy(this.group1,(o)=>o.capNumber)
    });
    axios.get(`${address}/code-by-field/name/Jack Detail`).then((response) => {
      this.details = response.data.member;
    });
  }
  
}
