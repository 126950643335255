import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "rounded shadow bg-white pb-3" }
const _hoisted_2 = { class: "summary-container summary-01" }
const _hoisted_3 = { class: "table main-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("h2", null, "Summary Equipment", -1)),
      _createElementVNode("table", _hoisted_3, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _cache[0] || (_cache[0] = _createElementVNode("th", null, [
              _createElementVNode("span", null, "JOB")
            ], -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobs, (job) => {
              return (_openBlock(), _createElementBlock("th", null, "J." + _toDisplayString(job), 1))
            }), 256)),
            _cache[1] || (_cache[1] = _createElementVNode("th", null, "TOTAL", -1))
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.code1, (item) => {
            return (_openBlock(), _createElementBlock("tr", null, [
              _createElementVNode("td", null, _toDisplayString(decodeURIComponent(item.name)), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobs, (job) => {
                return (_openBlock(), _createElementBlock("td", null, _toDisplayString(_ctx.getNumberJob(_ctx.group1,job,decodeURIComponent(item.name)).formatComma()), 1))
              }), 256)),
              _createElementVNode("td", null, _toDisplayString(_ctx.getNumberType(_ctx.group1,decodeURIComponent(item.name)).formatComma()), 1)
            ]))
          }), 256))
        ])
      ])
    ])
  ]))
}