import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "rounded shadow bg-white pb-3" }
const _hoisted_2 = { class: "summary-container summary-01" }
const _hoisted_3 = { class: "table main-table table-bordered" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "align-middle" }
const _hoisted_7 = ["rowspan"]
const _hoisted_8 = ["rowspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("h2", null, "SUMMARY SHEAR PIN AND WIRE ROPE", -1)),
      _createElementVNode("table", _hoisted_3, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _cache[0] || (_cache[0] = _createElementVNode("th", null, "DESCRIPTION", -1)),
            _cache[1] || (_cache[1] = _createElementVNode("th", null, "DIAMETER", -1)),
            _cache[2] || (_cache[2] = _createElementVNode("th", null, "DETAIL", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobs, (item) => {
              return (_openBlock(), _createElementBlock("th", null, [
                (item.length!=4)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(item), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_5, "J." + _toDisplayString(item), 1))
              ]))
            }), 256)),
            _cache[3] || (_cache[3] = _createElementVNode("th", null, "TOTAL", -1))
          ])
        ]),
        _createElementVNode("tbody", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.details, (item, i) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTotalRowForDesc(decodeURIComponent(item.name)), (el1, j) => {
                return (_openBlock(), _createElementBlock("tr", null, [
                  (j==0)
                    ? (_openBlock(), _createElementBlock("td", {
                        key: 0,
                        colspan: "1",
                        rowspan: _ctx.getTotalRowForDesc(decodeURIComponent(item.name)).length
                      }, _toDisplayString(decodeURIComponent(item.name)), 9, _hoisted_7))
                    : _createCommentVNode("", true),
                  ((_ctx.getTotalRowForDesc(decodeURIComponent(item.name))[j-1]??{}).counter != _ctx.getTotalRowForDesc(decodeURIComponent(item.name))[j].counter)
                    ? (_openBlock(), _createElementBlock("td", {
                        key: 1,
                        rowspan: _ctx.getTotalRowForDesc(decodeURIComponent(item.name)).filter(o=>o.counter==el1.counter).length,
                        colspan: "1"
                      }, [
                        _createElementVNode("span", null, _toDisplayString(el1.diameter), 1)
                      ], 8, _hoisted_8))
                    : _createCommentVNode("", true),
                  _createElementVNode("td", null, _toDisplayString(el1.detail), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobs, (job) => {
                    return (_openBlock(), _createElementBlock("td", null, _toDisplayString(_ctx.getNumberJob(job,decodeURIComponent(item.name),el1.diameter,el1.detail).formatComma()), 1))
                  }), 256)),
                  _createElementVNode("td", null, _toDisplayString(_ctx.getNumberType(decodeURIComponent(item.name),el1.diameter,el1.detail).formatComma()), 1)
                ]))
              }), 256))
            ], 64))
          }), 256))
        ])
      ])
    ])
  ]))
}