import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "container rounded shadow bg-white px-4 pb-5 pt-3" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "col-sm-6 mb-2" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "col-sm-6 mb-2" }
const _hoisted_7 = { class: "col-sm-6 mb-2" }
const _hoisted_8 = { class: "col-sm-6 mb-2l" }
const _hoisted_9 = { class: "col-sm-6 mb-2" }
const _hoisted_10 = { class: "col-sm-6 mb-2" }
const _hoisted_11 = { class: "col-sm-6 mb-2" }
const _hoisted_12 = { class: "col-sm-6 mb-2" }
const _hoisted_13 = { class: "col-sm-6 mb-2" }
const _hoisted_14 = { class: "col-sm-6 mb-2" }
const _hoisted_15 = { class: "col-sm-6 mb-2" }
const _hoisted_16 = { class: "list-group" }
const _hoisted_17 = { class: "list-group-item" }
const _hoisted_18 = { class: "d-flex justify-content-between" }
const _hoisted_19 = ["href"]
const _hoisted_20 = { class: "col-sm-6 mb-2" }
const _hoisted_21 = { class: "col-sm-6 mb-2" }
const _hoisted_22 = { class: "list-group" }
const _hoisted_23 = { class: "list-group-item" }
const _hoisted_24 = { class: "d-flex justify-content-between" }
const _hoisted_25 = ["href"]
const _hoisted_26 = { class: "col-sm-6 mb-2" }
const _hoisted_27 = { class: "col-sm-6 mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JMCalendar = _resolveComponent("JMCalendar")!
  const _component_JMUploads = _resolveComponent("JMUploads")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.params.id == undefined)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, "Add Form"))
      : (_openBlock(), _createElementBlock("h2", _hoisted_3, "Edit Form")),
    _createElementVNode("form", {
      class: "row needs-validation",
      onSubmit: _cache[15] || (_cache[15] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_4, [
        _cache[16] || (_cache[16] = _createElementVNode("label", { class: "form-label" }, "J.", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.doc.job) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.jobs, (item) => {
            return (_openBlock(), _createElementBlock("option", {
              value: item.j
            }, "J." + _toDisplayString(item.j) + " : " + _toDisplayString(item.name), 9, _hoisted_5))
          }), 256))
        ], 512), [
          [_vModelSelect, _ctx.doc.job]
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[17] || (_cache[17] = _createElementVNode("label", { class: "form-label" }, "Subject", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.doc.subject) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.subject]
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _cache[18] || (_cache[18] = _createElementVNode("label", { class: "form-label" }, "Title", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.doc.title) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.title]
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_JMCalendar, {
          label: "RecordDate",
          modelValue: _ctx.doc.recordDate,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.doc.recordDate) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _cache[19] || (_cache[19] = _createElementVNode("label", { class: "form-label" }, "Revision", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "number",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.doc.revision) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.revision]
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _cache[20] || (_cache[20] = _createElementVNode("label", { class: "form-label" }, "Status", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.doc.status) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.status]
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _cache[21] || (_cache[21] = _createElementVNode("label", { class: "form-label" }, "Number", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.doc.number) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.number]
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _cache[22] || (_cache[22] = _createElementVNode("label", { class: "form-label" }, "Type", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.doc.type) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.technicals, (item) => {
            return (_openBlock(), _createElementBlock("option", null, _toDisplayString(decodeURI(item.name)), 1))
          }), 256))
        ], 512), [
          [_vModelSelect, _ctx.doc.type]
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _cache[23] || (_cache[23] = _createElementVNode("label", { class: "form-label" }, "Category", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.doc.category) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.category]
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _cache[24] || (_cache[24] = _createElementVNode("label", { class: "form-label" }, "InURL", -1)),
        _createVNode(_component_JMUploads, {
          modelValue: _ctx.doc.inUrls,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.doc.inUrls) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("ul", _hoisted_16, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.doc.inUrls, (item) => {
            return (_openBlock(), _createElementBlock("li", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("span", null, _toDisplayString(item), 1),
                _createElementVNode("a", {
                  class: "btn btn-primary d-flex justify-content-center align-items-center text-white",
                  href: `${_ctx.filePath}/${item}`
                }, _cache[25] || (_cache[25] = [
                  _createElementVNode("i", { class: "fas fa-cloud" }, null, -1)
                ]), 8, _hoisted_19),
                _createElementVNode("a", {
                  class: "btn btn-danger d-flex justify-content-center align-items-center",
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.doc.inUrls.splice(_ctx.i,1)))
                }, _cache[26] || (_cache[26] = [
                  _createElementVNode("i", { class: "fas fa-trash-alt" }, null, -1)
                ]))
              ])
            ]))
          }), 256))
        ])
      ]),
      _createElementVNode("div", _hoisted_20, [
        _cache[27] || (_cache[27] = _createElementVNode("label", { class: "form-label" }, "OutURL", -1)),
        _createVNode(_component_JMUploads, {
          modelValue: _ctx.doc.outUrls,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.doc.outUrls) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("ul", _hoisted_22, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.doc.outUrls, (item) => {
            return (_openBlock(), _createElementBlock("li", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("span", null, _toDisplayString(item), 1),
                _createElementVNode("a", {
                  class: "btn btn-primary d-flex justify-content-center align-items-center text-white",
                  href: `${_ctx.filePath}/${item}`
                }, _cache[28] || (_cache[28] = [
                  _createElementVNode("i", { class: "fas fa-cloud" }, null, -1)
                ]), 8, _hoisted_25),
                _createElementVNode("a", {
                  class: "btn btn-danger d-flex justify-content-center align-items-center",
                  onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.doc.outUrls.splice(_ctx.i,1)))
                }, _cache[29] || (_cache[29] = [
                  _createElementVNode("i", { class: "fas fa-trash-alt" }, null, -1)
                ]))
              ])
            ]))
          }), 256))
        ])
      ]),
      _createElementVNode("div", _hoisted_26, [
        _cache[30] || (_cache[30] = _createElementVNode("label", { class: "form-label" }, "ReplyName", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.doc.replyName) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.replyName]
        ])
      ]),
      _createElementVNode("div", _hoisted_27, [
        _createVNode(_component_JMCalendar, {
          label: "ReplyDate",
          modelValue: _ctx.doc.replyDate,
          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.doc.replyDate) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _cache[31] || (_cache[31] = _createStaticVNode("<div class=\"row g-3 d-flex justify-content-center\"><div class=\"col-5\"><button class=\"w-100 btn btn-secondary text-white btn-lg mt-4\">ย้อนกลับ</button></div><div class=\"col-5\"><button class=\"w-100 btn btn-primary btn-lg mt-4 text-white\" id=\"save\" type=\"submit\">บันทึก</button></div></div>", 1))
    ], 32)
  ]))
}