
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import address from "@/variable";
import { DateTime } from "luxon";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class List extends Vue {
  store = useStore();
  docs: Array<any> = [];
  total: number = 0;
  show: number = 50;
  page: number = 1;
  pages: number = 0;
  sort: any = {};
  sortKey: string = "name";
  sortDir: number = 1;
  publicPath = "";
  search = "";
  filter1: any = {};
  filter2: any = {};
  filter3: any = {};
  filter4: any = {};
  filter5: any = {};
  types: Array<any> = [];
  lgs: Array<any> = [];
  async uploadExcel(ev: any) {
    const Excel = require("exceljs/dist/es5/exceljs.browser");
    let self = this;
    let file = ev.target.files[0];
    const workbook = new Excel.Workbook();
    let book = await workbook.xlsx.load(file);
    let sheet = book.getWorksheet("import");
    let arr: Array<any> = [];
    sheet.eachRow(function(row: any, rowNumber: any) {
      if (rowNumber > 1 && row.getCell(1).value != null) {
        let age = row.getCell("E").value;
        let birthDate = DateTime.now().minus({ year: age ?? 0 });
        arr.push({
          code: row.getCell("A").value,
          prefix: row.getCell("B").value,
          firstName: row.getCell("C").value,
          lastName: row.getCell("D").value,
          birthDate: birthDate.toJSDate(),
          rate: row.getCell("F").value,
          type: row.getCell("G").value,
          jobPayroll: row.getCell("H").value,
          jobWorking: row.getCell("I").value,
          condition: row.getCell("J").value,
          lg: row.getCell("K").value,
          hiredDate: row.getCell("L").value,
          educations: [{ degree: row.getCell("M").value }],
          remarks: row.getCell("N").value,
        });
      }
    });
    let promises: Array<Promise<any>> = [];
    arr.forEach((el) => {
      promises.push(axios.post(`${address}/manpower`, el));
    });
    Promise.all(promises).then((responses) => {
      console.log(responses);
      this.fetchData();
    });
  }

  mounted() {
    this.fetchData();
    this.publicPath = process.env.BASE_URL;
  }
  updatePaginate(i: number) {
    this.page = i;
    // console.log(this.page);
    this.fetchData();
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }
  clearPage() {
    if (this.show == 999999999) {
      this.page = 0;
      this.fetchData();
    }
  }
  deleteItem(id: string) {
    var c = confirm("คุณแน่ใจหรือว่าต้องการลบรายการนี้");
    if (c) {
      axios.delete(`${address}/manpower/${id}`).then((response) => {
        this.fetchData();
      });
    }
  }
  fetchData() {
    this.docs = [];
    let sort: any = this.sort;
    let query = {
      search: {
        ...this.filter1,
        ...this.filter2,
        ...this.filter3,
        ...this.filter4,
        ...this.filter5,
      },
      page: this.page,
      limit: this.show,
      skip: 0,
      sort,
    };
    if (this.search != "") {
      query.search = {
        ...query.search,
        $or: [
          { code: { $regex: this.search } },
          { firstName: { $regex: this.search } },
          { lastName: { $regex: this.search } },
        ],
      };
    }
    console.log(JSON.stringify(query));
    axios.post(`${address}/manpowers-paginate`, query).then((response) => {
      console.log(response.data);
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
    });
    axios
      .get(`${address}/code-by-field/name/Manpower Type`)
      .then((response) => {
        this.types = response.data.member;
      });
    axios
      .get(`${address}/code-by-field/name/Launching Girders`)
      .then((response) => {
        this.lgs = response.data.member;
      });
  }
}
