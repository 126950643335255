
import { Options, Vue } from 'vue-class-component';
import { useStore } from 'vuex';

@Options({
  components: {
  },
})
export default class List extends Vue {
  store = useStore()
}
