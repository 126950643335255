
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import address from "@/variable";
import { useStore } from "vuex";
import * as _ from "lodash";
import { DateTime } from "luxon";
import Chart from "chart.js";

@Options({
  components: {},
})
export default class Summary extends Vue {
  store = useStore();
  erections: Array<any> = [];
  progresses: Array<any> = [];

  mounted() {
    this.fetchData();
  }

  getLG(lg: string) {
    return this.progresses.filter((el) => el.lg == lg);
  }
  getLGActivity(lg: string) {
    let filter = this.progresses.filter((el) => el.lg == lg);
    let map = filter.map((el) => el.activities);
    return _.flatten(map);
  }
  getLGMap(lg: string, key: string) {
    let filter = this.progresses.filter((o) => o.span != "Assembly");
    return filter.filter((el) => el.lg == lg).map((el) => el[key]);
  }
  getMinDate(arr: Array<string>) {
    try {
      let result = arr.map((el) => new Date(el).getTime());
      let min = Math.min(...result);
      return new Date(min).toISOString();
    } catch (error) {
      return new Date().toISOString();
    }
  }
  getDiffNow(date: string) {
    let dt = DateTime.fromISO(date);
    let result = dt.diffNow("days").days;
    result = Math.round(result * -1);
    return result;
  }

  get lgs() {
    let lgs = this.progresses.map((el) => el.lg);
    lgs = _.uniq(lgs);
    // lgs = _.sort(lgs)
    lgs = lgs.sort();
    return lgs;
  }

  get timeElapse() {
    let dt = DateTime.fromISO(this.startDate);
    let diff = Math.round(dt.diffNow("days").days * -1);
    return diff;
  }
  get progressesOnly(){
    return this.progresses.filter((el) => el.span != "Assembly")
  }
  get progressesLength(){
    return this.progresses.filter((el) => el.span != "Assembly").length
  }

  get startDate() {
    console.log("get start date");
    let filter = this.progresses.filter((el) => el.span != "Assembly");
    let mapper = filter.map((el) => {
      var diff = 0;
      try {
        diff =
          DateTime.fromISO(el.startDate).diffNow("days").toObject().days ?? 0;
      } catch (error) {}

      return {
        lg: el.lg,
        startDate: el.startDate,
        diff,
        display: true,
      };
    });
    try {
      let result = mapper.map((el) => el.diff) as Array<number>;
      console.log(result);
      let min = Math.min(...result);
      return DateTime.now().plus({ days: min }).toISO();
    } catch (error) {
      return new Date().toISOString();
    }
  }
  lgs_name:Array<any> = []
  fetchLGName() {
    axios.get(`${address}/code-by-field/name/Launching Girders`).then(response=>{
      this.lgs_name = response.data.member
    })
  }
  renderFullName(lg:string){
    try {
      let found = this.lgs_name.find(item=>decodeURIComponent(item.name) === lg)
    return `${lg} (${found.text4})`
    } catch (error) {
      return lg
    }
  }
  fetchData() {
    let query = {
      search: {
        job: this.$route.params.job,
      },
      page: 1,
      limit: 100000,
      skip: 0,
    };
    axios.post(`${address}/progresses-paginate`, query).then((response) => {
      console.log(response);
      this.progresses = response.data.docs;
      this.initChart();
    });
    axios.post(`${address}/erections-paginate`, query).then((response) => {
      console.log(response);
      this.erections = response.data.docs;
      this.initChart();
    });
    this.fetchLGName()
  }
  initChart() {
    if (this.erections == [] && this.progresses == []) return "";
    var element = this.$refs.chart1 as HTMLCanvasElement;
    var ctx = element.getContext("2d") as CanvasRenderingContext2D;
    let finish =
      ((this.progresses.filter((el) => el.segment) ?? []).length ?? 0) /
      (this.erections.length ?? 0);
    var myChart = new Chart(ctx, {
      type: "pie",
      data: {
        labels: [
          "Finished " + (finish * 100).toFixed(2) + "%",
          "Remaining " + (100 - finish * 100).toFixed(2) + "%",
        ],
        datasets: [
          {
            label: "%",
            data: [finish * 100, 100 - finish * 100],
            barThickness: 30,
            backgroundColor: ["#89e894", "#EEEEEE"],
            borderColor: "#AAE8BF",
            borderWidth: 1,
          },
        ],
      },
    });
  }
}
