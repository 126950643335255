import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, vModelCheckbox as _vModelCheckbox, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "container-fluid rounded shadow bg-white px-4 pb-5 pt-3" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "col-sm-6 mb-2" }
const _hoisted_5 = { class: "col-sm-6 mb-2" }
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "col-sm-3 mb-2 mt-3 d-flex flex-column justify-content-center" }
const _hoisted_8 = { class: "form-check form-switch" }
const _hoisted_9 = { class: "form-check form-switch" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = {
  key: 0,
  class: "col-sm-6 mb-2 mt-3"
}
const _hoisted_12 = {
  key: 1,
  class: "col-sm-6 mb-2 mt-3"
}
const _hoisted_13 = { class: "col-sm-3 mb-2" }
const _hoisted_14 = { class: "col-sm-9 mb-2" }
const _hoisted_15 = { class: "col-sm-6 mb-2" }
const _hoisted_16 = { class: "col-sm-6 mb-2" }
const _hoisted_17 = { class: "list-group" }
const _hoisted_18 = { class: "list-group-item" }
const _hoisted_19 = { class: "d-flex justify-content-between" }
const _hoisted_20 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JMCalendar = _resolveComponent("JMCalendar")!
  const _component_JMUploads = _resolveComponent("JMUploads")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.params.id == undefined)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, "Add Form"))
      : (_openBlock(), _createElementBlock("h2", _hoisted_3, "Edit Form")),
    _createElementVNode("form", {
      class: "row needs-validation",
      onSubmit: _cache[10] || (_cache[10] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_4, [
        _cache[11] || (_cache[11] = _createElementVNode("label", { class: "form-label" }, "Description", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.doc.item) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.item]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[12] || (_cache[12] = _createElementVNode("label", { class: "form-label" }, "Group", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.doc.group) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (item) => {
            return (_openBlock(), _createElementBlock("option", {
              value: item.name
            }, _toDisplayString(decodeURIComponent(item.name)), 9, _hoisted_6))
          }), 256))
        ], 512), [
          [_vModelSelect, _ctx.doc.group]
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _withDirectives(_createElementVNode("input", {
            class: "form-check-input",
            id: "statusSubmit",
            type: "checkbox",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.doc.isSubmit) = $event))
          }, null, 512), [
            [_vModelCheckbox, _ctx.doc.isSubmit]
          ]),
          _cache[13] || (_cache[13] = _createElementVNode("label", {
            class: "form-check-label",
            for: "statusSubmit"
          }, "Submit", -1))
        ]),
        _createElementVNode("div", _hoisted_9, [
          _withDirectives(_createElementVNode("input", {
            class: "form-check-input",
            id: "statusApprove",
            type: "checkbox",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.doc.isApproved) = $event))
          }, null, 512), [
            [_vModelCheckbox, _ctx.doc.isApproved]
          ]),
          _cache[14] || (_cache[14] = _createElementVNode("label", {
            class: "form-check-label",
            for: "statusApprove"
          }, "Approve", -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        (_ctx.doc.isSubmit)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              (_openBlock(), _createBlock(_component_JMCalendar, {
                label: "Submit Date",
                modelValue: _ctx.doc.submitDate,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.doc.submitDate) = $event)),
                key: _ctx.doc.submitDate
              }, null, 8, ["modelValue"]))
            ]))
          : _createCommentVNode("", true),
        (_ctx.doc.isApproved)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              (_openBlock(), _createBlock(_component_JMCalendar, {
                label: "Approval Date",
                modelValue: _ctx.doc.approvedDate,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.doc.approvedDate) = $event)),
                key: _ctx.doc.approvedDate
              }, null, 8, ["modelValue"]))
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_13, [
        _cache[15] || (_cache[15] = _createElementVNode("label", { class: "form-label" }, "Revision", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "number",
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.doc.revision) = $event))
        }, null, 512), [
          [
            _vModelText,
            _ctx.doc.revision,
            void 0,
            { number: true }
          ]
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _cache[16] || (_cache[16] = _createElementVNode("label", { class: "form-label" }, "Remark", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.doc.remark) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.remark]
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _cache[17] || (_cache[17] = _createElementVNode("label", { class: "form-label" }, "Files", -1)),
        _createVNode(_component_JMUploads, {
          modelValue: _ctx.doc.files,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.doc.files) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("ul", _hoisted_17, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.doc.files, (item) => {
            return (_openBlock(), _createElementBlock("li", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("span", null, _toDisplayString(item), 1),
                _createElementVNode("a", {
                  class: "btn btn-primary d-flex justify-content-center align-items-center text-white",
                  href: `${_ctx.filePath}/${item}`
                }, _cache[18] || (_cache[18] = [
                  _createElementVNode("i", { class: "fas fa-cloud" }, null, -1)
                ]), 8, _hoisted_20),
                _createElementVNode("a", {
                  class: "btn btn-danger d-flex justify-content-center align-items-center",
                  onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.doc.files.splice(_ctx.i,1)))
                }, _cache[19] || (_cache[19] = [
                  _createElementVNode("i", { class: "fas fa-trash-alt" }, null, -1)
                ]))
              ])
            ]))
          }), 256))
        ])
      ]),
      _cache[20] || (_cache[20] = _createStaticVNode("<div class=\"row g-3 d-flex justify-content-center\"><div class=\"col-5\"><button class=\"w-100 btn btn-secondary text-white btn-lg mt-4\">ย้อนกลับ</button></div><div class=\"col-5\"><button class=\"w-100 btn btn-primary btn-lg mt-4 text-white\" id=\"save\" type=\"submit\">บันทึก</button></div></div>", 1))
    ], 32)
  ]))
}