
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import address from "@/variable";
import { DateTime } from "luxon";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class List extends Vue {
  store = useStore()
  docs: Array<any> = [];
  code1: Array<any> = [];
  total: number = 0;
  show: number = 50;
  page: number = 1;
  pages: number = 0;
  filter1: any = {};
  filter2: any = {};
  filter3: any = {};
  search: string = "";
  sort: any = {
    code: 1,
  };
  sortKey: string = "name";
  sortDir: number = 1;
  address = address
  publicPath = "";
  get dateTimeList() {
    let newArray: Array<any> = [];
    for (let i = 0; i < 36 * 3; i++) {
      let dt = DateTime.now().minus({ month: i - 24 });
      newArray.push({
        // value: dt.toFormat("yyyy")+","+dt.toFormat("M"),
        value: {
          $and: [
            { start: { $lte: dt.endOf("month") } },
            {
              $or: [
                { finish: { $gte: dt.startOf("month") } },
                { finish: { $exists: false } },
                { finish: null },
              ],
            },
          ],
        },
        show: dt.toFormat("MMM yyyy"),
      });
    }
    return newArray;
  }
  deleteMany() {
    let promises: Array<Promise<any>> = [];
    this.docs.forEach((el) => {
      promises.push(axios.delete(`${address}/steelSheet/${el._id}`));
    });
    Promise.all(promises).then((responses) => {
      console.log(responses);
      this.fetchData();
    });
  }
  mounted() {
    this.fetchData();
    this.publicPath = process.env.BASE_URL;
  }
  updatePaginate(i: number) {
    this.page = i;
    // console.log(this.page);
    this.fetchData();
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }
  clearPage() {
    if (this.show == 999999999) {
      this.page = 0;
      this.fetchData();
    }
  }
  deleteItem(id: string) {
    var c = confirm("คุณแน่ใจหรือว่าต้องการลบรายการนี้");
    if (c) {
      axios.delete(`${address}/steelSheet/${id}`).then((response) => {
        this.fetchData();
      });
    }
  }
  fetchData() {
    this.docs = [];
    let sort: any = this.sort;
    let query = {
      page: this.page,
      limit: this.show,
      skip: 0,
      sort,
    };
    axios.post(`${address}/steelSheets-paginate`, query).then((response) => {
      console.log(response)
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
    });
  }
}
