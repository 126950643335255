
import { Options, Vue } from "vue-class-component";
import address from "@/variable";
import axios from "axios";
import Sortable from "sortablejs";
import draggable from 'vuedraggable'

@Options({
  components: {
    draggable
  },
})
export default class Form extends Vue {
  doc: any = {
    member:[]
  };
  helper: boolean = false
  paste: string = ""

  addMember(){
    let data = this.paste
    let array = data.split("\n")
    this.doc.member = array.map((el)=>{
      return {
        name:el
      }
    })
  }

  mounted() {
    if (this.$route.params.id != undefined) this.fetchData();
  }
  pushMember() {
    if (!Array.isArray(this.doc.member)) this.doc.member = [];
    this.doc.member.push({
      name: "",
      text1: "",
      // category:"",
      // text2:"",
      // text3:"",
      // number1:0,
      // number2:0,
      // number3:0,
      // boolean1:false,
      // boolean2:false,
      // boolean3:false,
    });
  }
  fetchData() {
    axios.get(`${address}/code/${this.$route.params.id}`).then((response) => {
      let data = response.data;
      (data.member ?? []).forEach((el: any) => {
        el.name = decodeURIComponent(el.name);
      });
      this.doc = data;
    });
  }
  submitForm() {
    let prep:any = JSON.parse(JSON.stringify(this.doc));
    (prep.member ?? []).forEach((el: any) => {
      el.name = encodeURIComponent(el.name);
    });
    if (this.$route.params.id != undefined) {
      axios.patch(`${address}/code/${this.doc._id}`, prep).then((response) => {
        alert("แก้ไขข้อมูลแล้ว");
        this.$router.push("/code");
      });
    } else {
      axios
        .post(`${address}/code`, {
          ...prep,
        })
        .then((response) => {
          alert("บันทึกข้อมูลแล้ว");
          this.$router.push("/code");
        });
    }
  }
}
