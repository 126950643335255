
import { Options, Vue } from "vue-class-component";
import address, { filePath } from "@/variable";
import axios from "axios";
import { useStore } from "vuex";
import draggable from 'vuedraggable'


@Options({
  components: {
    draggable
  },
})
export default class Form extends Vue {
  store = useStore()
  doc: any = {};
  types: Array<any> = [];
  filePath = filePath;
  lgs: Array<any> = [];
  jobs: Array<any> = [];

  pushItem(field: string) {
    if (!Array.isArray(this.doc[field])) this.doc[field] = [];
    // console.log(this.doc[field])
    this.doc[field].push({});
  }
  mounted() {
    if (this.$route.params.id != undefined) this.fetchData();
    axios.get(`${address}/code-by-field/name/Manpower Type`).then((response) => {
      this.types = response.data.member;
    });
    axios.get(`${address}/code-by-field/name/Launching Girders`).then((response) => {
      this.lgs = response.data.member;
    })
    axios.get(`${address}/code-by-field/name/Job For Manpower`).then((response) => {
      this.jobs = response.data.member;
    })
  }
  fetchData() {
    axios.get(`${address}/manpower/${this.$route.params.id}`).then((response) => {
      this.doc = response.data;
    }); 
  }
  getManpowerType(){

  }
  submitForm() {
    if (this.$route.params.id != undefined) {
      axios
        .patch(`${address}/manpower/${this.doc._id}`, this.doc)
        .then((response) => {
          console.log(response)
          alert("แก้ไขข้อมูลแล้ว");
          this.$router.push("/manpower");
        });
    } else {
      axios
        .post(`${address}/manpower`, {
          ...this.doc,
        })
        .then((response) => {
          alert("บันทึกข้อมูลแล้ว");
          this.$router.push("/manpower");
        });
    }
  }
}
