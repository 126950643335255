import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelSelect as _vModelSelect, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "container-fluid rounded shadow bg-white px-4 pb-5 pt-3" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "col-sm-5 mb-2" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "col-sm-5 mb-2" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "col-sm-2 mb-2" }
const _hoisted_9 = { class: "col-sm-12 mb-2" }
const _hoisted_10 = { class: "col-sm-3 mb-2" }
const _hoisted_11 = { class: "form-check form-switch" }
const _hoisted_12 = ["id", "value"]
const _hoisted_13 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.params.id == undefined)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, "Add Form"))
      : (_openBlock(), _createElementBlock("h2", _hoisted_3, "Edit Form")),
    _createElementVNode("form", {
      class: "row needs-validation",
      onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_4, [
        _cache[5] || (_cache[5] = _createElementVNode("label", { class: "form-label" }, "Username", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.doc.username) = $event)),
          disabled: _ctx.$route.params.id != undefined
        }, null, 8, _hoisted_5), [
          [_vModelText, _ctx.doc.username]
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[6] || (_cache[6] = _createElementVNode("label", { class: "form-label" }, "Password", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.doc.password) = $event)),
          disabled: _ctx.$route.params.id != undefined
        }, null, 8, _hoisted_7), [
          [_vModelText, _ctx.doc.password]
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[8] || (_cache[8] = _createElementVNode("label", { class: "form-label" }, "Level", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.doc.level) = $event))
        }, _cache[7] || (_cache[7] = [
          _createElementVNode("option", { value: 1 }, "เจ้าหน้าที่ทั่วไป", -1),
          _createElementVNode("option", { value: 5 }, "เจ้าหน้าที่ปฏิบัติการ", -1),
          _createElementVNode("option", { value: 10 }, "ผู้บริหาร/ผู้ดูแลระบบ", -1)
        ]), 512), [
          [
            _vModelSelect,
            _ctx.doc.level,
            void 0,
            { number: true }
          ]
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _cache[9] || (_cache[9] = _createElementVNode("label", { class: "form-label" }, "Access", -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accesses, (item) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _withDirectives(_createElementVNode("input", {
                class: "form-check-input",
                type: "checkbox",
                id: item+'-id',
                value: item,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.doc.access) = $event))
              }, null, 8, _hoisted_12), [
                [_vModelCheckbox, _ctx.doc.access]
              ]),
              _createElementVNode("label", {
                class: "form-check-label",
                for: item+'-id'
              }, _toDisplayString(item), 9, _hoisted_13)
            ])
          ]))
        }), 256))
      ]),
      _cache[10] || (_cache[10] = _createStaticVNode("<div class=\"row g-3 d-flex justify-content-center\"><div class=\"col-5\"><button class=\"w-100 btn btn-secondary text-white btn-lg mt-4\">ย้อนกลับ</button></div><div class=\"col-5\"><button class=\"w-100 btn btn-primary btn-lg mt-4 text-white\" id=\"save\" type=\"submit\">บันทึก</button></div></div>", 1))
    ], 32)
  ]))
}