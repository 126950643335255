
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import address from "@/variable";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class List extends Vue {
  store = useStore();
  docs: Array<any> = [];
  show: number = 50;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  filter1: any = {};
  filter2: any = {};
  filter3: any = {};
  filter4: any = {};
  filter5: any = {};
  filter6: any = {};
  sort: any = {
    year: -1,
    month: -1,
  };

  publicPath = "";
  async uploadExcel(ev: any) {
    const Excel = require("exceljs/dist/es5/exceljs.browser");
    let self = this;
    let file = ev.target.files[0];
    const workbook = new Excel.Workbook();
    let book = await workbook.xlsx.load(file);
    let sheet = book.getWorksheet("import");
    let arr: Array<any> = [];
    sheet.eachRow(function(row: any, rowNumber: any) {
      if (rowNumber > 1 && row.getCell("J").value != null) {
        console.log(rowNumber)
        arr.push({
          month: String(row.getCell("A").value).monthToNumber(),
          year: parseInt((row.getCell("B").value||"").replace("Y","")),
          job: (row.getCell("C").value||"").replace("J.",""),
          category: row.getCell("D").value,
          code1: encodeURIComponent((row.getCell("E").value||"").trim()),
          code2: encodeURIComponent(String(row.getCell("F").value||"").trim().replace("LG #","LG#").replace("LW #","LW#")),
          code3: encodeURIComponent((row.getCell("G").value||"").trim()),
          unit: row.getCell("H").value||"ea",
          qty: row.getCell("I").value||"1",
          amount: row.getCell("J").value,
          by: (row.getCell("K").value||"").replace("J.",""),
          document: row.getCell("L").value||"-",
          remark: row.getCell("M").value||"-",
        });
      }
    });
    let promises: Array<Promise<any>> = [];
    arr.forEach((el) => {
      promises.push(axios.post(`${address}/ledger`, el));
    });
    Promise.all(promises).then((responses) => {
      console.log(responses);
      this.fetchData();
    });
  }

  deleteMany(){
    let promises: Array<Promise<any>> = [];
    this.docs.forEach(el=>{
      promises.push(axios.delete(`${address}/ledger/${el._id}`));
    })
    Promise.all(promises).then((responses) => {
      console.log(responses);
      this.fetchData();
    });
  }

  deleteItem(id: string) {
    var c = confirm("คุณแน่ใจหรือว่าต้องการลบรายการนี้");
    if (c) {
      axios.delete(`${address}/ledger/${id}`).then((response) => {
        this.fetchData();
      });
    }
  }
  mounted() {
    this.fetchData();
    this.publicPath = process.env.BASE_URL;
  }
  clearPage() {
    if (this.show == 999999999) {
      this.page = 0;
      this.fetchData();
    }
  }
  fetchData() {
    this.docs = [];
    let sort: any = this.sort;
    let query = {
      search: {
        ...this.filter1,
        ...this.filter2,
        ...this.filter3,
        ...this.filter4,
        ...this.filter5,
        ...this.filter6,
      },
      page: this.page,
      limit: this.show,
      skip: 0,
      sort,
    };
    axios.post(`${address}/ledgers-paginate`, query).then((response) => {
      console.log(response);
      this.docs = response.data.docs;
      this.pages = response.data.pages;
      this.total = response.data.total;
    });
  }
}
