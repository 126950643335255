import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, withCtx as _withCtx, vModelText as _vModelText, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-end pb-3" }
const _hoisted_2 = { class: "ms-2 text-primary fw-bold" }
const _hoisted_3 = { class: "ms-2 text-primary fw-bold" }
const _hoisted_4 = { class: "rounded shadow bg-white pb-3" }
const _hoisted_5 = { class: "d-flex justify-content-between px-4 py-3" }
const _hoisted_6 = { class: "d-flex align-items-center" }
const _hoisted_7 = { class: "d-flex align-items-center" }
const _hoisted_8 = { class: "col me-1" }
const _hoisted_9 = ["value"]
const _hoisted_10 = { class: "table main-table" }
const _hoisted_11 = { class: "text-primary fw-bold" }
const _hoisted_12 = ["href"]
const _hoisted_13 = { class: "text-muted" }
const _hoisted_14 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JMEntriesItem = _resolveComponent("JMEntriesItem")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_JMSort = _resolveComponent("JMSort")!
  const _component_JMTablePaginate = _resolveComponent("JMTablePaginate")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[25] || (_cache[25] = _createElementVNode("span", { class: "ms-2" }, "Total Rate", -1)),
      _createElementVNode("span", _hoisted_2, _toDisplayString((_ctx.docs.map(el=>el.rate??0).reduce((a,b)=>a+b,0)??0).formatShort()), 1),
      _cache[26] || (_cache[26] = _createElementVNode("span", { class: "ms-2" }, "Total Count", -1)),
      _createElementVNode("span", _hoisted_3, _toDisplayString((_ctx.docs.map(el=>1).reduce((a,b)=>a+b,0)??0).formatComma()), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _cache[28] || (_cache[28] = _createElementVNode("span", { class: "me-3" }, "Entries", -1)),
          _withDirectives(_createElementVNode("select", {
            class: "form-select me-3",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.show) = $event)),
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fetchData(),_ctx.clearPage()))
          }, [
            _createVNode(_component_JMEntriesItem)
          ], 544), [
            [_vModelSelect, _ctx.show]
          ]),
          _createVNode(_component_router_link, {
            class: "btn btn-primary me-3",
            to: "/steelSheet/form"
          }, {
            default: _withCtx(() => _cache[27] || (_cache[27] = [
              _createElementVNode("div", { class: "text-nowrap text-white" }, "Add Record", -1)
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("form", {
            class: "col me-1",
            onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.fetchData && _ctx.fetchData(...args)), ["prevent"]))
          }, [
            _cache[29] || (_cache[29] = _createElementVNode("label", { class: "form-label" }, "ค้นหา", -1)),
            _withDirectives(_createElementVNode("input", {
              class: "form-control",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.search) = $event)),
              type: "text",
              placeholder: "ค้นหา"
            }, null, 512), [
              [_vModelText, _ctx.search]
            ]),
            _cache[30] || (_cache[30] = _createElementVNode("button", {
              class: "d-none",
              type: "submit"
            }, null, -1))
          ], 32),
          _createElementVNode("div", _hoisted_8, [
            _cache[33] || (_cache[33] = _createElementVNode("label", { class: "form-label" }, "Job", -1)),
            _withDirectives(_createElementVNode("select", {
              class: "form-select",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filter3) = $event)),
              onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.fetchData()))
            }, [
              _cache[31] || (_cache[31] = _createElementVNode("option", {
                disabled: "",
                selected: "true"
              }, "Select Filter...", -1)),
              _cache[32] || (_cache[32] = _createElementVNode("option", { value: {} }, "All", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.jobs, (item) => {
                return (_openBlock(), _createElementBlock("option", {
                  value: {job:item.j}
                }, "J." + _toDisplayString(item.j), 9, _hoisted_9))
              }), 256))
            ], 544), [
              [_vModelSelect, _ctx.filter3]
            ])
          ])
        ])
      ]),
      _createElementVNode("table", _hoisted_10, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _cache[43] || (_cache[43] = _createElementVNode("th", null, [
              _createElementVNode("span", null, "#")
            ], -1)),
            _createElementVNode("th", null, [
              _cache[34] || (_cache[34] = _createElementVNode("span", null, "Job", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "job",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[35] || (_cache[35] = _createElementVNode("span", null, "DETAIL", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "detail",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[36] || (_cache[36] = _createElementVNode("span", null, "CAPACITY", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "capacity",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[11] || (_cache[11] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[37] || (_cache[37] = _createElementVNode("span", null, "BRAND", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "brand",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[13] || (_cache[13] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[38] || (_cache[38] = _createElementVNode("span", null, "QTY", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "qty",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[15] || (_cache[15] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[39] || (_cache[39] = _createElementVNode("span", null, "Status", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "status",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[17] || (_cache[17] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[40] || (_cache[40] = _createElementVNode("span", null, "LG No.", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "lg",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[19] || (_cache[19] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[41] || (_cache[41] = _createElementVNode("span", null, "Remark", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "remarks",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[21] || (_cache[21] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[42] || (_cache[42] = _createElementVNode("span", null, "FILE ATTACH", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "fileattach",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[23] || (_cache[23] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _cache[44] || (_cache[44] = _createElementVNode("th", null, "Actions", -1))
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.docs, (item, index) => {
            return (_openBlock(), _createElementBlock("tr", null, [
              _createElementVNode("td", null, [
                _createElementVNode("div", _hoisted_11, "#" + _toDisplayString(((_ctx.page-1)*_ctx.show)+(index+1)), 1)
              ]),
              _createElementVNode("td", null, _toDisplayString(item.job), 1),
              _createElementVNode("td", null, _toDisplayString(item.detail), 1),
              _createElementVNode("td", null, _toDisplayString(item.capacity), 1),
              _createElementVNode("td", null, _toDisplayString(item.brand), 1),
              _createElementVNode("td", null, _toDisplayString(item.qty), 1),
              _createElementVNode("td", null, _toDisplayString(item.status), 1),
              _createElementVNode("td", null, _toDisplayString(item.lg), 1),
              _createElementVNode("td", null, _toDisplayString(item.remark), 1),
              _createElementVNode("td", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.files, (item) => {
                  return (_openBlock(), _createElementBlock("a", {
                    href: `${_ctx.address}/${item}`
                  }, _cache[45] || (_cache[45] = [
                    _createElementVNode("i", { class: "fad fa-file-alt text-danger me-3" }, null, -1)
                  ]), 8, _hoisted_12))
                }), 256))
              ]),
              _createElementVNode("td", null, [
                _createElementVNode("div", _hoisted_13, [
                  (_ctx.store.state.accessLevel>=5)
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        to: `/steelSheet/edit/${item._id}`
                      }, {
                        default: _withCtx(() => _cache[46] || (_cache[46] = [
                          _createElementVNode("i", { class: "class fal fa-pencil text-warning" }, null, -1)
                        ])),
                        _: 2
                      }, 1032, ["to"]))
                    : _createCommentVNode("", true),
                  (_ctx.store.state.accessLevel>=5)
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 1,
                        class: "class fal fa-trash text-danger ms-2",
                        onClick: ($event: any) => (_ctx.deleteItem(item._id))
                      }, null, 8, _hoisted_14))
                    : _createCommentVNode("", true)
                ])
              ])
            ]))
          }), 256))
        ])
      ]),
      _createVNode(_component_JMTablePaginate, {
        page: _ctx.page,
        pages: _ctx.pages,
        show: _ctx.show,
        total: _ctx.total,
        onChange: _cache[24] || (_cache[24] = ($event: any) => {_ctx.page=$event; _ctx.fetchData()})
      }, null, 8, ["page", "pages", "show", "total"])
    ])
  ], 64))
}