import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelSelect as _vModelSelect, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container rounded shadow bg-white px-4 pb-5 pt-3" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "col-sm-3 mb-2" }
const _hoisted_5 = { class: "col-sm-3 mb-2" }
const _hoisted_6 = { class: "col-sm-3 mb-2" }
const _hoisted_7 = { class: "col-sm-3 mb-2" }
const _hoisted_8 = { class: "col-sm-6 mb-2" }
const _hoisted_9 = { class: "col-sm-12 mb-2" }
const _hoisted_10 = { class: "table" }
const _hoisted_11 = { ref: "items" }
const _hoisted_12 = ["onUpdate:modelValue"]
const _hoisted_13 = ["onUpdate:modelValue"]
const _hoisted_14 = ["onUpdate:modelValue"]
const _hoisted_15 = ["onUpdate:modelValue"]
const _hoisted_16 = ["onUpdate:modelValue"]
const _hoisted_17 = { class: "text-center" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = {
  key: 0,
  class: "col-sm-12 mb-2"
}
const _hoisted_20 = { class: "table" }
const _hoisted_21 = { ref: "items" }
const _hoisted_22 = ["onUpdate:modelValue"]
const _hoisted_23 = ["onUpdate:modelValue"]
const _hoisted_24 = ["onUpdate:modelValue"]
const _hoisted_25 = ["onUpdate:modelValue"]
const _hoisted_26 = ["onUpdate:modelValue"]
const _hoisted_27 = { class: "text-center" }
const _hoisted_28 = ["onClick"]
const _hoisted_29 = { class: "row g-3 d-flex justify-content-center" }
const _hoisted_30 = { class: "col-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.params.id == undefined)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, "Add Form"))
      : (_openBlock(), _createElementBlock("h2", _hoisted_3, "Edit Form")),
    _createElementVNode("form", {
      class: "row needs-validation",
      onSubmit: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_4, [
        _cache[8] || (_cache[8] = _createElementVNode("label", { class: "form-label" }, "Name", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.doc.name) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.name]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[9] || (_cache[9] = _createElementVNode("label", { class: "form-label" }, "J.", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.doc.j) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.j]
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[10] || (_cache[10] = _createElementVNode("label", { class: "form-label" }, "Total Length", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.doc.totalLength) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.totalLength]
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _cache[12] || (_cache[12] = _createElementVNode("label", { class: "form-label" }, "Category", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.doc.category) = $event))
        }, _cache[11] || (_cache[11] = [
          _createElementVNode("option", null, "Yard", -1),
          _createElementVNode("option", null, "Maintenance center", -1),
          _createElementVNode("option", null, "Construction Site", -1),
          _createElementVNode("option", null, "Other Job Site", -1),
          _createElementVNode("option", null, "Etc.", -1)
        ]), 512), [
          [_vModelSelect, _ctx.doc.category]
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[13] || (_cache[13] = _createElementVNode("label", { class: "form-label" }, "TotalCost", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.doc.totalCost) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.totalCost]
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _cache[16] || (_cache[16] = _createElementVNode("label", { class: "form-label" }, "Budget (Expense)", -1)),
        _createElementVNode("table", _hoisted_10, [
          _cache[15] || (_cache[15] = _createElementVNode("thead", { class: "table-light" }, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", null, "Sort"),
              _createElementVNode("th", null, "Name"),
              _createElementVNode("th", null, "Category"),
              _createElementVNode("th", null, "level"),
              _createElementVNode("th", null, "Code"),
              _createElementVNode("th", null, "Amount"),
              _createElementVNode("th", null, "Actions ")
            ])
          ], -1)),
          _createElementVNode("tbody", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.doc.budget, (item, i) => {
              return (_openBlock(), _createElementBlock("tr", null, [
                _cache[14] || (_cache[14] = _createElementVNode("td", { class: "text-center" }, [
                  _createElementVNode("i", { class: "fal fa-bars handle" })
                ], -1)),
                _createElementVNode("td", null, [
                  _withDirectives(_createElementVNode("input", {
                    class: "form-control",
                    type: "text",
                    "onUpdate:modelValue": ($event: any) => ((item.name) = $event)
                  }, null, 8, _hoisted_12), [
                    [_vModelText, item.name]
                  ])
                ]),
                _createElementVNode("td", null, [
                  _withDirectives(_createElementVNode("input", {
                    class: "form-control",
                    type: "text",
                    "onUpdate:modelValue": ($event: any) => ((item.category) = $event)
                  }, null, 8, _hoisted_13), [
                    [_vModelText, item.category]
                  ])
                ]),
                _createElementVNode("td", null, [
                  _withDirectives(_createElementVNode("input", {
                    class: "form-control",
                    type: "number",
                    "onUpdate:modelValue": ($event: any) => ((item.level) = $event),
                    step: ".01"
                  }, null, 8, _hoisted_14), [
                    [
                      _vModelText,
                      item.level,
                      void 0,
                      { number: true }
                    ]
                  ])
                ]),
                _createElementVNode("td", null, [
                  _withDirectives(_createElementVNode("input", {
                    class: "form-control",
                    type: "text",
                    "onUpdate:modelValue": ($event: any) => ((item.code) = $event)
                  }, null, 8, _hoisted_15), [
                    [_vModelText, item.code]
                  ])
                ]),
                _createElementVNode("td", null, [
                  _withDirectives(_createElementVNode("input", {
                    class: "form-control",
                    type: "number",
                    "onUpdate:modelValue": ($event: any) => ((item.amount) = $event),
                    step: ".00001"
                  }, null, 8, _hoisted_16), [
                    [_vModelText, item.amount]
                  ])
                ]),
                _createElementVNode("td", _hoisted_17, [
                  _createElementVNode("i", {
                    class: "fal fa-trash text-danger",
                    onClick: ($event: any) => (_ctx.doc.budget.splice(i,1))
                  }, null, 8, _hoisted_18)
                ])
              ]))
            }), 256))
          ], 512)
        ]),
        _createElementVNode("a", {
          class: "btn btn-secondary text-white mb-3",
          id: "add-item",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.pushBudget()))
        }, "เพิ่ม ")
      ]),
      (_ctx.doc.category=='Yard')
        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
            _cache[19] || (_cache[19] = _createElementVNode("label", { class: "form-label" }, "Income", -1)),
            _createElementVNode("table", _hoisted_20, [
              _cache[18] || (_cache[18] = _createElementVNode("thead", { class: "table-light" }, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, "Sort"),
                  _createElementVNode("th", null, "Name"),
                  _createElementVNode("th", null, "Category"),
                  _createElementVNode("th", null, "level"),
                  _createElementVNode("th", null, "Code"),
                  _createElementVNode("th", null, "Amount"),
                  _createElementVNode("th", null, "Actions ")
                ])
              ], -1)),
              _createElementVNode("tbody", _hoisted_21, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.doc.income, (item, i) => {
                  return (_openBlock(), _createElementBlock("tr", null, [
                    _cache[17] || (_cache[17] = _createElementVNode("td", { class: "text-center" }, [
                      _createElementVNode("i", { class: "fal fa-bars handle" })
                    ], -1)),
                    _createElementVNode("td", null, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-control",
                        type: "text",
                        "onUpdate:modelValue": ($event: any) => ((item.name) = $event)
                      }, null, 8, _hoisted_22), [
                        [_vModelText, item.name]
                      ])
                    ]),
                    _createElementVNode("td", null, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-control",
                        type: "text",
                        "onUpdate:modelValue": ($event: any) => ((item.category) = $event)
                      }, null, 8, _hoisted_23), [
                        [_vModelText, item.category]
                      ])
                    ]),
                    _createElementVNode("td", null, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-control",
                        type: "number",
                        "onUpdate:modelValue": ($event: any) => ((item.level) = $event),
                        step: ".01"
                      }, null, 8, _hoisted_24), [
                        [
                          _vModelText,
                          item.level,
                          void 0,
                          { number: true }
                        ]
                      ])
                    ]),
                    _createElementVNode("td", null, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-control",
                        type: "text",
                        "onUpdate:modelValue": ($event: any) => ((item.code) = $event)
                      }, null, 8, _hoisted_25), [
                        [_vModelText, item.code]
                      ])
                    ]),
                    _createElementVNode("td", null, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-control",
                        type: "number",
                        "onUpdate:modelValue": ($event: any) => ((item.amount) = $event),
                        step: ".00001"
                      }, null, 8, _hoisted_26), [
                        [_vModelText, item.amount]
                      ])
                    ]),
                    _createElementVNode("td", _hoisted_27, [
                      _createElementVNode("i", {
                        class: "fal fa-trash text-danger",
                        onClick: ($event: any) => (_ctx.doc.income.splice(i,1))
                      }, null, 8, _hoisted_28)
                    ])
                  ]))
                }), 256))
              ], 512)
            ]),
            _createElementVNode("a", {
              class: "btn btn-secondary text-white mb-3",
              id: "add-item",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.pushIncome()))
            }, "เพิ่ม ")
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_29, [
        _createElementVNode("div", _hoisted_30, [
          _createVNode(_component_router_link, {
            class: "w-100 btn btn-secondary text-white btn-lg mt-4",
            to: "/job"
          }, {
            default: _withCtx(() => _cache[20] || (_cache[20] = [
              _createTextVNode("ย้อนกลับ")
            ])),
            _: 1
          })
        ]),
        _cache[21] || (_cache[21] = _createElementVNode("div", { class: "col-5" }, [
          _createElementVNode("button", {
            class: "w-100 btn btn-primary btn-lg mt-4 text-white",
            id: "save",
            type: "submit"
          }, "บันทึก")
        ], -1))
      ])
    ], 32)
  ]))
}