
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import address from "@/variable";
import { useStore } from "vuex";
import * as _ from "lodash";

@Options({
  components: {},
})
export default class Summary extends Vue {
  store = useStore();
  docs: Array<any> = [];
  lgs: Array<any> = [];
  code1: Array<any> = [];
  group1: Array<any> = [];
  details: Array<any> = [];
  _ = _;

  get jobs() {
    let results = _.uniqBy(this.group1, "job");
    results = results.map((el) => {
      return (el._id ?? {}).job ?? "";
    });
    if (results.find((g) => g == "2496") == undefined) {
      results.push("2496");
    }
    results = _.sortBy(results);
    return results;
  }
  get group2() {
    let results: any = _.groupBy(this.group1, (o) => {
      return (o._id ?? {}).description;
    });
    return results;
  }

  getTotalRowForDesc(description: string) {
    let groupBy = _.uniqBy(
      this.group1
        .filter((o) => o.description == description)
        .map((o) => {
    var numberPattern = /\d+/g;
    const NUMERIC_REGEXP = /[-]{0,1}[\d]*[.]{0,1}[\d]+/g;
    let number = o.detail.match( NUMERIC_REGEXP )
    let length = 0
    if(number[0]!=undefined) length = parseFloat(number[0])
          return {
            ...o,
            checker: o.diameter + ":" + o.detail,
            counter: o.description + ":" + o.diameter,
            number: length,
          };
        }),
      "checker"
    );
    // let results = _.sortBy(groupBy, ["detail"]).reverse();
    let results = _.sortBy(groupBy, ["diameter.length", "diameter", "number"]);
    return results;
  }
  getTotalRowForDiameter(description: string, diameter: string) {
    let groupBy = _.groupBy(
      this.group1
        .filter((o) => o.description == description && o.diameter == diameter)
        .map((o) => {
          return {
            ...o,
            checker: o.detail,
          };
        }),
      "checker"
    );
    return _.flatten(_.values(groupBy));
  }

  getUniqueDiameter(description: string) {
    let results: any = _.uniqBy(this.group2[description], (o: any) => {
      return (o._id ?? {}).diameter;
    });
    results = _.sortBy(results, ["diameter.length", "diameter"]);
    return results;
  }
  getUniqueDetail(description: string, diameter: string) {
    let prep = this.group1.filter(
      (o) => o.description == description && o.diameter == diameter
    );
    let results: any = _.uniq(prep.map((o) => o.detail));
    // let prep = _.uniqBy(this.group2[description], (o:any) => { return (o._id??{}).diameter; })
    // prep = prep.filter(o=>o.diameter==diameter).map(o=>{
    //   return {
    //     ...o,
    //     checker: o.diameter+":"+o.detail
    //   }
    // })
    // let results:any = _.uniqBy(prep, (o:any) => { return (o._id??{}).detail; })
    // results = _.sortBy(results,['detail.length','detail'])
    return results;
  }

  getNumberJob(
    job: string,
    description: string,
    diameter: string,
    detail: string
  ) {
    let filter = this.group1.filter(
      (o) =>
        o.job == job &&
        o.description == description &&
        o.diameter == diameter &&
        o.detail == detail
    ).map(o=>(o.qty??0));
    return filter.reduce((a,b)=>a+b,0);
  }
  getNumberType(
    description: string,
    diameter: string,
    detail: string
  ) {
    let filter = this.group1.filter(
      (o) =>
        o.description == description &&
        o.diameter == diameter &&
        o.detail == detail
    ).map(o=>(o.qty??0));
    return filter.reduce((a,b)=>a+b,0);
  }
  mounted() {
    axios.get(`${address}/summary/equipment/4/`).then((response) => {
      console.log(response.data);
      this.group1 = response.data.map((el: any) => {
        return {
          ...el,
          description: decodeURIComponent(el._id.description) ?? "",
          diameter: el._id.diameter ?? "",
          detail: el._id.detail ?? "",
          job: el._id.job ?? "",
          // capNumber: parseInt((el._id.capacity ?? "").replace("T","").replace("BAR",""))??0
        };
      });
      this.group1 = _.sortBy(this.group1, (o) => o.capNumber);
    });
    axios
      .get(`${address}/code-by-field/name/Shear Pin + Sling + Shackle Detail`)
      .then((response) => {
        this.details = response.data.member;
      });
  }
}
