import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "rounded shadow bg-white pb-3" }
const _hoisted_2 = { class: "table main-table" }
const _hoisted_3 = { class: "text-start" }
const _hoisted_4 = { class: "blue" }
const _hoisted_5 = { class: "fw-bold" }
const _hoisted_6 = { class: "blue" }
const _hoisted_7 = { class: "text-start" }
const _hoisted_8 = { class: "blue" }
const _hoisted_9 = { class: "fw-bold" }
const _hoisted_10 = { class: "blue" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[9] || (_cache[9] = _createElementVNode("h2", null, "Summary Yard Na Phalan Running Cost", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("table", _hoisted_2, [
        _cache[6] || (_cache[6] = _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", null, [
              _createElementVNode("span", null, "ITEM")
            ]),
            _createElementVNode("th", null, [
              _createElementVNode("span", null, "DESCRIPTION")
            ]),
            _createElementVNode("th", null, [
              _createElementVNode("span", null, "BUDGET (MB)")
            ]),
            _createElementVNode("th", null, [
              _createElementVNode("span", null, "CODE")
            ]),
            _createElementVNode("th", null, [
              _createElementVNode("span", null, "Jan")
            ]),
            _createElementVNode("th", null, [
              _createElementVNode("span", null, "Feb")
            ]),
            _createElementVNode("th", null, [
              _createElementVNode("span", null, "Mar")
            ]),
            _createElementVNode("th", null, [
              _createElementVNode("span", null, "Apr")
            ]),
            _createElementVNode("th", null, [
              _createElementVNode("span", null, "May")
            ]),
            _createElementVNode("th", null, [
              _createElementVNode("span", null, "June")
            ]),
            _createElementVNode("th", null, [
              _createElementVNode("span", null, "Jul")
            ]),
            _createElementVNode("th", null, [
              _createElementVNode("span", null, "Aug")
            ]),
            _createElementVNode("th", null, [
              _createElementVNode("span", null, "Sep")
            ]),
            _createElementVNode("th", null, [
              _createElementVNode("span", null, "Oct")
            ]),
            _createElementVNode("th", null, [
              _createElementVNode("span", null, "Nov")
            ]),
            _createElementVNode("th", null, [
              _createElementVNode("span", null, "Dec")
            ]),
            _createElementVNode("th", null, [
              _createElementVNode("span", { class: "blue" }, "Acc")
            ])
          ])
        ], -1)),
        _cache[7] || (_cache[7] = _createElementVNode("tbody", null, [
          _createElementVNode("tr", { class: "text-start" }, [
            _createElementVNode("th", { colspan: "17" }, [
              _createElementVNode("span", { class: "ms-5 ps-3" }, "Income")
            ])
          ])
        ], -1)),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.doc.income, (item, i) => {
            return (_openBlock(), _createElementBlock("tr", null, [
              _createElementVNode("td", null, _toDisplayString(i+1), 1),
              _createElementVNode("td", _hoisted_3, _toDisplayString(item.name), 1),
              _createElementVNode("td", null, _toDisplayString((item.amount??0).formatMB()), 1),
              _createElementVNode("td", null, _toDisplayString(item.code), 1),
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (el) => {
                return _createElementVNode("td", null, _toDisplayString(_ctx.getByCodeMonth(item.code,el).formatMB()), 1)
              }), 64)),
              _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.getByCode(item.code).formatMB()), 1)
            ]))
          }), 256)),
          _createElementVNode("tr", _hoisted_5, [
            _cache[0] || (_cache[0] = _createElementVNode("td", null, null, -1)),
            _cache[1] || (_cache[1] = _createElementVNode("td", null, "TOTAL", -1)),
            _createElementVNode("td", null, _toDisplayString((_ctx.doc.income??[]).map(el=>el.amount??0).reduce((a,b)=>a+b,0).formatMB()), 1),
            _cache[2] || (_cache[2] = _createElementVNode("td", null, null, -1)),
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (el) => {
              return _createElementVNode("td", null, _toDisplayString((_ctx.doc.income??[]).map(it=>_ctx.getByCodeMonth(it.code,el)).reduce((a,b)=>a+b,0).formatMB()), 1)
            }), 64)),
            _createElementVNode("td", _hoisted_6, _toDisplayString((_ctx.doc.income??[]).map(it=>_ctx.getByCode(it.code)).reduce((a,b)=>a+b,0).formatMB()), 1)
          ])
        ]),
        _cache[8] || (_cache[8] = _createElementVNode("tbody", null, [
          _createElementVNode("tr", { class: "text-start" }, [
            _createElementVNode("th", { colspan: "17" }, [
              _createElementVNode("span", { class: "ms-5 ps-3" }, "Expense")
            ])
          ])
        ], -1)),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.doc.budget, (item, i) => {
            return (_openBlock(), _createElementBlock("tr", null, [
              _createElementVNode("td", null, _toDisplayString(i+1), 1),
              _createElementVNode("td", _hoisted_7, _toDisplayString(item.name), 1),
              _createElementVNode("td", null, _toDisplayString((item.amount??0).formatMB()), 1),
              _createElementVNode("td", null, _toDisplayString(item.code), 1),
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (el) => {
                return _createElementVNode("td", null, _toDisplayString(_ctx.getByCodeMonth(item.code,el).formatMB()), 1)
              }), 64)),
              _createElementVNode("td", _hoisted_8, _toDisplayString(_ctx.getByCode(item.code).formatMB()), 1)
            ]))
          }), 256)),
          _createElementVNode("tr", _hoisted_9, [
            _cache[3] || (_cache[3] = _createElementVNode("td", null, null, -1)),
            _cache[4] || (_cache[4] = _createElementVNode("td", null, "TOTAL", -1)),
            _createElementVNode("td", null, _toDisplayString((_ctx.doc.budget??[]).map(el=>el.amount??0).reduce((a,b)=>a+b,0).formatMB()), 1),
            _cache[5] || (_cache[5] = _createElementVNode("td", null, null, -1)),
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (el) => {
              return _createElementVNode("td", null, _toDisplayString((_ctx.doc.budget??[]).map(it=>_ctx.getByCodeMonth(it.code,el)).reduce((a,b)=>a+b,0).formatMB()), 1)
            }), 64)),
            _createElementVNode("td", _hoisted_10, _toDisplayString((_ctx.doc.budget??[]).map(it=>_ctx.getByCode(it.code)).reduce((a,b)=>a+b,0).formatMB()), 1)
          ])
        ])
      ])
    ])
  ], 64))
}