
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class List extends Vue {
  store = useStore();
  rooms: Array<any> = [];
  accommodations: Array<any> = [];
  today = new Date()
  mounted(){
    this.fetchData()
  }
  isOccupied(room:string){
    let found = this.rooms.find(el=>el.room==room)
    return found==undefined?false:true
  }
  fetchData(){
    axios.post(`${address}/accommodations-paginate`,{
      search:{
        checkOutDate:{
          $gte:this.today
        }
      },
      page:1,
      limit:100,
      skip:0,
    }).then((response) => {
      this.accommodations = response.data.docs;
      axios.get(`${address}/code-by-field/name/Accommodation Room`).then((response) => {
        this.rooms = response.data.member;
        this.rooms = this.rooms.map(el=>{
          return {
            ...el,
            isOccupied: this.accommodations.find(o=>o.room===el.name)!=undefined,
            _id: (this.accommodations.find(o=>o.room===el.name)??{})._id
          }
        })
      });
    });
  }
}
