
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import address from "@/variable";
import { useStore } from "vuex";
import * as _ from "lodash";

@Options({
  components: {},
})
export default class Summary extends Vue {
  store = useStore();
  docs: Array<any> = [];
  lgs: Array<any> = [];
  code1: Array<any> = [];
  group1: Array<any> = [];
  details: Array<any> = [];
  ptDetails = [
    "1.0m - 2.0m",
    "2.0m - 3.0m",
    "3.0m - 4.0m",
    "4.0m - 5.0m",
    "5.0m - 6.0m",
    "6.0m - 7.0m",
  ]
  _ = _

  get jobs() {
    let results = _.uniqBy(this.group1, "job");
    results = results.map((el) => {
      return (el._id ?? {}).job ?? "";
    });
    if (results.find((g) => g == "2496") == undefined) {
      results.push("2496");
    }
    results = _.sortBy(results);
    return results
  }
  get group2() {
    let map = this.group1.map((el) => {
      return {
        ...el,
        uid: `${el.detail}-${el.diameter}`,
      };
    });
    let results: any = _.groupBy(map, (o) => {
      return o.detail;
    });
    return results;
  }

  getNumberPT(diameter:string,period:string,job:string){
    let ptbars = this.group1
    let result:Array<any> = ptbars.filter(el=>el.detail=="PT Bar"&&el.diameter==diameter&&el.job==job)
    let sum = 0
    result.forEach(res=>{
      res.children.forEach((child:any) => {
        if(child.lengthPeriod==period)
          sum += child.qty
      });
    })
    return sum
  }

  getNumberPTTotal(diameter:string,period:string){
    let ptbars = this.group1
    let result:Array<any> = ptbars.filter(el=>el.detail=="PT Bar"&&el.diameter==diameter)
    let sum = 0
    result.forEach(res=>{
      res.children.forEach((child:any) => {
        if(child.lengthPeriod==period)
          sum += child.qty
      });
    })
    return sum
  }

  getNumberJob(detail: string, diameter: string, job: string) {
    let found = this.group1.filter((el) => {
      return el.detail == detail && el.diameter == diameter && el.job == job;
    });
    let map = found.map((el) => el.qty ?? 0);
    return map.reduce((a: number, b: number) => a + b, 0);

    return 

  }
    
  getNumberJobTotal(detail: string, diameter: string, job: string) {
    let found = this.group1.filter((el) => {
      return el.detail == detail && el.diameter == diameter;
    });
    let map = found.map((el) => el.qty ?? 0);
    return map.reduce((a: number, b: number) => a + b, 0);
  }

  getUniqueDiameter(detail: string) {
    // let results:any = _.uniqBy(this.group2[detail] as Array<any>, (o:any)=>o.diameter)
    let found = this.group2[detail];
    let sort = _.sortBy(found as Array<any>, (o: any) => o.diameter);
    let uniq = _.groupBy(sort as Array<any>, (o: any) => o.diameter);
    return uniq;
  }

  mounted() {
    axios.get(`${address}/summary/equipment/2/`).then((response) => {
      console.log(response);
      this.group1 = response.data.map((el: any) => {
        return {
          ...el,
          detail: el._id.detail ?? "",
          diameter: el._id.diameter ?? "",
          job: el._id.job ?? "",
        };
      });
      this.group1 = _.sortBy(this.group1, (o) => o._id.detail);
    });
    axios.get(`${address}/code-by-field/name/PTBar Detail`).then((response) => {
      this.details = response.data.member;
    });
  }
}
