import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rounded shadow bg-white pb-3 pt-3" }
const _hoisted_2 = { class: "table main-table" }
const _hoisted_3 = { class: "blue" }
const _hoisted_4 = { class: "blue" }
const _hoisted_5 = { class: "blue" }
const _hoisted_6 = { class: "fw-bold blue" }
const _hoisted_7 = { class: "blue" }
const _hoisted_8 = { class: "blue" }
const _hoisted_9 = { class: "fw-bold blue" }
const _hoisted_10 = { class: "blue" }
const _hoisted_11 = { class: "blue" }
const _hoisted_12 = { class: "fw-bold blue" }
const _hoisted_13 = { class: "fw-bold blue" }
const _hoisted_14 = { class: "fw-bold" }
const _hoisted_15 = { class: "fw-bold blue" }
const _hoisted_16 = { class: "fw-bold" }
const _hoisted_17 = { class: "fw-bold blue" }
const _hoisted_18 = { class: "fw-bold" }
const _hoisted_19 = { class: "fw-bold blue" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h2", null, "SUMMARY J.2369 Y" + _toDisplayString(new Date().getFullYear()), 1),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("table", _hoisted_2, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _cache[0] || (_cache[0] = _createElementVNode("th", null, [
              _createElementVNode("span", null, "ITEM")
            ], -1)),
            _cache[1] || (_cache[1] = _createElementVNode("th", null, [
              _createElementVNode("span", null, "DESCRIPTION")
            ], -1)),
            _createElementVNode("th", null, [
              _createElementVNode("span", _hoisted_3, "ACC.Y" + _toDisplayString(new Date().getFullYear()), 1)
            ])
          ])
        ]),
        _createElementVNode("tbody", null, [
          _cache[28] || (_cache[28] = _createElementVNode("tr", { class: "fw-bold" }, [
            _createElementVNode("td", null, "1"),
            _createElementVNode("td", { class: "text-start" }, "TOTAL SITE OPERATION"),
            _createElementVNode("td")
          ], -1)),
          _cache[29] || (_cache[29] = _createElementVNode("tr", { class: "fw-bold" }, [
            _createElementVNode("td"),
            _createElementVNode("td", { class: "text-start" }, [
              _createElementVNode("div", { class: "ps-3" }, "Income")
            ]),
            _createElementVNode("td")
          ], -1)),
          _createElementVNode("tr", null, [
            _cache[2] || (_cache[2] = _createElementVNode("td", null, "1.1", -1)),
            _cache[3] || (_cache[3] = _createElementVNode("td", { class: "text-start" }, [
              _createElementVNode("div", { class: "ps-4" }, "Segment Income + Bearing Income")
            ], -1)),
            _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.getSumFromCode1(['Bearing%E2%80%8B%20Payment','Bearing%20Payment','Segment%20Payment'],2).formatShort()), 1)
          ]),
          _createElementVNode("tr", null, [
            _cache[4] || (_cache[4] = _createElementVNode("td", null, "1.2", -1)),
            _cache[5] || (_cache[5] = _createElementVNode("td", { class: "text-start" }, [
              _createElementVNode("div", { class: "ps-4" }, "Engineering Service")
            ], -1)),
            _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.getSumFromCode1(['Engineering%20Payment'],2).formatShort()), 1)
          ]),
          _createElementVNode("tr", null, [
            _cache[6] || (_cache[6] = _createElementVNode("td", null, "1.3", -1)),
            _cache[7] || (_cache[7] = _createElementVNode("td", { class: "text-start fw-bold" }, [
              _createElementVNode("div", { class: "ps-5" }, "Total Income")
            ], -1)),
            _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.getSumFromCode1(['Bearing%E2%80%8B%20Payment', 'Engineering%20Payment','Bearing%20Payment','Segment%20Payment'],2).formatShort()), 1)
          ]),
          _cache[30] || (_cache[30] = _createElementVNode("tr", { class: "fw-bold" }, [
            _createElementVNode("td"),
            _createElementVNode("td", { class: "text-start" }, [
              _createElementVNode("div", { class: "ps-3" }, "Expense")
            ]),
            _createElementVNode("td")
          ], -1)),
          _createElementVNode("tr", null, [
            _cache[8] || (_cache[8] = _createElementVNode("td", null, "1.4", -1)),
            _cache[9] || (_cache[9] = _createElementVNode("td", { class: "text-start" }, [
              _createElementVNode("div", { class: "ps-4" }, "Segment Erection")
            ], -1)),
            _createElementVNode("td", _hoisted_7, _toDisplayString(((_ctx.segmentErection - _ctx.getSumFromCode1(['LG%20Depre'],2))??0).formatShort()), 1)
          ]),
          _createElementVNode("tr", null, [
            _cache[10] || (_cache[10] = _createElementVNode("td", null, "1.5", -1)),
            _cache[11] || (_cache[11] = _createElementVNode("td", { class: "text-start" }, [
              _createElementVNode("div", { class: "ps-4" }, "Depre. LG")
            ], -1)),
            _createElementVNode("td", _hoisted_8, _toDisplayString((_ctx.getSumFromCode1(['LG%20Depre'],2)??0).formatShort()), 1)
          ]),
          _createElementVNode("tr", null, [
            _cache[12] || (_cache[12] = _createElementVNode("td", null, "1.3", -1)),
            _cache[13] || (_cache[13] = _createElementVNode("td", { class: "text-start fw-bold" }, [
              _createElementVNode("div", { class: "ps-5" }, "Total Expense")
            ], -1)),
            _createElementVNode("td", _hoisted_9, _toDisplayString((_ctx.segmentErection??0).formatShort()), 1)
          ]),
          _cache[31] || (_cache[31] = _createElementVNode("tr", { class: "fw-bold" }, [
            _createElementVNode("td", null, "2"),
            _createElementVNode("td", { class: "text-start text-uppercase" }, "Yard Na Phalan"),
            _createElementVNode("td")
          ], -1)),
          _cache[32] || (_cache[32] = _createElementVNode("tr", { class: "fw-bold" }, [
            _createElementVNode("td"),
            _createElementVNode("td", { class: "text-start" }, [
              _createElementVNode("div", { class: "ps-3" }, "Income")
            ]),
            _createElementVNode("td")
          ], -1)),
          _createElementVNode("tr", null, [
            _cache[14] || (_cache[14] = _createElementVNode("td", null, "2.1", -1)),
            _cache[15] || (_cache[15] = _createElementVNode("td", { class: "text-start" }, [
              _createElementVNode("div", { class: "ps-4" }, "Miscellaneous Income")
            ], -1)),
            _createElementVNode("td", _hoisted_10, _toDisplayString(_ctx.getSumFromCode1(['Misc.%20Income'],1).formatShort()), 1)
          ]),
          _createElementVNode("tr", null, [
            _cache[16] || (_cache[16] = _createElementVNode("td", null, "2.2", -1)),
            _cache[17] || (_cache[17] = _createElementVNode("td", { class: "text-start" }, [
              _createElementVNode("div", { class: "ps-4" }, "LG Scrap Income")
            ], -1)),
            _createElementVNode("td", _hoisted_11, _toDisplayString(_ctx.getSumFromCode1(['LG%20Scrap'],1).formatShort()), 1)
          ]),
          _createElementVNode("tr", null, [
            _cache[18] || (_cache[18] = _createElementVNode("td", null, "2.3", -1)),
            _cache[19] || (_cache[19] = _createElementVNode("td", { class: "text-start fw-bold" }, [
              _createElementVNode("div", { class: "ps-5" }, "Total Income")
            ], -1)),
            _createElementVNode("td", _hoisted_12, _toDisplayString(_ctx.getSumFromCode1(['Misc.%20Income','LG%20Scrap'],1).formatShort()), 1)
          ]),
          _createElementVNode("tr", null, [
            _cache[20] || (_cache[20] = _createElementVNode("td", null, null, -1)),
            _cache[21] || (_cache[21] = _createElementVNode("td", { class: "text-start fw-bold" }, [
              _createElementVNode("div", { class: "ps-3" }, "Expense")
            ], -1)),
            _createElementVNode("td", _hoisted_13, _toDisplayString((_ctx.segmentYardExpenseNumber).formatShort()), 1)
          ]),
          _cache[33] || (_cache[33] = _createElementVNode("tr", { class: "fw-bold" }, [
            _createElementVNode("td", {
              colspan: "3",
              style: {"height":"2.5rem"}
            })
          ], -1)),
          _createElementVNode("tr", _hoisted_14, [
            _cache[22] || (_cache[22] = _createElementVNode("td", null, "3", -1)),
            _cache[23] || (_cache[23] = _createElementVNode("td", { class: "text-start" }, [
              _createElementVNode("div", { class: "ps-3text-uppercase" }, "Total Income")
            ], -1)),
            _createElementVNode("td", _hoisted_15, _toDisplayString((_ctx.getSumFromCode1Number(['Engineering%20Payment','Bearing%E2%80%8B%20Payment','Bearing%20Payment','Segment%20Payment'],2)+_ctx.getSumFromCode1Number(['Misc.%20Income','LG%20Scrap'],1)).formatShort()), 1)
          ]),
          _createElementVNode("tr", _hoisted_16, [
            _cache[24] || (_cache[24] = _createElementVNode("td", null, "4", -1)),
            _cache[25] || (_cache[25] = _createElementVNode("td", { class: "text-start" }, [
              _createElementVNode("div", { class: "ps-3text-uppercase" }, "Total Expense")
            ], -1)),
            _createElementVNode("td", _hoisted_17, _toDisplayString((_ctx.segmentErection + _ctx.segmentYardExpenseNumber).formatShort()), 1)
          ]),
          _createElementVNode("tr", _hoisted_18, [
            _cache[26] || (_cache[26] = _createElementVNode("td", null, "5", -1)),
            _cache[27] || (_cache[27] = _createElementVNode("td", { class: "text-start" }, [
              _createElementVNode("div", { class: "ps-3text-uppercase" }, "Balance")
            ], -1)),
            _createElementVNode("td", _hoisted_19, _toDisplayString(_ctx.balance), 1)
          ])
        ])
      ])
    ])
  ], 64))
}