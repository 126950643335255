
import { Options, Vue } from "vue-class-component";
import address from "@/variable";
import axios from "axios";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class Form extends Vue {
  doc: any = {};
  store = useStore();
  updateAmount(){
    this.doc.amount = parseFloat(((this.doc.length??0)*(this.doc.rate??0)).toFixed(2))
  }
  mounted() {
    if (this.$route.params.id != undefined) this.fetchData();
  }
  fetchData() {
    axios.get(`${address}/subcontractor/${this.$route.params.id}`).then((response) => {
      this.doc = response.data;
    });
  }
  push(field:string) {    if(this.doc[field] == undefined) this.doc[field] = []
    this.doc[field].push({})
  }
  submitForm() {
    if (this.$route.params.id != undefined) {
      axios
        .patch(`${address}/subcontractor/${this.doc._id}`, this.doc)
        .then((response) => {
          alert("แก้ไขข้อมูลแล้ว");
          this.$router.push("/subcontractor");
        });
    } else {
      axios
        .post(`${address}/subcontractor`, {
          ...this.doc,
        })
        .then((response) => {
          alert("บันทึกข้อมูลแล้ว");
          this.$router.push("/subcontractor");
        });
    }
  }
}
