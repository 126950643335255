import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "nav nav-tabs" }
const _hoisted_2 = { class: "nav-item" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "rounded shadow bg-white pb-3" }
const _hoisted_5 = { class: "d-flex justify-content-between px-4 py-3" }
const _hoisted_6 = { class: "d-flex align-items-center" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "table main-table" }
const _hoisted_9 = { class: "text-start" }
const _hoisted_10 = {
  class: "fw-bold",
  colspan: "9"
}
const _hoisted_11 = { class: "ms-5 ps-5" }
const _hoisted_12 = { class: "text-primary fw-bold" }
const _hoisted_13 = { class: "text-start" }
const _hoisted_14 = {
  key: 0,
  class: "fas fa-circle text-success"
}
const _hoisted_15 = {
  key: 0,
  class: "fas fa-circle text-success"
}
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = ["href"]
const _hoisted_19 = { class: "text-muted" }
const _hoisted_20 = ["onClick"]
const _hoisted_21 = {
  key: 0,
  class: "text-start"
}
const _hoisted_22 = { class: "text-primary fw-bold" }
const _hoisted_23 = { class: "text-start" }
const _hoisted_24 = {
  key: 0,
  class: "fas fa-circle text-success"
}
const _hoisted_25 = {
  key: 0,
  class: "fas fa-circle text-success"
}
const _hoisted_26 = { key: 0 }
const _hoisted_27 = { key: 1 }
const _hoisted_28 = ["href"]
const _hoisted_29 = { class: "text-muted" }
const _hoisted_30 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JMEntriesItem = _resolveComponent("JMEntriesItem")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_JMSort = _resolveComponent("JMSort")!
  const _component_JMTablePaginate = _resolveComponent("JMTablePaginate")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (item, i) => {
        return (_openBlock(), _createElementBlock("li", _hoisted_2, [
          _createElementVNode("a", {
            class: _normalizeClass(["nav-link", {'active':(_ctx.tabIndex==i)}]),
            onClick: ($event: any) => {_ctx.tabIndex = i; _ctx.category=item.name; _ctx.fetchData();},
            style: {"cursor":"pointer","position":"relative","bottom":"0px"}
          }, [
            _createElementVNode("span", null, _toDisplayString(decodeURIComponent(item.name)), 1)
          ], 10, _hoisted_3)
        ]))
      }), 256))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _cache[23] || (_cache[23] = _createElementVNode("span", { class: "me-3" }, "Entries", -1)),
          _withDirectives(_createElementVNode("select", {
            class: "form-select me-3",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.show) = $event)),
            onChange: _cache[1] || (_cache[1] = ($event: any) => {_ctx.fetchData();_ctx.clearPage()})
          }, [
            _createVNode(_component_JMEntriesItem)
          ], 544), [
            [_vModelSelect, _ctx.show]
          ]),
          _createVNode(_component_router_link, {
            class: "btn btn-primary me-3",
            to: "/lgspecifications/form"
          }, {
            default: _withCtx(() => _cache[20] || (_cache[20] = [
              _createElementVNode("div", { class: "text-nowrap text-white" }, "Add Record", -1)
            ])),
            _: 1
          }),
          _createElementVNode("a", {
            class: "btn btn-outline-success",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$refs.uploadExcel.click()))
          }, _cache[21] || (_cache[21] = [
            _createElementVNode("div", { class: "text-nowrap" }, "Import Excel", -1)
          ])),
          _createElementVNode("input", {
            class: "d-none",
            id: "file-input",
            ref: "uploadExcel",
            type: "file",
            name: "name",
            onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.uploadExcel($event)))
          }, null, 544),
          _createElementVNode("a", {
            class: "ms-3",
            href: `${_ctx.publicPath}xlsx/itd_import_lgspecifications.xlsx`
          }, "(Template)", 8, _hoisted_7),
          _createElementVNode("a", {
            class: "text-danger ms-3",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.deleteMany())),
            style: {"font-size":"0.7rem"}
          }, _cache[22] || (_cache[22] = [
            _createElementVNode("span", { class: "text-nowrap" }, "Delete Many (Caution!)", -1)
          ]))
        ]),
        _cache[24] || (_cache[24] = _createElementVNode("div", { class: "d-flex align-items-center" }, null, -1))
      ]),
      _createElementVNode("table", _hoisted_8, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _cache[32] || (_cache[32] = _createElementVNode("th", null, [
              _createElementVNode("span", null, "#")
            ], -1)),
            _createElementVNode("th", null, [
              _cache[25] || (_cache[25] = _createElementVNode("span", null, "Item", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "item",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[26] || (_cache[26] = _createElementVNode("span", null, "Submit", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "isSubmit",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[27] || (_cache[27] = _createElementVNode("span", null, "Approve", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "isApproved",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[28] || (_cache[28] = _createElementVNode("span", null, "Revision", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "revision",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[12] || (_cache[12] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[29] || (_cache[29] = _createElementVNode("span", null, "Date", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "recordDate",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[14] || (_cache[14] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[30] || (_cache[30] = _createElementVNode("span", null, "File", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "file",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[16] || (_cache[16] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[31] || (_cache[31] = _createElementVNode("span", null, "Remark", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "remark",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[18] || (_cache[18] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _cache[33] || (_cache[33] = _createElementVNode("th", null, [
              _createElementVNode("span", null, "Actions")
            ], -1))
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.codes, (code) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              _createElementVNode("tr", _hoisted_9, [
                _createElementVNode("td", _hoisted_10, [
                  _createElementVNode("span", _hoisted_11, _toDisplayString(code), 1)
                ])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterCode(code), (item, index) => {
                return (_openBlock(), _createElementBlock("tr", null, [
                  _createElementVNode("td", null, [
                    _createElementVNode("div", _hoisted_12, "#" + _toDisplayString(((_ctx.page-1)*_ctx.show)+(index+1)), 1)
                  ]),
                  _createElementVNode("td", _hoisted_13, _toDisplayString(item.item) + " (" + _toDisplayString(decodeURIComponent(item.group)) + ")", 1),
                  _createElementVNode("td", null, [
                    (item.isSubmit)
                      ? (_openBlock(), _createElementBlock("i", _hoisted_14))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("td", null, [
                    (item.isApproved)
                      ? (_openBlock(), _createElementBlock("i", _hoisted_15))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("td", null, "R-" + _toDisplayString(item.revision), 1),
                  _createElementVNode("td", null, [
                    (item.isApproved)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString((item.submitDate??"").formatDate()), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString((item.approvedDate??"").formatDate()), 1))
                  ]),
                  _createElementVNode("td", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.files, (item) => {
                      return (_openBlock(), _createElementBlock("a", {
                        href: `${_ctx.address}/${item}`
                      }, _cache[34] || (_cache[34] = [
                        _createElementVNode("i", { class: "fad fa-cloud text-warning" }, null, -1)
                      ]), 8, _hoisted_18))
                    }), 256))
                  ]),
                  _createElementVNode("td", null, _toDisplayString(item.remark), 1),
                  _createElementVNode("td", null, [
                    _createElementVNode("div", _hoisted_19, [
                      (_ctx.store.state.accessLevel>=5)
                        ? (_openBlock(), _createBlock(_component_router_link, {
                            key: 0,
                            to: `/lgspecifications/edit/${item._id}`
                          }, {
                            default: _withCtx(() => _cache[35] || (_cache[35] = [
                              _createElementVNode("i", { class: "class fal fa-pencil text-warning" }, null, -1)
                            ])),
                            _: 2
                          }, 1032, ["to"]))
                        : _createCommentVNode("", true),
                      (_ctx.store.state.accessLevel>=5)
                        ? (_openBlock(), _createElementBlock("i", {
                            key: 1,
                            class: "class fal fa-trash text-danger ms-2",
                            onClick: ($event: any) => (_ctx.deleteItem(item._id))
                          }, null, 8, _hoisted_20))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]))
              }), 256))
            ], 64))
          }), 256)),
          (_ctx.docs.filter(o=>(o.group=='ไม่ระบุ'||o.group==undefined||true)).length>0)
            ? (_openBlock(), _createElementBlock("tr", _hoisted_21, _cache[36] || (_cache[36] = [
                _createElementVNode("td", {
                  class: "fw-bold",
                  colspan: "9"
                }, [
                  _createElementVNode("span", { class: "ms-5 ps-5" }, "ไม่ระบุ/ไม่ตรงกับหมวดหมู่")
                ], -1)
              ])))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.docs.filter(o=>(o.group=='ไม่ระบุ'||o.group==''||o.group==undefined)), (item, index) => {
            return (_openBlock(), _createElementBlock("tr", null, [
              _createElementVNode("td", null, [
                _createElementVNode("div", _hoisted_22, "#" + _toDisplayString(((_ctx.page-1)*_ctx.show)+(index+1)), 1)
              ]),
              _createElementVNode("td", _hoisted_23, [
                _createElementVNode("span", null, _toDisplayString(item.item), 1)
              ]),
              _createElementVNode("td", null, [
                (item.isSubmit)
                  ? (_openBlock(), _createElementBlock("i", _hoisted_24))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("td", null, [
                (item.isApproved)
                  ? (_openBlock(), _createElementBlock("i", _hoisted_25))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("td", null, "R-" + _toDisplayString(item.revision), 1),
              _createElementVNode("td", null, [
                (item.isApproved)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_26, _toDisplayString((item.submitDate??"").formatDate()), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString((item.approvedDate??"").formatDate()), 1))
              ]),
              _createElementVNode("td", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.files, (item) => {
                  return (_openBlock(), _createElementBlock("a", {
                    href: `${_ctx.address}/${item}`
                  }, _cache[37] || (_cache[37] = [
                    _createElementVNode("i", { class: "fad fa-cloud text-warning" }, null, -1)
                  ]), 8, _hoisted_28))
                }), 256))
              ]),
              _createElementVNode("td", null, _toDisplayString(item.remark), 1),
              _createElementVNode("td", null, [
                _createElementVNode("div", _hoisted_29, [
                  (_ctx.store.state.accessLevel>=5)
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        to: `/lgspecifications/edit/${item._id}`
                      }, {
                        default: _withCtx(() => _cache[38] || (_cache[38] = [
                          _createElementVNode("i", { class: "class fal fa-pencil text-warning" }, null, -1)
                        ])),
                        _: 2
                      }, 1032, ["to"]))
                    : _createCommentVNode("", true),
                  (_ctx.store.state.accessLevel>=5)
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 1,
                        class: "class fal fa-trash text-danger ms-2",
                        onClick: ($event: any) => (_ctx.deleteItem(item._id))
                      }, null, 8, _hoisted_30))
                    : _createCommentVNode("", true)
                ])
              ])
            ]))
          }), 256))
        ])
      ]),
      _createVNode(_component_JMTablePaginate, {
        page: _ctx.page,
        pages: _ctx.pages,
        show: _ctx.show,
        total: _ctx.total,
        onChange: _cache[19] || (_cache[19] = ($event: any) => {_ctx.page=$event; _ctx.fetchData()})
      }, null, 8, ["page", "pages", "show", "total"])
    ])
  ], 64))
}