import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "container rounded shadow bg-white px-4 pb-5 pt-3" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "col-sm-6 mb-2" }
const _hoisted_5 = { class: "col-sm-6 mb-2" }
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "col-sm-6 mb-2" }
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "col-sm-6 mb-2" }
const _hoisted_10 = {
  key: 0,
  class: "col-sm-6 mb-2"
}
const _hoisted_11 = ["value"]
const _hoisted_12 = {
  key: 1,
  class: "col-sm-6 mb-2"
}
const _hoisted_13 = ["value"]
const _hoisted_14 = { class: "col-sm-6 mb-2" }
const _hoisted_15 = ["value"]
const _hoisted_16 = { class: "col-sm-6 mb-2" }
const _hoisted_17 = ["value"]
const _hoisted_18 = { class: "col-sm-6 mb-2" }
const _hoisted_19 = { class: "col-sm-6 mb-2" }
const _hoisted_20 = { class: "col-sm-6 mb-2" }
const _hoisted_21 = { class: "col-sm-6 mb-2" }
const _hoisted_22 = { class: "col-sm-6 mb-2" }
const _hoisted_23 = { class: "col-sm-6 mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JMMonth = _resolveComponent("JMMonth")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.params.id == undefined)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, "Add Form"))
      : (_openBlock(), _createElementBlock("h2", _hoisted_3, "Edit Form")),
    _createElementVNode("form", {
      class: "row needs-validation",
      onSubmit: _cache[15] || (_cache[15] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_4, [
        _cache[16] || (_cache[16] = _createElementVNode("label", { class: "form-label" }, "Month", -1)),
        _createVNode(_component_JMMonth, {
          modelValue: _ctx.doc.month,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.doc.month) = $event)),
          modelModifiers: { number: true },
          onChange2: _cache[1] || (_cache[1] = ($event: any) => (_ctx.doc.month = JSON.stringify($event)))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[17] || (_cache[17] = _createElementVNode("label", { class: "form-label" }, "Year", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.doc.year) = $event))
        }, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (item) => {
            return _createElementVNode("option", {
              value: new Date().getFullYear() - item + 1
            }, _toDisplayString(new Date().getFullYear() - item + 1), 9, _hoisted_6)
          }), 64))
        ], 512), [
          [_vModelSelect, _ctx.doc.year]
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _cache[18] || (_cache[18] = _createElementVNode("label", { class: "form-label" }, "Job", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.doc.job) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.jobs, (item) => {
            return (_openBlock(), _createElementBlock("option", {
              value: item.j
            }, "J." + _toDisplayString(item.j) + " : " + _toDisplayString(item.name), 9, _hoisted_8))
          }), 256))
        ], 512), [
          [_vModelSelect, _ctx.doc.job]
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _cache[20] || (_cache[20] = _createElementVNode("label", { class: "form-label" }, "Category", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.doc.category) = $event))
        }, _cache[19] || (_cache[19] = [
          _createElementVNode("option", null, "Expense", -1),
          _createElementVNode("option", null, "Income", -1)
        ]), 512), [
          [_vModelSelect, _ctx.doc.category]
        ])
      ]),
      (_ctx.doc.job!='2369')
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _cache[21] || (_cache[21] = _createElementVNode("label", { class: "form-label" }, "Code1 (Site)", -1)),
            _withDirectives(_createElementVNode("select", {
              class: "form-select",
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.doc.code1) = $event))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.code1Site, (item) => {
                return (_openBlock(), _createElementBlock("option", {
                  value: item.name
                }, _toDisplayString(decodeURIComponent(item.name)), 9, _hoisted_11))
              }), 256))
            ], 512), [
              [_vModelSelect, _ctx.doc.code1]
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _cache[22] || (_cache[22] = _createElementVNode("label", { class: "form-label" }, "Code1 (Yard)", -1)),
            _withDirectives(_createElementVNode("select", {
              class: "form-select",
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.doc.code1) = $event))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.code1Yard, (item) => {
                return (_openBlock(), _createElementBlock("option", {
                  value: item.name
                }, _toDisplayString(decodeURIComponent(item.name)), 9, _hoisted_13))
              }), 256))
            ], 512), [
              [_vModelSelect, _ctx.doc.code1]
            ])
          ])),
      _createElementVNode("div", _hoisted_14, [
        _cache[23] || (_cache[23] = _createElementVNode("label", { class: "form-label" }, "Code2", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.doc.code2) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.code2, (item) => {
            return (_openBlock(), _createElementBlock("option", {
              value: item.name
            }, _toDisplayString(decodeURIComponent(item.name)), 9, _hoisted_15))
          }), 256))
        ], 512), [
          [_vModelSelect, _ctx.doc.code2]
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _cache[24] || (_cache[24] = _createElementVNode("label", { class: "form-label" }, "Code3", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.doc.code3) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.code3, (item) => {
            return (_openBlock(), _createElementBlock("option", {
              value: item.name
            }, _toDisplayString(decodeURIComponent(item.name)), 9, _hoisted_17))
          }), 256))
        ], 512), [
          [_vModelSelect, _ctx.doc.code3]
        ])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _cache[25] || (_cache[25] = _createElementVNode("label", { class: "form-label" }, "Unit", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.doc.unit) = $event)),
          required: ""
        }, null, 512), [
          [_vModelText, _ctx.doc.unit]
        ])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _cache[26] || (_cache[26] = _createElementVNode("label", { class: "form-label" }, "Quantity", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "number",
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.doc.qty) = $event)),
          required: "",
          step: "0.01"
        }, null, 512), [
          [
            _vModelText,
            _ctx.doc.qty,
            void 0,
            { number: true }
          ]
        ])
      ]),
      _createElementVNode("div", _hoisted_20, [
        _cache[27] || (_cache[27] = _createElementVNode("label", { class: "form-label" }, "Amount", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "number",
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.doc.amount) = $event)),
          required: "",
          step: "0.01"
        }, null, 512), [
          [
            _vModelText,
            _ctx.doc.amount,
            void 0,
            { number: true }
          ]
        ])
      ]),
      _createElementVNode("div", _hoisted_21, [
        _cache[28] || (_cache[28] = _createElementVNode("label", { class: "form-label" }, "Cost By", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.doc.by) = $event)),
          required: ""
        }, null, 512), [
          [_vModelText, _ctx.doc.by]
        ])
      ]),
      _createElementVNode("div", _hoisted_22, [
        _cache[29] || (_cache[29] = _createElementVNode("label", { class: "form-label" }, "Doc No", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.doc.document) = $event)),
          required: ""
        }, null, 512), [
          [_vModelText, _ctx.doc.document]
        ])
      ]),
      _createElementVNode("div", _hoisted_23, [
        _cache[30] || (_cache[30] = _createElementVNode("label", { class: "form-label" }, "Remark", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.doc.remark) = $event)),
          required: ""
        }, null, 512), [
          [_vModelText, _ctx.doc.remark]
        ])
      ]),
      _cache[31] || (_cache[31] = _createStaticVNode("<div class=\"row g-3 d-flex justify-content-center\"><div class=\"col-5\"><button class=\"w-100 btn btn-secondary text-white btn-lg mt-4\">ย้อนกลับ</button></div><div class=\"col-5\"><button class=\"w-100 btn btn-primary btn-lg mt-4 text-white\" id=\"save\" type=\"submit\">บันทึก</button></div></div>", 1))
    ], 32)
  ]))
}