
import { Options, Vue } from "vue-class-component";
import address from "@/variable";
import axios from "axios";

@Options({
  components: {},
})
export default class Form extends Vue {
  doc: any = {};
  ledgers: Array<any> = [];
  year: number = new Date().getFullYear();
  mounted() {
    this.fetchData();
  }
  getByCodeMonth(code: string, month: number) {
    try {
      let result = this.ledgers
        .filter((el) => decodeURIComponent(el.code1) == code && el.month == month)
        .map((el) => el.amount)
        .reduce((a: any, b: any) => (a ?? 0) + (b ?? 0), 0);
      return result;
    } catch (error) {
      return 0;
    }
  }
  getByCode(code: string) {
    try {
      let result = this.ledgers
        .filter((el) => decodeURIComponent(el.code1) == code)
        .map((el) => el.amount)
        .reduce((a: any, b: any) => (a ?? 0) + (b ?? 0), 0);
      console.log(result);
      return result;
    } catch (error) {
      return 0;
    }
  }
  fetchData() {
    axios
      .get(`${address}/job-by-field/j/${this.$route.params.j}`)
      .then((response) => {
        this.doc = response.data;
      });
    let query = {
      search: {
        job: this.$route.params.j,
        year: this.year,
        // category: "Expense",
      },
      page: 1,
      limit: 9999999,
    };
    axios.post(`${address}/ledgers-paginate`, query).then((response) => {
      console.log(response);
      this.ledgers = response.data.docs;
      // this.$forceUpdate()
    });
  }
}
