
import { Options, Vue } from "vue-class-component";
import address from "@/variable";
import axios from "axios";
import { useStore } from "vuex";

@Options({
  components: {},
  props: {
    job: String,
    category: String,
  },
})
export default class Form extends Vue {
  store = useStore();
  doc: any = {
    access:[]
  };
  codes:Array<any> = []
  address = address;
  filePath = address
  category?:String
  job?:String

  accesses:Array<string> =  [
    'Cost',
    'Progress',
    'Progress Summary',
    'Accommodation',
    'Manpower',
    'Manpower Summary',
    'SHEAR PIN &amp; WIRE ROPE',
    'SHEAR PIN &amp; WIRE ROPE Summary',
    'PT Bar',
    'PT Bar Summary',
    'Jack',
    'Jack Summary',
    'Equipment',
    'Equipment Summary',
    'Steel Sheet',
    'Store',
    'Consumable',
    'Consumable Summary',
    'Spare Part',
    'Spare Part Summary',
    'Main Mat',
    'Main Mat Summary',
    'Small Tool',
    'Small Tool Summary',
    'POL',
    'POL Summary',
    'Tool',
    'Budget Agreement',
    'Drawing',
    // 'Submission',
    'MS &amp Mat.',
    'Presentation',
    'Monthly Progress',
    'Insurance',
    'Pier Schedules',
    'Pier Schedules (Erection)',
    'LG Specifications',
    // 'Bearing',
    'Sub Contractor &amp; Supplier',
    'Code',
    'Job',
    'User',
    'Report',

  ]

  mounted() {
    if (this.$route.params.id != undefined) this.fetchData();
  }
  pushAccess() {
    if (!Array.isArray(this.doc.access)) this.doc.access = [];
    this.doc.access.push({
      name: "",
      text1: "",
    });
  }
  fetchData() {
    axios.get(`${address}/user/${this.$route.params.id}`).then((response) => {
      let data = response.data;
      this.doc = data;
      if(!Array.isArray(this.doc.accesses)) this.doc.accesses = [];
    });
  }
  submitForm() {
    let prep:any = JSON.parse(JSON.stringify(this.doc));
    if (this.$route.params.id != undefined) {
      axios.patch(`${address}/user/${this.doc._id}`, prep).then((response) => {
        alert("แก้ไขข้อมูลแล้ว");
      });
    } else {
      axios
        .post(`${address}/user`, {
          ...prep,
        })
        .then((response) => {
          alert("บันทึกข้อมูลแล้ว");
        });
    }
  }
}
