import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "rounded shadow bg-white pb-3" }
const _hoisted_2 = { class: "d-flex justify-content-between px-4 py-3" }
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = { class: "table main-table" }
const _hoisted_5 = { class: "text-end" }
const _hoisted_6 = { class: "text-end" }
const _hoisted_7 = { class: "text-end" }
const _hoisted_8 = { class: "text-end" }
const _hoisted_9 = { class: "text-end" }
const _hoisted_10 = { class: "text-end" }
const _hoisted_11 = { class: "text-end" }
const _hoisted_12 = { class: "text-end" }
const _hoisted_13 = { class: "text-end" }
const _hoisted_14 = { class: "text-end" }
const _hoisted_15 = { class: "text-end" }
const _hoisted_16 = { class: "text-primary fw-bold" }
const _hoisted_17 = { class: "text-muted" }
const _hoisted_18 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JMEntriesItem = _resolveComponent("JMEntriesItem")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_JMSort = _resolveComponent("JMSort")!
  const _component_JMTablePaginate = _resolveComponent("JMTablePaginate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[32] || (_cache[32] = _createElementVNode("span", { class: "me-3" }, "Entries", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select me-3",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.show) = $event)),
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fetchData(),_ctx.clearPage()))
        }, [
          _createVNode(_component_JMEntriesItem)
        ], 544), [
          [_vModelSelect, _ctx.show]
        ]),
        _createVNode(_component_router_link, {
          class: "btn btn-primary",
          to: "/maintenance/form"
        }, {
          default: _withCtx(() => _cache[31] || (_cache[31] = [
            _createElementVNode("div", { class: "text-nowrap text-white" }, "Add Record", -1)
          ])),
          _: 1
        })
      ]),
      _cache[33] || (_cache[33] = _createElementVNode("div", { class: "d-flex align-items-center" }, [
        _createElementVNode("select", { class: "form-select" }, [
          _createElementVNode("option", {
            disabled: "",
            selected: "true"
          }, "Select Status")
        ])
      ], -1))
    ]),
    _createElementVNode("table", _hoisted_4, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _cache[48] || (_cache[48] = _createElementVNode("th", null, [
            _createElementVNode("span", null, "#")
          ], -1)),
          _createElementVNode("th", null, [
            _cache[34] || (_cache[34] = _createElementVNode("span", null, "Document Record Date", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "recordDate",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", null, [
            _cache[35] || (_cache[35] = _createElementVNode("span", null, "Reference Type", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "referenceType",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", null, [
            _cache[36] || (_cache[36] = _createElementVNode("span", null, "Reference Document", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "referenceDocument",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", _hoisted_5, [
            _cache[37] || (_cache[37] = _createElementVNode("span", null, "Name", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "name",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", _hoisted_6, [
            _cache[38] || (_cache[38] = _createElementVNode("span", null, "Equipment", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "equipment",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[11] || (_cache[11] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", _hoisted_7, [
            _cache[39] || (_cache[39] = _createElementVNode("span", null, "accessory", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "accessory",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[13] || (_cache[13] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", _hoisted_8, [
            _cache[40] || (_cache[40] = _createElementVNode("span", null, "tool", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "tool",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[15] || (_cache[15] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", _hoisted_9, [
            _cache[41] || (_cache[41] = _createElementVNode("span", null, "Maintenance Group", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "group",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[17] || (_cache[17] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", _hoisted_10, [
            _cache[42] || (_cache[42] = _createElementVNode("span", null, "Maintenance Code", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "code",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[19] || (_cache[19] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", _hoisted_11, [
            _cache[43] || (_cache[43] = _createElementVNode("span", null, "Amount", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "amount",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[21] || (_cache[21] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", _hoisted_12, [
            _cache[44] || (_cache[44] = _createElementVNode("span", null, "Description", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "description",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[23] || (_cache[23] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", _hoisted_13, [
            _cache[45] || (_cache[45] = _createElementVNode("span", null, "Cause", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "cause",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[25] || (_cache[25] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", _hoisted_14, [
            _cache[46] || (_cache[46] = _createElementVNode("span", null, "Job", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "job",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[27] || (_cache[27] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", _hoisted_15, [
            _cache[47] || (_cache[47] = _createElementVNode("span", null, "Detail", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "detail",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[29] || (_cache[29] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _cache[49] || (_cache[49] = _createElementVNode("th", null, "Actions", -1))
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.docs, (item, index) => {
          return (_openBlock(), _createElementBlock("tr", null, [
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_16, "#" + _toDisplayString(((_ctx.page-1)*_ctx.show)+(index+1)), 1)
            ]),
            _createElementVNode("td", null, _toDisplayString((item.recordDate).formatMonthYear()), 1),
            _createElementVNode("td", null, _toDisplayString(item.referenceType), 1),
            _createElementVNode("td", null, _toDisplayString(item.referenceDocument), 1),
            _createElementVNode("td", null, _toDisplayString(item.name), 1),
            _createElementVNode("td", null, _toDisplayString(item.equipment), 1),
            _createElementVNode("td", null, _toDisplayString(item.accessory), 1),
            _createElementVNode("td", null, _toDisplayString(item.tool), 1),
            _createElementVNode("td", null, _toDisplayString(item.group), 1),
            _createElementVNode("td", null, _toDisplayString(item.code), 1),
            _createElementVNode("td", null, _toDisplayString(item.amount), 1),
            _createElementVNode("td", null, _toDisplayString(item.description), 1),
            _createElementVNode("td", null, _toDisplayString(item.cause), 1),
            _createElementVNode("td", null, _toDisplayString(item.job), 1),
            _createElementVNode("td", null, _toDisplayString(item.detail), 1),
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_17, [
                (_ctx.store.state.accessLevel>=5)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: `/maintenance/edit/${item._id}`
                    }, {
                      default: _withCtx(() => _cache[50] || (_cache[50] = [
                        _createElementVNode("i", { class: "class fal fa-pencil text-warning" }, null, -1)
                      ])),
                      _: 2
                    }, 1032, ["to"]))
                  : _createCommentVNode("", true),
                (_ctx.store.state.accessLevel>=5)
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 1,
                      class: "class fal fa-trash text-danger ms-2",
                      onClick: ($event: any) => (_ctx.deleteItem(item._id))
                    }, null, 8, _hoisted_18))
                  : _createCommentVNode("", true)
              ])
            ])
          ]))
        }), 256))
      ])
    ]),
    _createVNode(_component_JMTablePaginate, {
      page: _ctx.page,
      pages: _ctx.pages,
      show: _ctx.show,
      total: _ctx.total,
      onChange: _cache[30] || (_cache[30] = ($event: any) => {_ctx.page=$event; _ctx.fetchData()})
    }, null, 8, ["page", "pages", "show", "total"])
  ]))
}