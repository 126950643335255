import { DateTime } from "luxon";

export {};

declare global {
  interface String {
    capitalize(): String;
    formatComma(): String;
    formatCizitenId(): String;
    formatThai():String;
    formatMonthYear():String;
    formatDate():String;
    formatDuration():String;
    formatDurationFull():String;
  }
  interface Number {
    formatComma(): string;
    formatFull(): string;
    formatShort(): string;
    formatFullFOC(): string;
    formatMB(): string;
    formatPercent(): string;
  }
}

String.prototype.formatThai = function(){
  var array = { "1": "๑", "2": "๒", "3": "๓", "4": "๔", "5": "๕", "6": "๖", "7": "๗", "8": "๘", "9": "๙", "0": "๐" };
  var str = this
  for (var val in array) {
    //@ts-ignore
    str = str.split(val).join(array[val]);
  }
  return str;
}
String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};
String.prototype.formatComma = function() {
  return this.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
String.prototype.formatCizitenId = function() {
  try {
    return this.replace(
      /(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/g,
      "$1-$2-$3-$4-$5"
    );
  } catch (err) {
    return "";
  }
};
String.prototype.formatMonthYear = function(){
  try {  
    let dt = DateTime.fromISO(this as string)
    let fm = dt.toFormat('MMM yy');
    if(fm=="Invalid DateTime") return "-"
    else return fm
  } catch (error) {
    return "-"
  }
}
String.prototype.formatDate = function(){
  try {  
    let dt = DateTime.fromISO(this as string)
    let fm = dt.toFormat('d MMM yy');
    if(fm=="Invalid DateTime") return "-"
    else return fm
  } catch (error) {
    return "-"
  }
}
String.prototype.formatDuration = function(){
  try {  
    let dt = DateTime.fromISO(this as string)
    let dtObj:any = dt.diffNow(['years','months', 'days']).toObject();
    let fm = `${Math.abs(dtObj.years)} ปี`
    return fm
  } catch (error) {
    return "-"
  }
}
String.prototype.formatDurationFull = function(){
  try {  
    let dt = DateTime.fromISO(this as string)
    let dtObj:any = dt.diffNow(['years','months', 'days']).toObject();
    let fm = `${Math.abs(dtObj.years)} ปี ${Math.abs(dtObj.months)} เดือน ${Math.round(Math.abs(dtObj.days))} วัน`
    return fm
  } catch (error) {
    return "-"
  }
}

Number.prototype.formatComma = function() {
  if(this == 0) return "-"
  return this.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
Number.prototype.formatFull = function() {
  if(this == 0) return "-"
  return this.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
Number.prototype.formatShort = function() {
  if(this == 0) return "-"
  return this.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
Number.prototype.formatFullFOC = function() {
  if(this == 0) return "FOC"
  return this.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
Number.prototype.formatMB = function() {
  if(this == 0) return "-"
  let num = this as number
  return (num/1000000).toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
Number.prototype.formatPercent = function() {
  let percent = Math.round((this as number)*100)
  let str = percent.toString()
  if(str=="NaN") return "0"
  else if (str=="Infinity") return "100"
  return percent.toString()
};
