import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "rounded shadow bg-white pb-3" }
const _hoisted_2 = { class: "d-flex justify-content-between px-4 py-3" }
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = { class: "table main-table" }
const _hoisted_5 = { class: "text-primary fw-bold" }
const _hoisted_6 = { class: "text-start" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-2" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 2 }
const _hoisted_12 = { key: 3 }
const _hoisted_13 = { class: "text-muted" }
const _hoisted_14 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JMEntriesItem = _resolveComponent("JMEntriesItem")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_JMSort = _resolveComponent("JMSort")!
  const _component_JMTablePaginate = _resolveComponent("JMTablePaginate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[10] || (_cache[10] = _createElementVNode("span", { class: "me-3" }, "Entries", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select me-3",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.show) = $event)),
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fetchData(),_ctx.clearPage()))
        }, [
          _createVNode(_component_JMEntriesItem)
        ], 544), [
          [_vModelSelect, _ctx.show]
        ]),
        _createVNode(_component_router_link, {
          class: "btn btn-primary me-3",
          to: "/user/form"
        }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createElementVNode("div", { class: "text-nowrap text-white" }, "Add Record", -1)
          ])),
          _: 1
        })
      ]),
      _cache[11] || (_cache[11] = _createElementVNode("div", { class: "d-flex align-items-center" }, null, -1))
    ]),
    _createElementVNode("table", _hoisted_4, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _cache[15] || (_cache[15] = _createElementVNode("th", null, [
            _createElementVNode("span", null, "#")
          ], -1)),
          _createElementVNode("th", null, [
            _cache[12] || (_cache[12] = _createElementVNode("span", null, "Username", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "username",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", null, [
            _cache[13] || (_cache[13] = _createElementVNode("span", null, "Access", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "access",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", null, [
            _cache[14] || (_cache[14] = _createElementVNode("span", null, "Level", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "level",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _cache[16] || (_cache[16] = _createElementVNode("th", null, [
            _createElementVNode("span", null, "Actions")
          ], -1))
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.docs, (item, index) => {
          return (_openBlock(), _createElementBlock("tr", null, [
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_5, "#" + _toDisplayString(((_ctx.page-1)*_ctx.show)+(index+1)), 1)
            ]),
            _createElementVNode("td", null, _toDisplayString(item.username), 1),
            _createElementVNode("td", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.access, (access) => {
                  return (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(access), 1))
                }), 256))
              ])
            ]),
            _createElementVNode("td", null, [
              (item.level==1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, "เจ้าหน้าที่ทั่วไป"))
                : (item.level==5)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_10, "เจ้าหน้าที่ปฏิบัติการ"))
                  : (item.level==10)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, "ผู้บริหาร/ผู้ดูแลระบบ"))
                    : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(item.level), 1))
            ]),
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_13, [
                (_ctx.store.state.accessLevel>=5)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: `/user/edit/${item._id}`
                    }, {
                      default: _withCtx(() => _cache[17] || (_cache[17] = [
                        _createElementVNode("i", { class: "class fal fa-pencil text-warning" }, null, -1)
                      ])),
                      _: 2
                    }, 1032, ["to"]))
                  : _createCommentVNode("", true),
                (_ctx.store.state.accessLevel>=5)
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 1,
                      class: "class fal fa-trash text-danger ms-2",
                      onClick: ($event: any) => (_ctx.deleteItem(item._id))
                    }, null, 8, _hoisted_14))
                  : _createCommentVNode("", true)
              ])
            ])
          ]))
        }), 256))
      ])
    ]),
    _createVNode(_component_JMTablePaginate, {
      page: _ctx.page,
      pages: _ctx.pages,
      show: _ctx.show,
      total: _ctx.total,
      onChange: _cache[8] || (_cache[8] = ($event: any) => {_ctx.page=$event; _ctx.fetchData()})
    }, null, 8, ["page", "pages", "show", "total"])
  ]))
}