
import { Options, Vue } from "vue-class-component";
import TopNavigation from "@/components/TopNavigation.vue";
import { useStore } from "vuex";
import axios from "axios";
import address from "./variable";

@Options({
  components: {
    TopNavigation,
  },
  props: {},
})
export default class MyApp extends Vue {
  store = useStore();
  mounted() {
    axios.get(`${address}/jobs`).then(response=>{
      this.store.commit('setJobs',response.data.map((el:any)=>{ let prep = el; delete prep.budget; delete prep.cost; return {...prep}}))
    })

    axios.get(`${address}/code-by-field/name/Cost Code 1 Site`).then(response=>{
      this.store.commit('setCode1Site',response.data.member)
    })
    axios.get(`${address}/code-by-field/name/Cost Code 1 Yard`).then(response=>{
      this.store.commit('setCode1Yard',response.data.member)
    })
    axios.get(`${address}/code-by-field/name/Cost Code 2`).then(response=>{
      this.store.commit('setCode2',response.data.member)
    })
    axios.get(`${address}/code-by-field/name/Cost Code 3`).then(response=>{
      this.store.commit('setCode3',response.data.member)
    })

    axios.get(`${address}/code-by-field/name/Technical Type`).then(response=>{
      this.store.commit('setTechnicals',response.data.member)
    })
    axios.get(`${address}/code-by-field/name/Accessory Type`).then(response=>{
      this.store.commit('setAccessories',response.data.member)
    })
    axios.get(`${address}/code-by-field/name/Equipment Type`).then(response=>{
      this.store.commit('setEquipments',response.data.member)
    })
    axios.get(`${address}/code-by-field/name/Tool Type`).then(response=>{
      this.store.commit('setTools',response.data.member)
    })
    axios.get(`${address}/code-by-field/name/Fuel Type`).then(response=>{
      this.store.commit('setFuels',response.data.member)
    })
    axios.get(`${address}/code-by-field/name/Technical Type`).then(response=>{
      this.store.commit('setTechnicals',response.data.member)
    })
  }
}
