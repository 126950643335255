
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import address, { filePath } from "@/variable";
import { useStore } from "vuex";

@Options({
  components: {},
  props: {
    job: String,
    category: String,
  },
})
export default class List extends Vue {
  store = useStore();
  docs: Array<any> = [];
  filter1: any = {};
  filter2: any = {};
  path: string = address;
  address = address;
  total: number = 0;
  show: number = 50;
  page: number = 1;
  pages: number = 0;
  sort: any = {
    username: -1
  };
  sortKey: string = "name";
  sortDir: number = 1;
  category?:String
  job?:String
  publicPath = "";
  groups?: Array<any>;
   tabIndex: number = -1;


  async uploadExcel(ev: any) {
    const Excel = require("exceljs/dist/es5/exceljs.browser");
    let self = this;
    let file = ev.target.files[0];
    const workbook = new Excel.Workbook();
    let book = await workbook.xlsx.load(file);
    let sheet = book.getWorksheet("import");
    let arr: Array<any> = [];
    sheet.eachRow(function(row: any, rowNumber: any) {
      if (rowNumber > 1 && row.getCell("A").value != null) {
        console.log(rowNumber)
        arr.push({
          job: row.getCell("A").value,
          description: row.getCell("B").value,
          supplier: row.getCell("C").value,
          code: encodeURIComponent(row.getCell("D").value),
          isSubmit: row.getCell("E").value == "n" ? true : false,
          submitDate: row.getCell("F").value,
          isApproved: row.getCell("G").value == "n" ? true : false,
          approvedDate: row.getCell("H").value,
          revision: row.getCell("I").value,
          recordDate: row.getCell("J").value,
          remark: row.getCell("K").value,
        });
      }
    });
    let promises: Array<Promise<any>> = [];
    arr.forEach((el) => {
      promises.push(axios.post(`${address}/user`, el));
    });
    Promise.all(promises).then((responses) => {
      console.log(responses);
      this.fetchData();
    });
  }

  deleteMany(){
    let promises: Array<Promise<any>> = [];
    this.docs.forEach(el=>{
      promises.push(axios.delete(`${address}/user/${el._id}`));
    })
    Promise.all(promises).then((responses) => {
      console.log(responses);
      this.fetchData();
    });
  }

  mounted() {
    try {
      console.log(this.groups);
      let foundIndex = this.groups!.findIndex((o) => o.text2 == this.$route.params.job);
      this.category = this.groups![foundIndex].name;
      this.tabIndex = foundIndex
    } catch (error) {}
    this.fetchData();
    this.publicPath = process.env.BASE_URL;
  }
  updatePaginate(i: number) {
    this.page = i;
    // console.log(this.page);
    this.fetchData();
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }
  clearPage() {
    if (this.show == 999999999) {
      this.page = 0;
      this.fetchData();
    }
  }
  deleteItem(id: string) {
    var c = confirm("คุณแน่ใจหรือว่าต้องการลบรายการนี้");
    if (c) {
      axios.delete(`${address}/user/${id}`).then((response) => {
        this.fetchData();
      });
    }
  }
  fetchData() {
    this.docs = [];
    let sort: any = this.sort;
    let query = {
      search: {  },
      page: this.page,
      limit: this.show,
      skip: 0,
      sort,
    };
    axios.post(`${address}/users-paginate`, query).then((response) => {
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
    });
  }
}
