
import { Options, Vue } from "vue-class-component";
import address, { filePath } from "@/variable";
import axios from "axios";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class Form extends Vue {
  doc: any = {};
  lgs: Array<any> = [];
  actings: Array<any> = [];
  brands: Array<any> = [];
  details: Array<any> = [];
  series: Array<any> = [];
  status: Array<any> = [];
  store = useStore();
  filePath = filePath;
  mounted() {
    if (this.$route.params.id != undefined) this.fetchData();
    axios.get(`${address}/code-by-field/name/Launching Girders`).then((response) => {
      this.lgs = response.data.member;
    });
    axios.get(`${address}/code-by-field/name/Jack Acting`).then((response) => {
      this.actings = response.data.member;
    });
    axios.get(`${address}/code-by-field/name/Jack Brand`).then((response) => {
      this.brands = response.data.member;
    });
    axios.get(`${address}/code-by-field/name/Jack Detail`).then((response) => {
      this.details = response.data.member;
    });
    axios.get(`${address}/code-by-field/name/Jack Series`).then((response) => {
      this.series = response.data.member;
    });
    axios.get(`${address}/code-by-field/name/Jack Status`).then((response) => {
      this.status = response.data.member;
    });
  }
  fetchData() {
    axios.get(`${address}/jack/${this.$route.params.id}`).then((response) => {
      this.doc = response.data;
    });
  }
  submitForm() {
    if (this.$route.params.id != undefined) {
      axios
        .patch(`${address}/jack/${this.doc._id}`, this.doc)
        .then((response) => {
          alert("แก้ไขข้อมูลแล้ว");
          this.$router.push("/jack");
        });
    } else {
      axios
        .post(`${address}/jack`, {
          ...this.doc,
        })
        .then((response) => {
          alert("บันทึกข้อมูลแล้ว");
          // console.log(response)
          this.$router.push("/jack");
        });
    }
  }
}
