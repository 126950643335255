import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "rounded shadow bg-white pb-3" }
const _hoisted_2 = { class: "table main-table table-bordered" }
const _hoisted_3 = { class: "text-end" }
const _hoisted_4 = { class: "text-end" }
const _hoisted_5 = { class: "text-start" }
const _hoisted_6 = ["rowspan"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { class: "text-end" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "text-end" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { class: "text-end blue" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { key: 1 }
const _hoisted_19 = ["rowspan"]
const _hoisted_20 = ["rowspan"]
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { key: 1 }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = {
  key: 1,
  class: "text-danger"
}
const _hoisted_25 = ["rowspan"]
const _hoisted_26 = ["rowspan"]
const _hoisted_27 = { key: 0 }
const _hoisted_28 = { key: 1 }
const _hoisted_29 = { key: 0 }
const _hoisted_30 = { key: 1 }
const _hoisted_31 = {
  key: 1,
  class: "fw-bold"
}
const _hoisted_32 = { class: "text-center" }
const _hoisted_33 = { class: "text-end" }
const _hoisted_34 = { class: "text-end" }
const _hoisted_35 = { class: "text-end" }
const _hoisted_36 = { class: "text-end blue" }
const _hoisted_37 = { class: "text-end" }
const _hoisted_38 = { class: "text-end" }
const _hoisted_39 = { key: 0 }
const _hoisted_40 = { key: 1 }
const _hoisted_41 = { class: "fw-bold" }
const _hoisted_42 = { class: "text-end" }
const _hoisted_43 = { class: "text-end" }
const _hoisted_44 = { class: "text-end" }
const _hoisted_45 = { class: "text-end blue" }
const _hoisted_46 = { class: "text-end" }
const _hoisted_47 = { class: "text-end" }
const _hoisted_48 = { class: "fw-bold" }
const _hoisted_49 = { class: "text-end" }
const _hoisted_50 = { class: "fw-bold" }
const _hoisted_51 = { class: "text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h2", null, "J." + _toDisplayString(_ctx.$route.params.j) + " Budget Report", 1),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("table", _hoisted_2, [
        _createElementVNode("thead", null, [
          _cache[4] || (_cache[4] = _createElementVNode("tr", null, [
            _createElementVNode("th", { rowspan: "2" }, [
              _createElementVNode("span", null, "ITEM")
            ]),
            _createElementVNode("th", { rowspan: "2" }, [
              _createElementVNode("span", null, "DESCRIPTION")
            ]),
            _createElementVNode("th", { colspan: "1" }, [
              _createElementVNode("span", null, "BUDGET (MB)")
            ]),
            _createElementVNode("th", { colspan: "5" }, [
              _createElementVNode("span", null, "COST (MB)")
            ])
          ], -1)),
          _createElementVNode("tr", null, [
            _cache[0] || (_cache[0] = _createElementVNode("th", null, "SITE", -1)),
            _createElementVNode("th", _hoisted_3, "UP TO Y" + _toDisplayString(_ctx.year-1), 1),
            _createElementVNode("th", _hoisted_4, "Y" + _toDisplayString(_ctx.year), 1),
            _cache[1] || (_cache[1] = _createElementVNode("th", { class: "text-end blue" }, "ACC", -1)),
            _cache[2] || (_cache[2] = _createElementVNode("th", { class: "text-end" }, "REMAIN", -1)),
            _cache[3] || (_cache[3] = _createElementVNode("th", { class: "text-end" }, "% ACC", -1))
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.doc.budget.filter(el=>el.name!='TOTAL LENGTH (m)'), (item, i) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (item.name!='Sub-Total')
                ? (_openBlock(), _createElementBlock("tr", {
                    key: 0,
                    class: _normalizeClass(`${item.level==1?'fw-bold':''}`)
                  }, [
                    _createElementVNode("td", null, [
                      _createElementVNode("span", null, _toDisplayString(i+1), 1)
                    ]),
                    _createElementVNode("td", _hoisted_5, [
                      _createElementVNode("span", {
                        class: _normalizeClass(`ms-${(item.level??0)+1} ps-${(item.level??0)+1}`)
                      }, _toDisplayString(item.name), 3)
                    ]),
                    (item.name!='Fuel'&&item.name!='Lubricant')
                      ? (_openBlock(), _createElementBlock("td", {
                          key: 0,
                          class: _normalizeClass(["align-middle", {'text-end':(item.amount>=0)}]),
                          rowspan: item.name=='Equipment'?3:1
                        }, [
                          (item.amount==-1)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_7, "by J." + _toDisplayString(_ctx.$route.params.j), 1))
                            : (item.amount<0)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_8))
                              : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString((item.amount??0).formatMB()), 1))
                        ], 10, _hoisted_6))
                      : _createCommentVNode("", true),
                    _createElementVNode("td", _hoisted_10, [
                      (item.amount<0)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_11))
                        : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.getFromCodePrevious(item.code).formatMB()), 1))
                    ]),
                    _createElementVNode("td", _hoisted_13, [
                      (item.amount<0)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_14))
                        : (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.getFromCodeCurrent(item.code).formatMB()), 1))
                    ]),
                    _createElementVNode("td", _hoisted_16, [
                      (item.amount<0)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_17))
                        : (_openBlock(), _createElementBlock("span", _hoisted_18, [
                            _cache[5] || (_cache[5] = _createElementVNode("span", null, null, -1)),
                            _createElementVNode("span", null, _toDisplayString((_ctx.getFromCodePrevious(item.code)+_ctx.getFromCodeCurrent(item.code)).formatMB()), 1)
                          ]))
                    ]),
                    (item.name=='Equipment')
                      ? (_openBlock(), _createElementBlock("td", {
                          key: 1,
                          class: "text-end align-middle",
                          rowspan: item.name=='Equipment'?3:1
                        }, [
                          _createElementVNode("span", null, _toDisplayString(((item.amount??0)-((_ctx.getFromCodePrevious("Equipment")+_ctx.getFromCodeCurrent("Equipment"))+(_ctx.getFromCodePrevious("Fuel")+_ctx.getFromCodeCurrent("Fuel"))+(_ctx.getFromCodePrevious("Lubricant")+_ctx.getFromCodeCurrent("Lubricant")))).formatMB()), 1)
                        ], 8, _hoisted_19))
                      : (item.name!='Fuel'&&item.name!='Lubricant')
                        ? (_openBlock(), _createElementBlock("td", {
                            key: 2,
                            class: "text-end",
                            rowspan: item.name=='Equipment'?3:1
                          }, [
                            (item.amount<0)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_21))
                              : (_openBlock(), _createElementBlock("span", _hoisted_22, [
                                  (((item.amount??0)-(_ctx.getFromCodePrevious(item.code)+_ctx.getFromCodeCurrent(item.code)))>=0)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(((item.amount??0)-(_ctx.getFromCodePrevious(item.code)+_ctx.getFromCodeCurrent(item.code))).formatMB()), 1))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_24, "(" + _toDisplayString(((item.amount??0)-(_ctx.getFromCodePrevious(item.code)+_ctx.getFromCodeCurrent(item.code))).formatMB()) + ")", 1))
                                ]))
                          ], 8, _hoisted_20))
                        : _createCommentVNode("", true),
                    (item.name=='Equipment')
                      ? (_openBlock(), _createElementBlock("td", {
                          key: 3,
                          class: "text-end align-middle",
                          rowspan: item.name=='Equipment'?3:1
                        }, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.calcPercent((_ctx.getFromCodePrevious("Equipment")+_ctx.getFromCodeCurrent("Equipment"))+(_ctx.getFromCodePrevious("Fuel")+_ctx.getFromCodeCurrent("Fuel"))+(_ctx.getFromCodePrevious("Lubricant")+_ctx.getFromCodeCurrent("Lubricant")),(item.amount??0)).toFixed(2)) + "%", 1)
                        ], 8, _hoisted_25))
                      : (item.name!='Fuel'&&item.name!='Lubricant')
                        ? (_openBlock(), _createElementBlock("td", {
                            key: 4,
                            class: "text-end",
                            rowspan: item.name=='Equipment'?3:1
                          }, [
                            (item.amount<0)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_27))
                              : (_openBlock(), _createElementBlock("span", _hoisted_28, [
                                  (_ctx.calcPercent((_ctx.getFromCodePrevious(item.code)+_ctx.getFromCodeCurrent(item.code)),(item.amount??0))==-999)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_29))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_30, _toDisplayString(_ctx.calcPercent((_ctx.getFromCodePrevious(item.code)+_ctx.getFromCodeCurrent(item.code)),(item.amount??0)).toFixed(2)) + " %", 1))
                                ]))
                          ], 8, _hoisted_26))
                        : _createCommentVNode("", true)
                  ], 2))
                : (_openBlock(), _createElementBlock("tr", _hoisted_31, [
                    _createElementVNode("td", null, [
                      _createElementVNode("span", null, _toDisplayString(i+1), 1)
                    ]),
                    _createElementVNode("td", _hoisted_32, _toDisplayString(item.name), 1),
                    _createElementVNode("td", _hoisted_33, _toDisplayString(_ctx.getSubTotal(i).formatMB()), 1),
                    _createElementVNode("td", _hoisted_34, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.getSubTotalCodePrevious(i,item.code).formatMB()), 1)
                    ]),
                    _createElementVNode("td", _hoisted_35, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.getSubTotalCodeCurrent(i,item.code).formatMB()), 1)
                    ]),
                    _createElementVNode("td", _hoisted_36, [
                      _createElementVNode("span", null, _toDisplayString((_ctx.getSubTotalCodePrevious(i,item.code)+_ctx.getSubTotalCodeCurrent(i,item.code)).formatMB()), 1)
                    ]),
                    _createElementVNode("td", _hoisted_37, [
                      _createElementVNode("span", null, _toDisplayString(((_ctx.getSubTotal(i))-(_ctx.getSubTotalCodePrevious(i,item.code)+_ctx.getSubTotalCodeCurrent(i,item.code))).formatMB()), 1)
                    ]),
                    _createElementVNode("td", _hoisted_38, [
                      (_ctx.calcPercent((_ctx.getSubTotalCodePrevious(i,item.code)+_ctx.getSubTotalCodeCurrent(i,item.code)),(_ctx.getSubTotal(i)??0))==-999)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_39))
                        : (_openBlock(), _createElementBlock("span", _hoisted_40, _toDisplayString(_ctx.calcPercent((_ctx.getSubTotalCodePrevious(i,item.code)+_ctx.getSubTotalCodeCurrent(i,item.code)),(_ctx.getSubTotal(i)??0)).toFixed(2)) + " %", 1))
                    ])
                  ]))
            ], 64))
          }), 256)),
          _createElementVNode("tr", _hoisted_41, [
            _cache[6] || (_cache[6] = _createElementVNode("td", null, null, -1)),
            _cache[7] || (_cache[7] = _createElementVNode("td", { class: "text-start" }, "TOTAL COST", -1)),
            _createElementVNode("td", _hoisted_42, _toDisplayString((_ctx.doc.totalCost??0).formatMB()), 1),
            _createElementVNode("td", _hoisted_43, _toDisplayString((_ctx.getGrandTotalCodePrevious(_ctx.doc.budget.filter(el=>el.name!='TOTAL LENGTH (m)').length-1)).formatMB()), 1),
            _createElementVNode("td", _hoisted_44, _toDisplayString((_ctx.getGrandTotalCodeCurrent(_ctx.doc.budget.filter(el=>el.name!='TOTAL LENGTH (m)').length-1)).formatMB()), 1),
            _createElementVNode("td", _hoisted_45, _toDisplayString(((_ctx.getGrandTotalCodeCurrent(_ctx.doc.budget.filter(el=>el.name!='TOTAL LENGTH (m)').length-1)) + (_ctx.getGrandTotalCodePrevious(_ctx.doc.budget.filter(el=>el.name!='TOTAL LENGTH (m)').length-1))).formatMB()), 1),
            _createElementVNode("td", _hoisted_46, _toDisplayString(((_ctx.doc.totalCost??0)-(_ctx.getGrandTotalCodeCurrent(_ctx.doc.budget.filter(el=>el.name!='TOTAL LENGTH (m)').length-1)) - (_ctx.getGrandTotalCodePrevious(_ctx.doc.budget.filter(el=>el.name!='TOTAL LENGTH (m)').length-1))).formatMB()), 1),
            _createElementVNode("td", _hoisted_47, _toDisplayString(_ctx.calcPercent(((_ctx.getGrandTotalCodeCurrent(_ctx.doc.budget.filter(el=>el.name!='TOTAL LENGTH (m)').length-1)) + (_ctx.getGrandTotalCodePrevious(_ctx.doc.budget.filter(el=>el.name!='TOTAL LENGTH (m)').length-1))),_ctx.doc.totalCost).toFixed(2)) + " %", 1)
          ]),
          _createElementVNode("tr", _hoisted_48, [
            _cache[8] || (_cache[8] = _createElementVNode("td", null, null, -1)),
            _cache[9] || (_cache[9] = _createElementVNode("td", { class: "text-start" }, "TOTAL LENGTH (m)", -1)),
            _createElementVNode("td", _hoisted_49, _toDisplayString((_ctx.doc.totalLength??0).toFixed(0)), 1)
          ]),
          _createElementVNode("tr", _hoisted_50, [
            _cache[10] || (_cache[10] = _createElementVNode("td", null, null, -1)),
            _cache[11] || (_cache[11] = _createElementVNode("td", { class: "text-start" }, "Bath/m", -1)),
            _createElementVNode("td", _hoisted_51, _toDisplayString(((_ctx.doc.totalCost??0)/(_ctx.doc.totalLength??0)).toFixed(0)), 1)
          ])
        ])
      ])
    ])
  ], 64))
}