
import { Options, Vue } from "vue-class-component";
import address from "@/variable";
import axios from "axios";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class Form extends Vue {
  store = useStore();
  doc: any = {};

  mounted() {
    if (this.$route.params.id != undefined) this.fetchData();
  }
  pushMember() {
    if (!Array.isArray(this.doc.member)) this.doc.member = [];
    this.doc.member.push({
      name: "",
      text1: "",
      // category:"",
      // text2:"",
      // text3:"",
      // number1:0,
      // number2:0,
      // number3:0,
      // boolean1:false,
      // boolean2:false,
      // boolean3:false,
    });
  }
  fetchData() {
    axios.get(`${address}/budget-agreement/${this.$route.params.id}`).then((response) => {
      let data = response.data;
      (data.member ?? []).forEach((el: any) => {
        el.name = decodeURI(el.name);
      });
      this.doc = data;
    });
  }
  submitForm() {
    let prep:any = JSON.parse(JSON.stringify(this.doc));
    (prep.member ?? []).forEach((el: any) => {
      el.name = encodeURI(el.name);
    });
    if (this.$route.params.id != undefined) {
      axios.patch(`${address}/budget-agreement/${this.doc._id}`, prep).then((response) => {
        alert("แก้ไขข้อมูลแล้ว");
        this.$router.push("/budget/agreement");
      });
    } else {
      axios
        .post(`${address}/budget-agreement`, {
          ...prep,
        })
        .then((response) => {
          alert("บันทึกข้อมูลแล้ว");
          this.$router.push("/budget/agreement");
        });
    }
  }
}
