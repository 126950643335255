
import { Options, Vue } from "vue-class-component";
import address from "@/variable";
import axios from "axios";

@Options({
  components: {},
})
export default class Form extends Vue {
  reports: Array<any> = [];
  year: number = new Date().getFullYear();
  created() {
    this.fetchData();
  }
  get balance(){
    let expense = this.segmentErection + this.segmentYardExpenseNumber
    let income = (this.getSumFromCode1Number(['Engineering%20Payment','Bearing%E2%80%8B%20Payment','Bearing%20Payment','Segment%20Payment'],2)+this.getSumFromCode1Number(['Misc.%20Income','LG%20Scrap'],1))
    return (income - expense).formatShort()
  }
  get segmentErection(){
    let sum = 0;
    this.reports.forEach((r) => {
        if (r.job!='2369' && r.category == 'Expense') {
            sum += r.amount;
        }
    });
    return sum;
  }
  get segmentErectionNumber(){
    let sum = 0;
    this.reports.forEach((r) => {
        if (r.job!='2369' && r.category =='Expense') sum += r.amount;
    });
    return sum;
  }
  get segmentYardExpenseNumber(){
    let sum = 0;
    this.reports.forEach((r) => {
        if (r.job=='2369' && r.category =='Expense' && r.code1 != 'LG%20Depre') sum += r.amount;
    });
    return sum;
  }
  getSumFromCode1(codes: Array<any>, isNPL: Number) {
    let sum = 0;
    this.reports.forEach((r) => {
      if(isNPL == 1){
        if (codes.findIndex((c) => c == r.code1) != -1 && r.job=='2369') sum += r.amount;
      }
      else if(isNPL == 2){
        if (codes.findIndex((c) => c == r.code1) != -1 && r.job!='2369') sum += r.amount;
      }
      else {
        if (codes.findIndex((c) => c == r.code1) != -1) sum += r.amount;
      }
    });
    return sum;
  }
  getSumFromCode1Number(codes: Array<any>, isNPL: Number) {
    let sum = 0;
    this.reports.forEach((r) => {
      if(isNPL == 1){
        if (codes.findIndex((c) => c == r.code1) != -1 && r.job=='2369') sum += r.amount;
      }
      else if(isNPL == 2){
        if (codes.findIndex((c) => c == r.code1) != -1 && r.job!='2369') sum += r.amount;
      }
      else {
        if (codes.findIndex((c) => c == r.code1) != -1) sum += r.amount;
      }
    });
    return sum;
  }
  fetchData() {
    axios.get(`${address}/report/cost/annual`).then((response) => {
      this.reports = response.data;
    });
  }
}
