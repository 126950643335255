import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-end pb-3" }
const _hoisted_2 = { class: "ms-2 text-primary fw-bold" }
const _hoisted_3 = { class: "ms-2 text-primary fw-bold" }
const _hoisted_4 = { class: "rounded shadow bg-white pb-3" }
const _hoisted_5 = { class: "d-flex justify-content-between px-4 py-3" }
const _hoisted_6 = { class: "d-flex align-items-center" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "table main-table table-bordered" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "align-middle" }
const _hoisted_12 = { class: "text-primary fw-bold" }
const _hoisted_13 = { class: "align-middle" }
const _hoisted_14 = { class: "align-middle" }
const _hoisted_15 = { class: "align-middle" }
const _hoisted_16 = { class: "align-middle" }
const _hoisted_17 = { class: "align-middle" }
const _hoisted_18 = { class: "align-middle" }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { key: 1 }
const _hoisted_21 = { class: "align-middle" }
const _hoisted_22 = { class: "align-middle" }
const _hoisted_23 = { class: "align-middle" }
const _hoisted_24 = {
  key: 0,
  class: "fas fa-circle text-success"
}
const _hoisted_25 = { class: "align-middle" }
const _hoisted_26 = {
  key: 0,
  class: "fas fa-circle text-success"
}
const _hoisted_27 = { class: "align-middle" }
const _hoisted_28 = {
  key: 0,
  class: "fas fa-circle text-success"
}
const _hoisted_29 = { class: "align-middle" }
const _hoisted_30 = {
  key: 0,
  class: "fas fa-circle text-success"
}
const _hoisted_31 = { class: "align-middle" }
const _hoisted_32 = {
  key: 0,
  class: "fas fa-circle text-success"
}
const _hoisted_33 = { class: "align-middle" }
const _hoisted_34 = { class: "text-muted" }
const _hoisted_35 = ["onClick"]
const _hoisted_36 = ["rowspan"]
const _hoisted_37 = { class: "text-primary fw-bold" }
const _hoisted_38 = ["rowspan"]
const _hoisted_39 = ["rowspan"]
const _hoisted_40 = ["rowspan"]
const _hoisted_41 = ["rowspan"]
const _hoisted_42 = ["rowspan"]
const _hoisted_43 = ["rowspan"]
const _hoisted_44 = { key: 0 }
const _hoisted_45 = { key: 1 }
const _hoisted_46 = ["rowspan"]
const _hoisted_47 = ["rowspan"]
const _hoisted_48 = { style: {"font-size":"0.75rem"} }
const _hoisted_49 = { style: {"font-size":"0.75rem"} }
const _hoisted_50 = { style: {"font-size":"0.75rem"} }
const _hoisted_51 = { style: {"font-size":"0.75rem"} }
const _hoisted_52 = ["rowspan"]
const _hoisted_53 = {
  key: 0,
  class: "fas fa-circle text-success"
}
const _hoisted_54 = ["rowspan"]
const _hoisted_55 = {
  key: 0,
  class: "fas fa-circle text-success"
}
const _hoisted_56 = ["rowspan"]
const _hoisted_57 = {
  key: 0,
  class: "fas fa-circle text-success"
}
const _hoisted_58 = ["rowspan"]
const _hoisted_59 = {
  key: 0,
  class: "fas fa-circle text-success"
}
const _hoisted_60 = ["rowspan"]
const _hoisted_61 = {
  key: 0,
  class: "fas fa-circle text-success"
}
const _hoisted_62 = ["rowspan"]
const _hoisted_63 = { class: "text-muted" }
const _hoisted_64 = ["onClick"]
const _hoisted_65 = { key: 2 }
const _hoisted_66 = { class: "align-middle" }
const _hoisted_67 = { class: "text-primary fw-bold" }
const _hoisted_68 = { class: "align-middle" }
const _hoisted_69 = { class: "align-middle" }
const _hoisted_70 = { class: "align-middle" }
const _hoisted_71 = { class: "align-middle" }
const _hoisted_72 = { class: "align-middle" }
const _hoisted_73 = { class: "align-middle" }
const _hoisted_74 = { key: 0 }
const _hoisted_75 = { key: 1 }
const _hoisted_76 = { class: "align-middle" }
const _hoisted_77 = { class: "align-middle" }
const _hoisted_78 = { class: "align-middle" }
const _hoisted_79 = { class: "text-muted" }
const _hoisted_80 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JMEntriesItem = _resolveComponent("JMEntriesItem")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_JMGantt = _resolveComponent("JMGantt")!
  const _component_JMSort = _resolveComponent("JMSort")!
  const _component_JMTablePaginate = _resolveComponent("JMTablePaginate")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[32] || (_cache[32] = _createElementVNode("span", { class: "ms-2" }, "Total Amount", -1)),
      _createElementVNode("span", _hoisted_2, _toDisplayString((_ctx.docs.map(el=>el.amount??0).reduce((a,b)=>a+b,0)??0).formatShort()), 1),
      _cache[33] || (_cache[33] = _createElementVNode("span", { class: "ms-2" }, "Total Quantity", -1)),
      _createElementVNode("span", _hoisted_3, _toDisplayString((_ctx.docs.map(el=>el.qty??0).reduce((a,b)=>a+b,0)??0).formatShort()), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _cache[36] || (_cache[36] = _createElementVNode("span", { class: "me-3" }, "Entries", -1)),
          _withDirectives(_createElementVNode("select", {
            class: "form-select me-3",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.show) = $event)),
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fetchData(),_ctx.clearPage()))
          }, [
            _createVNode(_component_JMEntriesItem)
          ], 544), [
            [_vModelSelect, _ctx.show]
          ]),
          _createVNode(_component_router_link, {
            class: "btn btn-primary me-3",
            to: `/progress/form/${encodeURIComponent(_ctx.$route.params.lg)}`
          }, {
            default: _withCtx(() => _cache[34] || (_cache[34] = [
              _createElementVNode("div", { class: "text-nowrap text-white" }, "Add Record", -1)
            ])),
            _: 1
          }, 8, ["to"]),
          _createElementVNode("a", {
            class: "btn btn-outline-success",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$refs.uploadExcel.click()))
          }, _cache[35] || (_cache[35] = [
            _createElementVNode("div", { class: "text-nowrap" }, "Import Excel", -1)
          ])),
          _createElementVNode("input", {
            class: "d-none",
            id: "file-input",
            ref: "uploadExcel",
            type: "file",
            name: "name",
            onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.uploadExcel($event)))
          }, null, 544),
          _createElementVNode("a", {
            class: "ms-3",
            href: `${_ctx.publicPath}xlsx/itd_import_progress.xlsx`
          }, "(Template)", 8, _hoisted_7)
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_JMGantt, {
          modelValue: _ctx.doc,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.doc) = $event)),
          editable: true
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("table", _hoisted_9, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _cache[50] || (_cache[50] = _createElementVNode("th", null, [
              _createElementVNode("span", null, "#")
            ], -1)),
            _createElementVNode("th", null, [
              _cache[37] || (_cache[37] = _createElementVNode("span", null, "Order", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "order",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[38] || (_cache[38] = _createElementVNode("span", null, "Span", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "span",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[39] || (_cache[39] = _createElementVNode("span", null, "Side", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "side",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[40] || (_cache[40] = _createElementVNode("span", null, "Job", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "job",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[12] || (_cache[12] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[41] || (_cache[41] = _createElementVNode("span", null, "Start", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "startDate",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[14] || (_cache[14] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[42] || (_cache[42] = _createElementVNode("span", null, "Finish", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "endDate",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[16] || (_cache[16] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[43] || (_cache[43] = _createElementVNode("span", null, "Cycle Time Calendar", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "timeCalendar",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[18] || (_cache[18] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[44] || (_cache[44] = _createElementVNode("span", null, "Cycle Time Perform", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "timePerform",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[20] || (_cache[20] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _cache[51] || (_cache[51] = _createElementVNode("th", null, [
              _createElementVNode("span", null, "Code 1")
            ], -1)),
            _cache[52] || (_cache[52] = _createElementVNode("th", null, [
              _createElementVNode("span", null, "Code 2")
            ], -1)),
            _cache[53] || (_cache[53] = _createElementVNode("th", null, [
              _createElementVNode("span", null, "Period (day)")
            ], -1)),
            _cache[54] || (_cache[54] = _createElementVNode("th", null, [
              _createElementVNode("span", null, "Remarks")
            ], -1)),
            _createElementVNode("th", null, [
              _cache[45] || (_cache[45] = _createElementVNode("span", null, "Segment", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "segment",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[22] || (_cache[22] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[46] || (_cache[46] = _createElementVNode("span", null, "B Install", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "install",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[24] || (_cache[24] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[47] || (_cache[47] = _createElementVNode("span", null, "B Grout", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "grout",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[26] || (_cache[26] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[48] || (_cache[48] = _createElementVNode("span", null, "S Payment", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "segmentPayment",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[28] || (_cache[28] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[49] || (_cache[49] = _createElementVNode("span", null, "B Payment", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "bearingPayment",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[30] || (_cache[30] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _cache[55] || (_cache[55] = _createElementVNode("th", null, [
              _createElementVNode("span", null, "Actions")
            ], -1))
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.docs, (item, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (item.span=='Assembly')
                ? (_openBlock(), _createElementBlock("tr", _hoisted_10, [
                    _createElementVNode("td", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, "#" + _toDisplayString(((_ctx.page-1)*_ctx.show)+(index+1)), 1)
                    ]),
                    _createElementVNode("td", _hoisted_13, _toDisplayString(item.order), 1),
                    _createElementVNode("td", _hoisted_14, _toDisplayString(item.span), 1),
                    _createElementVNode("td", _hoisted_15, _toDisplayString(item.side), 1),
                    _createElementVNode("td", _hoisted_16, "J." + _toDisplayString(item.job), 1),
                    _createElementVNode("td", _hoisted_17, _toDisplayString((item.startDate??"").formatDate()), 1),
                    _createElementVNode("td", _hoisted_18, [
                      (item.isMax)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString((new Date().toISOString()).formatDate()), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString((item.endDate??"").formatDate()), 1))
                    ]),
                    _createElementVNode("td", _hoisted_21, _toDisplayString((item.timeCalendar??0).formatShort()), 1),
                    _createElementVNode("td", _hoisted_22, _toDisplayString((item.timePerform??0).formatShort()), 1),
                    _cache[57] || (_cache[57] = _createElementVNode("td", null, null, -1)),
                    _cache[58] || (_cache[58] = _createElementVNode("td", null, null, -1)),
                    _cache[59] || (_cache[59] = _createElementVNode("td", null, null, -1)),
                    _cache[60] || (_cache[60] = _createElementVNode("td", null, null, -1)),
                    _createElementVNode("td", _hoisted_23, [
                      (item.segment)
                        ? (_openBlock(), _createElementBlock("i", _hoisted_24))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("td", _hoisted_25, [
                      (item.install)
                        ? (_openBlock(), _createElementBlock("i", _hoisted_26))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("td", _hoisted_27, [
                      (item.grout)
                        ? (_openBlock(), _createElementBlock("i", _hoisted_28))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("td", _hoisted_29, [
                      (item.segmentPayment)
                        ? (_openBlock(), _createElementBlock("i", _hoisted_30))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("td", _hoisted_31, [
                      (item.bearingPayment)
                        ? (_openBlock(), _createElementBlock("i", _hoisted_32))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("td", _hoisted_33, [
                      _createElementVNode("div", _hoisted_34, [
                        _createVNode(_component_router_link, {
                          to: `/progress/edit/${encodeURIComponent(_ctx.$route.params.lg)}/${item._id}`
                        }, {
                          default: _withCtx(() => _cache[56] || (_cache[56] = [
                            _createElementVNode("i", { class: "class fal fa-pencil text-warning" }, null, -1)
                          ])),
                          _: 2
                        }, 1032, ["to"]),
                        (_ctx.store.state.accessLevel>=5)
                          ? (_openBlock(), _createElementBlock("i", {
                              key: 0,
                              class: "class fal fa-trash text-danger ms-2",
                              onClick: ($event: any) => (_ctx.deleteItem(item._id))
                            }, null, 8, _hoisted_35))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]))
                : ((item.activities??[]).length>0)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(item.activities, (activity, j) => {
                      return (_openBlock(), _createElementBlock("tr", null, [
                        (j==0)
                          ? (_openBlock(), _createElementBlock("td", {
                              key: 0,
                              class: "align-middle",
                              rowspan: (item.activities??[]).length
                            }, [
                              _createElementVNode("div", _hoisted_37, "#" + _toDisplayString(((_ctx.page-1)*_ctx.show)+(index+1)), 1)
                            ], 8, _hoisted_36))
                          : _createCommentVNode("", true),
                        (j==0)
                          ? (_openBlock(), _createElementBlock("td", {
                              key: 1,
                              class: "align-middle",
                              rowspan: (item.activities??[]).length
                            }, _toDisplayString(item.order), 9, _hoisted_38))
                          : _createCommentVNode("", true),
                        (j==0)
                          ? (_openBlock(), _createElementBlock("td", {
                              key: 2,
                              class: "align-middle",
                              rowspan: (item.activities??[]).length
                            }, _toDisplayString(item.span), 9, _hoisted_39))
                          : _createCommentVNode("", true),
                        (j==0)
                          ? (_openBlock(), _createElementBlock("td", {
                              key: 3,
                              class: "align-middle",
                              rowspan: (item.activities??[]).length
                            }, _toDisplayString(item.side), 9, _hoisted_40))
                          : _createCommentVNode("", true),
                        (j==0)
                          ? (_openBlock(), _createElementBlock("td", {
                              key: 4,
                              class: "align-middle",
                              rowspan: (item.activities??[]).length
                            }, "J." + _toDisplayString(item.job), 9, _hoisted_41))
                          : _createCommentVNode("", true),
                        (j==0)
                          ? (_openBlock(), _createElementBlock("td", {
                              key: 5,
                              class: "align-middle",
                              rowspan: (item.activities??[]).length
                            }, _toDisplayString((item.startDate??"").formatDate()), 9, _hoisted_42))
                          : _createCommentVNode("", true),
                        (j==0)
                          ? (_openBlock(), _createElementBlock("td", {
                              key: 6,
                              class: "align-middle",
                              rowspan: (item.activities??[]).length
                            }, [
                              (item.isMax)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_44, _toDisplayString((new Date().toISOString()).formatDate()), 1))
                                : (_openBlock(), _createElementBlock("span", _hoisted_45, _toDisplayString((item.endDate??"").formatDate()), 1))
                            ], 8, _hoisted_43))
                          : _createCommentVNode("", true),
                        (j==0)
                          ? (_openBlock(), _createElementBlock("td", {
                              key: 7,
                              class: "align-middle",
                              rowspan: (item.activities??[]).length
                            }, _toDisplayString((item.timeCalendar??0).formatFull()), 9, _hoisted_46))
                          : _createCommentVNode("", true),
                        (j==0)
                          ? (_openBlock(), _createElementBlock("td", {
                              key: 8,
                              class: "align-middle",
                              rowspan: (item.activities??[]).length
                            }, _toDisplayString((item.timePerform??0).formatFull()), 9, _hoisted_47))
                          : _createCommentVNode("", true),
                        _createElementVNode("td", null, [
                          _createElementVNode("span", _hoisted_48, _toDisplayString(decodeURIComponent(activity.code1)), 1)
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("span", _hoisted_49, _toDisplayString(decodeURIComponent(activity.code2)), 1)
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("span", _hoisted_50, _toDisplayString((activity.period??0).formatShort()), 1)
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("span", _hoisted_51, _toDisplayString(activity.remarks), 1)
                        ]),
                        (j==0)
                          ? (_openBlock(), _createElementBlock("td", {
                              key: 9,
                              class: "align-middle",
                              rowspan: (item.activities??[]).length
                            }, [
                              (item.segment)
                                ? (_openBlock(), _createElementBlock("i", _hoisted_53))
                                : _createCommentVNode("", true)
                            ], 8, _hoisted_52))
                          : _createCommentVNode("", true),
                        (j==0)
                          ? (_openBlock(), _createElementBlock("td", {
                              key: 10,
                              class: "align-middle",
                              rowspan: (item.activities??[]).length
                            }, [
                              (item.install)
                                ? (_openBlock(), _createElementBlock("i", _hoisted_55))
                                : _createCommentVNode("", true)
                            ], 8, _hoisted_54))
                          : _createCommentVNode("", true),
                        (j==0)
                          ? (_openBlock(), _createElementBlock("td", {
                              key: 11,
                              class: "align-middle",
                              rowspan: (item.activities??[]).length
                            }, [
                              (item.grout)
                                ? (_openBlock(), _createElementBlock("i", _hoisted_57))
                                : _createCommentVNode("", true)
                            ], 8, _hoisted_56))
                          : _createCommentVNode("", true),
                        (j==0)
                          ? (_openBlock(), _createElementBlock("td", {
                              key: 12,
                              class: "align-middle",
                              rowspan: (item.activities??[]).length
                            }, [
                              (item.segmentPayment)
                                ? (_openBlock(), _createElementBlock("i", _hoisted_59))
                                : _createCommentVNode("", true)
                            ], 8, _hoisted_58))
                          : _createCommentVNode("", true),
                        (j==0)
                          ? (_openBlock(), _createElementBlock("td", {
                              key: 13,
                              class: "align-middle",
                              rowspan: (item.activities??[]).length
                            }, [
                              (item.bearingPayment)
                                ? (_openBlock(), _createElementBlock("i", _hoisted_61))
                                : _createCommentVNode("", true)
                            ], 8, _hoisted_60))
                          : _createCommentVNode("", true),
                        (j==0)
                          ? (_openBlock(), _createElementBlock("td", {
                              key: 14,
                              class: "align-middle",
                              rowspan: (item.activities??[]).length
                            }, [
                              _createElementVNode("div", _hoisted_63, [
                                _createVNode(_component_router_link, {
                                  to: `/progress/edit/${encodeURIComponent(_ctx.$route.params.lg)}/${item._id}`
                                }, {
                                  default: _withCtx(() => _cache[61] || (_cache[61] = [
                                    _createElementVNode("i", { class: "class fal fa-pencil text-warning" }, null, -1)
                                  ])),
                                  _: 2
                                }, 1032, ["to"]),
                                (_ctx.store.state.accessLevel>=5)
                                  ? (_openBlock(), _createElementBlock("i", {
                                      key: 0,
                                      class: "class fal fa-trash text-danger ms-2",
                                      onClick: ($event: any) => (_ctx.deleteItem(item._id))
                                    }, null, 8, _hoisted_64))
                                  : _createCommentVNode("", true)
                              ])
                            ], 8, _hoisted_62))
                          : _createCommentVNode("", true)
                      ]))
                    }), 256))
                  : (_openBlock(), _createElementBlock("tr", _hoisted_65, [
                      _createElementVNode("td", _hoisted_66, [
                        _createElementVNode("div", _hoisted_67, "#" + _toDisplayString(((_ctx.page-1)*_ctx.show)+(index+1)), 1)
                      ]),
                      _createElementVNode("td", _hoisted_68, _toDisplayString(item.order), 1),
                      _createElementVNode("td", _hoisted_69, _toDisplayString(item.span), 1),
                      _createElementVNode("td", _hoisted_70, _toDisplayString(item.side), 1),
                      _createElementVNode("td", _hoisted_71, "J." + _toDisplayString(item.job), 1),
                      _createElementVNode("td", _hoisted_72, _toDisplayString((item.startDate??"").formatDate()), 1),
                      _createElementVNode("td", _hoisted_73, [
                        (item.isMax)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_74, _toDisplayString((new Date().toISOString()).formatDate()), 1))
                          : (_openBlock(), _createElementBlock("span", _hoisted_75, _toDisplayString((item.endDate??"").formatDate()), 1))
                      ]),
                      _createElementVNode("td", _hoisted_76, _toDisplayString((item.timeCalendar??0).formatShort()), 1),
                      _createElementVNode("td", _hoisted_77, _toDisplayString((item.timePerform??0).formatShort()), 1),
                      _cache[63] || (_cache[63] = _createElementVNode("td", null, null, -1)),
                      _cache[64] || (_cache[64] = _createElementVNode("td", null, null, -1)),
                      _cache[65] || (_cache[65] = _createElementVNode("td", null, null, -1)),
                      _cache[66] || (_cache[66] = _createElementVNode("td", null, null, -1)),
                      _cache[67] || (_cache[67] = _createElementVNode("td", null, null, -1)),
                      _cache[68] || (_cache[68] = _createElementVNode("td", null, null, -1)),
                      _cache[69] || (_cache[69] = _createElementVNode("td", null, null, -1)),
                      _cache[70] || (_cache[70] = _createElementVNode("td", null, null, -1)),
                      _cache[71] || (_cache[71] = _createElementVNode("td", null, null, -1)),
                      _createElementVNode("td", _hoisted_78, [
                        _createElementVNode("div", _hoisted_79, [
                          _createVNode(_component_router_link, {
                            to: `/progress/edit/${encodeURIComponent(_ctx.$route.params.lg)}/${item._id}`
                          }, {
                            default: _withCtx(() => _cache[62] || (_cache[62] = [
                              _createElementVNode("i", { class: "class fal fa-pencil text-warning" }, null, -1)
                            ])),
                            _: 2
                          }, 1032, ["to"]),
                          (_ctx.store.state.accessLevel>=5)
                            ? (_openBlock(), _createElementBlock("i", {
                                key: 0,
                                class: "class fal fa-trash text-danger ms-2",
                                onClick: ($event: any) => (_ctx.deleteItem(item._id))
                              }, null, 8, _hoisted_80))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ]))
            ], 64))
          }), 256))
        ])
      ]),
      _createVNode(_component_JMTablePaginate, {
        page: _ctx.page,
        pages: _ctx.pages,
        show: _ctx.show,
        total: _ctx.total,
        onChange: _cache[31] || (_cache[31] = ($event: any) => {_ctx.page=$event; _ctx.fetchData()})
      }, null, 8, ["page", "pages", "show", "total"])
    ])
  ], 64))
}