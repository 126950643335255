
import { Options, Vue } from "vue-class-component";
import address from "@/variable";
import axios from "axios";
import { useStore } from "vuex";
import * as _ from "lodash"

@Options({
  components: {},
})
export default class Main extends Vue {
  store = useStore();
  doc: any = {};
  tabIndex: number = -1;
  groups: Array<any> = [];

  get JobList():Array<string> {
    let data:any = this.groups
    data = _.groupBy(data,'text2')
    data = _.values(data)
    return data
  }


  get job() {
    try {
      return decodeURIComponent((this.groups[this.tabIndex] ?? {}).text2);
    } catch (error) {
      return "0";
    }
  }
  get category() {
    try {
      return decodeURIComponent((this.groups[this.tabIndex] ?? {}).name);
    } catch (error) {
      return "0";
    }
  }

  mounted() {
    axios
      .get(`${address}/code-by-field/name/Monthly Progress Group`)
      .then((response) => {
        this.groups = response.data.member;
        
        this.$nextTick(() => {
          this.tabIndex = 0;
        });
      });
  }
}
