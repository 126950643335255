import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-end pb-3" }
const _hoisted_2 = { class: "ms-2 text-primary fw-bold" }
const _hoisted_3 = { class: "ms-2 text-primary fw-bold" }
const _hoisted_4 = { class: "rounded shadow bg-white pb-3" }
const _hoisted_5 = { class: "d-flex justify-content-between px-4 py-3" }
const _hoisted_6 = { class: "d-flex align-items-center" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "d-flex align-items-center" }
const _hoisted_9 = ["value"]
const _hoisted_10 = ["value"]
const _hoisted_11 = ["value"]
const _hoisted_12 = ["value"]
const _hoisted_13 = ["value"]
const _hoisted_14 = ["value"]
const _hoisted_15 = ["value"]
const _hoisted_16 = ["value"]
const _hoisted_17 = ["value"]
const _hoisted_18 = { class: "table main-table" }
const _hoisted_19 = { class: "text-end" }
const _hoisted_20 = { class: "text-end" }
const _hoisted_21 = { class: "text-primary fw-bold" }
const _hoisted_22 = {
  key: 0,
  class: "badge rounded-pill text-success",
  style: {"background":"#ecf8ef"}
}
const _hoisted_23 = {
  key: 1,
  class: "badge rounded-pill text-danger",
  style: {"background":"#faebeb"}
}
const _hoisted_24 = { class: "text-end" }
const _hoisted_25 = { class: "text-end" }
const _hoisted_26 = {
  key: 0,
  class: "text-success"
}
const _hoisted_27 = {
  key: 1,
  class: "text-danger"
}
const _hoisted_28 = { class: "text-muted" }
const _hoisted_29 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JMEntriesItem = _resolveComponent("JMEntriesItem")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_JMSort = _resolveComponent("JMSort")!
  const _component_JMTablePaginate = _resolveComponent("JMTablePaginate")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[44] || (_cache[44] = _createElementVNode("span", { class: "ms-2" }, "Total Amount", -1)),
      _createElementVNode("span", _hoisted_2, _toDisplayString((_ctx.docs.map(el=>el.amount??0).reduce((a,b)=>a+b,0)??0).formatShort()), 1),
      _cache[45] || (_cache[45] = _createElementVNode("span", { class: "ms-2" }, "Total Quantity", -1)),
      _createElementVNode("span", _hoisted_3, _toDisplayString((_ctx.docs.map(el=>el.qty??0).reduce((a,b)=>a+b,0)??0).formatShort()), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _cache[49] || (_cache[49] = _createElementVNode("span", { class: "me-3" }, "Entries", -1)),
          _withDirectives(_createElementVNode("select", {
            class: "form-select me-3",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.show) = $event)),
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fetchData(),_ctx.clearPage()))
          }, [
            _createVNode(_component_JMEntriesItem)
          ], 544), [
            [_vModelSelect, _ctx.show]
          ]),
          _createVNode(_component_router_link, {
            class: "btn btn-primary me-3",
            to: "/cost/form"
          }, {
            default: _withCtx(() => _cache[46] || (_cache[46] = [
              _createElementVNode("div", { class: "text-nowrap text-white" }, "Add Record", -1)
            ])),
            _: 1
          }),
          _createElementVNode("a", {
            class: "btn btn-outline-success",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$refs.uploadExcel.click()))
          }, _cache[47] || (_cache[47] = [
            _createElementVNode("div", { class: "text-nowrap" }, "Import Excel", -1)
          ])),
          _createElementVNode("input", {
            class: "d-none",
            id: "file-input",
            ref: "uploadExcel",
            type: "file",
            name: "name",
            onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.uploadExcel($event)))
          }, null, 544),
          _createElementVNode("a", {
            class: "ms-3",
            href: `${_ctx.publicPath}xlsx/itd_import_cost.xlsx`
          }, "(Template)", 8, _hoisted_7),
          _createElementVNode("a", {
            class: "text-danger",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.deleteMany()))
          }, _cache[48] || (_cache[48] = [
            _createElementVNode("span", null, "Delete", -1)
          ]))
        ]),
        _createElementVNode("div", _hoisted_8, [
          _withDirectives(_createElementVNode("select", {
            class: "form-select ms-1",
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.filter6) = $event)),
            onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.fetchData()))
          }, [
            _cache[50] || (_cache[50] = _createElementVNode("option", {
              value: {},
              disabled: "",
              selected: "true"
            }, "Select Code 1", -1)),
            _cache[51] || (_cache[51] = _createElementVNode("option", { value: {} }, "All", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.code1Site, (item) => {
              return (_openBlock(), _createElementBlock("option", {
                value: {code1:item.name}
              }, _toDisplayString(decodeURIComponent(item.name)), 9, _hoisted_9))
            }), 256)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.code1Yard, (item) => {
              return (_openBlock(), _createElementBlock("option", {
                value: {code1:item.name}
              }, _toDisplayString(decodeURIComponent(item.name)), 9, _hoisted_10))
            }), 256))
          ], 544), [
            [_vModelSelect, _ctx.filter6]
          ]),
          _withDirectives(_createElementVNode("select", {
            class: "form-select ms-1",
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.filter5) = $event)),
            onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.fetchData()))
          }, [
            _cache[52] || (_cache[52] = _createElementVNode("option", {
              value: {},
              disabled: "",
              selected: "true"
            }, "Select Code 2", -1)),
            _cache[53] || (_cache[53] = _createElementVNode("option", { value: {} }, "All", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.code2, (item) => {
              return (_openBlock(), _createElementBlock("option", {
                value: {code2:item.name}
              }, _toDisplayString(decodeURIComponent(item.name)), 9, _hoisted_11))
            }), 256))
          ], 544), [
            [_vModelSelect, _ctx.filter5]
          ]),
          _withDirectives(_createElementVNode("select", {
            class: "form-select ms-1",
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.filter4) = $event)),
            onChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.fetchData()))
          }, [
            _cache[54] || (_cache[54] = _createElementVNode("option", {
              value: {},
              disabled: "",
              selected: "true"
            }, "Select Code 3", -1)),
            _cache[55] || (_cache[55] = _createElementVNode("option", { value: {} }, "All", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.code3, (item) => {
              return (_openBlock(), _createElementBlock("option", {
                value: {code3:item.name}
              }, _toDisplayString(decodeURIComponent(item.name)), 9, _hoisted_12))
            }), 256))
          ], 544), [
            [_vModelSelect, _ctx.filter4]
          ]),
          _withDirectives(_createElementVNode("select", {
            class: "form-select ms-1",
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.filter3) = $event)),
            onChange: _cache[12] || (_cache[12] = ($event: any) => (_ctx.fetchData()))
          }, [
            _cache[56] || (_cache[56] = _createElementVNode("option", {
              value: {},
              disabled: "",
              selected: "true"
            }, "Select Year", -1)),
            _cache[57] || (_cache[57] = _createElementVNode("option", { value: {} }, "All", -1)),
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (item) => {
              return _createElementVNode("option", {
                value: {year:new Date().getFullYear()+1-item}
              }, _toDisplayString(new Date().getFullYear()+1-item), 9, _hoisted_13)
            }), 64))
          ], 544), [
            [_vModelSelect, _ctx.filter3]
          ]),
          _withDirectives(_createElementVNode("select", {
            class: "form-select ms-1",
            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.filter2) = $event)),
            onChange: _cache[14] || (_cache[14] = ($event: any) => (_ctx.fetchData()))
          }, [
            _cache[58] || (_cache[58] = _createElementVNode("option", {
              value: {},
              disabled: "",
              selected: "true"
            }, "Select Month", -1)),
            _cache[59] || (_cache[59] = _createElementVNode("option", { value: {} }, "All", -1)),
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (item) => {
              return _createElementVNode("option", {
                value: {month:item}
              }, _toDisplayString(_ctx.store.state.months[item-1]), 9, _hoisted_14)
            }), 64))
          ], 544), [
            [_vModelSelect, _ctx.filter2]
          ]),
          _withDirectives(_createElementVNode("select", {
            class: "form-select ms-1",
            "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.filter1) = $event)),
            onChange: _cache[16] || (_cache[16] = ($event: any) => (_ctx.fetchData()))
          }, [
            _cache[60] || (_cache[60] = _createElementVNode("option", {
              value: {},
              disabled: "",
              selected: "true"
            }, "Select Filter", -1)),
            _cache[61] || (_cache[61] = _createElementVNode("option", { value: {} }, "All", -1)),
            _cache[62] || (_cache[62] = _createElementVNode("option", { value: {category:'Income'} }, "Income", -1)),
            _cache[63] || (_cache[63] = _createElementVNode("option", { value: {category:'Expense'} }, "Expense", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.jobs, (item) => {
              return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createElementVNode("option", {
                  value: {job:item.j}
                }, "J." + _toDisplayString(item.j) + ": All", 9, _hoisted_15),
                _createElementVNode("option", {
                  value: {job:item.j,category:'Income'}
                }, "J." + _toDisplayString(item.j) + ": Income", 9, _hoisted_16),
                _createElementVNode("option", {
                  value: {job:item.j,category:'Expense'}
                }, "J." + _toDisplayString(item.j) + ": Expense", 9, _hoisted_17)
              ], 64))
            }), 256))
          ], 544), [
            [_vModelSelect, _ctx.filter1]
          ])
        ])
      ]),
      _createElementVNode("table", _hoisted_18, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _cache[77] || (_cache[77] = _createElementVNode("th", null, [
              _createElementVNode("span", null, "#")
            ], -1)),
            _createElementVNode("th", null, [
              _cache[64] || (_cache[64] = _createElementVNode("span", null, "Month", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "month",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[18] || (_cache[18] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[65] || (_cache[65] = _createElementVNode("span", null, "Year", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "year",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[20] || (_cache[20] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[66] || (_cache[66] = _createElementVNode("span", null, "Job", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "job",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[22] || (_cache[22] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[67] || (_cache[67] = _createElementVNode("span", null, "Category", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "category",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[24] || (_cache[24] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[68] || (_cache[68] = _createElementVNode("span", null, "Code 1", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "code1",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[26] || (_cache[26] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[69] || (_cache[69] = _createElementVNode("span", null, "Code 2", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "code2",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[28] || (_cache[28] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[70] || (_cache[70] = _createElementVNode("span", null, "Code 3", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "code3",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[30] || (_cache[30] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[71] || (_cache[71] = _createElementVNode("span", null, "Unit", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "unit",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[32] || (_cache[32] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", _hoisted_19, [
              _cache[72] || (_cache[72] = _createElementVNode("span", null, "Quantity", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "qty",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[34] || (_cache[34] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", _hoisted_20, [
              _cache[73] || (_cache[73] = _createElementVNode("span", null, "Amount", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "amount",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[36] || (_cache[36] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[74] || (_cache[74] = _createElementVNode("span", null, "Cost By", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "by",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[38] || (_cache[38] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[75] || (_cache[75] = _createElementVNode("span", null, "Doc. No.", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "document",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[40] || (_cache[40] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("th", null, [
              _cache[76] || (_cache[76] = _createElementVNode("span", null, "Remark", -1)),
              _createVNode(_component_JMSort, {
                sortKey: "remark",
                modelValue: _ctx.sort,
                "onUpdate:modelValue": _cache[41] || (_cache[41] = ($event: any) => ((_ctx.sort) = $event)),
                onChange: _cache[42] || (_cache[42] = ($event: any) => (_ctx.fetchData()))
              }, null, 8, ["modelValue"])
            ]),
            _cache[78] || (_cache[78] = _createElementVNode("th", null, [
              _createElementVNode("span", null, "Actions")
            ], -1))
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.docs, (item, index) => {
            return (_openBlock(), _createElementBlock("tr", null, [
              _createElementVNode("td", null, [
                _createElementVNode("div", _hoisted_21, "#" + _toDisplayString(((_ctx.page-1)*_ctx.show)+(index+1)), 1)
              ]),
              _createElementVNode("td", null, _toDisplayString(item.month), 1),
              _createElementVNode("td", null, _toDisplayString(item.year), 1),
              _createElementVNode("td", null, "J." + _toDisplayString(item.job), 1),
              _createElementVNode("td", null, [
                (item.category=='Income')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(item.category), 1))
                  : (_openBlock(), _createElementBlock("div", _hoisted_23, _toDisplayString(item.category), 1))
              ]),
              _createElementVNode("td", null, [
                _createElementVNode("span", null, _toDisplayString((item.code1=='null'||item.code1==undefined)?"":decodeURIComponent(item.code1).replace("undefined","-")), 1)
              ]),
              _createElementVNode("td", null, [
                _createElementVNode("span", null, _toDisplayString((item.code2=='null'||item.code2==undefined)?"":decodeURIComponent(item.code2).replace("undefined","-")), 1)
              ]),
              _createElementVNode("td", null, [
                _createElementVNode("span", null, _toDisplayString((item.code3=='null'||item.code3==undefined)?"":decodeURIComponent(item.code3).replace("undefined","-")), 1)
              ]),
              _createElementVNode("td", null, _toDisplayString(item.unit), 1),
              _createElementVNode("td", _hoisted_24, _toDisplayString((item.qty??0).formatShort()), 1),
              _createElementVNode("td", _hoisted_25, [
                (item.category=='Income')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_26, _toDisplayString((item.amount??0).formatShort()), 1))
                  : (_openBlock(), _createElementBlock("div", _hoisted_27, _toDisplayString((item.amount??0).formatShort()), 1))
              ]),
              _createElementVNode("td", null, "J." + _toDisplayString(item.by), 1),
              _createElementVNode("td", null, _toDisplayString(item.document), 1),
              _createElementVNode("td", null, _toDisplayString(item.remark), 1),
              _createElementVNode("td", null, [
                _createElementVNode("div", _hoisted_28, [
                  (_ctx.store.state.accessLevel>=5)
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        to: `/cost/edit/${item._id}`
                      }, {
                        default: _withCtx(() => _cache[79] || (_cache[79] = [
                          _createElementVNode("i", { class: "class fal fa-pencil text-warning" }, null, -1)
                        ])),
                        _: 2
                      }, 1032, ["to"]))
                    : _createCommentVNode("", true),
                  (_ctx.store.state.accessLevel>=5)
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 1,
                        class: "class fal fa-trash text-danger ms-2",
                        onClick: ($event: any) => (_ctx.deleteItem(item._id))
                      }, null, 8, _hoisted_29))
                    : _createCommentVNode("", true)
                ])
              ])
            ]))
          }), 256))
        ])
      ]),
      _createVNode(_component_JMTablePaginate, {
        page: _ctx.page,
        pages: _ctx.pages,
        show: _ctx.show,
        total: _ctx.total,
        onChange: _cache[43] || (_cache[43] = ($event: any) => {_ctx.page=$event; _ctx.fetchData()})
      }, null, 8, ["page", "pages", "show", "total"])
    ])
  ], 64))
}