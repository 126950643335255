
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class Home extends Vue {
  store = useStore();
  username: string = "";
  password: string = "";
  level: number = 0;
  remember: boolean = false;
  users: Array<any> = [
    {
      username: "developer",
      password: "gd8c5s4e",
      level: 10
    },
    {
      username: "test",
      password: "12345",
      level: 1
    },
    {
      username: "testuser",
      password: "12345678",
      level: 1
    },
    {
      username: "itdj2369",
      password: "lgsc.com",
      level: 1
    },
    {
      username: "operator",
      password: "operator0000",
      level: 5
    },
    {
      username: "management",
      password: "management0000",
      level: 10
    },
  ];
  mounted() {
    this.username = this.store.state.remember.username ?? "";
    this.password = this.store.state.remember.password ?? "";
    this.level = this.store.state.remember.level ?? 0;
    this.remember = this.store.state.remember.remember ?? true;
  }
  loginOnline(){
    console.log("loggin in...")
    let prep = {
      username: this.username,
      password: this.password
    }
    axios.post(`${address}/user/login`, prep).then(response=>{
      if(response.data=="Unauthorized") alert("คุณไม่มีสิทธิเข้าถึง")
      else {
        console.log("login success")
        console.log(response.data.user)
        let user = response.data.user
        this.store.commit(
          "login",
          this.remember
            ? {
                username: this.username,
                password: this.password,
                remember: this.remember,
                accessLevel: user.level,
                access: user.access,
              }
            : {
                username: this.username,
                // password: this.password,
                remember: this.remember,
                accessLevel: user.level,
                access: user.access,
              }
        );
        this.$router.push("/");
      }
    }).catch(error=>{
      alert("ไม่พบผู้ใช้/หรือรหัสผ่านผิด : "+error)
    })
  }

  login() {
    let found = this.users.find((el) => el.username == this.username);
    if (found != undefined) {
      if (found.password == this.password) {
        this.store.commit(
          "login",
          this.remember
            ? {
                username: this.username,
                password: this.password,
                remember: this.remember,
                accessLevel: found.level,
              }
            : {
                username: this.username,
                // password: this.password,
                remember: this.remember,
                accessLevel: found.level,
              }
        );
        alert("ลงชื่อเข้าใช้ถูกต้อง");
        this.$router.push("/cost");
      } else {
        alert("รหัสผ่านไม่ถูกต้อง");
      }
    } else {
      alert("ไม่พบชื่อผู้ใช้");
    }
    console.log(this.username, this.password);
  }
  logout() {
    this.store.commit("logout");
  }
}
