import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "rounded shadow bg-white pb-3" }
const _hoisted_2 = { class: "d-flex justify-content-between px-4 py-3 mt-5" }
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = { class: "form-select me-3" }
const _hoisted_5 = { class: "d-flex align-items-center" }
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "table main-table" }
const _hoisted_8 = { class: "text-primary fw-bold" }
const _hoisted_9 = {
  key: 0,
  class: "ms-2"
}
const _hoisted_10 = { class: "text-muted" }
const _hoisted_11 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JMEntriesItem = _resolveComponent("JMEntriesItem")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_JMTablePaginate = _resolveComponent("JMTablePaginate")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[8] || (_cache[8] = _createElementVNode("h2", null, "Progress List (LG List)", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "me-3" }, "Entries", -1)),
          _createElementVNode("select", _hoisted_4, [
            _createVNode(_component_JMEntriesItem)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createElementVNode("select", {
            class: "form-select",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter1) = $event)),
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fetchData()))
          }, [
            _cache[4] || (_cache[4] = _createElementVNode("option", {
              disabled: "",
              selected: "true"
            }, "Select Filter...", -1)),
            _cache[5] || (_cache[5] = _createElementVNode("option", { value: {} }, "All", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.jobs, (item) => {
              return (_openBlock(), _createElementBlock("option", {
                value: {job:item.j}
              }, "J." + _toDisplayString(item.j), 9, _hoisted_6))
            }), 256))
          ], 544), [
            [_vModelSelect, _ctx.filter1]
          ])
        ])
      ]),
      _createElementVNode("table", _hoisted_7, [
        _cache[7] || (_cache[7] = _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", null, [
              _createElementVNode("span", null, "#")
            ]),
            _createElementVNode("th", null, [
              _createElementVNode("span", null, "Name"),
              _createElementVNode("i", { class: "fad fa-sort-up ms-2" })
            ]),
            _createElementVNode("th", null, [
              _createElementVNode("span", null, "Notes"),
              _createElementVNode("i", { class: "fad fa-sort-up ms-2" })
            ]),
            _createElementVNode("th", null, [
              _createElementVNode("span", null, "Action ")
            ])
          ])
        ], -1)),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.docs, (item, index) => {
            return (_openBlock(), _createElementBlock("tr", null, [
              _createElementVNode("td", null, [
                _createElementVNode("div", _hoisted_8, "#" + _toDisplayString(((_ctx.page-1)*_ctx.show)+(index+1)), 1)
              ]),
              _createElementVNode("td", null, [
                _createElementVNode("span", null, _toDisplayString(decodeURIComponent(item.name)), 1),
                (item.text4!='-'&&item.text4!=undefined&&item.text4!='')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, "(" + _toDisplayString(item.text4) + ")", 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("td", null, [
                _createElementVNode("span", null, _toDisplayString(item.text1), 1)
              ]),
              _createElementVNode("td", null, [
                _createElementVNode("div", _hoisted_10, [
                  (_ctx.store.state.accessLevel>=5)
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        to: `/progress/lg/${encodeURIComponent(item.name)}`
                      }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createElementVNode("i", { class: "fal fa-eye" }, null, -1)
                        ])),
                        _: 2
                      }, 1032, ["to"]))
                    : _createCommentVNode("", true),
                  (_ctx.store.state.accessLevel>=5)
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 1,
                        class: "class fal fa-trash text-danger ms-2",
                        onClick: ($event: any) => (_ctx.deleteItem(item._id))
                      }, null, 8, _hoisted_11))
                    : _createCommentVNode("", true)
                ])
              ])
            ]))
          }), 256))
        ])
      ]),
      _createVNode(_component_JMTablePaginate, {
        page: _ctx.page,
        pages: _ctx.pages,
        show: _ctx.show,
        total: _ctx.total,
        onChange: _cache[2] || (_cache[2] = ($event: any) => {_ctx.page=$event; _ctx.fetchData()})
      }, null, 8, ["page", "pages", "show", "total"])
    ])
  ], 64))
}