
import { Options, Vue } from "vue-class-component";
import address from "@/variable";
import axios from "axios";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class Form extends Vue {
  store = useStore()
  doc: any = {};
  updateAmount(){
    this.doc.amount = parseFloat(((this.doc.length??0)*(this.doc.rate??0)).toFixed(2))
  }
  mounted() {
    if (this.$route.params.id != undefined) this.fetchData();
  }
  fetchData() {
    axios.get(`${address}/erection/${this.$route.params.id}`).then((response) => {
      this.doc = response.data;
    });
  }
  submitForm() {
    if (this.$route.params.id != undefined) {
      axios
        .patch(`${address}/erection/${this.doc._id}`, this.doc)
        .then((response) => {
          alert("แก้ไขข้อมูลแล้ว");
          this.$router.push("/cost");
        });
    } else {
      axios
        .post(`${address}/erection`, {
          ...this.doc,
        })
        .then((response) => {
          alert("บันทึกข้อมูลแล้ว");
          this.$router.push("/cost");
        });
    }
  }
}
