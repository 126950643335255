
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import address from "@/variable";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class Summary extends Vue {
  store = useStore();
  docs: Array<any> = [];
  lgs: Array<any> = [];
  group1: Array<any> = [];
  group2: Array<any> = [];
  group3: Array<any> = [];
  group4: Array<any> = [];
  get internalJob() {
    return this.store.state.jobs.filter(
      (el: any) => el.category != "Other Job Site" && el.category != "Etc."
    );
  }
  get otherJob() {
    let result = this.store.state.jobs.filter(
      (el: any) => el.category == "Other Job Site"
    );
    //prettier-ignore
    // try { result.push(this.store.state.jobs.find((el: any) => el.j == "2452")) } catch (error) {}
    //prettier-ignore
    // try { result.push(this.store.state.jobs.find((el: any) => el.j == "2496")) } catch (error) {}
    return result;
  }
  calcP(l: number, r: number) {
    let result = l / r;
    if (r == 0) return "No Current Year Cost";
    else if (l == 0) return "No Previous Year Cost";
    else return result.formatPercent() + " %";
  }
  getNumberJob(array: Array<any>, job: string, type: string) {
    let found = array.find((el) => {
      return el.job == job && el.type == type;
    });
    return (found ?? {}).count ?? 0;
  }
  getNumberJobTotal(array: Array<any>, job: string) {
    let filter = array.filter((el) => {
      return el.job == job;
    });
    let map = filter.map((el) => el.count ?? 0);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }
  getNumberTypeTotal(array: Array<any>, type: string, jobs: Array<any>) {
    let filter = array.filter((el) => {
      let foundJob = jobs.findIndex((job) => job.j == el.job) != -1;
      return el.type == type && foundJob;
    });
    let map = filter.map((el) => el.count ?? 0);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }
  getNumberLG(array: Array<any>, lg: string, type: string) {
    let filter = array.filter((el) => {
      // console.log(decodeURIComponent(el.type) == decodeURIComponent(type),el.lg,lg)
      return (
        el.lg == decodeURIComponent(lg) &&
        decodeURIComponent(el.type) == decodeURIComponent(type)
      );
    });
    let map = filter.map((el) => el.count ?? 0);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }
  getNumberLGTotal(array: Array<any>, lg: string) {
    let filter = array.filter((el) => {
      return el.lg == decodeURIComponent(lg) && el.lg != "-";
    });
    let map = filter.map((el) => el.count ?? 0);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }
  getNumberLGTypeTotal(array: Array<any>, type: string) {
    // console.log(array)
    let filter = array.filter((el) => {
      return (
        decodeURIComponent(el.type) == decodeURIComponent(type) &&
        el.lg != undefined &&
        el.lg != "ไม่ระบุ"
      );
    });
    let map = filter.map((el) => el.count ?? 0);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }

  getNumberCostPrevYear(array: Array<any>) {
    let filter = array.filter((el) => {
      return (
        el.year < new Date().getFullYear() &&
        (el.detail == "Wage" ||
          el.detail == "Hardship" ||
          el.detail == "Incentive")
      );
    });
    let sum = 0;
    filter.forEach((f) => {
      f.children.forEach((element: any) => {
        sum += element.amount;
      });
    });
    return sum;
  }
  getNumberCostCurrYear(array: Array<any>) {
    let filter = array.filter((el) => {
      return (
        el.detail == "Wage" ||
        el.detail == "Hardship" ||
        el.detail == "Incentive"
      );
    });
    let sum = 0;
    filter.forEach((f) => {
      f.children.forEach((element: any) => {
        sum += element.amount;
      });
    });
    return sum;
  }
  getNumberCostMonthYear(array: Array<any>, month: number) {
    let filter = array.filter((el) => {
      return (
        el.year == new Date().getFullYear() &&
        (el.detail == "Wage" ||
          el.detail == "Hardship" ||
          el.detail == "Incentive")
      );
    });

    let sum = 0;
    filter.forEach((f) => {
      f.children.forEach((element: any) => {
        if (element.month == month) sum += element.amount;
      });
    });
    return sum;
  }
  getNumberCostPrev(array: Array<any>, lg: string, detail: string) {
    let found = array.find((el) => {
      return (
        el.lg == encodeURIComponent(lg) &&
        el.detail == encodeURIComponent(detail) &&
        el.year < new Date().getFullYear()
      );
    });
    return (found ?? {}).sum ?? 0;
  }
  getNumberCostCurr(array: Array<any>, lg: string, detail: string) {
    let found = array.find((el) => {
      return (
        el.lg == encodeURIComponent(lg) &&
        el.detail == encodeURIComponent(detail) &&
        el.year == new Date().getFullYear()
      );
    });
    return (found ?? {}).sum ?? 0;
  }
  getNumberCost(array: Array<any>, lg: string, detail: string, month: number) {
    let found = array.find((el) => {
      return (
        el.lg == encodeURIComponent(lg) &&
        el.detail == encodeURIComponent(detail) &&
        el.year == new Date().getFullYear()
      );
    });
    let children: Array<any> = (found ?? {}).children ?? [];
    let sum = 0;
    children.forEach((element: any) => {
      if (element.month == month) sum += element.amount;
    });
    return sum;
  }
  getNumberCostTotal(array: Array<any>, lg: string, detail: string) {
    let found = array.find((el) => {
      return (
        el.lg == encodeURIComponent(lg) &&
        el.detail == encodeURIComponent(detail)
      );
    });
    let children: Array<any> = (found ?? {}).children ?? [];
    return (found ?? {}).sum ?? 0;
  }

  mounted() {
    axios.get(`${address}/summary/manpower/1-2/`).then((response) => {
      // console.log(response);
      this.group1 = response.data.map((el: any) => {
        return {
          ...el,
          type: el._id.type ?? "",
          job: el._id.job ?? "",
        };
      });
    });
    axios.get(`${address}/summary/manpower/2/`).then((response) => {
      // console.log(response);
      this.group2 = response.data.map((el: any) => {
        return {
          ...el,
          type: el._id.type ?? "",
          job: el._id.job ?? "",
        };
      });
    });
    axios.get(`${address}/summary/manpower/3/`).then((response) => {
      // console.log(response);
      this.group3 = response.data.map((el: any) => {
        return {
          ...el,
          type: el._id.type ?? "",
          job: el._id.job ?? "",
          lg: el._id.lg ?? "",
        };
      });
    });
    axios.get(`${address}/summary/manpower/4/`).then((response) => {
      // console.log(response);
      let filter = response.data.filter((o: any) => {
        return true;
        // console.log(decodeURIComponent(o._id.lg).search("LG#") != -1);
        // try {
        //   return decodeURIComponent(o._id.lg).search("LG#") != -1;
        // } catch (error) {
        //   return false;
        // }
      });
      this.group4 = filter;
    });

    axios
      .get(`${address}/code-by-field/name/Launching Girders`)
      .then((response) => {
        this.lgs = response.data.member;
        // this.lgs.push({ name: "ไม่ระบุ", text1: "-" });
        // this.lgs = this.lgs.filter(o=>o.name!='')
      });
  }
}
