import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container rounded shadow bg-white px-4 pb-5 pt-3" }
const _hoisted_2 = { class: "row needs-validation" }
const _hoisted_3 = { class: "row g-3 d-flex flex-column align-items-center" }
const _hoisted_4 = { class: "text-nowrap text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          class: "w-50 btn btn-primary",
          to: `/report/annual`
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("div", { class: "text-nowrap text-white" }, "J.2369 Annual Report", -1)
          ])),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "w-50 btn btn-primary",
          to: `/report/site`
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("div", { class: "text-nowrap text-white" }, "Site Operation", -1)
          ])),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "w-50 btn btn-primary",
          to: `/report/yard/2369`
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createElementVNode("div", { class: "text-nowrap text-white" }, "POS NPL (J.2369)", -1)
          ])),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "w-50 btn btn-primary",
          to: `/report/lg`
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createElementVNode("div", { class: "text-nowrap text-white" }, "POS LG", -1)
          ])),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.jobs, (item) => {
          return (_openBlock(), _createElementBlock(_Fragment, null, [
            (item.category=='Construction Site')
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  class: "w-50 btn btn-primary",
                  to: `/report/pos/${item.j}`
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, "POS " + _toDisplayString(item.name) + " (J." + _toDisplayString(item.j) + ")", 1)
                  ]),
                  _: 2
                }, 1032, ["to"]))
              : _createCommentVNode("", true)
          ], 64))
        }), 256)),
        _createVNode(_component_router_link, {
          class: "w-50 btn btn-primary",
          to: `/report/manpower-by-lg`
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createElementVNode("div", { class: "text-nowrap text-white" }, "Manpower Cost By LG", -1)
          ])),
          _: 1
        })
      ])
    ])
  ]))
}