import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "rounded shadow bg-white pb-3 mb-3" }
const _hoisted_2 = { class: "summary-container summary-01" }
const _hoisted_3 = { class: "table main-table" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "align-middle",
  rowspan: 6
}
const _hoisted_6 = {
  key: 1,
  class: "align-middle",
  rowspan: "6"
}
const _hoisted_7 = { class: "rounded shadow bg-white pb-3" }
const _hoisted_8 = { class: "summary-container summary-01" }
const _hoisted_9 = { class: "table main-table" }
const _hoisted_10 = ["rowspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[5] || (_cache[5] = _createElementVNode("h2", null, "SUMMARY PT BAR", -1)),
        _createElementVNode("table", _hoisted_3, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _cache[0] || (_cache[0] = _createElementVNode("th", null, "DESCRIPTION", -1)),
              _cache[1] || (_cache[1] = _createElementVNode("th", null, "DIAMETER", -1)),
              _cache[2] || (_cache[2] = _createElementVNode("th", null, "DETAILS", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobs, (item) => {
                return (_openBlock(), _createElementBlock("th", null, [
                  (item.length==4)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, "J."))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", null, _toDisplayString(item), 1)
                ]))
              }), 256)),
              _cache[3] || (_cache[3] = _createElementVNode("th", null, "TOTAL", -1))
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.getUniqueDiameter(decodeURIComponent('PT Bar'))), (item, i) => {
              return (_openBlock(), _createElementBlock(_Fragment, null, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, (elem, j) => {
                  return _createElementVNode("tr", null, [
                    (j==0)
                      ? (_openBlock(), _createElementBlock("td", _hoisted_5, _cache[4] || (_cache[4] = [
                          _createElementVNode("span", null, "PT Bar", -1)
                        ])))
                      : _createCommentVNode("", true),
                    (j==0)
                      ? (_openBlock(), _createElementBlock("td", _hoisted_6, [
                          _createElementVNode("span", null, _toDisplayString(Object.keys(_ctx.getUniqueDiameter(decodeURIComponent('PT Bar')))[i]), 1)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("td", null, _toDisplayString(_ctx.ptDetails[j]), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobs, (job) => {
                      return (_openBlock(), _createElementBlock("td", null, [
                        _createElementVNode("span", null, _toDisplayString((_ctx.getNumberPT(Object.keys(_ctx.getUniqueDiameter(decodeURIComponent('PT Bar')))[i],_ctx.ptDetails[j],job)??0).formatComma()), 1)
                      ]))
                    }), 256)),
                    _createElementVNode("td", null, [
                      _createElementVNode("span", null, _toDisplayString((_ctx.getNumberPTTotal(Object.keys(_ctx.getUniqueDiameter(decodeURIComponent('PT Bar')))[i],_ctx.ptDetails[j])??0).formatComma()), 1)
                    ])
                  ])
                }), 64))
              ], 64))
            }), 256))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _cache[9] || (_cache[9] = _createElementVNode("h2", null, "SUMMARY ACCESSORIES", -1)),
        _createElementVNode("table", _hoisted_9, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _cache[6] || (_cache[6] = _createElementVNode("th", null, "DESCRIPTION", -1)),
              _cache[7] || (_cache[7] = _createElementVNode("th", null, "DIAMETER", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobs, (item) => {
                return (_openBlock(), _createElementBlock("th", null, [
                  _createElementVNode("span", null, "J." + _toDisplayString(item), 1)
                ]))
              }), 256)),
              _cache[8] || (_cache[8] = _createElementVNode("th", null, "TOTAL", -1))
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.details, (item) => {
              return (_openBlock(), _createElementBlock(_Fragment, null, [
                (decodeURIComponent(item.name)!='PT Bar')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("td", {
                          class: "align-middle",
                          colspan: "1",
                          rowspan: Object.keys(_ctx.getUniqueDiameter(decodeURIComponent(item.name))).length  + 1
                        }, [
                          _createElementVNode("span", null, _toDisplayString(decodeURIComponent(item.name)), 1)
                        ], 8, _hoisted_10)
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getUniqueDiameter(decodeURIComponent(item.name)), (el) => {
                        return (_openBlock(), _createElementBlock("tr", null, [
                          _createElementVNode("td", null, _toDisplayString(el[0].diameter), 1),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobs, (job) => {
                            return (_openBlock(), _createElementBlock("td", null, [
                              _createElementVNode("span", null, _toDisplayString(_ctx.getNumberJob(decodeURIComponent(item.name),el[0].diameter,job).formatFull()), 1)
                            ]))
                          }), 256)),
                          _createElementVNode("td", null, _toDisplayString(_ctx.getNumberJobTotal(decodeURIComponent(item.name),el[0].diameter)), 1)
                        ]))
                      }), 256))
                    ], 64))
                  : _createCommentVNode("", true)
              ], 64))
            }), 256))
          ])
        ])
      ])
    ])
  ], 64))
}