
import { Options, Vue } from "vue-class-component";
import address from "@/variable";
import axios from "axios";

@Options({
  components: {},
})
export default class Form extends Vue {
  doc: any = {
    budget: [],
  };
  ledgers: Array<any> = [];
  year: number = new Date().getFullYear();
  calcPercent(l: number, r: number) {
    let result = (l / r) * 100;
    if (Number.isNaN(result)) return 0;
    else if (!Number.isFinite(result)) return -999;
    return result;
  }
  getSubTotalCodePrevious(index: number) {
    let subFilter = [{ i: 0 }];
    let subFilter2 = this.doc.budget
      .map((el: any, i: number) => {
        return {
          i: i,
          ...el,
        };
      })
      .filter((el: any) => el.name == "Sub-Total");
    subFilter.push(...subFilter2);
    let from = subFilter.findIndex((o: any) => o.i == index) - 1;
    console.log("subFilter", subFilter, index, from);
    let budgets: Array<string> = (this.doc.budget ?? [])
      .filter((el: any, i: number) => i >= subFilter[from].i && i < index)
      .map((el: any) => el.code)
      .filter((el: any) => el != null);
    budgets = budgets.map((el) => this.jmEncode(el));
    let filter = this.ledgers.filter(
      (l) => budgets.findIndex((b) => b == l.code1) != -1 && l.year < this.year
    );
    let map = filter.map((el) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }
  getSubTotalCodeCurrent(index: number) {
    let subFilter = [{ i: 0 }];
    let subFilter2 = this.doc.budget
      .map((el: any, i: number) => {
        return {
          i: i,
          ...el,
        };
      })
      .filter((el: any) => el.name == "Sub-Total");
    subFilter.push(...subFilter2);
    let from = subFilter.findIndex((o: any) => o.i == index) - 1;
    console.log("subFilter", subFilter, index, from);
    let budgets: Array<string> = (this.doc.budget ?? [])
      .filter((el: any, i: number) => i >= subFilter[from].i && i < index)
      .map((el: any) => el.code)
      .filter((el: any) => el != null);
    budgets = budgets.map((el) => this.jmEncode(el));
    let filter = this.ledgers.filter(
      (l) => budgets.findIndex((b) => b == l.code1) != -1 && l.year == this.year
    );
    let map = filter.map((el) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }

  getGrandTotalCodePrevious(index: number) {
    let budgets: Array<string> = (this.doc.budget ?? [])
      .filter((el: any, i: number) => i < index)
      .map((el: any) => el.code)
      .filter((el: any) => el != null);
    budgets = budgets.map((el) => this.jmEncode(el));
    let filter = this.ledgers.filter(
      (l) => budgets.findIndex((b) => b == l.code1) != -1 && l.year < this.year
    );
    let map = filter.map((el) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }
  getGrandTotalCodeCurrent(index: number) {
    let budgets: Array<string> = (this.doc.budget ?? [])
      .filter((el: any, i: number) => i < index)
      .map((el: any) => el.code)
      .filter((el: any) => el != null);
    budgets = budgets.map((el) => this.jmEncode(el));
    let filter = this.ledgers.filter(
      (l) => budgets.findIndex((b) => b == l.code1) != -1 && l.year == this.year
    );
    let map = filter.map((el) => el.amount);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }

  getSubTotal(i: number) {
    let found = this.subTotal1.find((el: any) => el.i == i);
    let foundIndex = this.subTotal1.findIndex((el: any) => el.i == i);
    let prev = (this.subTotal1[foundIndex - 1] ?? { i: 0 }).i;
    try {
      return (
        (this.doc.budget ?? [])
          .filter((el: any, j: number) => {
            return i > j && j > prev;
          })
          .map((el: any) => el.amount ?? 0)
          .reduce((a: number, b: number) => a + b, 0) ?? 0
      );
    } catch (error) {
      return 0;
    }
  }
  get subTotal1() {
    return (this.doc.budget ?? [])
      .map((el: any, i: number) => {
        return { i, name: el.name };
      })
      .filter((el: any, i: number) => el.name == "Sub-Total");
  }
  updateAmount() {
    this.doc.amount = parseFloat(
      ((this.doc.length ?? 0) * (this.doc.rate ?? 0)).toFixed(2)
    );
  }
  mounted() {
    if (this.$route.params.j != undefined) this.fetchData();
  }
  getFromCodeCurrent(code: string) {
    try {
      let filter = this.ledgers.filter((el) => {
        return el.code1 == this.jmEncode(code) && el.year == this.year;
      });
      let map = filter.map((el) => el.amount);
      let sum = map.reduce((a: number, b: number) => a + b, 0);
      // console.log(sum)
      return sum;
    } catch (error) {
      // console.log(error)
      return 0;
    }
  }
  getFromCodePrevious(code: string) {
    try {
      let filter = this.ledgers.filter((el) => {
        return el.code1 == this.jmEncode(code) && el.year < this.year;
      });
      let map = filter.map((el) => el.amount);
      let sum = map.reduce((a: number, b: number) => a + b, 0);
      // console.log(sum)
      return sum;
    } catch (error) {
      // console.log(error)
      return 0;
    }
  }
  fetchData() {
    axios
      .get(`${address}/job-by-field/j/${this.$route.params.j}`)
      .then((response) => {
        this.doc = response.data;
      });
    let query = {
      search: {
        job: this.$route.params.j,
        // category: "Expense",
      },
      page: 1,
      limit: 9999999,
    };
    axios.post(`${address}/ledgers-paginate`, query).then((response) => {
      console.log(response);
      this.ledgers = response.data.docs;
    });
  }
  jmEncode(code: string) {
    return (code);
  };
}
