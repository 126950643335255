import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, withCtx as _withCtx, vModelText as _vModelText, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "rounded shadow bg-white pb-3" }
const _hoisted_2 = { class: "d-flex justify-content-between px-4 py-3" }
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "d-flex align-items-center" }
const _hoisted_6 = { class: "col me-3" }
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "table main-table" }
const _hoisted_9 = { class: "text-primary fw-bold" }
const _hoisted_10 = { class: "d-flex flex-column" }
const _hoisted_11 = {
  key: 0,
  class: "progress"
}
const _hoisted_12 = {
  key: 1,
  class: "progress"
}
const _hoisted_13 = ["aria-valuenow"]
const _hoisted_14 = { class: "text-muted" }
const _hoisted_15 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JMEntriesItem = _resolveComponent("JMEntriesItem")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_JMSort = _resolveComponent("JMSort")!
  const _component_JMTablePaginate = _resolveComponent("JMTablePaginate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[37] || (_cache[37] = _createElementVNode("span", { class: "me-3" }, "Entries", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select me-3",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.show) = $event)),
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fetchData(),_ctx.clearPage()))
        }, [
          _createVNode(_component_JMEntriesItem)
        ], 544), [
          [_vModelSelect, _ctx.show]
        ]),
        _createVNode(_component_router_link, {
          class: "btn btn-primary me-3",
          to: "/ptbar/form"
        }, {
          default: _withCtx(() => _cache[35] || (_cache[35] = [
            _createElementVNode("div", { class: "text-nowrap text-white" }, "Add Record", -1)
          ])),
          _: 1
        }),
        _createElementVNode("a", {
          class: "btn btn-outline-success",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$refs.uploadExcel.click()))
        }, _cache[36] || (_cache[36] = [
          _createElementVNode("div", { class: "text-nowrap" }, "Import Excel", -1)
        ])),
        _createElementVNode("input", {
          class: "d-none",
          id: "file-input",
          ref: "uploadExcel",
          type: "file",
          name: "name",
          onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.uploadExcel($event)))
        }, null, 544),
        _createElementVNode("a", {
          class: "ms-3",
          href: `${_ctx.publicPath}xlsx/itd_import_ptbar.xlsx`
        }, "(Template)", 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("form", {
          class: "col me-3",
          onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.fetchData && _ctx.fetchData(...args)), ["prevent"]))
        }, [
          _cache[38] || (_cache[38] = _createElementVNode("label", { class: "form-label" }, "Diameter", -1)),
          _withDirectives(_createElementVNode("input", {
            class: "form-control",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.search2) = $event)),
            type: "text",
            placeholder: "Diameter"
          }, null, 512), [
            [_vModelText, _ctx.search2]
          ]),
          _cache[39] || (_cache[39] = _createElementVNode("button", {
            class: "d-none",
            type: "submit"
          }, null, -1))
        ], 32),
        _createElementVNode("form", {
          class: "col me-3",
          onSubmit: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.fetchData && _ctx.fetchData(...args)), ["prevent"]))
        }, [
          _cache[40] || (_cache[40] = _createElementVNode("label", { class: "form-label" }, "ค้นหา", -1)),
          _withDirectives(_createElementVNode("input", {
            class: "form-control",
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.search) = $event)),
            type: "text",
            placeholder: "Detail"
          }, null, 512), [
            [_vModelText, _ctx.search]
          ]),
          _cache[41] || (_cache[41] = _createElementVNode("button", {
            class: "d-none",
            type: "submit"
          }, null, -1))
        ], 32),
        _createElementVNode("div", _hoisted_6, [
          _cache[44] || (_cache[44] = _createElementVNode("label", { class: "form-label" }, "J.", -1)),
          _withDirectives(_createElementVNode("select", {
            class: "col form-select",
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.filter1) = $event)),
            onChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.fetchData()))
          }, [
            _cache[42] || (_cache[42] = _createElementVNode("option", {
              disabled: "",
              selected: "true"
            }, "Select Filter...", -1)),
            _cache[43] || (_cache[43] = _createElementVNode("option", { value: {} }, "All", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.jobs, (item) => {
              return (_openBlock(), _createElementBlock("option", {
                value: {job:item.j}
              }, "J." + _toDisplayString(item.j), 9, _hoisted_7))
            }), 256))
          ], 544), [
            [_vModelSelect, _ctx.filter1]
          ])
        ])
      ])
    ]),
    _createElementVNode("table", _hoisted_8, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _cache[57] || (_cache[57] = _createElementVNode("th", null, [
            _createElementVNode("span", null, "#")
          ], -1)),
          _createElementVNode("th", null, [
            _cache[45] || (_cache[45] = _createElementVNode("span", null, "Job", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "job",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[11] || (_cache[11] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", null, [
            _cache[46] || (_cache[46] = _createElementVNode("span", null, "LG", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "lg",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[13] || (_cache[13] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", null, [
            _cache[47] || (_cache[47] = _createElementVNode("span", null, "Detail", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "detail",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[15] || (_cache[15] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", null, [
            _cache[48] || (_cache[48] = _createElementVNode("span", null, "Diameter", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "diameter",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[17] || (_cache[17] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", null, [
            _cache[49] || (_cache[49] = _createElementVNode("span", null, "Spiral", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "spiral",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[19] || (_cache[19] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", null, [
            _cache[50] || (_cache[50] = _createElementVNode("span", null, "Length Period", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "lengthPeriod",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[21] || (_cache[21] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", null, [
            _cache[51] || (_cache[51] = _createElementVNode("span", null, "Length (mm)", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "length",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[23] || (_cache[23] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", null, [
            _cache[52] || (_cache[52] = _createElementVNode("span", null, "Thickness (mm)", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "thickness",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[25] || (_cache[25] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", null, [
            _cache[53] || (_cache[53] = _createElementVNode("span", null, "Brand", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "brand",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[27] || (_cache[27] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", null, [
            _cache[54] || (_cache[54] = _createElementVNode("span", null, "Status", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "status",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[29] || (_cache[29] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", null, [
            _cache[55] || (_cache[55] = _createElementVNode("span", null, "Qty", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "qty",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[31] || (_cache[31] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("th", null, [
            _cache[56] || (_cache[56] = _createElementVNode("span", null, "Remark", -1)),
            _createVNode(_component_JMSort, {
              sortKey: "remarks",
              modelValue: _ctx.sort,
              "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.sort) = $event)),
              onChange: _cache[33] || (_cache[33] = ($event: any) => (_ctx.fetchData()))
            }, null, 8, ["modelValue"])
          ]),
          _cache[58] || (_cache[58] = _createElementVNode("th", null, "Actions", -1))
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.docs, (item, index) => {
          return (_openBlock(), _createElementBlock("tr", null, [
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_9, "#" + _toDisplayString(((_ctx.page-1)*_ctx.show)+(index+1)), 1)
            ]),
            _createElementVNode("td", null, _toDisplayString(item.job), 1),
            _createElementVNode("td", null, _toDisplayString(item.lg), 1),
            _createElementVNode("td", null, _toDisplayString(item.detail), 1),
            _createElementVNode("td", null, _toDisplayString(item.diameter), 1),
            _createElementVNode("td", null, _toDisplayString(item.spiral), 1),
            _createElementVNode("td", null, _toDisplayString(item.lengthPeriod), 1),
            _createElementVNode("td", null, _toDisplayString((item.length??0).formatComma()), 1),
            _createElementVNode("td", null, _toDisplayString((item.thickness??0).formatComma()), 1),
            _createElementVNode("td", null, _toDisplayString(item.brand), 1),
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_10, [
                (item.status==0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("div", {
                        class: "progress-bar",
                        role: "progressbar",
                        style: _normalizeStyle(`background-color:hsl(${(item.status??0).formatPercent()*1.2}, 77%, 36%); width: 100%;`),
                        "aria-valuenow": "100%",
                        "aria-valuemin": "0",
                        "aria-valuemax": "100"
                      }, _cache[59] || (_cache[59] = [
                        _createElementVNode("span", null, "DAMAGE", -1)
                      ]), 4)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createElementVNode("div", {
                        class: "progress-bar",
                        role: "progressbar",
                        style: _normalizeStyle(`background-color:hsl(${(item.status??0).formatPercent()*1.2}, 77%, 36%); width: ${(item.status??0).formatPercent()}%;`),
                        "aria-valuenow": (item.status??0).formatPercent(),
                        "aria-valuemin": "0",
                        "aria-valuemax": "100"
                      }, [
                        _createElementVNode("span", null, _toDisplayString((item.status??0).formatPercent()) + "%", 1)
                      ], 12, _hoisted_13)
                    ]))
              ])
            ]),
            _createElementVNode("td", null, _toDisplayString(item.qty), 1),
            _createElementVNode("td", null, _toDisplayString(item.remarks), 1),
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_14, [
                (_ctx.store.state.accessLevel>=5)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: `/ptbar/edit/${item._id}`
                    }, {
                      default: _withCtx(() => _cache[60] || (_cache[60] = [
                        _createElementVNode("i", { class: "class fal fa-pencil text-warning" }, null, -1)
                      ])),
                      _: 2
                    }, 1032, ["to"]))
                  : _createCommentVNode("", true),
                (_ctx.store.state.accessLevel>=5)
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 1,
                      class: "class fal fa-trash text-danger ms-2",
                      onClick: ($event: any) => (_ctx.deleteItem(item._id))
                    }, null, 8, _hoisted_15))
                  : _createCommentVNode("", true)
              ])
            ])
          ]))
        }), 256))
      ])
    ]),
    _createVNode(_component_JMTablePaginate, {
      page: _ctx.page,
      pages: _ctx.pages,
      show: _ctx.show,
      total: _ctx.total,
      onChange: _cache[34] || (_cache[34] = ($event: any) => {_ctx.page=$event; _ctx.fetchData()})
    }, null, 8, ["page", "pages", "show", "total"])
  ]))
}