
import { Options, Vue } from "vue-class-component";
import address from "@/variable";
import axios from "axios";
import Sortable from "sortablejs";

@Options({
  components: {},
})
export default class Form extends Vue {
  doc: any = {};

  mounted() {
    if (this.$route.params.id != undefined) this.fetchData();
  }
  pushBudget() {
    if (!Array.isArray(this.doc.budget)) this.doc.budget = [];
    this.doc.budget.push({
      name: "",
      category: "",
      unit: "",
      amount: 0,
      remark: "",
    });
  }
  pushIncome() {
    if (!Array.isArray(this.doc.income)) this.doc.income = [];
    this.doc.income.push({
      name: "",
      category: "",
      unit: "",
      amount: 0,
      remark: "",
    });
  }
  pushCost() {
    if (!Array.isArray(this.doc.cost)) this.doc.cost = [];
    this.doc.cost.push({
      name: "",
      category: "",
      unit: "",
      amount: 0,
      remark: "",
    });
  }
  sortItem(evt: any) {
    let x = evt.oldIndex;
    let y = evt.newIndex;
    var b = this.doc.budget[y];
    this.doc.budget[y] = this.doc.budget[x];
    this.doc.budget[x] = b;
  }
  fetchData() {
    axios.get(`${address}/job/${this.$route.params.id}`).then((response) => {
      this.doc = response.data;
      this.doc.budget = this.doc.budget.map((o: any) => {
        return {
          ...o,
          code: (o.code == "undefined" || o.code==undefined) ? undefined : decodeURIComponent(o.code),
        };
      });
      let self = this;
      var el = this.$refs.items as HTMLElement;
      var sortable = Sortable.create(el, {
        animation: 150,
        ghostClass: "sortable-ghost",
        chosenClass: "sortable-chosen",
        handle: ".handle",
        onUpdate: self.sortItem,
      });
    });
  }
  submitForm() {
    this.doc.budget = this.doc.budget.map((o: any) => {
      return {
        ...o,
        code: (o.code == "undefined"||o.code == "") ? undefined : encodeURIComponent(o.code),
      };
    });
    if (this.$route.params.id != undefined) {
      axios
        .patch(`${address}/job/${this.doc._id}`, {
          ...this.doc,
        })
        .then((response) => {
          alert("แก้ไขข้อมูลแล้ว");
          this.$router.push("/job");
        });
    } else {
      axios
        .post(`${address}/job`, {
          ...this.doc,
        })
        .then((response) => {
          alert("บันทึกข้อมูลแล้ว");
          this.$router.push("/job");
        });
    }
  }
}
