
import { Options, Vue } from "vue-class-component";
import address from "@/variable";
import axios from "axios";
import { useStore } from "vuex";

@Options({
  components: {},
  props: {
    job: String,
    category: String,
  },
})
export default class Form extends Vue {
  store = useStore();
  doc: any = {};
  address = address
  filePath = address
  codes:Array<any> = []
  list:Array<any> = []
  category?:String
  job?:String

  updateAmount(){
    this.doc.rate = parseFloat(((this.doc.amount??1) / (this.doc.qty??1)).toFixed(2))
  }
  mounted() {
    if (this.$route.params.id != undefined) this.fetchData();
    else{
      this.doc.category = this.category ?? ""
      this.doc.job = this.$route.params.job
    }
    axios
      .get(`${address}/code-by-field/name/Supplier Code`)
      .then((response) => {
        this.codes = response.data.member;
      });
    axios
      .get(`${address}/code-by-field/name/Supplier List`)
      .then((response) => {
        this.list = response.data.member;
      });
  }
  pushMember() {
    if (!Array.isArray(this.doc.member)) this.doc.member = [];
    this.doc.member.push({
      name: "",
      text1: "",
    });
  }
  fetchData() {
    console.log("fetching...")
    axios.get(`${address}/supplier/${this.$route.params.id}`).then((response) => {
      let data = response.data;
      this.doc = data;
    });
  }
  submitForm() {
    let prep:any = JSON.parse(JSON.stringify(this.doc));
    (prep.member ?? []).forEach((el: any) => {
      el.name = encodeURI(el.name);
    });
    if (this.$route.params.id != undefined) {
      axios.patch(`${address}/supplier/${this.doc._id}`, prep).then((response) => {
        alert("แก้ไขข้อมูลแล้ว");
        this.$router.push("/supplier/j/"+this.doc.job);
      });
    } else {
      axios
        .post(`${address}/supplier`, {
          ...prep,
        })
        .then((response) => {
          alert("บันทึกข้อมูลแล้ว");
        this.$router.push("/supplier/j/"+this.doc.job);
        });
    }
  }
}
