
import { Options, Vue } from "vue-class-component";
import address from "@/variable";
import axios from "axios";
import { useStore } from "vuex";

@Options({
  components: {},
  props: {
    job: String,
    category: String,
  },
})
export default class Form extends Vue {
  store = useStore();
  doc: any = {};
  codes:Array<any> = []
  address = address;
  filePath = address
  category?:String
  job?:String

  mounted() {
    if (this.$route.params.id != undefined) this.fetchData();
    else{
      this.doc.category = this.category ?? ""
      this.doc.job = this.$route.params.job
    }
    axios
      .get(`${address}/code-by-field/name/insurance Code`)
      .then((response) => {
        this.codes = response.data.member;
      });
  }
  pushMember() {
    if (!Array.isArray(this.doc.member)) this.doc.member = [];
    this.doc.member.push({
      name: "",
      text1: "",
    });
  }
  fetchData() {
    axios.get(`${address}/insurance/${this.$route.params.id}`).then((response) => {
      let data = response.data;
      (data.member ?? []).forEach((el: any) => {
        el.name = decodeURI(el.name);
      });
      this.doc = data;
    });
  }
  submitForm() {
    let prep:any = JSON.parse(JSON.stringify(this.doc));
    (prep.member ?? []).forEach((el: any) => {
      el.name = encodeURI(el.name);
    });
    if (this.$route.params.id != undefined) {
      axios.patch(`${address}/insurance/${this.doc._id}`, prep).then((response) => {
        alert("แก้ไขข้อมูลแล้ว");
        this.$router.push("/insurance/j/"+this.doc.job);
      });
    } else {
      axios
        .post(`${address}/insurance`, {
          ...prep,
        })
        .then((response) => {
          alert("บันทึกข้อมูลแล้ว");
          this.$router.push("/insurance/j/"+this.doc.job);
        });
    }
  }
}
