
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class List extends Vue {
  store = useStore();

  docs: Array<any> = [];
  show: number = 50;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  filter1: any = {};
  sort: any = {
    month: -1,
    year: -1,
  };
  mounted() {
    this.fetchData();
  }

  updatePaginate(i: number) {
    this.page = i;
    // console.log(this.page);
    this.fetchData();
  }
  getpaginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }
  clearPage() {
    if (this.show == 999999999) {
      this.page = 0;
      this.fetchData();
    }
  }
  deleteItem(id: string) {
    var c = confirm("คุณแน่ใจหรือว่าต้องการลบรายการนี้");
    if (c) {
      axios.delete(`${address}/store/${id}`).then((response) => {
        this.fetchData();
      });
    }
  }
  fetchData() {
    axios.get(`${address}/code-by-field/name/Launching Girders`).then(response=>{
      this.docs = response.data.member
    })
  }
}
