
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import address from "@/variable";
import { useStore } from "vuex";
import * as _ from "lodash";
import { DateTime } from "luxon";

@Options({
  components: {},
})
export default class List extends Vue {
  store = useStore();
  doc: any = {};
  docs: Array<any> = [];
  show: number = 50;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  max: any = {};
  sort: any = {
    order: 1,
  };

  deleteMany() {
    let promises: Array<Promise<any>> = [];
    this.docs.forEach((el) => {
      promises.push(axios.delete(`${address}/progress/${el._id}`));
    });
    Promise.all(promises).then((responses) => {
      console.log(responses);
      this.fetchData();
    });
  }

  async uploadExcel(ev: any) {
    const Excel = require("exceljs/dist/es5/exceljs.browser");
    let self = this;
    let file = ev.target.files[0];
    const workbook = new Excel.Workbook();
    let book = await workbook.xlsx.load(file);
    let sheet = book.getWorksheet("import");
    let arr: Array<any> = [];
    sheet.eachRow(function(row: any, rowNumber: any) {
      if (rowNumber > 1 && row.getCell(1).value != null) {
        let span = row.getCell("A").value;
        let side = row.getCell("B").value;
        let foundIndex = arr.findIndex(
          (el) => el.span == span && el.side == side
        );
        if (foundIndex == -1) {
          arr.push({
            lg: self.$route.params.lg,
            span: row.getCell("A").value,
            side: row.getCell("B").value,
            job: row.getCell("C").value,
            startDate: row.getCell("D").value,
            endDate: row.getCell("E").value,
            timeCalendar: row.getCell("F").value,
            timePerform: row.getCell("G").value,
            activities: [
              {
                code1:
                  row.getCell("H").value == null
                    ? ""
                    : encodeURIComponent(row.getCell("H").value),
                code2:
                  row.getCell("I").value == null
                    ? ""
                    : encodeURIComponent(row.getCell("I").value),
                period: row.getCell("J").value,
                remarks: row.getCell("K").value,
              },
            ],
            segment: row.getCell("L").value == "n" ? true : false,
            install: row.getCell("M").value == "n" ? true : false,
            grout: row.getCell("N").value == "n" ? true : false,
            segmentPayment: row.getCell("O").value == "n" ? true : false,
            bearingPayment: row.getCell("P").value == "n" ? true : false,
          });
        } else {
          console.log(foundIndex, arr[foundIndex]);
          arr[foundIndex].activities.push({
            code1:
              row.getCell("H").value == null
                ? ""
                : encodeURIComponent(row.getCell("H").value),
            code2:
              row.getCell("I").value == null
                ? ""
                : encodeURIComponent(row.getCell("I").value),
            period: row.getCell("J").value,
            remarks: row.getCell("K").value,
          });
        }
      }
    });
    let promises: Array<Promise<any>> = [];
    arr.forEach((el) => {
      promises.push(axios.post(`${address}/progress`, el));
    });
    Promise.all(promises).then((responses) => {
      console.log(responses);
      this.fetchData();
    });
  }

  publicPath = "";
  deleteItem(id: string) {
    var c = confirm("คุณแน่ใจหรือว่าต้องการลบรายการนี้");
    if (c) {
      axios.delete(`${address}/progress/${id}`).then((response) => {
        this.fetchData();
      });
    }
  }
  mounted() {
    this.fetchData();
    this.publicPath = process.env.BASE_URL;
  }
  clearPage() {
    if (this.show == 999999999) {
      this.page = 0;
      this.fetchData();
    }
  }

  getMinDate(arr: Array<string>): string {
    try {
      let result = arr.map((el) => new Date(el).getTime());
      let min = Math.min(...result);
      return new Date(min).toISOString();
    } catch (error) {
      return new Date().toISOString();
    }
  }

  getMaxDate(arr: Array<string>): string {
    try {
      let result = arr.map((el) => new Date(el).getTime());
      let max = Math.max(...result);
      return new Date(max).toISOString();
    } catch (error) {
      return new Date().toISOString();
    }
  }
  fetchData() {
    this.docs = [];
    let sort: any = this.sort;
    let query = {
      search: {
        lg: decodeURIComponent(this.$route.params.lg as string),
      },
      page: this.page,
      limit: this.show,
      skip: 0,
      sort,
    };
    axios.post(`${address}/progresses-paginate`, query).then((response) => {
      console.log(response);
      this.docs = response.data.docs;
      this.max = response.data.max;
      if (Object.keys(this.sort).length === 0) {
        this.docs = _.sortBy(this.docs, (o) => {
          try {
            let split = o.span.split(" - ");
            let left = split[0] ?? 9999;
            let no = left.replace("P", "");
            return parseInt(no);
          } catch (error) {
            console.log(error);
            return 9999;
          }
        });
      }
      let minDate = this.getMinDate(this.docs.map((doc) => doc.startDate));
      let maxDate = this.getMaxDate(this.docs.map((doc) => doc.endDate));
      let schedules: Array<any> = this.docs.map((doc, i) => {
        let progress = 0;
        progress += doc.segment ? 20 : 0;
        progress += doc.install ? 20 : 0;
        progress += doc.grout ? 20 : 0;
        progress += doc.segmentPayment ? 20 : 0;
        progress += doc.bearingPayment ? 20 : 0;
        return {
          ...doc,
          code: doc.span,
          name: doc.side ?? "",
          progress,
          start: doc.startDate,
          finish: doc.endDate,
        };
      });

      this.doc = {
        startDate: DateTime.fromISO(minDate).toISO(),
        endDate: DateTime.fromISO(maxDate)
          .plus({ year: 1 })
          .toISO(),
        schedules,
        keydates: [],
      };
      this.pages = response.data.pages;
      this.total = response.data.total;
    });
  }
}
