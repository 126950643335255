import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "rounded shadow bg-white pb-3" }
const _hoisted_2 = { class: "table main-table" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "text-start" }
const _hoisted_5 = { class: "ps-3" }
const _hoisted_6 = { class: "text-start" }
const _hoisted_7 = { class: "text-end" }
const _hoisted_8 = { class: "text-end" }
const _hoisted_9 = { class: "text-end" }
const _hoisted_10 = { class: "text-end" }
const _hoisted_11 = { class: "text-end blue" }
const _hoisted_12 = { class: "text-end" }
const _hoisted_13 = { class: "fw-bold" }
const _hoisted_14 = { class: "text-end" }
const _hoisted_15 = { class: "text-end" }
const _hoisted_16 = { class: "text-end" }
const _hoisted_17 = { class: "text-end" }
const _hoisted_18 = { class: "text-end blue" }
const _hoisted_19 = { class: "text-end" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { class: "text-start" }
const _hoisted_22 = { class: "ps-3" }
const _hoisted_23 = { class: "text-start" }
const _hoisted_24 = { class: "text-end" }
const _hoisted_25 = { class: "text-end" }
const _hoisted_26 = { class: "text-end" }
const _hoisted_27 = { class: "text-end" }
const _hoisted_28 = { class: "text-end blue" }
const _hoisted_29 = { class: "text-end" }
const _hoisted_30 = { class: "fw-bold" }
const _hoisted_31 = { class: "text-end" }
const _hoisted_32 = { class: "text-end" }
const _hoisted_33 = { class: "text-end" }
const _hoisted_34 = { class: "text-end" }
const _hoisted_35 = { class: "text-end blue" }
const _hoisted_36 = { class: "text-end" }
const _hoisted_37 = { class: "fw-bold" }
const _hoisted_38 = { class: "text-end" }
const _hoisted_39 = { class: "text-end" }
const _hoisted_40 = { class: "text-end" }
const _hoisted_41 = { class: "text-end" }
const _hoisted_42 = { class: "text-end blue" }
const _hoisted_43 = { class: "text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[13] || (_cache[13] = _createElementVNode("h2", null, "Summary Launching Girder Cost", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("table", _hoisted_2, [
        _createElementVNode("thead", null, [
          _cache[4] || (_cache[4] = _createElementVNode("tr", null, [
            _createElementVNode("th", { rowspan: "2" }, [
              _createElementVNode("span", null, "LG No.")
            ]),
            _createElementVNode("th", { rowspan: "2" }, [
              _createElementVNode("span", null, "CODE")
            ]),
            _createElementVNode("th", { colspan: "2" }, [
              _createElementVNode("span", null, "COST (MB)")
            ]),
            _createElementVNode("th", { colspan: "4" }, [
              _createElementVNode("span", null, "DEPRE. (MB)")
            ])
          ], -1)),
          _createElementVNode("tr", null, [
            _cache[0] || (_cache[0] = _createElementVNode("th", null, "BOOKED", -1)),
            _cache[1] || (_cache[1] = _createElementVNode("th", null, "SCRAP", -1)),
            _createElementVNode("th", null, "UP TO Y" + _toDisplayString(_ctx.year-1), 1),
            _createElementVNode("th", null, "Y" + _toDisplayString(_ctx.year), 1),
            _cache[2] || (_cache[2] = _createElementVNode("th", null, [
              _createElementVNode("span", { class: "blue" }, "ACC")
            ], -1)),
            _cache[3] || (_cache[3] = _createElementVNode("th", null, "REMAIN", -1))
          ])
        ]),
        _createElementVNode("tbody", null, [
          _cache[11] || (_cache[11] = _createElementVNode("tr", { class: "fw-bold" }, [
            _createElementVNode("td", { class: "text-start" }, "Launching Girder")
          ], -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.doc.member, (item, i) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              ((item.name??'').search('LG')!=-1)
                ? (_openBlock(), _createElementBlock("tr", _hoisted_3, [
                    _createElementVNode("td", _hoisted_4, [
                      _createElementVNode("span", _hoisted_5, _toDisplayString(decodeURIComponent(item.text1)), 1)
                    ]),
                    _createElementVNode("td", _hoisted_6, _toDisplayString(decodeURIComponent(item.name)), 1),
                    _createElementVNode("td", _hoisted_7, _toDisplayString(_ctx.getBooked(item.text2).formatMB()), 1),
                    _createElementVNode("td", _hoisted_8, _toDisplayString(_ctx.getScrap(item.text2).formatMB()), 1),
                    _createElementVNode("td", _hoisted_9, _toDisplayString(_ctx.getFromCodePrevious(item.name).formatMB()), 1),
                    _createElementVNode("td", _hoisted_10, _toDisplayString(_ctx.getFromCodeCurrent(item.name).formatMB()), 1),
                    _createElementVNode("td", _hoisted_11, _toDisplayString((_ctx.getFromCodeCurrent(item.name)+_ctx.getFromCodePrevious(item.name)).formatMB()), 1),
                    _createElementVNode("td", _hoisted_12, [
                      _createElementVNode("span", null, _toDisplayString((_ctx.getBooked(item.text2) - _ctx.getScrap(item.text2) - _ctx.getFromCodeCurrent(item.name) - _ctx.getFromCodePrevious(item.name)).formatMB()), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          }), 256)),
          _createElementVNode("tr", _hoisted_13, [
            _cache[5] || (_cache[5] = _createElementVNode("td", null, "Sub-Total", -1)),
            _cache[6] || (_cache[6] = _createElementVNode("td", null, null, -1)),
            _createElementVNode("td", _hoisted_14, _toDisplayString(_ctx.getTotalBooked(_ctx.doc.member.filter(el=>(el.name??"").search('LG')!=-1).map(el=>el.text2)).formatMB()), 1),
            _createElementVNode("td", _hoisted_15, _toDisplayString(_ctx.getTotalScrap(_ctx.doc.member.filter(el=>(el.name??"").search('LG')!=-1).map(el=>el.text2)).formatMB()), 1),
            _createElementVNode("td", _hoisted_16, _toDisplayString(_ctx.getTotalCodePrevious(_ctx.doc.member.filter(el=>(el.name??"").search('LG')!=-1).map(el=>el.name)).formatMB()), 1),
            _createElementVNode("td", _hoisted_17, _toDisplayString(_ctx.getTotalCodeCurrent(_ctx.doc.member.filter(el=>(el.name??"").search('LG')!=-1).map(el=>el.name)).formatMB()), 1),
            _createElementVNode("td", _hoisted_18, _toDisplayString((_ctx.getTotalCodePrevious(_ctx.doc.member.filter(el=>(el.name??"").search('LG')!=-1).map(el=>el.name))+_ctx.getTotalCodeCurrent(_ctx.doc.member.filter(el=>(el.name??"").search('LG')!=-1).map(el=>el.name))).formatMB()), 1),
            _createElementVNode("td", _hoisted_19, _toDisplayString((_ctx.getTotalBooked(_ctx.doc.member.filter(el=>(el.name??"").search('LG')!=-1).map(el=>el.text2)) - _ctx.getTotalScrap(_ctx.doc.member.filter(el=>(el.name??"").search('LG')!=-1).map(el=>el.text2)) - _ctx.getTotalCodePrevious(_ctx.doc.member.filter(el=>(el.name??"").search('LG')!=-1).map(el=>el.name)) - _ctx.getTotalCodeCurrent(_ctx.doc.member.filter(el=>(el.name??"").search('LG')!=-1).map(el=>el.name))).formatMB()), 1)
          ]),
          _cache[12] || (_cache[12] = _createElementVNode("tr", { class: "fw-bold" }, [
            _createElementVNode("td", { class: "text-start" }, "Lowering System")
          ], -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.doc.member, (item, i) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              ((item.name??'').search('LG')==-1)
                ? (_openBlock(), _createElementBlock("tr", _hoisted_20, [
                    _createElementVNode("td", _hoisted_21, [
                      _createElementVNode("span", _hoisted_22, _toDisplayString(decodeURIComponent(item.name)), 1)
                    ]),
                    _createElementVNode("td", _hoisted_23, _toDisplayString(decodeURIComponent(item.name)), 1),
                    _createElementVNode("td", _hoisted_24, _toDisplayString(_ctx.getBooked(item.text2).formatMB()), 1),
                    _createElementVNode("td", _hoisted_25, _toDisplayString(_ctx.getScrap(item.text2).formatMB()), 1),
                    _createElementVNode("td", _hoisted_26, _toDisplayString(_ctx.getFromCodePrevious(item.name).formatMB()), 1),
                    _createElementVNode("td", _hoisted_27, _toDisplayString(_ctx.getFromCodeCurrent(item.name).formatMB()), 1),
                    _createElementVNode("td", _hoisted_28, _toDisplayString((_ctx.getFromCodeCurrent(item.name)+_ctx.getFromCodePrevious(item.name)).formatMB()), 1),
                    _createElementVNode("td", _hoisted_29, _toDisplayString((((_ctx.getBooked(item.text2) - (_ctx.getFromCodeCurrent(item.name)+_ctx.getFromCodePrevious(item.name)) - _ctx.getScrap(item.text2)) )).formatMB()), 1)
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          }), 256)),
          _createElementVNode("tr", _hoisted_30, [
            _cache[7] || (_cache[7] = _createElementVNode("td", null, "Sub-Total", -1)),
            _cache[8] || (_cache[8] = _createElementVNode("td", null, null, -1)),
            _createElementVNode("td", _hoisted_31, _toDisplayString(_ctx.getTotalBooked(_ctx.doc.member.filter(el=>(el.name??"").search('LG')==-1).map(el=>el.text2)).formatMB()), 1),
            _createElementVNode("td", _hoisted_32, _toDisplayString(_ctx.getTotalScrap(_ctx.doc.member.filter(el=>(el.name??"").search('LG')==-1).map(el=>el.text2)).formatMB()), 1),
            _createElementVNode("td", _hoisted_33, _toDisplayString(_ctx.getTotalCodePrevious(_ctx.doc.member.filter(el=>(el.name??"").search('LG')==-1).map(el=>el.name)).formatMB()), 1),
            _createElementVNode("td", _hoisted_34, _toDisplayString(_ctx.getTotalCodeCurrent(_ctx.doc.member.filter(el=>(el.name??"").search('LG')==-1).map(el=>el.name)).formatMB()), 1),
            _createElementVNode("td", _hoisted_35, _toDisplayString((_ctx.getTotalCodePrevious(_ctx.doc.member.filter(el=>(el.name??"").search('LG')==-1).map(el=>el.name))+_ctx.getTotalCodeCurrent(_ctx.doc.member.filter(el=>(el.name??"").search('LG')==-1).map(el=>el.name))).formatMB()), 1),
            _createElementVNode("td", _hoisted_36, _toDisplayString((_ctx.getTotalBooked(_ctx.doc.member.filter(el=>(el.name??"").search('LG')==-1).map(el=>el.text2)) - _ctx.getTotalScrap(_ctx.doc.member.filter(el=>(el.name??"").search('LG')==-1).map(el=>el.text2)) - _ctx.getTotalCodePrevious(_ctx.doc.member.filter(el=>(el.name??"").search('LG')==-1).map(el=>el.name)) - _ctx.getTotalCodeCurrent(_ctx.doc.member.filter(el=>(el.name??"").search('LG')==-1).map(el=>el.name))).formatMB()), 1)
          ]),
          _createElementVNode("tr", _hoisted_37, [
            _cache[9] || (_cache[9] = _createElementVNode("td", { class: "text-start" }, "TOTAL", -1)),
            _cache[10] || (_cache[10] = _createElementVNode("td", null, null, -1)),
            _createElementVNode("td", _hoisted_38, _toDisplayString(_ctx.getTotalBooked(_ctx.doc.member.map(el=>el.text2)).formatMB()), 1),
            _createElementVNode("td", _hoisted_39, _toDisplayString(_ctx.getTotalScrap(_ctx.doc.member.map(el=>el.text2)).formatMB()), 1),
            _createElementVNode("td", _hoisted_40, _toDisplayString(_ctx.getTotalCodePrevious(_ctx.doc.member.map(el=>el.name)).formatMB()), 1),
            _createElementVNode("td", _hoisted_41, _toDisplayString(_ctx.getTotalCodeCurrent(_ctx.doc.member.map(el=>el.name)).formatMB()), 1),
            _createElementVNode("td", _hoisted_42, _toDisplayString((_ctx.getTotalCodePrevious(_ctx.doc.member.map(el=>el.name))+_ctx.getTotalCodeCurrent(_ctx.doc.member.map(el=>el.name))).formatMB()), 1),
            _createElementVNode("td", _hoisted_43, _toDisplayString((_ctx.getTotalBooked(_ctx.doc.member.map(el=>el.text2)) - _ctx.getTotalScrap(_ctx.doc.member.map(el=>el.text2)) - _ctx.getTotalCodePrevious(_ctx.doc.member.map(el=>el.name)) - _ctx.getTotalCodeCurrent(_ctx.doc.member.map(el=>el.name))).formatMB()), 1)
          ])
        ])
      ])
    ])
  ], 64))
}