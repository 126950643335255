
import { Options, Vue } from "vue-class-component";
import address, { filePath } from "@/variable";
import axios from "axios";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class Form extends Vue {
  doc: any = {};
  lgs: Array<any> = []
  stores: Array<any> = []
  store = useStore();
  filePath = filePath;
  units: Array<any> = [];

  mounted() {
    this.fetchSetting();
    if (this.$route.params.id != undefined) this.fetchData();
    axios.get(`${address}/code-by-field/name/Launching Girders`).then((response) => {
      this.lgs = response.data.member;
    });
    this.fetchStore()
  }
  fetchStore() {
    axios.get(`${address}/stores/`).then((response) => {
      this.stores = response.data;
    });
  }
  fetchData() {
    axios.get(`${address}/mainmat/${this.$route.params.id}`).then((response) => {
      this.doc = response.data;
    });
  }
  fetchSetting() {
    axios.get(`${address}/code-by-field/name/Store Unit`).then((response) => {
      console.log('response',response)
      this.units = response.data.member;
    });
  }
  submitForm() {
    if (this.$route.params.id != undefined) {
      axios
        .patch(`${address}/mainmat/${this.doc._id}`, this.doc)
        .then((response) => {
          alert("แก้ไขข้อมูลแล้ว");
          this.$router.push("/main-mat");
        });
    } else {
      axios
        .post(`${address}/mainmat`, {
          ...this.doc,
        })
        .then((response) => {
          alert("บันทึกข้อมูลแล้ว");
          this.$router.push("/main-mat");
        });
    }
  }
}
