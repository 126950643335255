import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logoITD.png'


const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "form-signin" }
const _hoisted_3 = { class: "form-floating" }
const _hoisted_4 = { class: "form-floating" }
const _hoisted_5 = { class: "checkbox mb-3 mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", _hoisted_2, [
      _createElementVNode("form", {
        class: "text-center",
        onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.loginOnline()), ["prevent"]))
      }, [
        _cache[7] || (_cache[7] = _createElementVNode("img", {
          class: "mb-4",
          src: _imports_0,
          alt: "",
          width: "72",
          height: "72"
        }, null, -1)),
        _cache[8] || (_cache[8] = _createElementVNode("h1", { class: "h3 mb-3 fw-normal" }, " Please sign in", -1)),
        _createElementVNode("div", _hoisted_3, [
          _withDirectives(_createElementVNode("input", {
            class: "form-control",
            id: "floatingInput",
            type: "text",
            placeholder: "username",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event))
          }, null, 512), [
            [_vModelText, _ctx.username]
          ]),
          _cache[4] || (_cache[4] = _createElementVNode("label", { for: "floatingInput" }, "Email address", -1))
        ]),
        _createElementVNode("div", _hoisted_4, [
          _withDirectives(_createElementVNode("input", {
            class: "form-control",
            id: "floatingPassword",
            type: "password",
            placeholder: "Password",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event))
          }, null, 512), [
            [_vModelText, _ctx.password]
          ]),
          _cache[5] || (_cache[5] = _createElementVNode("label", { for: "floatingPassword" }, "Password", -1))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", null, [
            _withDirectives(_createElementVNode("input", {
              type: "checkbox",
              value: "remember-me",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.remember) = $event))
            }, null, 512), [
              [_vModelCheckbox, _ctx.remember]
            ]),
            _cache[6] || (_cache[6] = _createTextVNode(" Remember Me"))
          ])
        ]),
        _cache[9] || (_cache[9] = _createElementVNode("button", {
          class: "w-100 btn btn-lg btn-primary text-white",
          type: "submit"
        }, "Sign in", -1))
      ], 32)
    ])
  ]))
}