import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, resolveComponent as _resolveComponent, createBlock as _createBlock, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container rounded shadow bg-white px-4 pb-5 pt-3" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "text-muted" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-sm-3 mb-2" }
const _hoisted_7 = { class: "col-sm-3 mb-2" }
const _hoisted_8 = { class: "col-sm-3 mb-2" }
const _hoisted_9 = { class: "col-sm-3 mb-2" }
const _hoisted_10 = { class: "col-sm-4 mb-2" }
const _hoisted_11 = ["value"]
const _hoisted_12 = { class: "col-sm-4 mt-1" }
const _hoisted_13 = { class: "col-sm-4 mt-1" }
const _hoisted_14 = { class: "col-sm-6 mb-2" }
const _hoisted_15 = { class: "col-sm-6 mb-2" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "col-sm-6 mb-2 mt-3" }
const _hoisted_18 = { class: "form-check form-switch" }
const _hoisted_19 = { class: "form-check form-switch" }
const _hoisted_20 = { class: "form-check form-switch" }
const _hoisted_21 = { class: "form-check form-switch" }
const _hoisted_22 = { class: "form-check form-switch" }
const _hoisted_23 = { class: "col-sm-12 mb-2 mt-3" }
const _hoisted_24 = { class: "d-flex justify-content-between" }
const _hoisted_25 = { class: "table" }
const _hoisted_26 = { ref: "items" }
const _hoisted_27 = { class: "col-sm-12" }
const _hoisted_28 = ["onUpdate:modelValue"]
const _hoisted_29 = ["value"]
const _hoisted_30 = { class: "col-sm-12" }
const _hoisted_31 = ["onUpdate:modelValue"]
const _hoisted_32 = ["value"]
const _hoisted_33 = ["onUpdate:modelValue"]
const _hoisted_34 = ["onUpdate:modelValue"]
const _hoisted_35 = { class: "text-center" }
const _hoisted_36 = ["onClick"]
const _hoisted_37 = { class: "row g-3 d-flex justify-content-center" }
const _hoisted_38 = { class: "col-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JMCalendar = _resolveComponent("JMCalendar")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.params.id == undefined)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, "Add Form"))
      : (_openBlock(), _createElementBlock("h2", _hoisted_3, "Edit Form")),
    _createElementVNode("h3", _hoisted_4, _toDisplayString(decodeURIComponent(_ctx.$route.params.lg)) + ": J." + _toDisplayString(_ctx.job.j) + " - " + _toDisplayString(_ctx.job.name), 1),
    _createElementVNode("form", {
      class: "row needs-validation",
      onSubmit: _cache[15] || (_cache[15] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _cache[16] || (_cache[16] = _createElementVNode("label", { class: "form-label" }, "Order", -1)),
          _withDirectives(_createElementVNode("input", {
            class: "form-control",
            type: "number",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.doc.order) = $event))
          }, null, 512), [
            [_vModelText, _ctx.doc.order]
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[18] || (_cache[18] = _createElementVNode("label", { class: "form-label" }, "Span No.", -1)),
          _withDirectives(_createElementVNode("select", {
            class: "form-select",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.doc.span) = $event))
          }, [
            _cache[17] || (_cache[17] = _createElementVNode("option", null, "Assembly", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.spans, (item) => {
              return (_openBlock(), _createElementBlock("option", null, _toDisplayString(item), 1))
            }), 256))
          ], 512), [
            [_vModelSelect, _ctx.doc.span]
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _cache[19] || (_cache[19] = _createElementVNode("label", { class: "form-label" }, "Side", -1)),
          _withDirectives(_createElementVNode("select", {
            class: "form-select",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.doc.side) = $event))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sides, (item) => {
              return (_openBlock(), _createElementBlock("option", null, _toDisplayString(item), 1))
            }), 256))
          ], 512), [
            [_vModelSelect, _ctx.doc.side]
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _cache[20] || (_cache[20] = _createElementVNode("label", { class: "form-label" }, "LG", -1)),
          _withDirectives(_createElementVNode("input", {
            class: "form-control",
            type: "text",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.doc.lg) = $event)),
            disabled: ""
          }, null, 512), [
            [_vModelText, _ctx.doc.lg]
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _cache[21] || (_cache[21] = _createElementVNode("label", { class: "form-label" }, "Job", -1)),
          _withDirectives(_createElementVNode("select", {
            class: "form-select",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.doc.job) = $event))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.jobs, (item) => {
              return (_openBlock(), _createElementBlock("option", {
                value: item.j
              }, "J." + _toDisplayString(item.j) + " : " + _toDisplayString(item.name), 9, _hoisted_11))
            }), 256))
          ], 512), [
            [_vModelSelect, _ctx.doc.job]
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          (_openBlock(), _createBlock(_component_JMCalendar, {
            label: "Start",
            modelValue: _ctx.doc.startDate,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.doc.startDate) = $event)),
            key: _ctx.doc.startDate,
            onChange: _ctx.updateCycleTime
          }, null, 8, ["modelValue", "onChange"]))
        ]),
        _createElementVNode("div", _hoisted_13, [
          (_openBlock(), _createBlock(_component_JMCalendar, {
            label: "Finish",
            modelValue: _ctx.doc.endDate,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.doc.endDate) = $event)),
            key: _ctx.doc.endDate,
            onChange: _ctx.updateCycleTime
          }, null, 8, ["modelValue", "onChange"]))
        ]),
        _createElementVNode("div", _hoisted_14, [
          _cache[22] || (_cache[22] = _createElementVNode("label", { class: "form-label" }, "Cycle Time Calendar(day)", -1)),
          _withDirectives(_createElementVNode("input", {
            class: "form-control",
            type: "number",
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.doc.timeCalendar) = $event)),
            step: "0.01"
          }, null, 512), [
            [
              _vModelText,
              _ctx.doc.timeCalendar,
              void 0,
              { number: true }
            ]
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _cache[23] || (_cache[23] = _createElementVNode("label", { class: "form-label" }, "Cycle Time Perform(day)", -1)),
          _withDirectives(_createElementVNode("input", {
            class: "form-control",
            type: "number",
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.doc.timePerform) = $event)),
            step: "0.01"
          }, null, 512), [
            [
              _vModelText,
              _ctx.doc.timePerform,
              void 0,
              { number: true }
            ]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _withDirectives(_createElementVNode("input", {
              class: "form-check-input",
              id: "statusSegment",
              type: "checkbox",
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.doc.segment) = $event))
            }, null, 512), [
              [_vModelCheckbox, _ctx.doc.segment]
            ]),
            _cache[24] || (_cache[24] = _createElementVNode("label", {
              class: "form-check-label",
              for: "statusSegment"
            }, "Segment", -1))
          ]),
          _createElementVNode("div", _hoisted_19, [
            _withDirectives(_createElementVNode("input", {
              class: "form-check-input",
              id: "statusBearingInstall",
              type: "checkbox",
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.doc.install) = $event))
            }, null, 512), [
              [_vModelCheckbox, _ctx.doc.install]
            ]),
            _cache[25] || (_cache[25] = _createElementVNode("label", {
              class: "form-check-label",
              for: "statusBearingInstall"
            }, "Bearing Install", -1))
          ]),
          _createElementVNode("div", _hoisted_20, [
            _withDirectives(_createElementVNode("input", {
              class: "form-check-input",
              id: "statusBearingGrouting",
              type: "checkbox",
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.doc.grout) = $event))
            }, null, 512), [
              [_vModelCheckbox, _ctx.doc.grout]
            ]),
            _cache[26] || (_cache[26] = _createElementVNode("label", {
              class: "form-check-label",
              for: "statusBearingGrouting"
            }, "Bearing Grout", -1))
          ]),
          _createElementVNode("div", _hoisted_21, [
            _withDirectives(_createElementVNode("input", {
              class: "form-check-input",
              id: "statusSegmentPayment",
              type: "checkbox",
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.doc.segmentPayment) = $event))
            }, null, 512), [
              [_vModelCheckbox, _ctx.doc.segmentPayment]
            ]),
            _cache[27] || (_cache[27] = _createElementVNode("label", {
              class: "form-check-label",
              for: "statusSegmentPayment"
            }, "Segment Payment", -1))
          ]),
          _createElementVNode("div", _hoisted_22, [
            _withDirectives(_createElementVNode("input", {
              class: "form-check-input",
              id: "statusBearingPayment",
              type: "checkbox",
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.doc.bearingPayment) = $event))
            }, null, 512), [
              [_vModelCheckbox, _ctx.doc.bearingPayment]
            ]),
            _cache[28] || (_cache[28] = _createElementVNode("label", {
              class: "form-check-label",
              for: "statusBearingPayment"
            }, "Bearing Payment", -1))
          ])
        ]),
        _cache[31] || (_cache[31] = _createElementVNode("hr", null, null, -1)),
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("div", _hoisted_24, [
            _cache[29] || (_cache[29] = _createElementVNode("h4", null, "Activity", -1)),
            _createElementVNode("a", {
              class: "btn btn-secondary text-white mb-3",
              id: "add-item",
              onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.pushActivity()))
            }, "เพิ่ม ")
          ]),
          _createElementVNode("table", _hoisted_25, [
            _cache[30] || (_cache[30] = _createElementVNode("thead", { class: "table-light" }, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, "Code 1"),
                _createElementVNode("th", null, "Code 2"),
                _createElementVNode("th", null, "Period (day)"),
                _createElementVNode("th", null, "Remarks")
              ])
            ], -1)),
            _createElementVNode("tbody", _hoisted_26, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.doc.activities, (item, i) => {
                return (_openBlock(), _createElementBlock("tr", null, [
                  _createElementVNode("td", null, [
                    _createElementVNode("div", _hoisted_27, [
                      _withDirectives(_createElementVNode("select", {
                        class: "form-select",
                        "onUpdate:modelValue": ($event: any) => ((item.code1) = $event)
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.code1, (item) => {
                          return (_openBlock(), _createElementBlock("option", {
                            value: item.name
                          }, _toDisplayString(decodeURIComponent(item.name)), 9, _hoisted_29))
                        }), 256))
                      ], 8, _hoisted_28), [
                        [_vModelSelect, item.code1]
                      ])
                    ])
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("div", _hoisted_30, [
                      _withDirectives(_createElementVNode("select", {
                        class: "form-select",
                        "onUpdate:modelValue": ($event: any) => ((item.code2) = $event)
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.code2, (item) => {
                          return (_openBlock(), _createElementBlock("option", {
                            value: item.name
                          }, _toDisplayString(decodeURIComponent(item.name)), 9, _hoisted_32))
                        }), 256))
                      ], 8, _hoisted_31), [
                        [_vModelSelect, item.code2]
                      ])
                    ])
                  ]),
                  _createElementVNode("td", null, [
                    _withDirectives(_createElementVNode("input", {
                      class: "form-control",
                      type: "number",
                      "onUpdate:modelValue": ($event: any) => ((item.period) = $event),
                      step: ".01"
                    }, null, 8, _hoisted_33), [
                      [
                        _vModelText,
                        item.period,
                        void 0,
                        { number: true }
                      ]
                    ])
                  ]),
                  _createElementVNode("td", null, [
                    _withDirectives(_createElementVNode("input", {
                      class: "form-control",
                      type: "text",
                      "onUpdate:modelValue": ($event: any) => ((item.remarks) = $event)
                    }, null, 8, _hoisted_34), [
                      [_vModelText, item.remarks]
                    ])
                  ]),
                  _createElementVNode("td", _hoisted_35, [
                    _createElementVNode("i", {
                      class: "fal fa-trash text-danger",
                      onClick: ($event: any) => (_ctx.doc.activities.splice(i,1))
                    }, null, 8, _hoisted_36)
                  ])
                ]))
              }), 256))
            ], 512)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_37, [
        _createElementVNode("div", _hoisted_38, [
          _createVNode(_component_router_link, {
            class: "w-100 btn btn-secondary text-white btn-lg mt-4",
            to: "/progress"
          }, {
            default: _withCtx(() => _cache[32] || (_cache[32] = [
              _createTextVNode("ย้อนกลับ")
            ])),
            _: 1
          })
        ]),
        _cache[33] || (_cache[33] = _createElementVNode("div", { class: "col-5" }, [
          _createElementVNode("button", {
            class: "w-100 btn btn-primary btn-lg mt-4 text-white",
            id: "save",
            type: "submit"
          }, "บันทึก")
        ], -1))
      ])
    ], 32)
  ]))
}