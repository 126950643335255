
import { Options, Vue } from 'vue-class-component';
import axios from "axios";
import address  from "@/variable";
import { useStore } from 'vuex';

@Options({
  components: {
  },
})
export default class List extends Vue {
  store = useStore()
  filter1:any = {}
  docs: Array<any> = [];
  show: number = 50;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  sort:any = {
    month:-1,
    year:-1,
  }
  sortKey:string = "name"
  sortDir:number = 1
  async uploadExcel(ev: any) {
    const Excel = require("exceljs/dist/es5/exceljs.browser");
    let self = this;
    let file = ev.target.files[0];
    const workbook = new Excel.Workbook();
    let book = await workbook.xlsx.load(file);
    let sheet = book.getWorksheet("import");
    let arr: Array<any> = [];
    sheet.eachRow(function(row: any, rowNumber: any) {
      if (rowNumber > 1 && row.getCell(1).value != null) {
        arr.push({
          name: row.getCell("A").value,
          j: row.getCell("B").value,
          category: row.getCell("C").value,
          totalLength: row.getCell("D").value,
          totalCost: row.getCell("E").value
        });
      }
    });
    let promises: Array<Promise<any>> = [];
    arr.forEach((el) => {
      promises.push(axios.post(`${address}/job`, el));
    });
    Promise.all(promises).then((responses) => {
      console.log(responses);
      this.fetchData();
    });
  }
  mounted(){
    this.fetchData()
  }
  updatePaginate(i: number) {
    this.page = i;
    // console.log(this.page);
    this.fetchData();
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }
  clearPage() {
    if (this.show == 999999999) {
      this.page = 0;
      this.fetchData();
    }
  }
  fetchData(){
    this.docs = []
    let sort:any = this.sort
    let query = {
      search:{...this.filter1},
      page:this.page,
      limit:this.show,
      skip:0,
      sort
    }
    axios.post(`${address}/jobs-paginate`,query).then((response) => {
      this.docs = response.data.docs;
      this.pages = response.data.pages;
      this.total = response.data.total;
    });
  }
  deleteData(item: any) {
    var r = confirm("คุณต้องการลบข้อมูลใช่หรือไม่ ?");
    if (r == true) {
      axios.delete(`${address}/job/${item._id}`).then((response) => {
        this.fetchData();
      });
    } else {
      // txt = "You pressed Cancel!";
    }
  }
}
