import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    isLogin: false,
    remember: {},
    accessLevel:0,
    access:[],
    jobs: [],

    code1Site:[],
    code1Yard:[],
    code2:[],
    code3:[],

    accessories: [],
    tools: [],
    equipments: [],
    fuels: [],
    technicals: [],
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    position: [
      "Mechanic",
      "Eletrician",
      "Driver",
      "Security",
      "Housekeeping",
      "Yard Maintenance",
      "Store",
      "Administrator",
      "Plant&Equipment",
      "Engineering"
    ],
    diameters: [
      `20 mm`,
      "22 mm",
      "32 mm",
      "35 mm",
      "36 mm",
      "38 mm",
      "40 mm",
      "44 mm",
      "48 mm",
      "50 mm",
      "53 mm",
      "55 mm",
      "58 mm",
      "60 mm",
      "65 mm",
      "66 mm",
      "70 mm",
      "80 mm",
      "88 mm",
      "90 mm",
      "95 mm",
      "100 mm",
      "120 mm",
      "150 mm",
      `½"`,
      `¾"`,
      `1"`,
      `1 ¼"`,
      `1 ½"`,
      `1 ¾"`,
      `1 ⅜"`,
      `2"`,
      `2 ¼`,
      `2 ½"`,
      `2 ¾"`,
      `2 ⅛"`,
      `3"`,
    ],
  },
  mutations: {
    login: (state, value) => {
      state.isLogin = true
      state.remember = value
      state.accessLevel = value.accessLevel
      state.access = value.access
    },
    logout: (state, value) => {
      state.isLogin = false
      state.remember = {}
      state.accessLevel = -1
    },
    setJobs: (state, value) => {
      state.jobs = value
    },
    setAccessories: (state, value) => {
      state.accessories = value
    },
    setTools: (state, value) => {
      state.tools = value
    },
    setEquipments: (state, value) => {
      state.equipments = value
    },
    setFuels: (state, value) => {
      state.fuels = value
    },
    setTechnicals: (state, value) => {
      state.technicals = value
    },

    setCode1Site: (state, value) => {
      state.code1Site = value
    },
    setCode1Yard: (state, value) => {
      state.code1Yard = value
    },
    setCode2: (state, value) => {
      state.code2 = value
    },
    setCode3: (state, value) => {
      state.code3 = value
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      key: "itd201_20220303",
      paths: ["isLogin", "remember", "accessLevel", "access"]
    })
  ]
})
