import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "container rounded shadow bg-white px-4 pb-5 pt-3" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "col-sm-4 mb-2" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "col-sm-4 mb-2" }
const _hoisted_7 = { class: "col-sm-4 mb-2" }
const _hoisted_8 = { class: "col-sm-4 mb-2" }
const _hoisted_9 = { class: "col-sm-4 mb-2" }
const _hoisted_10 = { class: "col-sm-4 mb-2" }
const _hoisted_11 = { class: "col-sm-4 mb-2" }
const _hoisted_12 = { class: "col-sm-4 mb-2" }
const _hoisted_13 = ["value"]
const _hoisted_14 = { class: "col-sm-4 mb-2" }
const _hoisted_15 = { class: "col-sm-6 mb-2" }
const _hoisted_16 = { class: "col-sm-6 mb-2" }
const _hoisted_17 = { class: "col-sm-6 mb-2" }
const _hoisted_18 = { class: "col-sm-6 mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JMCalendar = _resolveComponent("JMCalendar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.params.id == undefined)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, "Add Form"))
      : (_openBlock(), _createElementBlock("h2", _hoisted_3, "Edit Form")),
    _createElementVNode("form", {
      class: "row needs-validation",
      onSubmit: _cache[13] || (_cache[13] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_4, [
        _cache[14] || (_cache[14] = _createElementVNode("label", { class: "form-label" }, "Room", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.doc.room) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rooms, (item) => {
            return (_openBlock(), _createElementBlock("option", {
              value: item.name
            }, _toDisplayString(decodeURI(item.name)), 9, _hoisted_5))
          }), 256))
        ], 512), [
          [_vModelSelect, _ctx.doc.room]
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[15] || (_cache[15] = _createElementVNode("label", { class: "form-label" }, "Code", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.doc.code) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.code]
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _cache[16] || (_cache[16] = _createElementVNode("label", { class: "form-label" }, "ITD ID", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.doc.employeeCode) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.employeeCode]
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[17] || (_cache[17] = _createElementVNode("label", { class: "form-label" }, "Prefix", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.doc.prefix) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.prefix]
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _cache[18] || (_cache[18] = _createElementVNode("label", { class: "form-label" }, "First Name", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.doc.firstName) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.firstName]
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _cache[19] || (_cache[19] = _createElementVNode("label", { class: "form-label" }, "Last Name", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.doc.lastName) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.lastName]
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _cache[20] || (_cache[20] = _createElementVNode("label", { class: "form-label" }, "Position", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.doc.position) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.position]
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _cache[21] || (_cache[21] = _createElementVNode("label", { class: "form-label" }, "Job", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.doc.job) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.jobs, (item) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (item.category!='Other Job Site')
                ? (_openBlock(), _createElementBlock("option", {
                    key: 0,
                    value: item.j
                  }, "J." + _toDisplayString(item.j) + " : " + _toDisplayString(item.name), 9, _hoisted_13))
                : _createCommentVNode("", true)
            ], 64))
          }), 256))
        ], 512), [
          [_vModelSelect, _ctx.doc.job]
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _cache[23] || (_cache[23] = _createElementVNode("label", { class: "form-label" }, "Check In Type", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.doc.checkInType) = $event))
        }, _cache[22] || (_cache[22] = [
          _createElementVNode("option", null, "ภายในหน่วยงาน", -1),
          _createElementVNode("option", null, "ภายนอกหน่วยงาน", -1)
        ]), 512), [
          [_vModelSelect, _ctx.doc.checkInType]
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_JMCalendar, {
          label: "Check In Date",
          modelValue: _ctx.doc.checkInDate,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.doc.checkInDate) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_JMCalendar, {
          label: "Check Out Date",
          modelValue: _ctx.doc.checkOutDate,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.doc.checkOutDate) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _cache[24] || (_cache[24] = _createElementVNode("label", { class: "form-label" }, "Room Mate First Name", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.doc.roomMatefirstName) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.roomMatefirstName]
        ])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _cache[25] || (_cache[25] = _createElementVNode("label", { class: "form-label" }, "Room Mate Last Name", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.doc.roomMatelastName) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.roomMatelastName]
        ])
      ]),
      _cache[26] || (_cache[26] = _createStaticVNode("<div class=\"row g-3 d-flex justify-content-center\"><div class=\"col-5\"><button class=\"w-100 btn btn-secondary text-white btn-lg mt-4\">ย้อนกลับ</button></div><div class=\"col-5\"><button class=\"w-100 btn btn-primary btn-lg mt-4 text-white\" id=\"save\" type=\"submit\">บันทึก</button></div></div>", 1))
    ], 32)
  ]))
}