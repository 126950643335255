
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import address from "@/variable";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class List extends Vue {
  store = useStore();
  docs: Array<any> = [];
  total: number = 0;
  show: number = 50;
  page: number = 1;
  pages: number = 0;
  sort: any = {
    month: -1,
    year: -1,
  };
  sortKey: string = "name";
  sortDir: number = 1;
  publicPath = "";
  search1: string = "";
  search2: string = "";
  search3: string = "";
  search4: string = "";
  filter1 = {};

  deleteMany() {
    let promises: Array<Promise<any>> = [];
    this.docs.forEach((el) => {
      promises.push(axios.delete(`${address}/pin/${el._id}`));
    });
    Promise.all(promises).then((responses) => {
      console.log(responses);
      this.fetchData();
    });
  }

  async uploadExcel(ev: any) {
    const Excel = require("exceljs/dist/es5/exceljs.browser");
    let self = this;
    let file = ev.target.files[0];
    const workbook = new Excel.Workbook();
    let book = await workbook.xlsx.load(file);
    let sheet = book.getWorksheet("import");
    let arr: Array<any> = [];
    sheet.eachRow(function(row: any, rowNumber: any) {
      if (rowNumber > 1 && row.getCell(1).value != null) {
        arr.push({
          job: row.getCell("A").value,
          lg: encodeURIComponent(row.getCell("B").value).replace("null", ""),
          description: row.getCell("C").value,
          diameter: row.getCell("D").value,
          detail: row.getCell("E").value,
          status: row.getCell("F").value,
          qty: row.getCell("G").value,
          typeOfSling: row.getCell("H").value,
          diameterHook: row.getCell("I").value,
          remark: row.getCell("J").text,
        });
      }
    });
    let promises: Array<Promise<any>> = [];
    arr.forEach((el) => {
      // console.log(el)
      promises.push(axios.post(`${address}/pin`, el));
    });
    Promise.all(promises).then((responses) => {
      console.log(responses);
      this.fetchData();
    });
  }
  mounted() {
    this.fetchData();
    this.publicPath = process.env.BASE_URL;
  }
  updatePaginate(i: number) {
    this.page = i;
    // console.log(this.page);
    this.fetchData();
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }
  clearPage() {
    if (this.show == 999999999) {
      this.page = 0;
      this.fetchData();
    }
  }
  deleteItem(id: string) {
    var c = confirm("คุณแน่ใจหรือว่าต้องการลบรายการนี้");
    if (c) {
      axios.delete(`${address}/pin/${id}`).then((response) => {
        this.fetchData();
      });
    }
  }
  fetchData() {
    this.docs = [];
    let sort: any = this.sort;
    let query = {
      search: {
        $and: [
          this.filter1,
          {
            lg:
              this.search4 != ""
                ? { $regex: encodeURIComponent(this.search4), $options: "-i" }
                : undefined,
          },
          {
            description:
              this.search3 != ""
                ? { $regex: this.search3, $options: "-i" }
                : undefined,
          },
          {
            diameter:
              this.search2 != ""
                ? { $regex: this.search2, $options: "-i" }
                : undefined,
          },
          {
            detail:
              this.search1 != ""
                ? { $regex: this.search1, $options: "-i" }
                : undefined,
          },
        ],
      },
      page: this.page,
      limit: this.show,
      skip: 0,
      sort,
    };
    axios.post(`${address}/pins-paginate`, query).then((response) => {
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
    });
  }
}
