import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container rounded shadow bg-white px-4 pb-5 pt-3" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "col-sm-6 mb-2" }
const _hoisted_5 = { class: "col-sm-6 mb-2" }
const _hoisted_6 = { class: "col-sm-12 mb-2" }
const _hoisted_7 = { class: "table" }
const _hoisted_8 = ["onUpdate:modelValue"]
const _hoisted_9 = ["onUpdate:modelValue"]
const _hoisted_10 = ["onUpdate:modelValue"]
const _hoisted_11 = ["onUpdate:modelValue"]
const _hoisted_12 = ["onUpdate:modelValue"]
const _hoisted_13 = { class: "text-center" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "form-check form-switch" }
const _hoisted_16 = { class: "row g-3 d-flex justify-content-center" }
const _hoisted_17 = { class: "col-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_draggable = _resolveComponent("draggable")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.params.id == undefined)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, "Add Form"))
      : (_openBlock(), _createElementBlock("h2", _hoisted_3, "Edit Form")),
    _createElementVNode("form", {
      class: "row needs-validation",
      onSubmit: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_4, [
        _cache[8] || (_cache[8] = _createElementVNode("label", { class: "form-label" }, "Name", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.doc.name) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.name]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[9] || (_cache[9] = _createElementVNode("label", { class: "form-label" }, "Category", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          type: "text",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.doc.category) = $event))
        }, null, 512), [
          [_vModelText, _ctx.doc.category]
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[12] || (_cache[12] = _createElementVNode("label", { class: "form-label" }, "Member", -1)),
        _createElementVNode("table", _hoisted_7, [
          _cache[11] || (_cache[11] = _createElementVNode("thead", { class: "table-light" }, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", null, "Sort"),
              _createElementVNode("th", null, "Name"),
              _createElementVNode("th", null, "Notes"),
              _createElementVNode("th", { width: "250" }, "Job"),
              _createElementVNode("th", { width: "250" }, "Job Name"),
              _createElementVNode("th", null, "Notes 2 (Optional)"),
              _createElementVNode("th", { width: "80" }, "Actions")
            ])
          ], -1)),
          _createVNode(_component_draggable, {
            modelValue: _ctx.doc.member,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.doc.member) = $event)),
            tag: "tbody",
            handle: ".handle",
            "item-key": "code"
          }, {
            item: _withCtx(({element, index}) => [
              _createElementVNode("tr", null, [
                _cache[10] || (_cache[10] = _createElementVNode("td", { class: "text-center" }, [
                  _createElementVNode("i", { class: "fal fa-bars handle" })
                ], -1)),
                _createElementVNode("td", null, [
                  _withDirectives(_createElementVNode("input", {
                    class: "form-control",
                    type: "text",
                    "onUpdate:modelValue": ($event: any) => ((element.name) = $event)
                  }, null, 8, _hoisted_8), [
                    [_vModelText, element.name]
                  ])
                ]),
                _createElementVNode("td", null, [
                  _withDirectives(_createElementVNode("input", {
                    class: "form-control",
                    type: "text",
                    "onUpdate:modelValue": ($event: any) => ((element.text1) = $event)
                  }, null, 8, _hoisted_9), [
                    [_vModelText, element.text1]
                  ])
                ]),
                _createElementVNode("td", null, [
                  _withDirectives(_createElementVNode("input", {
                    class: "form-control",
                    type: "text",
                    "onUpdate:modelValue": ($event: any) => ((element.text2) = $event)
                  }, null, 8, _hoisted_10), [
                    [_vModelText, element.text2]
                  ])
                ]),
                _createElementVNode("td", null, [
                  _withDirectives(_createElementVNode("input", {
                    class: "form-control",
                    type: "text",
                    "onUpdate:modelValue": ($event: any) => ((element.text3) = $event)
                  }, null, 8, _hoisted_11), [
                    [_vModelText, element.text3]
                  ])
                ]),
                _createElementVNode("td", null, [
                  _withDirectives(_createElementVNode("input", {
                    class: "form-control",
                    type: "text",
                    "onUpdate:modelValue": ($event: any) => ((element.text4) = $event)
                  }, null, 8, _hoisted_12), [
                    [_vModelText, element.text4]
                  ])
                ]),
                _createElementVNode("td", _hoisted_13, [
                  _createElementVNode("i", {
                    class: "fal fa-trash text-danger",
                    onClick: ($event: any) => (_ctx.doc.member.splice(index,1))
                  }, null, 8, _hoisted_14)
                ])
              ])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _createElementVNode("a", {
          class: "btn btn-secondary text-white mb-3",
          id: "add-item",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.pushMember()))
        }, "เพิ่ม ")
      ]),
      _createElementVNode("div", _hoisted_15, [
        _withDirectives(_createElementVNode("input", {
          class: "form-check-input",
          id: "helper",
          type: "checkbox",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.helper) = $event))
        }, null, 512), [
          [_vModelCheckbox, _ctx.helper]
        ]),
        _cache[13] || (_cache[13] = _createElementVNode("label", {
          class: "form-check-label",
          for: "helper"
        }, "แสดงตัวช่วยกรอกจาก excel", -1))
      ]),
      (_ctx.helper)
        ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
            key: 0,
            class: "form-control",
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.paste) = $event)),
            onInput: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.addMember && _ctx.addMember(...args)))
          }, null, 544)), [
            [_vModelText, _ctx.paste]
          ])
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          _createVNode(_component_router_link, {
            class: "w-100 btn btn-secondary text-white btn-lg mt-4",
            to: "/code"
          }, {
            default: _withCtx(() => _cache[14] || (_cache[14] = [
              _createTextVNode("ย้อนกลับ")
            ])),
            _: 1
          })
        ]),
        _cache[15] || (_cache[15] = _createElementVNode("div", { class: "col-5" }, [
          _createElementVNode("button", {
            class: "w-100 btn btn-primary btn-lg mt-4 text-white",
            id: "save",
            type: "submit"
          }, "บันทึก")
        ], -1))
      ])
    ], 32)
  ]))
}